import { Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "../common/service/erpnextapi.service";

@Component({
  selector: "app-vehicles-history",
  templateUrl: "./vehicles-history.component.html",
  styleUrls: ["./vehicles-history.component.css"],
})
export class VehiclesHistoryComponent implements OnInit {
  p1: any = 1;
  customer: any;
  searchString:any=''
  insUplaodvehicleNo: any;
  vehicleList: any = [];

  constructor(private _erpService: ErpnextapiService) {}

  ngOnInit(): void {
    this.customer = localStorage.getItem("customer");
    // this.customer = "Logesh";
    this.getVehiclesList() ;
  }

  attachInsuraceBtn(vehNo: any) {
    if (vehNo) {
      this.insUplaodvehicleNo = vehNo;
    }
  }

  insuranceFileAttach(event: any) {
    let file = event.target.files[0];
    if (file) {
      // console.log(file);
    }
  }

  getVehiclesList() {
    let payload = {
      customer: this.customer,
      doctype: "Vehicle Registration Certificate",
    };
    this._erpService.getAllLinkedVehicles(payload).subscribe((res: any) => {
      // console.log("getVehiclesList-->", res);
      if(res.errorCode=='0' && res.data.message && typeof(res.data.message)!='string'){
        let response=res.data.message;

        this.vehicleList=response
      }
    });
  }
}
