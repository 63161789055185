import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  username;
  todayDate;
  error: boolean = false;
  errorMessage: String = "";
  dataLoading: boolean = false;
  private querySubscription;
  savedChanges: boolean = false;
  showErrorMsg = false;
  userInfo;
  navmenu = false;
  navbarList = [
    { navbarName: "products" },
    { navbarName: "dashboard" },
    { navbarName: "driverCreditAssign" },
  ];

  hideDashboardEmails: any = [
    "mrmtravels304@gmail.com",
    "chinnathambiac@yahoo.com",
    "test@demo.in",
    "prashant.bhatia@intrcity.com",
    "admin@universaltravels.in",
  ];
  hideDashboardUsername: any = ["RAMAKRISHNAN MAHENDRAN"];
  hideDashboard: boolean;

  super_customer: any = ["IndoRailBus Travels Pvt Ltd"];
  sub_customer: any = ["SRI SHA VOYAGES", "Universal Bus Services"];
  hide_opt_sub_dashboards: boolean;
  hideOptDashboards: any = ["muthujayamtravels8@gmail.com"];
  hideOptDashboardsCustomerName: any = ["MJT Travels"];
  optCustomDashboard: boolean;

  showTyreDashbordes: boolean = false;
  notifications: any = 0;

  constructor(
    private router: Router,
    private _erpNextApiService: ErpnextapiService
  ) {}

  ngOnInit() {
    // localStorage.getItem('noOfNotifications')


    let email = localStorage.getItem("email");
    let customer = localStorage.getItem("customer");

    if (customer == "Universal Bus Services") {
      this.showTyreDashbordes = true;
      let notifications = new Observable((observer) => {
        setInterval(() =>
          observer.next(localStorage.getItem("noOfNotifications"))
        ),
          3000;
      });
      notifications.subscribe((res) => {
        this.notifications = res;
        //  console.log(this.notifications);
      });
    } else {
      this.showTyreDashbordes = false;
    }

    // for super customers(Intrcity dashbord)
    if (this.super_customer.includes(customer)) {
      this.hide_opt_sub_dashboards = true;
    } else {
      this.hide_opt_sub_dashboards = false;
    }

    // old dashboards
    if (
      this.hideDashboardEmails.includes(email) ||
      this.sub_customer.includes(customer)
    ) {
      this.hideDashboard = true;
    } else {
      this.hideDashboard = false;
    }

    //under opt dashboards

    if (
      this.hideOptDashboards.includes(email) ||
      this.hideOptDashboardsCustomerName.includes(customer)
    ) {
      this.optCustomDashboard = true;
      this.hideDashboard = true;
    } else {
      this.optCustomDashboard = false;
    }

    let date = new Date();
    const month = date.toLocaleString("default", { month: "long" });
    this.todayDate = month + " " + new Date().getDate();
    this.username = localStorage.getItem("userName");
    //console.log(this.username);
    this.getUserDetails();
  }

  checkModuleAuth(authRole) {
    let authModules = JSON.parse(localStorage.getItem("authModule"));
    if (authModules != undefined || authModules != null) {
      return authModules.includes(authRole);
    }
    //  console.log(authRole);
  }

  getUserDetails() {
    this.dataLoading = true;
    this.querySubscription = this._erpNextApiService.getUserDetails().subscribe(
      (res) => {
        if (res["errorCode"] == "0") {
          this.error = false;
          this.errorMessage = "";
          this.dataLoading = false;
          this.userInfo = res["data"]["data"];
        } else {
          this.error = true;
          this.errorMessage = res["errorMessage"];
          this.dataLoading = false;
        }
      },
      (error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.dataLoading = false;
      },
      () => {
        this.dataLoading = false;
      }
    );
  }

  showmenuItems() {
    document.getElementById("mySidebar").style.display = "block";
  }
  selectedNavbar(navbar) {
    this.router.navigate([`/${navbar}`]);
    this.navmenu = true;
    document.getElementById("mySidebar").style.display = "none";
  }
  openNav() {
    document.getElementById("mySidenav").style.display = "block";
  }

  closeNav() {
    document.getElementById("mySidenav").style.display = "none";
  }

  logout() {
    this.router.navigate(["/login"]);
    localStorage.removeItem("userName");
    localStorage.removeItem("apiKey");
    localStorage.removeItem("apiSecret");
    localStorage.removeItem("cartItemsList");
    localStorage.removeItem("cartItemsWithCredit");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("email");
    localStorage.removeItem("cartItems4Invoice");
    localStorage.removeItem("bpclSfaAccessToken");
    localStorage.removeItem("userRole");
    localStorage.removeItem("authModule");
    localStorage.removeItem("FAID");
    localStorage.removeItem("FCID");
    localStorage.removeItem("itemGroup");
    localStorage.removeItem("marchantid");
    localStorage.removeItem("walletid");
    localStorage.removeItem("requsetSource");
    localStorage.clear();
  }
}

// Login permission ==> products Permission () ==>
