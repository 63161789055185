import { Component, OnChanges, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'Lnder-Web';

  routerLink;


  constructor(private router: Router) {


  }

  ngOnInit() {


    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          this.routerLink = this.router.url;

          this.routerLink = this.routerLink.split("?")[0];
          console.log("router-Link-->", this.routerLink);
          if (this.routerLink === "/login" || this.routerLink === "/upload-wtsapp-vehicle-document" || this.routerLink=="/driverGeoLocationForm") {
            // if (this.routerLink === "/login" || this.routerLink === "/upload-wtsapp-vehicle-document" ) {
            this.routerLink = "/login";

          }
          if(this.routerLink != "/login" && this.routerLink != "/upload-wtsapp-vehicle-document"){

          this.routerLink=this.routerLink.split("/")[1];
          console.log("router-Link-->", this.routerLink);
          // if( this.routerLink=="unprocessed_not_moving_vehicles"){
          //   this.routerLink = "/login";
          // }
          if( this.routerLink=="submitreport"){
            this.routerLink = "/login";
          }


          }
        }
      }
    )



  }

}
