import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpnextapiService } from '../common/service/erpnextapi.service';

@Component({
  selector: 'app-insurance-details',
  templateUrl: './insurance-details.component.html',
  styleUrls: ['./insurance-details.component.css']
})
export class InsuranceDetailsComponent implements OnInit {

  searchString: any = '';
  IOCL_List: any;
  mobile_no: number;
  insurance_opt_id: any;
  insurance_token: any;
  insExpPolData: any = [];
  filterdInsExpPolData: any = [];
  p1: number = 1;
  p2: number = 1;
  isInsExpData: boolean = false;
  noInsExpData: boolean = false;
  // no_of_exp_pol: number = 0;
  dataLoading: boolean = true;
  New_Vehicle_Insurance_Request_data: any = [];
  uploadQuatationHistory: any = [];
  isUploadTxn = true;
  isNoUploadData: boolean = true;
  newReqFilesNames: any = [];
  // tab:any;
  no_of_exp_pol: any = []
  customer:any;

  constructor(private route: Router, private _erpNextApiService: ErpnextapiService, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {





    // this.getInsuranceToken();
    this.customer=localStorage.getItem("customer");


    this.get_New_Vehicle_Insurance_Request_data();

    this.insurance_token = localStorage.getItem('InsuranceToken');
    this.insurance_opt_id = localStorage.getItem('insoptid');
    this.mobile_no = parseInt(localStorage.getItem('mobile_no'));
    // console.log(this.insurance_opt_id);

    if (this.insurance_opt_id && this.insurance_token) {
      this.insuranceExpStatus();
    }
    else{
      this.dataLoading=false;
      this.noInsExpData = true;
      this.isInsExpData=true;
    }
    this.getLoggedInUser();


  }



  getLoggedInUser() {
    this._erpNextApiService.getUserDetails().subscribe((res) => {
      //console.log(res);
      if (res["data"] === '') {
        this.logout();
      }
    })
  }

  
  logout() {
    this.route.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }

  uploadDoc() {

    this.route.navigate(['/upload-vehicle-document'], {
      queryParams: {
        p: this.mobile_no
      },
      relativeTo: this.activatedRoute,
    });



  }





  insuranceExpStatus() {
    // console.log(this.insurance_opt_id);
    // console.log(this.insurance_token);
    // console.log(this.insurance_token);
    
    


    let customparams = `?custid=${this.insurance_opt_id}&token=${this.insurance_token}`;
    // console.log(this.insurance_opt_id);


    this._erpNextApiService.getInsuExpPol(customparams).subscribe(res => {
      // console.log("insurance exp pol responses--->", res);

      if (res['errorCode'] == 0 && res['data']) {



        this.insExpPolData = res['data']["insexpdet"];


        // console.log(this.insExpPolData.length);

        for (let i = 0; i < this.insExpPolData.length;) {

          let polExpDays = this.insExpPolData[i]['polexpires'];
          this.insExpPolData[i]['inspolno'] = this.insExpPolData[i]['inspolno'].replace(/:/g, "");

          if (polExpDays > 0 && polExpDays <= 30) {
            console.log(i);
            this.no_of_exp_pol.push(this.insExpPolData[i]);
            this.insExpPolData.splice(i, 1);
            i = i;
          }
          else if (polExpDays<0){
            this.insExpPolData[i]['polexpires']="Expired"
          }
          else {
            i = i + 1
          }
        }
        if (this.no_of_exp_pol.length > 0) {
          this.insExpPolData = [...this.no_of_exp_pol, ... this.insExpPolData];
        }



        // console.log(this.insExpPolData);
        // console.log(this.insExpPolData.length);
        





        this.isInsExpData = true;
        this.dataLoading = false
        if (this.insExpPolData.length == 0) {
          this.noInsExpData = true;
          this.isInsExpData = true;
          this.dataLoading = false;


        }

      }
      else {
        this.dataLoading = false;
        this.noInsExpData = true;
        this.isInsExpData=true;
      }

    })
  
  




  }

  get_New_Vehicle_Insurance_Request_data() {
    let files_names = [];
    this._erpNextApiService.getVehInsData().subscribe(res => {
      // console.log(" get_New_Vehicle_Insurance_Request_data--->", res);
      if (res['errorCode'] == '0' && res['data']['data']) {
        this.New_Vehicle_Insurance_Request_data = res['data']['data'].reverse();
        // console.log(this.New_Vehicle_Insurance_Request_data);

        for (let name of this.New_Vehicle_Insurance_Request_data) {
          // console.log(name.name);

          // this.respectiveUserQutHistory(name.name);


          files_names.push(name.name)



        }
        setTimeout(() => {
          this.isUploadTxn = false;
          this.isNoUploadData = false;
        }, 3000)



      }
      else {
        this.isUploadTxn = false;
        this.isNoUploadData = false;
      }
      // this.newReqFilesNames = files_names.slice(files_names.length - 10,);

      // console.log( files_names);
      this.respectiveUserQutHistory();


    })
  }

  respectiveUserQutHistory() {

    let params=`?filters=[["customer_name","=","${this.customer}"],["phone_no","=","${this.mobile_no}"]]&fields=%5B"vehicle_no","customer_name","phone_no","existing_insurance_policy","vehicle_rc"%5D`

    // for (let i = 0; i < this.newReqFilesNames.length; i++) {


      this._erpNextApiService.getEachVehichleReqDetails(params).subscribe(res => {
        // console.log("uploadQuatationHistory-->",res);

        if (res['errorCode'] == '0' && res['data']['data']) {

          this.uploadQuatationHistory=res['data']['data'];
        }
        
        // console.log("each req response--->", res['data']['data']);
        // let data = res['data']['data'];

        // if (data.phone_no == this.mobile_no) {
        //   // console.log(data.name);


        //   // console.log(name);
        //   this.uploadQuatationHistory.push(data);


        //   // console.log(this.uploadQuatationHistory);
        // }

      })


    // }
    // console.log(this.uploadQuatationHistory);
    // console.log(name);




  }

  onlyExpiringploicies(expiring){

    
  }






}
