import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexDataLabels,
  ApexTitleSubtitle,
  ApexStroke,
  ApexGrid,
  ApexYAxis,
  ApexAnnotations,
  ApexResponsive
} from "ng-apexcharts";
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';


export type dailyChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
  responsive: ApexResponsive;
};
export type weeklyChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
};
export type monthlyChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  annotations: ApexAnnotations;
};


@Component({
  selector: 'app-vehicle-expenses-graph',
  templateUrl: './vehicle-expenses-graph.component.html',
  styleUrls: ['./vehicle-expenses-graph.component.css']
})
export class VehicleExpensesGraphComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public dailyChartOptions: Partial<dailyChartOptions>;
  public weeklyChartOptions: Partial<weeklyChartOptions>;
  public monthlyChartOptions: Partial<monthlyChartOptions>;



  dailyLoading: boolean = true;
  weeklyLoading: boolean = true;
  monthlyLoading: boolean = true;
  dataLoading: boolean = true;


  isGraphOpen: boolean = false;
  isTableOpen: boolean = true;
  searchString: any = ''
  result: any;
  customDateForm: any;
  daily_vehicleNo_ExpensesForm: any;
  weekly_vehicleNo_ExpensesForm: any;
  monthly_vehicleNo_ExpensesForm: any;



  fa_id: any;
  marchent_id: any;
  wallet_id: any;

  dailyVehicleExpensesData: any;
  dailyVehicleExpensesDatalength: number = 0;
  dailyTransaction: number = 0;
  dailyPendingCashback: number = 0;
  notFoundDailyVehicleNo: boolean = false;


  weeklyVehicleExpensesData: any;
  weeklyVehicleExpensesDatalength: number = 0;
  weeklyTransaction: number = 0;
  weeklyPendingCashback: number = 0;
  notFoundWeeklyVehicleNo: boolean = false;

  monthlyVehicleExpensesData: any;
  monthlyVehicleExpensesDatalength: number = 0;
  monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;
  notFoundMonthlyVehicleNo: boolean = false;

  customVehicleExpensesData: any;
  customVehicleExpensesDatalength: number = 0;
  customTransaction: number = 0;
  customPendingCashback: number = 0;

  isDailyVehExpTxn: boolean = true;
  isMonthlyVehExpTxn: boolean = true;
  isWeeklyVehExpTxn: boolean = true;
  isCustomVehExpTxn: boolean;


  noDailyVehExpData: boolean = false;
  noWeeklyVehExpData: boolean = false;
  noMonthlyVehExpData: boolean = false;
  noCustomVehExpData: boolean = false;
  todayDate: any;


  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;


  dailyExpVehicleNos: any = [];
  dailyExpTotalExp: any = [];
  dailySeriesData: any = [];
  daily_Xaxis_vehicleNos: any = [];
  dailyMin: number = 0;
  dailyMax: number = 0;
  dailyUserOptVehilceNo: any;
  dailyTotalAvgsum: number = 0;
  sum_of_all_daily_vehexp: number = 0;

  weeklyExpVehicleNos: any = [];
  weeklyExpTotalExp: any = [];
  weeklySeriesData: any = [];
  weekly_Xaxis_vehicleNos: any = [];
  weeklyMin: number = 0;
  weeklyMax: number = 0;

  weeklyUserOptVehilceNo: any;
  weeklyTotalAvgsum: number = 0
  sum_of_all_weekly_vehexp: number = 0;


  monthlyExpVehicleNos: any = [];
  monthlyExpTotalExp: any = [];
  monthlySeriesData: any = [];
  monthly_Xaxis_vehicleNos: any = [];
  monthlyMin: number = 0;
  monthlyMax: number = 0;
  monUserOptVehilceNo: any;
  monthlyTotalAvgsum: number = 0
  sum_of_all_monthly_vehexp: number = 0;


  dailyExpgraph: boolean = true;
  weeklyExpgraph: boolean;
  monthlyExpgraph: boolean;

  isDailyReset: boolean = false;
  isWeeklyReset: boolean = false;
  isMonthlyReset: boolean = false;

  todaysDate: any;
  weeklyDate: any;
  monthlyDate: any;
  search_vehicleNo_ExpensesForm: any;

  No_of_vehicles: number = 0;




  constructor(private fb: FormBuilder, private _erpService: ErpnextapiService, private route: ActivatedRoute, private router: Router) {
    this.customDateForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],

    })

    this.daily_vehicleNo_ExpensesForm = fb.group({
      vehicleNo: ['', Validators.required],
      // minvalue: ['', Validators.required],

    })

    this.weekly_vehicleNo_ExpensesForm = fb.group({
      vehicleNo: ['', Validators.required],
      // minvalue: ['', Validators.required],

    })

    this.monthly_vehicleNo_ExpensesForm = fb.group({
      vehicleNo: ['', Validators.required],
      // minvalue: ['', Validators.required],

    });

    this.search_vehicleNo_ExpensesForm = fb.group({
      vehicleNo: ['', Validators.required],
      // minvalue: ['', Validators.required],

    })


  };



  ngOnInit(): void {



    this.fa_id = localStorage.getItem("FAID");
    this.marchent_id = localStorage.getItem('marchantid');
    this.wallet_id = localStorage.getItem('walletid');

    // this.result = [{ v: 1234, f: 100, fas: 200, tyre: 300, spares: 400, ins: 600 },
    // { v: 5678, f: 100, fas: 200, tyre: 300, spares: 400, ins: 600 },
    // { v: 86736, f: 100, fas: 200, tyre: 300, spares: 400, ins: 600 },
    // { v: 83838, f: 100, fas: 200, tyre: 300, spares: 400, ins: 600 }
    // ]




    var today = new Date();
    let currentyear = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();
    let day = today.getDate().toString();


    if ((month.length == 2 && day.length == 2)) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate = `${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate = `${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate = `${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate = `${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    // console.log(this.todayDate);


    this.dailyVehiclesExpenses();
    this.weeklyVehiclesExpenses();
    this.monthlyVehiclesExpenses();
    this.getLoggedInUser();











  }



  getLoggedInUser() {
    this._erpService.getUserDetails().subscribe((res) => {
      //console.log(res);
      if (res["data"] === '') {
        this.logout();
      }
    })
  }
  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }

  searchVehNo() {
    let vehNo = this.search_vehicleNo_ExpensesForm.value.vehicleNo;
    console.log(vehNo);

    this.searchString = vehNo
  }




  logic() {


    this.daily_Xaxis_vehicleNos = this.dailyExpVehicleNos;
    this.weekly_Xaxis_vehicleNos = this.weeklyExpVehicleNos;
    this.monthly_Xaxis_vehicleNos = this.monthlyExpVehicleNos;



    this.dailySeriesData = this.dailyExpTotalExp;
    this.weeklySeriesData = this.weeklyExpTotalExp;
    this.monthlySeriesData = this.monthlyExpTotalExp;

    this.isGraphOpen = true;
    this.isTableOpen = false;
    // console.log(this.dailyExpTotalExp);
    this.dailyMax = Math.max(...this.dailyExpTotalExp);
    // this.dailyMax=this.sum_of_all_daily_vehexp;
    // console.log("dailyMax-->", this.dailyMax);
    this.weeklyMax = Math.max(...this.weeklyExpTotalExp);
    // console.log("weeklyMax-->", this.weeklyMax);
    this.monthlyMax = Math.max(...this.monthlyExpTotalExp);
    // console.log("monthlyMax-->", this.monthlyMax);


    // console.log("sum_of_all_daily_vehexp-->", this.sum_of_all_daily_vehexp);
    // console.log("sum_of_all_weekly_vehexp-->", this.sum_of_all_weekly_vehexp);
    // console.log("sum_of_all_monthly_vehexp-->", this.sum_of_all_monthly_vehexp);
    // console.log("No of daily vehicle expenses--->", this.dailyExpTotalExp.length);
    // console.log("No of weekly vehicle expenses--->", this.weeklyExpTotalExp.length);
    // console.log("No of monthly vehicle expenses--->", this.monthlyExpTotalExp.length);




    this.dailyTotalAvgsum = Math.round(this.sum_of_all_daily_vehexp / this.dailyExpTotalExp.length);

    // console.log('dailyTotalAvgsum-->', this.dailyTotalAvgsum);

    this.weeklyTotalAvgsum = Math.round(this.sum_of_all_weekly_vehexp / this.weeklyExpTotalExp.length);
    // console.log("weeklyTotalAvgsum--->", this.weeklyTotalAvgsum);

    this.monthlyTotalAvgsum = Math.round(this.sum_of_all_monthly_vehexp / this.monthlyExpTotalExp.length);
    // console.log("monthlyTotalAvgsum--->", this.monthlyTotalAvgsum);



    this.dailyExpGraph();
    // this.weeklyExpGraph();
    // this.monthlyExpGraph();





  }



  //************************ Daily Vehicle Expenses ************************//
  dailyVehiclesExpenses() {


    let body = {
      "merchantid": this.marchent_id,
      "walletid": this.wallet_id,
      "account_id": this.fa_id,
      "from_date": `${this.todayDate}`,
      "to_date": `${this.todayDate}`

    }

    // console.log(" dailyVehiclesExpenses body---->", body);

    this._erpService.getVehiclesExpenses(body).subscribe(res => {
      // console.log(" dailyVehiclesExpenses()--->", res);

      this.No_of_vehicles = res['data']['total_vehicle'];

      if (res['errorCode'] == '0' && res['data'].message && res['data'].message != "No Record Found") {

        this.dailyVehicleExpensesData = res['data'].message;
        // this.dailyVehicleExpensesData=[];
        this.dailyVehicleExpensesDatalength = this.dailyVehicleExpensesData.length;
        // this.No_of_vehicles = res['data']['total_vehicle'];
        if (this.dailyVehicleExpensesDatalength > 0) {
          for (let data of this.dailyVehicleExpensesData) {
            if (data.vehicle_no != "Not Specified") {
              this.dailyExpVehicleNos.push(data.vehicle_no);
              let bpcl = parseInt(data.bpcl_total);
              let fastag = parseInt(data.fastag_total);
              // console.log(typeof (fastag));

              let total_expense = (bpcl + fastag);
              // this.dailyMax+=total_expense;
              this.sum_of_all_daily_vehexp += total_expense;
              this.dailyExpTotalExp.push(total_expense);
            }
          }
          this.logic();

        }


        if (res['data'].message.length == 0 || this.dailyVehicleExpensesDatalength == 0) {
          this.isDailyVehExpTxn = false;
          this.noDailyVehExpData = true;
          this.dailyLoading = false;
          this.dataLoading = false;
        }
        else {
          this.isDailyVehExpTxn = false;
          this.dailyLoading = false;
          this.dataLoading = false;
        }


      }
      else {
        this.isDailyVehExpTxn = false;
        this.noDailyVehExpData = true;
        this.dailyLoading = false;
        this.dataLoading = false;

      }

    })
    // console.log(this.dailyMax);









  }

  //***********************************************************************//



  //************************ Weekly Vehicle Expenses ************************//
  weeklyVehiclesExpenses() {


    let sevendaysAgoDate;

    let todaydate = new Date();
    var sevendaysback = new Date(new Date().setDate(todaydate.getDate() - 6));
    // console.log("sevendaysback--->", sevendaysback);
    let currentyear = sevendaysback.getFullYear().toString();
    let month = (sevendaysback.getMonth() + 1).toString();
    let day = sevendaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`;

    }
    else if (month.length == 2 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`;
    }

    else if (month.length == 1 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`;
    }

    // console.log(sevendaysAgoDate);

    let body = {
      "merchantid": this.marchent_id,
      "walletid": this.wallet_id,
      "account_id": this.fa_id,
      "from_date":`${sevendaysAgoDate}`,
      "to_date": `${this.todayDate}`


    }
    // console.log(" weeklyVehiclesExpenses body---->", body);


    this._erpService.getVehiclesExpenses(body).subscribe(res => {
      // console.log(" weeklyVehiclesExpenses()--->", res);

      if (res['errorCode'] == '0' && res['data'].message && res['data'].message != "No Record Found") {

        this.weeklyVehicleExpensesData = res['data'].message;
        // this.weeklyVehicleExpensesData = []
        this.weeklyVehicleExpensesDatalength = this.weeklyVehicleExpensesData.length;

        if (this.weeklyVehicleExpensesDatalength > 0) {
          for (let data of this.weeklyVehicleExpensesData) {
            if (data.vehicle_no != "Not Specified") {
              this.weeklyExpVehicleNos.push(data.vehicle_no);
              let bpcl = parseInt(data.bpcl_total);
              let fastag = parseInt(data.fastag_total);
              let total_expense = (bpcl + fastag);

              // this.weeklyMax+=total_expense;
              this.sum_of_all_weekly_vehexp += total_expense;
              this.weeklyExpTotalExp.push(total_expense);

            }
          }

        }
        this.logic();




        if (res['data'].message.length == 0 || this.weeklyVehicleExpensesDatalength == 0) {
          this.isWeeklyVehExpTxn = false;
          this.noWeeklyVehExpData = true;
          this.weeklyLoading = false;
        }
        else {
          this.isWeeklyVehExpTxn = false;
          this.weeklyLoading = false;
        }

      }
      else {

        this.isWeeklyVehExpTxn = false;
        this.noWeeklyVehExpData = true;
        this.weeklyLoading = false;
        this.dataLoading = false;

      }

    })


  }

  //***********************************************************************//



  //************************ Monthly Vehicle Expenses ************************//
  monthlyVehiclesExpenses() {

    let thirtydaysAgoDate;

    let todaydate = new Date();
    var thirtydaysback = new Date(new Date().setDate(todaydate.getDate() - 31));
    // console.log("sevendaysback--->", thirtydaysback);
    let currentyear = thirtydaysback.getFullYear().toString();
    let month = (thirtydaysback.getMonth() + 1).toString();
    let day = thirtydaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`;

    }
    else if (month.length == 2 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`;

    }

    else if (month.length == 1 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`;
    }

    // console.log(thirtydaysAgoDate);



    let body = {
      "merchantid": this.marchent_id,
      "walletid": this.wallet_id,
      "account_id": this.fa_id,
      "from_date": `${thirtydaysAgoDate}`,
      "to_date": `${this.todayDate}`

    }

    // console.log(" monthlyVehiclesExpenses body---->", body);

    this._erpService.getVehiclesExpenses(body).subscribe(res => {
      // console.log(" monthlyVehiclesExpenses()--->", res);

      if (res['errorCode'] == '0' && res['data'].message && res['data'].message != "No Record Found") {

        this.monthlyVehicleExpensesData = res['data'].message;
        this.monthlyVehicleExpensesDatalength = this.monthlyVehicleExpensesData.length;


        if (this.monthlyVehicleExpensesDatalength > 0) {
          for (let data of this.monthlyVehicleExpensesData) {
            if (data.vehicle_no != "Not Specified") {
              this.monthlyExpVehicleNos.push(data.vehicle_no);
              let bpcl = parseInt(data.bpcl_total);
              let fastag = parseInt(data.fastag_total);
              let total_expense = (bpcl + fastag);
              // this.monthlyMax+=total_expense;
              this.sum_of_all_monthly_vehexp += total_expense;
              this.monthlyExpTotalExp.push(total_expense);

            }
          }

        }
        this.logic();



        if (res['data'].message.length == 0 || this.monthlyVehicleExpensesDatalength == 0) {
          this.isMonthlyVehExpTxn = false;
          this.noMonthlyVehExpData = true;
        }
        else {
          this.isMonthlyVehExpTxn = false;
        }

      }
      else {
        this.isMonthlyVehExpTxn = false;
        this.noMonthlyVehExpData = true;
        this.monthlyLoading = false;
        this.dataLoading = false;
      }

    })

  }

  //***********************************************************************//



  //************************ Custom Vehicle Expenses ************************//

  // onCustomExpenses() {
  //   this.customVehicleExpensesData = [];
  //   this.isCustomVehExpTxn = true;
  //   this.customDateForm.value.fromdate = this.customDateForm.value.fromdate.replace(/-/g, "");
  //   this.customDateForm.value.todate = this.customDateForm.value.todate.replace(/-/g, "");

  //   // console.log(this.customFastagForm.value);
  //   // console.log(this.customFastagForm.value.todate.split());

  //   if (this.customDateForm.valid) {
  //     this.p4 = 1;
  //     this.isCustomVehExpTxn = true;
  //     this.noCustomVehExpData = false;
  //     // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
  //     this.customVehiclesExpenses(this.customDateForm.value);
  //   }

  // }


  // customVehiclesExpenses(formvalue) {

  //   let body = {
  //     "merchantid": this.marchent_id,
  //     "walletid": this.wallet_id,
  //     "account_id": this.fa_id,
  //     "from_date": formvalue.fromdate,
  //     "to_date": formvalue.todate

  //   }

  //   console.log("vehicle expenses Custom  Body--->", body);

  //   this._erpService.getVehiclesExpenses(body).subscribe(res => {
  //     console.log(" customVehiclesExpenses()--->", res);
  //     if (res['errorCode'] == '0' && res['data'].message) {

  //       this.customVehicleExpensesData = res['data'].message;

  //       this.customVehicleExpensesDatalength = this.customVehicleExpensesData.length;

  //       if (res['data'].message.length == 0 || this.customVehicleExpensesDatalength == 0) {
  //         this.isCustomVehExpTxn = false;
  //         this.noCustomVehExpData = true;
  //       }
  //       else {
  //         this.isCustomVehExpTxn = false;
  //       }

  //     }
  //     else {
  //       this.isCustomVehExpTxn = false;
  //       this.noCustomVehExpData = true;
  //     }

  //   })

  // }

  //***********************************************************************//


  dailyExpGraph() {
    // this.dailyMax=Math.max(...this.dailyExpTotalExp);
    // this.dailyTotalAvgsum = this.sum_of_all_daily_vehexp / this.dailyExpTotalExp.length;
    // console.log('dailyTotalAvgsum-->', this.dailyTotalAvgsum);
    // console.log(this.daily_Xaxis_vehicleNos.length);
    

    // this.logic();
    let width="";
    if(this.daily_Xaxis_vehicleNos.length>10){
      width="150%";
    }
    else if(this.daily_Xaxis_vehicleNos.length>20){
      width="200%";
    }
    else if(this.daily_Xaxis_vehicleNos.length>30){
      width="500%";
    }
    else{
      // width="100%";
    }

    // console.log(this.dailyMax);
    // console.log("Daily");
    if (this.daily_Xaxis_vehicleNos.length > 0) {
      this.dailyChartOptions = {
        series: [
          {
            name: "Expenses",
            data: this.dailySeriesData
          },


        ],
        chart: {
          height: '500',
         
          type: "line",
          zoom: {
            enabled: false
          }
        },
        annotations: {
          yaxis: [
            {
              y: this.dailyTotalAvgsum,
              // y2: this.dailyTotalAvgsum - 1000,
              borderColor: '#00E396',
              fillColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: `Average total expenses of all vehicles ${this.dailyTotalAvgsum}`
              }
            }
          ]
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333']
          },
          // offsetX: 30
        },
        stroke: {
          curve: "straight"

        },
        title: {
          text: "Vehicles Expenses ",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          // range:this.daily_Xaxis_vehicleNos.length,

          title: {
            text: "Vehicle Numbers"
          },
          categories: this.daily_Xaxis_vehicleNos,
          labels: {
            showDuplicates: false,
          }

        },
        yaxis: {
          title: {
            text: "Total Expenses"
          },
          min: this.dailyMin,
          max: this.dailyMax

        }
      }
    }
    else {

    }



    this.monthlyExpgraph = false;
    this.weeklyExpgraph = false;
    this.dailyExpgraph = true;

  }

  weeklyExpGraph() {
    // this.logic();

    // this.weeklyTotalAvgsum=this.sum_of_all_weekly_vehexp/this.weeklyExpTotalExp.length;
    // console.log("weeklyTotalAvgsum--->",this.weeklyTotalAvgsum);

    // this.weeklyMax=Math.max(...this.weeklyExpTotalExp);
    // console.log( this.weeklyMax);
    console.log("Weekly");
    let width="";
    if(this.weekly_Xaxis_vehicleNos.length>10){
      width="150%";
    }
    else if(this.weekly_Xaxis_vehicleNos.length>20){
      width="200%";
    }
    else if(this.weekly_Xaxis_vehicleNos.length>30){
      width="500%";
    }

    if (this.weeklyVehicleExpensesDatalength > 0) {
      this.weeklyLoading = false;
      this.weeklyChartOptions = {
        series: [
          {
            name: "Expenses",
            data: this.weeklySeriesData,

          },

        ],
        chart: {
          height: '500',
        
          type: "line",
          zoom: {
            enabled: false
          }
        },
        annotations: {
          yaxis: [
            {
              y: this.weeklyTotalAvgsum,
              // y2: this.weeklyTotalAvgsum - 1000,
              borderColor: '#00E396',
              fillColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: `Average total expenses of all vehicles ${this.weeklyTotalAvgsum}`
              }
            }
          ]
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333']
          },
        },
        stroke: {
          curve: "straight"
        },
        title: {
          text: "Vehicles Expenses ",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {
          // range:this.weekly_Xaxis_vehicleNos.length,

          type: 'category',
          title: {
            text: "Vehicle Numbers"
          },
          categories: this.weekly_Xaxis_vehicleNos,
          labels: {
            showDuplicates: false,
          },




        },
        yaxis: {
          title: {
            text: "Total Expenses"
          },
          min: this.weeklyMin,
          max: this.weeklyMax

        }

      }
    }
    else {
      setTimeout(() => {
        this.weeklyLoading = false;
        this.isWeeklyVehExpTxn = false;
        this.noWeeklyVehExpData = true;
      }, 2000)



    }



    this.dailyExpgraph = false;
    this.monthlyExpgraph = false;
    this.weeklyExpgraph = true;






  }

  monthlyExpGraph() {
    // this.logic();
    // this.monthlyMax=Math.max(...this.monthlyExpTotalExp);
    // this.monthlyTotalAvgsum=this.sum_of_all_monthly_vehexp/this.monthlyExpTotalExp.length;
    // console.log("monthlyTotalAvgsum--->",this.monthlyTotalAvgsum);
    let width;

    if (this.monthly_Xaxis_vehicleNos.length > 10) {
      width = 200;
    }
    else {
      width = 100;
    }


    console.log(this.monthlyMax);
    console.log("mon");
 

    if (this.monthlyVehicleExpensesDatalength > 0) {
      this.monthlyLoading = false;

      this.monthlyChartOptions = {
        series: [
          {
            name: "Expenses",
            data: this.monthlySeriesData
          }
        ],
        chart: {
          height: '500',
         
          type: "line",
          zoom: {
            enabled: false,



          }
        },
        annotations: {
          yaxis: [
            {
              y: this.monthlyTotalAvgsum,
              // y2: this.monthlyTotalAvgsum - 1000,
              borderColor: '#00E396',
              fillColor: '#00E396',
              label: {
                borderColor: '#00E396',
                style: {
                  color: '#fff',
                  background: '#00E396'
                },
                text: `Average total expenses of all vehicles ${this.monthlyTotalAvgsum}`
              }
            }
          ]
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#333']
          },
        },
        stroke: {
          curve: "straight"
        },
        title: {
          text: "Vehicles Expenses ",
          align: "left"
        },
        grid: {
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        xaxis: {

          // range:this.monthly_Xaxis_vehicleNos.length,

          title: {
            text: "Vehicle Numbers"
          },
          categories: this.monthly_Xaxis_vehicleNos,
          labels: {
            showDuplicates: false,
          }

        },
        yaxis: {

          title: {
            text: "Total Expenses"
          },

          min: this.monthlyMin,
          max: this.monthlyMax,
          tickAmount: 12

        }
      }
    }
    else {
      setTimeout(() => {
        this.monthlyLoading = false;
        this.isMonthlyVehExpTxn = false;
        this.noMonthlyVehExpData = true;
      },5000)


    }



    this.dailyExpgraph = false;
    this.weeklyExpgraph = false;
    this.monthlyExpgraph = true;




  }


  onDailyExpensesVehicleNo() {



    this.isDailyReset = true;

    // this.dailyMax= this.daily_max_min_ExpensesForm.value.maxvalue;
    // this.dailyMin=this.daily_max_min_ExpensesForm.value.minvalue;

    let dailyVehicleNewArray;
    let dailyVehicleNewdata

    this.dailyUserOptVehilceNo = this.daily_vehicleNo_ExpensesForm.value.vehicleNo.toUpperCase();
    console.log(this.dailyUserOptVehilceNo);

    if (this.dailyExpVehicleNos.includes(this.dailyUserOptVehilceNo)) {

      let vehicleIndex = this.dailyExpVehicleNos.indexOf(this.dailyUserOptVehilceNo);
      console.log("daily vehicleIndex--->", vehicleIndex);

      if (vehicleIndex > 2) {
        dailyVehicleNewArray = this.dailyExpVehicleNos.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("dailyVehicleNewArray--->", dailyVehicleNewArray);
        dailyVehicleNewdata = this.dailyExpTotalExp.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("dailyVehicleNewdata--->", dailyVehicleNewdata);
      }
      else {
        dailyVehicleNewArray = this.dailyExpVehicleNos.slice(vehicleIndex, vehicleIndex + 4);
        console.log("dailyVehicleNewArray--->", dailyVehicleNewArray);
        dailyVehicleNewdata = this.dailyExpTotalExp.slice(vehicleIndex, vehicleIndex + 4);
        console.log("dailyVehicleNewdata--->", dailyVehicleNewdata);
      }
      this.dailySeriesData = dailyVehicleNewdata;
      this.daily_Xaxis_vehicleNos = dailyVehicleNewArray;
      this.dailyExpGraph();

    }
    else {
      this.notFoundDailyVehicleNo = true;
      console.log("wrong number");

    }







  }
  onWeeklyExpensesVehicleNo() {
    this.isWeeklyReset = true;

    let weeklyVehicleNewArray;
    let weeklyVehicleNewdata;

    // this.weeklyMax= this.weekly_vehicleNo_ExpensesForm.value.maxvalue;
    // this.weeklyMin=this.weekly_vehicleNo_ExpensesForm.value.minvalue;
    // console.log( this.weekly_vehicleNo_ExpensesForm.value.maxvalue);
    // console.log( this.weekly_vehicleNo_ExpensesForm.value.minvalue);



    this.weeklyUserOptVehilceNo = this.weekly_vehicleNo_ExpensesForm.value.vehicleNo.toUpperCase();
    console.log(this.weeklyUserOptVehilceNo);


    if (this.weeklyExpVehicleNos.includes(this.weeklyUserOptVehilceNo)) {
      let vehicleIndex = this.weeklyExpVehicleNos.indexOf(this.weeklyUserOptVehilceNo);
      console.log("weekly vehicleIndex--->", vehicleIndex);


      if (vehicleIndex > 2) {

        weeklyVehicleNewArray = this.weeklyExpVehicleNos.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("weeklyVehicleNewArray--->", weeklyVehicleNewArray);

        weeklyVehicleNewdata = this.weeklyExpTotalExp.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("weeklyVehicleNewdata--->", weeklyVehicleNewdata);

      }
      else {

        weeklyVehicleNewArray = this.weeklyExpVehicleNos.slice(vehicleIndex, vehicleIndex + 4);
        console.log("weeklyVehicleNewArray--->", weeklyVehicleNewArray);

        weeklyVehicleNewdata = this.weeklyExpTotalExp.slice(vehicleIndex, vehicleIndex + 4);
        console.log("weeklyVehicleNewdata--->", weeklyVehicleNewdata);

      }



      this.weeklySeriesData = weeklyVehicleNewdata;
      this.weekly_Xaxis_vehicleNos = weeklyVehicleNewArray;
      this.weeklyExpGraph();



    }
    else {
      this.notFoundWeeklyVehicleNo = true;
      console.log("wrong vehicle no in weekly");

    }





  }
  onMonthlyExpensesVehicleNo() {

    this.isMonthlyReset = true;

    // this.monthlyMax= this.monthly_vehicleNo_ExpensesForm.value.maxvalue;
    // this.monthlyMin=this.monthly_vehicleNo_ExpensesForm.value.minvalue;
    // console.log( this.monthly_vehicleNo_ExpensesForm.value.maxvalue);
    // console.log( this.monthly_vehicleNo_ExpensesForm.value.minvalue);

    let monthlyVehicleNewArray;
    let monthlyVehicleNewdata;

    this.monUserOptVehilceNo = this.monthly_vehicleNo_ExpensesForm.value.vehicleNo.toUpperCase();
    console.log(this.monUserOptVehilceNo);

    if (this.monthlyExpVehicleNos.includes(this.monUserOptVehilceNo)) {
      let vehicleIndex = this.monthlyExpVehicleNos.indexOf(this.monUserOptVehilceNo);
      console.log("monthly vehicleIndex--->", vehicleIndex);

      if (vehicleIndex > 2) {
        monthlyVehicleNewArray = this.monthlyExpVehicleNos.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("monthlyVehicleNewArray--->", monthlyVehicleNewArray);
        monthlyVehicleNewdata = this.monthlyExpTotalExp.slice(vehicleIndex - 2, vehicleIndex + 2);
        console.log("monthlyVehicleNewdata--->", monthlyVehicleNewdata);
      }
      else {
        monthlyVehicleNewArray = this.monthlyExpVehicleNos.slice(vehicleIndex, vehicleIndex + 4);
        console.log("monthlyVehicleNewArray--->", monthlyVehicleNewArray);
        monthlyVehicleNewdata = this.monthlyExpTotalExp.slice(vehicleIndex, vehicleIndex + 4);
        console.log("monthlyVehicleNewdata--->", monthlyVehicleNewdata);
      }





      this.monthlySeriesData = monthlyVehicleNewdata;
      this.monthly_Xaxis_vehicleNos = monthlyVehicleNewArray;



      this.monthlyExpGraph();

    }
    else {
      console.log('wrong vehicle no in monthly');
      this.notFoundMonthlyVehicleNo = true


    }




  }


  onDailyExpensesReset() {


    this.isDailyReset = false;
    this.notFoundDailyVehicleNo = false;
    this.dailySeriesData = this.dailyExpTotalExp;
    this.daily_Xaxis_vehicleNos = this.dailyExpVehicleNos;
    this.daily_vehicleNo_ExpensesForm.reset();


    this.dailyExpGraph();

  }


  onWeeklyExpensesReset() {

    this.isWeeklyReset = false;
    this.notFoundWeeklyVehicleNo = false;
    this.weeklySeriesData = this.weeklyExpTotalExp;
    this.weekly_Xaxis_vehicleNos = this.weeklyExpVehicleNos;
    this.weekly_vehicleNo_ExpensesForm.reset();


    this.weeklyExpGraph();

  }


  onMonthlyExpensesReset() {

    this.isMonthlyReset = false;
    this.notFoundMonthlyVehicleNo = false;
    this.monthlySeriesData = this.monthlyExpTotalExp;
    this.monthly_Xaxis_vehicleNos = this.monthlyExpVehicleNos;
    this.monthly_vehicleNo_ExpensesForm.reset();


    this.monthlyExpGraph();

  }

  dailyExpenses() {
    this.searchString = ''
  }
  weeklyExpenses() {
    this.searchString = ''
  }
  monthlyExpenses() {
    this.searchString = ''
  }
  customExpenses() {
    this.searchString = ''
  }
}
