import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';

@Component({
  selector: 'app-bpcl-dashboard',
  templateUrl: './bpcl-dashboard.component.html',
  styleUrls: ['./bpcl-dashboard.component.css']
})
export class BpclDashboardComponent implements OnInit {

  fa_id: any;

  dailyBpclTransactionData: any;
  dailyBpclDatalength: number = 0;
  dailyTransaction: number = 0;
  dailyPendingCashback: number = 0;


  weeklyBpclTransactionData: any;
  weeklyBpclDatalength: number = 0;
  weeklyTransaction: number = 0;
  weeklyPendingCashback: number = 0;

  monthlyBpclTransactionData: any;
  monthlyBpclDatalength: number = 0;
  monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;

  customBpclTransactionData: any;
  customBpclDatalength: number = 0;
  customTransaction: number = 0;
  customPendingCashback: number = 0;

  isDailyBpclTxn: boolean=true;
  isMonthlyBpclTxn: boolean=true;
  isWeeklyBpclTxn: boolean=true;
  isCustomBpclTxn: boolean;


  noDailyBpclData: boolean = false;
  noWeeklyBpclData: boolean = false;
  noMonthlyBpclData: boolean = false;
  noCustomBpclData: boolean = false;
  todayDate: any;


  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;

  customBpclForm: any;

  todaysDate:any;
  weeklyDate:any;
  monthlyDate:any;

  dataLoading:boolean=true;
  searchString:any='';
  customTimeFilterDetails:any=[];

  bpclBalance:number=0;





  constructor(private fb: FormBuilder, private _erpService: ErpnextapiService, private route: ActivatedRoute, private router: Router) {

    this.customBpclForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      time:['']

    })

  }

  ngOnInit(): void {




    var today = new Date();
    let currentyear = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();
    let day = today.getDate().toString();



    if ((month.length == 2 && day.length == 2)) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    // console.log(this.todayDate);



    // var priordate = new Date(new Date().setDate(beforedate.getDate()-30));


    this.fa_id = localStorage.getItem("FAID");
    this.getBpclBalnace();
    this.getDailyBpclTxn();
    this.getWeeklyBpclTxn();
    this.getMonthlyBpclTxn();
    this. getLoggedInUser();

  }





  getLoggedInUser() {
    this._erpService.getUserDetails().subscribe((res) => {
      //console.log(res);
      if(res["data"]=== ''){
        this.logout();
      }
  })
  }
  logout(){
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }


  /***************************GET BPCL FUEL BALANCE FROM ERP **********************************************/
  getBpclBalnace(){
    this._erpService.FromErpGetBpclFuelBal(this.fa_id).subscribe((res)=>{
      // console.log("BPCL Balance",res)
      if(res['errorCode']=='0' && res['data']['message']>0){
        this.bpclBalance=res['data']['message']

      }
      console.log(  this.bpclBalance)
    })
  }



  /***************************END GET BPCL BALANCE **********************************************/

  /***************************Daily Bpcl Txn **********************************************/
  getDailyBpclTxn() {

    let body = {
      "account_id": this.fa_id,
      "from_date": `${this.todayDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`

    }

    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getDailyBpclTxn()----->", res);
      console.log("*******");

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {
        this.dailyTransaction = res['data'].expenditure.daily_transaction;
        this.dailyPendingCashback = (0.4 / 100) * this.dailyTransaction;


        if (res['data'].message != 'No record found') {
          console.log("*******");


          this.dailyBpclTransactionData = res['data'].message.reverse();
          // this.dailyBpclTransactionData=[];
          for(let i=0; i<this.dailyBpclTransactionData.length;i++){
            let date= this.dailyBpclTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.dailyBpclTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.dailyBpclTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.dailyBpclTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.dailyBpclTransactionData[i]['product_details']['Date']=date;
            this.dailyBpclTransactionData[i]['product_details']['Time']=time;

          }


          this.dailyBpclDatalength = this.dailyBpclTransactionData.length;


          if (this.dailyBpclDatalength == 0) {
            this.noDailyBpclData = true;
            this.dataLoading=false;
          }


          this.isDailyBpclTxn = false;
          this.dataLoading=false;
        }
        else {
          console.log("+++++++++++");
          this.isDailyBpclTxn = false;
          this.noDailyBpclData = true;
          this.dataLoading=false;
        }

      }
      else{
        console.log("+++++++++++");
          this.isDailyBpclTxn = false;
          this.noDailyBpclData = true;
          this.dataLoading=false;
      }

    });

  }


  /*******************************************************************************************/




  /***************************Weekly Bpcl Txn **********************************************/
  getWeeklyBpclTxn() {

    let sevendaysAgoDate;

    let todaydate = new Date();
    var sevendaysback = new Date(new Date().setDate(todaydate.getDate() - 6));
    // console.log("sevendaysback--->", sevendaysback);
    let currentyear = sevendaysback.getFullYear().toString();
    let month = (sevendaysback.getMonth() + 1).toString();
    let day = sevendaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate=`${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`

    }
    else if (month.length == 1 && day.length == 2) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate=`${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate=`${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate=`${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`
    }

    // console.log(sevendaysAgoDate);

    let body = {
      "account_id": this.fa_id,
      "from_date": `${sevendaysAgoDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`

    }


    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getWeeklyBpclTxn()----->", res);

      if (res['errorCode'] == '0' &&res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {
        this.weeklyTransaction = res['data'].expenditure.weekly_transaction;
        this.weeklyPendingCashback = (0.4 / 100) * this.weeklyTransaction;

        if (res['data'].message != 'No record found') {

          this.weeklyBpclTransactionData = res['data'].message.reverse();

          for(let i=0; i<this.weeklyBpclTransactionData.length;i++){
            let date= this.weeklyBpclTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.weeklyBpclTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.weeklyBpclTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.weeklyBpclTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.weeklyBpclTransactionData[i]['product_details']['Date']=date;
            this.weeklyBpclTransactionData[i]['product_details']['Time']=time;

          }

          this.weeklyBpclDatalength = this.weeklyBpclTransactionData.length
          if (this.weeklyBpclDatalength == 0) {
            this.noWeeklyBpclData = true;
          }
          this.isWeeklyBpclTxn = false;
        }
        else {
          this.isWeeklyBpclTxn = false;
          this.noWeeklyBpclData = true;
        }

      }
      else {
        this.isWeeklyBpclTxn = false;
        this.noWeeklyBpclData = true;
      }

    });







  }


  /*******************************************************************************************/




  /***************************Monthly Bpcl Txn **********************************************/
  getMonthlyBpclTxn() {

    let thirtydaysAgoDate;

    let todaydate = new Date();
    var thirtydaysback = new Date(new Date().setDate(todaydate.getDate() - 31));
    // console.log("sevendaysback--->", thirtydaysback);
    let currentyear = thirtydaysback.getFullYear().toString();
    let month = (thirtydaysback.getMonth() + 1).toString();
    let day = thirtydaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }

    // console.log(thirtydaysAgoDate);

    let body = {
      "account_id": this.fa_id,
      "from_date":` ${thirtydaysAgoDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`

    }


    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getMonthlyBpclTxn()----->", res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {
        this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
        this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

        if (res['data'].message != 'No record found') {

          this.monthlyBpclTransactionData = res['data'].message.reverse();
          for(let i=0; i<this.monthlyBpclTransactionData.length;i++){
            let date= this.monthlyBpclTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.monthlyBpclTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.monthlyBpclTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.monthlyBpclTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.monthlyBpclTransactionData[i]['product_details']['Date']=date;
            this.monthlyBpclTransactionData[i]['product_details']['Time']=time;

          }

          this.monthlyBpclDatalength = this.monthlyBpclTransactionData.length;

          if (this.monthlyBpclDatalength == 0) {
            this.noMonthlyBpclData = true;
          }
          this.isMonthlyBpclTxn = false;
        }
        else {
          this.isMonthlyBpclTxn = false;
          this.noMonthlyBpclData = true;
        }

      }
      else {
        this.isMonthlyBpclTxn = false;
        this.noMonthlyBpclData = true;
      }

    });



  }


  /*******************************************************************************************/



  /***************************Custon Bpcl Txn **********************************************/

  onCustomTxn() {
    this.customBpclTransactionData=[];
    this.customTimeFilterDetails=[]
    this.isCustomBpclTxn=true;
    this.customBpclForm.value.fromdate = this.customBpclForm.value.fromdate.replace(/-/g, "");
    this.customBpclForm.value.todate = this.customBpclForm.value.todate.replace(/-/g, "");

    // console.log(this.customFastagForm.value);
    // console.log(this.customFastagForm.value.todate.split());

    if (this.customBpclForm.valid) {
      this.p4=1;
      this.isCustomBpclTxn = true;
      this.noCustomBpclData = false;
      // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
      this.getCustomBpclTxn(this.customBpclForm.value);
    }
  }



  getCustomBpclTxn(formvalue) {

    let body = {
      "account_id": this.fa_id,
      "from_date": formvalue.fromdate,
      "to_date": formvalue.todate

    }

    // console.log("Bpcl Custom Txn Body--->", body);

    this.customBpclDatalength = 0;

    this._erpService.getBpclTxnHistory(body).subscribe((res) => {
      console.log(" getBpclcustomTxn()---->", res);
      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {
        if (res['data'].message != 'No record found') {
          // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;

          this.customBpclTransactionData = res['data'].message;
          let timefilter=this.customBpclForm.value.time.slice(0,2);
          console.log(timefilter);


          for(let i=0; i<this.customBpclTransactionData.length;i++){
            let date= this.customBpclTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.customBpclTransactionData[i]['product_details'].createdDT.slice(11,22);
            // console.log(date);
            // console.log(time);


            this.customBpclTransactionData[i]['product_details']['Date']=date;
            this.customBpclTransactionData[i]['product_details']['Time']=time;
            if(this.customBpclTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.customBpclTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }

            // let hour=  time.slice(0,2);
            // console.log(hour);



            // if(timefilter===hour){
            //   this.customTimeFilterDetails.push(this.customBpclTransactionData[i]);
            // }


          }

          // if(timefilter!=""){
          //   this.customBpclTransactionData=this.customTimeFilterDetails
          // }
          // console.log(this.customFastagTransactionData);
          this.customBpclDatalength = this.customBpclTransactionData.length;
          if (this.customBpclDatalength == 0) {
            this.noCustomBpclData = true;
          }
          this.isCustomBpclTxn = false;

        }
        else {
          // console.log();
          // this.customFastagDatalength=0;
          this.isCustomBpclTxn = false;
          this.noCustomBpclData = true;
        }




      }
      else {
        // console.log();
        // this.customFastagDatalength=0;
        this.isCustomBpclTxn = false;
        this.noCustomBpclData = true;
      }

    })


  }


  dailyBpcltxn(){
    this.searchString='';
  }
  weeklyBpcltxn(){
    this.searchString='';
  }
  monthlyBpcltxn(){
    this.searchString='';
  }
  customBpcltxn(){
    this.searchString='';
  }





  /*******************************************************************************************/
}