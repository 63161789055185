import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { element } from 'protractor';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';

@Component({
  selector: 'app-sideheader',
  templateUrl: './sideheader.component.html',
  styleUrls: ['./sideheader.component.css']
})
export class SideheaderComponent implements OnInit {

  userRole;
  error: boolean = false;
  errorMessage: String = "";
  dataLoading: boolean = false;
  private querySubscription;
  savedChanges: boolean = false;
  itemGroupParam = '?fields=%5B"name","icon","parent_item_group","image"%5D';
  itemCategoryParent;
  itemCategory = [];
  hideDashboardEmails: any = ["mrmtravels304@gmail.com","chinnathambiac@yahoo.com", "test@demo.in","prashant.bhatia@intrcity.com","admin@universaltravels.in"];
  sub_customer:any=["SRI SHA VOYAGES","Universal Bus Services"]

  super_customer :any =["IndoRailBus Travels Pvt Ltd"]
  hideDashboardUsername: any = ["RAMAKRISHNAN MAHENDRAN"];


  hideDashboard: boolean;
  hide_opt_sub_dashboards :boolean;
  customer:any;
  hideOptDashboards:any=['muthujayamtravels8@gmail.com'];
  hideOptDashboardsCustomerName:any=['MJT Travels']
  optCustomDashboard:boolean;
  showTyreDashbordes:boolean=true;

  constructor(private router: Router, private _erpNextApiService: ErpnextapiService) { }

  ngOnInit() {


    let email = localStorage.getItem("email");
    this.customer =localStorage.getItem("customer")
    //  const el:HTMLElement= document.getElementsByClassName('opt-dash');

    if(this.customer=='Universal Bus Services'){
      this.showTyreDashbordes=true;
    }
    else{
      this.showTyreDashbordes=false;
    }


    // for super customers(Intrcity dashbord)
    if(this.super_customer.includes( this.customer)){
      this.hide_opt_sub_dashboards=true;
      // el.style.disply='none'

    }
    else{
      this.hide_opt_sub_dashboards=false;
    }
    console.log(this.hide_opt_sub_dashboards)


    //under opt dashboards

    if(this.hideOptDashboards.includes(email) || this.hideOptDashboardsCustomerName.includes(this.customer)){
      this.optCustomDashboard=true;


    }
    else{
      this.optCustomDashboard=false;
    }



    // old dashboards
    if(this.hideDashboardEmails.includes(email) || this.sub_customer.includes(this.customer)){
      this.hideDashboard=true;
    }
    else{
      this.hideDashboard=false;
    }

    this.userRole = localStorage.getItem('userRole');
    // console.log(this.userRole);
    this.getItemGroups();
  }

  checkModuleAuth(authRole) {
    let authModules = JSON.parse(localStorage.getItem('authModule'));
    if (authModules != undefined || authModules != null) {
      return authModules.includes(authRole);
    }
    //  console.log(authRole);
  }


  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('SFAID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    localStorage.removeItem('tax_category');
    localStorage.removeItem('bpclFAAccessToken');
    localStorage.removeItem('marchantid');
    localStorage.removeItem('walletid');
    localStorage.removeItem('requsetSource');
    localStorage.clear()

  }


  getItemGroups() {
    this.dataLoading = true;
    //console.log(this.itemGroupParam);
    this._erpNextApiService.getitemGroup(this.itemGroupParam).subscribe((res) => {
      if (res["errorCode"] == '0') {
        this.error = false;
        this.errorMessage = "";
        this.dataLoading = false;
        this.itemCategoryParent = res["data"];
        for (var i = 0; i < this.itemCategoryParent.length; i++) {
          if (this.itemCategoryParent[i].parent_item_group === 'All Item Groups') {
            this.itemCategory.push(this.itemCategoryParent[i]);
          }
        }
        // console.log(this.itemCategory);
      } else {
        this.error = true;
        this.errorMessage = res["errorMessage"];
        this.dataLoading = false;
      }
    },
      (error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.dataLoading = false;
      },
      () => {
        this.dataLoading = false;
      });
  }
  selectNavbar(itemGroup) {
    this.router.navigate(['/products/', itemGroup]);
    // ['/products/',itemGroup.name]
  }
  gotopayout() {
    this.router.navigate(['/payout']);
  }
}
