import { Component, OnInit } from '@angular/core';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credits-allocation-history',
  templateUrl: './credits-allocation-history.component.html',
  styleUrls: ['./credits-allocation-history.component.css']
})
export class CreditsAllocationHistoryComponent implements OnInit {
  email:any;
  customer:any;

  allocation_history:any=[];
  isAllocationHistory:any;
  NoAllocationHistory:any=false;
  allocationHistoryDatalength:number=0;


  todayDate:any
  todaysDate:any;
  weeklyDate:any;
  monthlyDate:any;

  constructor(private _erpService: ErpnextapiService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private tostr:ToastrService) { }

  ngOnInit(): void {
    this.email=localStorage.getItem('email');
    this.customer=localStorage.getItem('customer');


    var today = new Date();
    let currentyear = today.getFullYear().toString();
    let month = (today.getMonth() + 1).toString();
    let day = today.getDate().toString();
  


    if ((month.length == 2 && day.length == 2)) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/${today.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      this.todayDate = today.getFullYear().toString() + '0' + (today.getMonth() + 1).toString() + '0' + today.getDate().toString();
      this.todaysDate=`${today.getFullYear().toString()}/0${(today.getMonth() + 1).toString()}/0${today.getDate().toString()}`
    }

    this.getAllocationHistory()
  }

  getAllocationHistory(){
    this.isAllocationHistory=true;



    let thirtydaysAgoDate;

    let todaydate = new Date();
    var thirtydaysback = new Date(new Date().setDate(todaydate.getDate() - 31));
    // console.log("sevendaysback--->", thirtydaysback);
    let currentyear = thirtydaysback.getFullYear().toString();
    let month = (thirtydaysback.getMonth() + 1).toString();
    let day = thirtydaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate=`${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }


    let body={
      "args": {
        "customer": this.customer,
        "from_date": "20230529",
        "to_date": "20260529"
      }
    }
    // console.log(body)

    this._erpService.s_getAllocationHistory(body).subscribe((res)=>{
      // console.log("getAllocationHistory ---->", res['data'].message);

      if(res['errorCode'] == '0' && res['data'].message!="No data found"  && res["data"]["exc_type"]!="ValidationError" && res['data'].message.length>0){
      this.allocation_history=res["data"].message.reverse()
      this.allocationHistoryDatalength=this.allocation_history.length;
     
      this.isAllocationHistory=false;
       

      }
      else{
        this.NoAllocationHistory=true;
        this.isAllocationHistory=false;
      }
    })
  }

}
