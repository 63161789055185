import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractControlDirective } from "@angular/forms";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Login } from "../models/login.model";
import { log } from "console";
import Swal from "sweetalert2";

@Injectable({
  providedIn: "root",
})
export class ErpnextapiService {
  // public serverHost = "http://localhost";
  // public serverPort = 8080;
  // public serverHostPort = this.serverHost + ":" + this.serverPort;
  // public serverHostPort = "https://lnder-dev-server.el.r.appspot.com";
  public serverHostPort = "https://lnder-pd-server.el.r.appspot.com";
  // https://lnder-pd-server.el.r.appspot.com/.

  authtoken = "abcd";
  LoginData: Login;
  userInfo;
  faId = localStorage.getItem("FAID");
  sfa_id = localStorage.getItem("SFAID");

  constructor(private _http: HttpClient) { }

  // Login components
  login(formData) {
    // console.log(formData)
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.post(
      `${this.serverHostPort}/login`,
      formData,
      httpOptions
    );
  }

  // forgotPasswordEmail(formData) {
  //   let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
  //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': token }) };
  //   return this._http.post(`${this.serverHostPort}/forgot-password-email`, formData, httpOptions);
  // }
  // resetPassword(formData) {
  //   let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
  //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': token }) };
  //   return this._http.post(`${this.serverHostPort}/reset-password`, formData, httpOptions);
  // }

  // Fetch All Lookups into Cache
  getAllItems(paramList) {
    let customParams = paramList;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    // // console.log(httpOptions);
    return this._http.get(`${this.serverHostPort}/get-all-items`, httpOptions);
  }

  getItemById(itemCode) {
    let customParams = itemCode;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(`${this.serverHostPort}/get-item-by-id`, httpOptions);
  }
  getCreditLimit(customer) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let customParams = customer;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(`${this.serverHostPort}/get-credits`, httpOptions);
  }
  updateItemGroupLevelSplitRatio(customer, splitInfo) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let customParams = customer;
    let splitDetails = splitInfo;
    //console.log(splitDetails);
    //console.log(customParams);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.put(
      `${this.serverHostPort}/update-split-ratio`,
      splitDetails,
      httpOptions
    );
  }

  getSalesOrderCart(saleOrderId) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = saleOrderId;
    // console.log(customParams);
    let authToken = apiKey + ":" + apiSecret;
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-sales-order-name`,
      httpOptions
    );
  }

  getSalesOrderDetails(saleOrderId) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = saleOrderId;
    // // console.log(customParams);
    let authToken = apiKey + ":" + apiSecret;
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-sales-order`,
      httpOptions
    );
  }

  getCustomerSalesOrder(salesParam) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = salesParam;
    // // console.log(customParams);
    let authToken = apiKey + ":" + apiSecret;
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-customer-sales-order`,
      httpOptions
    );
  }

  createSalesOrder(orderData) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/create-sales-order`,
      orderData,
      httpOptions
    );
  }

  updateSalesOrder(orderDetails, salesOrderId) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    let customParams = salesOrderId;
    // // console.log(orderDetails);
    // // console.log(salesOrderId)
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.put(
      `${this.serverHostPort}/update-sales-order`,
      orderDetails,
      httpOptions
    );
  }

  getShippingAddress(addressParams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    let customParams = addressParams;
    // // console.log(orderDetails);
    // // console.log(salesOrderId)
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/customer-address`,
      httpOptions
    );
  }
  // downloadPdf(pdfParams){
  //   let apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : 'abcd';
  //   let apiSecret = localStorage.getItem('apiSecret') ? localStorage.getItem('apiSecret') : 'abcd';
  //   // let params = new HttpParams();
  //   let authToken = apiKey+":"+apiSecret;
  //   let customParams = pdfParams;
  // //console.log(customParams);
  //   // // console.log(orderDetails);
  //   // // console.log(salesOrderId)
  //   // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
  //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json','token': authToken, 'customparams':customParams}) };
  //   return this._http.get(`${this.serverHostPort}/download-sales-order`, httpOptions);
  // }
  deleteSalesOrder(salesOrderId) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    let customParams = salesOrderId;
    // // console.log(orderDetails);
    // // console.log(salesOrderId)
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.delete(
      `${this.serverHostPort}/delete-sales-order`,
      httpOptions
    );
  }

  createInvoice(invoiceData) {
    // // console.log(invoiceData);
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let authToken = apiKey + ":" + apiSecret;
    // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/post-invoice-details`,
      invoiceData,
      httpOptions
    );
  }

  getInvoiceById(invoiceName) {
    let customParams = invoiceName;
    // console.log(customParams);
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    // // console.log(customParams);
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-invoice-by-id`,
      httpOptions
    );
  }

  getInvoicesList(invoiceParams) {
    let customParams = invoiceParams;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    // console.log(customParams);
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-invoice-list`,
      httpOptions
    );
  }

  getUserDetails() {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let email = localStorage.getItem("email")
      ? localStorage.getItem("email")
      : "abcd";
    // let params = new HttpParams();
    let customParams = email;
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(`${this.serverHostPort}/get-user`, httpOptions);
  }

  getUserDetailsInsInfo(token, phoneno) {
    // let apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : 'abcd';
    // let apiSecret = localStorage.getItem('apiSecret') ? localStorage.getItem('apiSecret') : 'abcd';
    // let email = localStorage.getItem('email') ? localStorage.getItem('email') : 'abcd';
    // // let params = new HttpParams();
    let customParams = phoneno;
    let authToken = token;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/get-user-ins-info`,
      httpOptions
    );
  }

  uploadInsDoc(token, formData) {
    
  
      let authToken = token;


    // let authToken = apiKey + ":" + apiSecret;



    let httpOptions = {
      headers: {
        token: authToken,
      },
    };
    console.log("FD", formData);

    //   for (var key of formData.entries()) {
    //     console.log(key);
    //     console.log(typeof(key[1]));

    // }

    return this._http.post<any>(
      `${this.serverHostPort}/upload-ins-doc`,
      formData,
      httpOptions
    );
  }

  uploadFile(formData) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: {
        token: `token ${authToken}`,
      },
    };
    return this._http.post<any>(
      `${this.serverHostPort}/upload-ins-doc`,
      formData,
      httpOptions
    );
  }

  getInsuranceToken(body) {
    return this._http.post<any>(
      `${this.serverHostPort}/generate-ins-token`,
      body
    );
  }

  getcustomerId() {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";
    let customer = localStorage.getItem("customer");

    let httpOptions = {
      headers: {
        token: `token ${authToken}`,
        customer: customer,
      },
    };

    return this._http.get(`${this.serverHostPort}/get-customerid`, httpOptions);
  }

  // /get-insurance-expiring-policies

  getInsuExpPol(cusparm) {
    let httpOptions = {
      headers: {
        customparams: cusparm,
      },
    };

    return this._http.get(
      `${this.serverHostPort}/get-insurance-expiring-policies`,
      httpOptions
    );
  }

  newVehicleInsReq(data, token) {
    let authtoken = token;
    let httpOptions = {
      headers: {
        token: authtoken,
      },
    };
    return this._http.post<any>(
      `${this.serverHostPort}/new-vehicle-ins-req`,
      data,
      httpOptions
    );
  }

  // get-veh-ins-req-data

  getVehInsData() {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = `5d86d079564a18a:80e46996b1b9eaf`;

    // console.log(authToken);

    let httpOptions = {
      headers: {
        token: `token ${authToken}`,
      },
    };

    return this._http.get(
      `${this.serverHostPort}/get-veh-ins-req-data`,
      httpOptions
    );
  }

  // get-each-req-details

  getEachVehichleReqDetails(params) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = `5d86d079564a18a:80e46996b1b9eaf`;

    let httpOptions = {
      headers: {
        token: `token ${authToken}`,
        params: params,
      },
    };

    return this._http.get(
      `${this.serverHostPort}/get-each-req-details`,
      httpOptions
    );
  }

  getVehicleNo(token, vehicle_no) {
    let httpOptions = {
      headers: {
        token: token,
        vehicle_no: vehicle_no,
      },
    };

    return this._http.get(
      `${this.serverHostPort}/get-ins-vehicle-no`,
      httpOptions
    );
  }

  //get-each-vehicle-expenses

  getVehiclesExpenses(bodydata) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: {
        token: `token ${authToken}`,
      },
    };

    return this._http.post(
      `${this.serverHostPort}/get-each-vehicle-expenses`,
      bodydata,
      httpOptions
    );
  }

  getBpclAuthToken() {
    let head = {};
    return this._http.post<any>(
      `${this.serverHostPort}/get_bpcl_authToken`,
      head
    );
  }

  getCustomerDetails(customerParams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let email = localStorage.getItem("email")
      ? localStorage.getItem("email")
      : "abcd";
    // let params = new HttpParams();
    let customParams = customerParams;
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(`${this.serverHostPort}/get-customer`, httpOptions);
  }

  getAdvanceAmount(advanceParams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = advanceParams;
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(`${this.serverHostPort}/get-advance`, httpOptions);
  }
  getItemGroupPermission(itemParam) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = itemParam;
    let authToken = apiKey + ":" + apiSecret;
    // console.log(authToken);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    //console.log(httpOptions);
    return this._http.get(
      `${this.serverHostPort}/get-item-group-permission`,
      httpOptions
    );
  }
  getitemGroup(itemParam) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = itemParam;
    let authToken = apiKey + ":" + apiSecret;
    // console.log(authToken);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    //console.log(httpOptions);
    return this._http.get(`${this.serverHostPort}/get-item-group`, httpOptions);
  }
  getitemGroupByName(itemGroupName) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = itemGroupName;

    let authToken = apiKey + ":" + apiSecret;
    //// console.log(authToken);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    // console.log(httpOptions);
    return this._http.get(
      `${this.serverHostPort}/get-item-group-by-name`,
      httpOptions
    );
  }

  updateitemGroup(itemParam, itemGroupUpdate) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();
    let customParams = itemParam;

    let authToken = apiKey + ":" + apiSecret;
    //// console.log(authToken);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    // console.log(httpOptions);
    return this._http.put(
      `${this.serverHostPort}/update-item-group`,
      itemGroupUpdate,
      httpOptions
    );
  }

  getBPCLFAAuthentication() {
    let httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    return this._http.post(
      `${this.serverHostPort}/post-sfa-bpcl-login`,
      httpOptions
    );
  }

  getBPCLSFAAuthentication() {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let params = new HttpParams();

    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/post-fa-bpcl-login`,
      httpOptions
    );
  }

  getAllFADetails() {
    let accessstoken = localStorage.getItem("bpclSfaAccessToken")
      ? localStorage.getItem("bpclSfaAccessToken")
      : "abcd";
    // console.log(accessstoken);
    let customParams = this.sfa_id;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
        customparams: customParams,
      }),
    };
    // console.log(httpOptions);
    return this._http.get(
      `${this.serverHostPort}/get-all-fa-details`,
      httpOptions
    );
  }

  getBPCLFCCredits() {
    let accessstoken = localStorage.getItem("bpclFAAccessToken")
      ? localStorage.getItem("bpclFAAccessToken")
      : "abcd";
    let customParams = this.faId;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
        customparams: customParams,
      }),
    };
    // console.log(httpOptions);
    return this._http.get(
      `${this.serverHostPort}/get-bpcl-fc-credits`,
      httpOptions
    );
  }
  // getBPCLCMSWalletAmt(){
  //   let accessstoken = localStorage.getItem('bpclSfaAccessToken') ? localStorage.getItem('bpclSfaAccessToken') : 'abcd';
  //   // console.log(accessstoken);
  //   let customParams = this.faId;
  //   let httpOptions = {
  //     headers: new HttpHeaders({'Content-Type': 'application/json',  'token': `Bearer ${accessstoken}`,'customparams':customParams},
  //     )
  //   }
  //   // console.log(httpOptions);
  //   return this._http.get(`${this.serverHostPort}/get-bpcl-cms-wallet` ,httpOptions);
  // }
  transferBPCLAmount(bpclBody) {
    let bpclbody = bpclBody;
    //console.log(bpclbody);
    let accessstoken = localStorage.getItem("bpclFAAccessToken")
      ? localStorage.getItem("bpclFAAccessToken")
      : "abcd";
    //console.log(accessstoken);
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/transfer-bpcl-fa-fc`,
      bpclbody,
      httpOptions
    );
  }
  transferSFAtoFA(bpclBody) {
    let bpclbody = bpclBody;
    let accessstoken = localStorage.getItem("bpclSfaAccessToken")
      ? localStorage.getItem("bpclSfaAccessToken")
      : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/transfer-bpcl-sfa-fa`,
      bpclbody,
      httpOptions
    );
  }
  getBPCLTransactionHistory(bpclBody) {
    let bpclbody = bpclBody;
    let accessstoken = localStorage.getItem("bpclSFA Token")
      ? localStorage.getItem("bpclSFA Token")
      : "abcd";
    // console.log("bpclTxn accessToken in erp--->", accessstoken);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/get-bpcl-transaction-history`,
      bpclbody,
      httpOptions
    );
  }

  //get-bpcl-txn-history from Erp-Next

  getBpclTxnHistory(bpclbody) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get-bpcl-txn-history`,
      bpclbody,
      httpOptions
    );
  }
  //FROM BPCL SERVER
  getBpclFuelBalance() {
    let accessstoken = localStorage.getItem("bpclSFA Token");
    // console.log("bpcl access-token in erp--->", accessstoken);

    let accountId = localStorage.getItem("FAID");
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
        fa_id: accountId,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/get-bpcl-fuel-balance`,
      httpOptions
    );
  }

  // FROM ERP SERVER
  FromErpGetBpclFuelBal(fa_id) {
    let body_data = {
      bpcl_details: fa_id,
    };
    let token = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authToken: `token ${token}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_bpcl_fuel_balance`,
      body_data,
      httpOptions
    );
  }

  // bpcl-fuel-recharge
  getBpclFuelRecharge(customer, amount) {
    console.log("Type of amount---->", typeof amount);

    let body = {
      args: {
        party: customer,
        amount: amount,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;

    let authToken = "5d86d079564a18a:80e46996b1b9eaf";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/bpcl-fuel-recharge`,
      body,
      httpOptions
    );
  }

  createBPCLTransferRecord(bpclTransferDetails) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/bpcl-tranfer-record`,
      bpclTransferDetails,
      httpOptions
    );
  }

  getIoclFuelRecharge(customer, amount) {
    let body = {
      args: {
        party: customer,
        amount: amount,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    // let authToken = "token 9ca914ac2c9bb6b:3115d3cadf092b0";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/iocl-fuel-recharge`,
      body,
      httpOptions
    );
  }

  getFastagRechargeRes(customer, amount) {
    let body = {
      args: {
        party: customer,
        amount: amount,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    // let authToken = "token 9ca914ac2c9bb6b:3115d3cadf092b0";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-recharge`,
      body,
      httpOptions
    );
  }

  //Recharge History
  s_getRechargeTxnHistory(body) {
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/get-recharge-txn-history`,
      body,
      httpOptions
    );
  }

  getErpNextTransactionHistory(transactionParam) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let customParams = transactionParam;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-erpnext-transaction-history`,
      httpOptions
    );
  }

  getFasTagCustomerDetails(userParams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let customParams = userParams;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customparams: customParams,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-erpnext-fastag-customer`,
      httpOptions
    );
  }

  // get-fastag-customer
  getFastagCustomerId(customer) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    let authToken = apiKey + ":" + apiSecret;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
        customer: customer,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-fastag-customer`,
      httpOptions
    );
  }

  getFastagEncryptDetails(customerDetails) {
    let body = customerDetails;

    // console.log("encrypt body in erp--->",body);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        header: "partner1",
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-encrypt`,
      body,
      httpOptions
    );
  }

  getFastagWalletBalance(customerEncryptDetails, marchantId) {
    // console.log("marchantId in erp--->", marchantId);
    // console.log("encryptCustomerDeatils in erp---->", customerEncryptDetails);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: marchantId,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastagWalletValidation`,
      customerEncryptDetails,
      httpOptions
    );
  }

  ///fastag-toll-Txn

  // From Erp-next
  getFastagTollTxn(body) {
    // let marchentId=localStorage.getItem("fasTagCustomerId");
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;

    let authToken = `5d86d079564a18a:80e46996b1b9eaf`;
    // let checksum_key="95ef659313847c7485d43d66b8c5b9e8b817c9c136d2798333c5df693b6efc2a";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-toll-Txn`,
      body,
      httpOptions
    );
  }

  //From HDFC

  fromHdfcFastagTollTxn(body, Fastagcustid) {
    // console.log('fromHdfcFastagTollTxn body-->',body);
    // console.log("this.Fastag_Cust_Id--->",Fastagcustid);

    let checksum_key =
      "95ef659313847c7485d43d66b8c5b9e8b817c9c136d2798333c5df693b6efc2a";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        checksumkey: checksum_key,
        fascusid: Fastagcustid,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/hdfc-fastag-toll-Txn`,
      body,
      httpOptions
    );
  }

  //from HDFC parent wallet Txn history
  parentWalletTxn(body, Fastagcustid) {
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        fascusid: Fastagcustid,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/hdfc-parent-wallet-Txn`,
      body,
      httpOptions
    );
  }

  // fastag-receviable-amt

  getReceviableAmt(customer) {
    let supplier = customer;
    // let supplier="Test"
    let body = {
      supplier: supplier,
    };

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "3543223fc0a5174:399d026f14a672c";
    // console.log(authToken);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/receviable-amt`,
      body,
      httpOptions
    );
  }

  getWithDrawBal(customer1, amount) {
    //   let body = {
    //     "customer": customer,
    //     "amount": amount
    // }

    console.log("withdraw amount in erp----->", amount);

    // let customer="Test";

    console.log("withdraw customer in erp---->", customer1);
    let body = {
      customer: customer1,
      amount: amount,
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/withdraw-balance`,
      body,
      httpOptions
    );
  }

  getFastagBal(Fastagcustid, body) {
    // console.log(id);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        customerid: Fastagcustid,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-balance`,
      body,
      httpOptions
    );
  }

  getCreditBal(customer) {
    let body = {
      customer: customer,
    };

    let token = "token 5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: token,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/credit-bal`,
      body,
      httpOptions
    );
  }

  ///overdue-amount

  getOverdueAmount(customer) {
    let body = {
      customer: customer,
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/overdue-amount`,
      body,
      httpOptions
    );
  }

  ///total-outstanding-amount

  getTotalOutstandingAmt(customer) {
    let body = {
      customer: customer,
    };

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = `5d86d079564a18a:80e46996b1b9eaf`;

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/total-outstanding-amount`,
      body,
      httpOptions
    );
  }

  getCashBal(customer) {
    let body = {
      customer: customer,
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey + ":" + apiSecret;
    let authToken = `5d86d079564a18a:80e46996b1b9eaf`;

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/cash-bal`,
      body,
      httpOptions
    );
  }

  getFastagDecryptDetails(fastagWalletDetails) {
    console.log("WalletCDeatils in erp---->", fastagWalletDetails);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        header: "partner1",
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-decrypt`,
      fastagWalletDetails,
      httpOptions
    );
  }

  // fastag-txn-decrypt

  // getFastagTxnDecryptDetails(fastagTxnDetails){

  //   // console.log("WalletCDeatils in erp---->", fastagWalletDetails);

  //   let httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json', 'header': 'partner1'}),
  //   };

  //   return this._http.post(`${this.serverHostPort}/fastag-txn-decrypt`,fastagTxnDetails,httpOptions);

  //   }

  getFastagTranscationEnquiry(customerEncryptDetails, marchantId) {
    // console.log("marchantId in erp1--->", marchantId);

    // console.log("encryptCustomerDeatils in erp1---->", customerEncryptDetails);

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: marchantId,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/fastag-txn-enquiry`,
      customerEncryptDetails,
      httpOptions
    );
  }

  getFasTagWalletValidation(fasTagCustomerDetails) {
    let merchantId = fasTagCustomerDetails.merchantID;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: merchantId,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/fastag-wallet-validation`,
      fasTagCustomerDetails,
      httpOptions
    );
  }

  getFasTagTransactionHistory(fasTagCustomerDetails) {
    let merchantId = fasTagCustomerDetails.merchantID;
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: merchantId,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/fastag-transaction-history`,
      fasTagCustomerDetails,
      httpOptions
    );
  }

  setCardLimits(cardLimitBody) {
    console.log(cardLimitBody);
    let accessstoken = localStorage.getItem("bpclFAAccessToken")
      ? localStorage.getItem("bpclFAAccessToken")
      : "abcd";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `Bearer ${accessstoken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/set-fc-limit`,
      cardLimitBody,
      httpOptions
    );
  }

  //payout

  // getAllPurchaseOrder(purchaseOrderId){
  //   let apiKey = localStorage.getItem('apiKey') ? localStorage.getItem('apiKey') : 'abcd';
  //   let apiSecret = localStorage.getItem('apiSecret') ? localStorage.getItem('apiSecret') : 'abcd';
  //   // let params = new HttpParams();
  //   let customParams = purchaseOrderId;
  //   // console.log(customParams);
  //   let authToken = apiKey+":"+apiSecret;
  //   // let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
  //   let httpOptions = {
  //     headers: new HttpHeaders({ 'Content-Type': 'application/json','token': authToken,'customparams':customParams})
  //   };
  //   return this._http.get(`${this.serverHostPort}/get-purchase-invoice-list`, httpOptions);
  //  }

  getPurchaseInvoice() {
    //let customParams=invoiceParams;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    //// let apiSecret=localStorage.getItem('apiSecret')?localStorage.getItem('apiSecret'):'abcd';
    // let apiSecret="token 22b5e6630022577:ffc01f00f98895e";
    // console.log(customParams)
    ////let authToken=apiKey+apiSecret;

    let authToken = "22b5e6630022577:ffc01f00f98895e";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: authToken,
      }),
    };
    return this._http.get(
      `${this.serverHostPort}/get-purchase-invoice-list`,
      httpOptions
    );
  }

  //get-current-Threshold-Recharge-Amt
  getCurrentThresholdRechargeAmt(customer) {
    let body = {
      args: {
        customer: customer,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get-current-Threshold-Recharge-Amt`,
      body,
      httpOptions
    );
  }

  ///auto-wallet-recharge

  updateAutoWalletRecharge(object) {
    let body = {
      args: object,
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/auto-wallet-recharge`,
      body,
      httpOptions
    );
  }

  //Intrcity Sub Customer Details
  getCustomerDetailsofIntrcity(email) {
    let body = {
      args: {
        username: email,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_intrcity_customer_details`,
      body,
      httpOptions
    );
  }

  //get_intrcity_super_customer_details
  S_getSuperustomerDetailsofIntrcity(cus) {
    let body = {
      args: {
        customer: cus,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_intrcity_super_customer_details`,
      body,
      httpOptions
    );
  }

  //get_customer_details

  getSubCustomerDetails(email) {
    let body = {
      args: {
        username: email,
      },
    };
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_customer_details`,
      body,
      httpOptions
    );
  }

  //credits_allocation
  S_CreditsAllocation(data) {
    let body = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/credits_allocation`,
      body,
      httpOptions
    );
  }

  ///super_cus_alloc_history

  s_getAllocationHistory(data) {
    let body = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        token: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_super_cus_alloc_history`,
      body,
      httpOptions
    );
  }

  s_getNewSalesInvoices(data) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/get_new_sales_invoices`,
      payload,
      httpOptions
    );
  }

  getJioBpTxn(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getJioBpTxn`,
      payload,
      httpOptions
    );
  }

  getJioBPCardBalDetails(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getJioBPCardBalDetails`,
      payload,
      httpOptions
    );
  }

  //jioBpCreditAlloc

  jioBpCreditAlloc(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/jioBpCreditAlloc`,
      payload,
      httpOptions
    );
  }

  jioBpAutoRecharge(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/jioBpAutoRecharge`,
      payload,
      httpOptions
    );
  }

  //jioBpRecharge

  jioBpRecharge(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/jioBpRecharge`,
      payload,
      httpOptions
    );
  }

  //CredirBalNewAPI

  getCreditBalDetails(data: any) {
    let payload = data;
    // console.log(payload)

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/CredirBalNewAPI`,
      payload,
      httpOptions
    );
  }

  //tyrepurchasedetails

  tyrePurchaseHistory(data: any) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/tyrepurchasedetails`,
      data,
      httpOptions
    );
  }

  //linkedvehiclesofcustomer

  getLinkedVehiclesofCustomer(data: any) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/linkedvehiclesofcustomer`,
      data,
      httpOptions
    );
  }

  // getTyresKeyPoints

  getTyresKeyPoints(data: any) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getTyresKeyPoints`,
      data,
      httpOptions
    );
  }

  // getLinkedVehicleDeatils

  getAllLinkedVehicles(data: any) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getLinkedVehicleDeatils`,
      data,
      httpOptions
    );
  }

  //uploadTyreServiceReport
  uploadTyreServiceReport(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";
    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };
    return this._http.post(
      `${this.serverHostPort}/uploadTyreServiceReport`,
      payload,
      httpOptions
    );
  }

  //get Tyre service reports
  getAllTyreServiceReports(customparams: any) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/getTyreServiceReports`,
      httpOptions
    );
  }

  //getSmartTyreRealTimeData

  getSmartTyreRealTimeData(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "4567d5a4c58d5ba:50f7dcc70df884f";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getSmartTyreRealTimeData`,
      payload,
      httpOptions
    );
  }

  getRealTimeData(data: any): Observable<any> {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "4567d5a4c58d5ba:50f7dcc70df884f";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return new Observable((observer) => {
      setInterval(
        () =>
          observer.next(
            this._http.post(
              `${this.serverHostPort}/getSmartTyreRealTimeData`,
              payload,
              httpOptions
            )
          ),
        3000
      );
    });
  }

  //getOdometerData

  getOdometerData(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "4567d5a4c58d5ba:50f7dcc70df884f";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getOdometerData`,
      payload,
      httpOptions
    );
  }

  //getTyreNeedService
  getTyreNeedService(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getTyreNeedService`,
      payload,
      httpOptions
    );
  }

  getVehilcleCurrentLocation(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "4567d5a4c58d5ba:50f7dcc70df884f";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getVehilcleCurrentLocation`,
      payload,
      httpOptions
    );
  }

  getVehicleTrackingLogName(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getVehicleTrackingLogName`,
      payload,
      httpOptions
    );
  }

  updateVeicleTrackingLog(data: any, customparams: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.put(
      `${this.serverHostPort}/updateVeicleTrackingLog`,
      payload,
      httpOptions
    );
  }

  getLinkedTyres(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/getLinkedTyres`,
      payload,
      httpOptions
    );
  }

  routePriceCalculate(data: any) {
    let payload = data;

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
      }),
    };

    return this._http.post(
      `${this.serverHostPort}/calculateRoutePrice`,
      payload,
      httpOptions
    );
  }

  getDLDetails(customparams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/getVehicleDrivingLicence`,

      httpOptions
    );
  }

  getFCDetails(customparams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/getVehicleFitness`,

      httpOptions
    );
  }
  getRCDetails(customparams) {
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/getVehicleRegistration`, httpOptions
    );
  }

  createDL(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,

      }),
    };

    return this._http.post(
      `${this.serverHostPort}/createVehicleDrivingLicence`,
      payload,
      httpOptions
    );

  }

  createFC(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,

      }),
    };

    return this._http.post(
      `${this.serverHostPort}/createVehicleFitness`,
      payload,
      httpOptions
    );

  }

  createRC(data: any) {
    let payload = data;
    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,

      }),
    };

    return this._http.post(
      `${this.serverHostPort}/createVehicleRegistration`,
      payload,
      httpOptions
    );
  }

  getVehicleCategoryList(customparams: any) {

    let apiKey = localStorage.getItem("apiKey")
      ? localStorage.getItem("apiKey")
      : "abcd";
    let apiSecret = localStorage.getItem("apiSecret")
      ? localStorage.getItem("apiSecret")
      : "abcd";
    // let authToken = apiKey+":"+apiSecret;
    let authToken = "5d86d079564a18a:80e46996b1b9eaf";

    let httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authtoken: `token ${authToken}`,
        params: customparams,
      }),
    };

    return this._http.get(
      `${this.serverHostPort}/getVehicleCategory`, httpOptions
    );
  }


  checkFileSize(file: any) {
    // let checkFileSize = parseInt((file.size / 1024**2).toFixed(2));
    let checkFileSize = (file.size / 1024 ** 2)
    console.log("file-chosed-to-upload-", file, "checkFileSize--", checkFileSize)
    if (checkFileSize > 5) {
      console.log('File size greater than 5MB. Please upload a file less than 5MB')
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      return Toast.fire({
        icon: 'error',
        title:
          'File size greater than 5MB. Please upload a file less than 5MB',
      });
      // return `File size greater than 5MB. Please upload a file less than 5MB`
    } else {
      // return "File size within the limit 5MB"
      console.log('File size within the limit 5MB')
      // return `File size within the limit 5MB`
      const Toast = Swal.mixin({
        toast: true,
        position: 'top-right',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        },
      });
      return Toast.fire({
        icon: 'info',
        title:
          'File size within the limit 5MB',
      });
    }
  }
}

// downloadProductTemp(company_id,company_code,dept_name){
//   let token = localStorage.getItem('token') ? localStorage.getItem('token') : "abcd";
//   let params = new HttpParams();
//   params = params.append('companyIdParam',company_id);
//   params = params.append('companyCodeParam',company_code);
//   params = params.append('deptNameParam',dept_name);
//   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'token': token }),
//                         params: params };
//   return this._http.get(`${this.serverHostPort}/get-prod-temp`, httpOptions);
// }
