import { Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "../common/service/erpnextapi.service";

@Component({
  selector: "app-purchase-order",
  templateUrl: "./purchase-order.component.html",
  styleUrls: ["./purchase-order.component.css"],
})
export class PurchaseOrderComponent implements OnInit {
  searchString: any = "";
  dataLoading: any;

  tyreHistoryData1: any = [];

  customer: any;
  tyreHistoryData: any = [];
  tyreHistoryDatalength: number = 0;
  noTyreHistoryData: boolean = false;

  noOfVehicles: number = 0;
  avgMaintainceCost: number = 0;
  avgBreakdownCost: number = 0;
  avgCostPerKm: number = 0;
  noOfSmartTyres: number = 0;
  noOfRegularTyres: number = 0;
  noOfVehiclesWithSmartTyre: any = [];
  noOfVehiclesWithRegularTyre: any = [];
  noOfTyreNeedService: number = 0;
  totalMaintenanceCost:number=0;
  totalBreakDownCost:number=0;

  p1: any = 1;
  p2:any=1;

  vehicleNoArray: any = [];
  vehicleList: any = [];
  vehicleList1: any = [];

  MaintainceCostArray: any = [];
  BreakdownCostArray: any = [];
  DurabilityArray: any = [];
  serialNoArray: any = [];
  // vehicleNoArray:any=[]

  vehicleNo: any = "";
  MaintainceCost: any = "";
  BreakdownCost: any = "";
  Durability: any = "";
  serialNo: any = "";
  smartTyre: any = "";

  kpiTabActive: boolean = true;
  tyrePurHisTabActive: boolean = false;

  tabTitle: any = "";
  tabActive: boolean = false;
  filterVehicleDeatilsTab: boolean;
  filterTyreDetailsTab: boolean;
  tyreNeedServiceDetailsTab:boolean;
  filterdData: any = [];

  tyreNeedserviceList:any=[]
  isNeedServiceData:any=false;

  serviceCardLoader:any=true;


  constructor(private _erpService: ErpnextapiService) {}

  ngOnInit(): void {
    this.customer = localStorage.getItem("customer");
    // this.customer = "Logesh";

    this.getTyreKeyPointsData();
    this.getTyrePurchaseHistory();
    this.getVehiclesList();
    this.getTyresWhichNeedService();


  }

  keyPintsTab() {
    this.kpiTabActive = true;
    this.tyrePurHisTabActive = false;
  }

  getVehiclesList() {
    let payload = {
      customer: this.customer,
      doctype: "Vehicle Registration Certificate",
    };
    this._erpService.getAllLinkedVehicles(payload).subscribe((res: any) => {
      // console.log("getVehiclesList-->", res);
      if (res.errorCode == "0" && res.data.message) {
        let response = res.data.message;
        this.vehicleList1 = response;
        this.vehicleList = response;
      }
    });
  }

  tyrePurchaseHistoryTab() {
    this.kpiTabActive = false;
    this.tyrePurHisTabActive = true;
  }

  getTyreKeyPointsData() {
    let payload = {
      customer: this.customer,
    };
    this._erpService.getTyresKeyPoints(payload).subscribe((res: any) => {
      // console.log("getTyreKeyPointsData---->", res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message;

        this.noOfVehicles = response.no_of_vehicles;
        this.noOfVehiclesWithSmartTyre = response.vehicles_with_smart_tyre;
        this.noOfVehiclesWithRegularTyre = response.vehicles_with_regular_tyre;
        this.noOfSmartTyres = response.no_of_smart_tyres;
        this.noOfRegularTyres = response.no_of_regular_tyres;
        // this.noOfTyreNeedService = response.no_of_tyres_need_service;
        this.avgMaintainceCost = response.avgMaintainceCost;
        this.avgBreakdownCost = response.avgBreakdownCost;

        this.totalMaintenanceCost=response.total_maintenance_cost;
        this.totalBreakDownCost=response.total_breakdown_cost;
        this.avgCostPerKm=response.avgCost_Km;
        //(TMC+TBC)/No of tyres
        // this.avgCostPerKm = ( this.totalMaintenanceCost+ this.totalBreakDownCost)/ this.tyreHistoryDatalength;
        // console.log( this.avgCostPerKm)


      }
    });
  }

  getTyrePurchaseHistory() {
    this.noTyreHistoryData = false;
    this.dataLoading = true;
    let payload = {
      customer: this.customer,
      filter_serial_no: this.serialNo,
      filter_vehicle_no: this.vehicleNo,
      filter_is_smart_tyre: this.smartTyre,
    };
    // console.log(payload);
    this._erpService.tyrePurchaseHistory(payload).subscribe((res: any) => {
      // console.log("getTyrePurchaseHistory--->", res);

      if (
        res["errorCode"] == 0 &&
        res["data"]["message"] &&
        res["data"]["message"].length > 0 &&
        res["data"]["message"] != "No Data Found"
      ) {
        let response = res["data"]["message"];
        this.tyreHistoryData = response;
        this.tyreHistoryData1 = response;
        this.tyreHistoryDatalength = this.tyreHistoryData.length;
        // this.avgCostPerKm = ( this.totalMaintenanceCost+ this.totalBreakDownCost)/ this.tyreHistoryDatalength;
        console.log( this.avgCostPerKm)


        for (let item of this.tyreHistoryData) {
          // MC += item.cummulative_preventive_maintenance_cost;
          // BC += item.cummulative_breakdown_cost;
          // costperkm += item.cost_per_kms;

          // if (item.is_smart_tyre == 0) {
          //   this.noOfRegularTyres += 1;
          // } else {
          //   this.noOfSmartTyres += 1;
          // }

          if(item.nsd_value<4){
            item.nsd_status="Needs Change"
          }
          else if(item.nsd_value>=4 && item.nsd_value<10){
            // item.nsd_status="Tyre wear high"
            item.nsd_status="Low"
          }
          else if(item.nsd_value>=10 && item.nsd_value<20){
            // item.nsd_status="Tyre wear medium"
            item.nsd_status="Medium"
          }
          else if(item.nsd_value>=20){
            // item.nsd_status="Tyre wear low"
            item.nsd_status="High"
          }
          else{
            item.nsd_status="NULL"
          }

          if (item.vehicle_no && item.vehicle_no != null) {


            if (!this.vehicleNoArray.includes(item.vehicle_no)) {
              this.vehicleNoArray.push(item.vehicle_no);
            }
          }

          // if (item.serial_no && item.serial_no != null) {
          //   this.serialNoArray.push(item.serial_no);
          // }

          // if (
          //   item.cummulative_preventive_maintenance_cost.toString() &&
          //   item.cummulative_preventive_maintenance_cost != null
          // ) {
          //   if (
          //     !this.MaintainceCostArray.includes(
          //       item.cummulative_preventive_maintenance_cost
          //     )
          //   ) {
          //     this.MaintainceCostArray.push(
          //       item.cummulative_preventive_maintenance_cost
          //     );
          //   }
          // }

          // if (
          //   item.cummulative_breakdown_cost.toString() &&
          //   item.cummulative_breakdown_cost != null
          // ) {
          //   if (
          //     !this.BreakdownCostArray.includes(item.cummulative_breakdown_cost)
          //   ) {
          //     this.BreakdownCostArray.push(item.cummulative_breakdown_cost);
          //   }
          // }
          // if (
          //   item.duration_in_operation.toString() &&
          //   item.duration_in_operation != null
          // ) {
          //   if (!this.DurabilityArray.includes(item.duration_in_operation)) {
          //     this.DurabilityArray.push(item.duration_in_operation);
          //   }
          // }
        }
        this.tyreHistoryData1=this.tyreHistoryData
        // console.log("vehicleNoArray-->", this.vehicleNoArray);

        // this.noOfVehicles = noofveh;
        // this.avgMaintainceCost = MC / this.tyreHistoryDatalength;
        // this.avgBreakdownCost = BC / this.tyreHistoryDatalength;
        // this.avgCostPerKm = costperkm / this.tyreHistoryDatalength;

        // console.log("noofveh, Mc, Bc, cpKm--->", noofveh, MC, BC, costperkm);

        if (this.tyreHistoryDatalength == 0) {
          this.noTyreHistoryData = true;
          this.dataLoading = false;
        }

        // console.log(this.tyreHistoryData);
        this.dataLoading = false;
      } else {
        this.tyreHistoryDatalength = 0;
        this.noTyreHistoryData = true;
        this.dataLoading = false;
      }
    });
  }

  cardClick(title: any) {
    // console.log("title-->", title);
    if (title) {
      this.tabActive = true;
      this.tabTitle = title;
      if (title == "Number of Vehicles") {
        this.filterVehicleDeatilsTab = true;
        this.filterTyreDetailsTab = false;
        this.tyreNeedServiceDetailsTab=false;
        this.filterdData = this.vehicleList1;
      } else if (title == "No of Vehicles with Smart Tyre") {
        this.filterVehicleDeatilsTab = true;
        this.filterTyreDetailsTab = false;
        this.tyreNeedServiceDetailsTab=false;
        let data = [];
        // let filter = this.tyreHistoryData1.filter((tyre: any) => {
        //   return tyre.is_smart_tyre === 1;
        // });
        // this.filterdData = filter;
        if (this.noOfVehiclesWithSmartTyre.length > 0 && this.vehicleList1) {
          let filter = this.vehicleList1.filter((veh: any, index: any) => {
            for(let vehicle of this.noOfVehiclesWithSmartTyre){
              return veh.vehicle_no === vehicle;
            }

          });
          this.filterdData = filter;
          // console.log( "no of vehicles with smart tyre-->",this.filterdData)
        } else {
          this.filterdData = [];
        }

        // console.log("Smart Tyres", filter);
      } else if (title == "No of Vehicles with Regular Tyre") {
        this.filterVehicleDeatilsTab = true;
        this.filterTyreDetailsTab = false;
        this.tyreNeedServiceDetailsTab=false;
        // let filter = this.tyreHistoryData1.filter((tyre: any) => {
        //   return tyre.is_smart_tyre === 0;
        // });
        if (this.noOfVehiclesWithRegularTyre.length > 0 && this.vehicleList1) {
          let filter = this.vehicleList1.filter((veh: any, index: any) => {
            for(let vehicle of this.noOfVehiclesWithRegularTyre ){
              return veh.vehicle_no === vehicle;
            }

          });
          this.filterdData = filter;
          // console.log("Regular Tyres", filter);
        } else {
          this.filterdData = [];
        }
      } else if (title == "No of Smart Tyres") {
        this.filterVehicleDeatilsTab = false;
        this.filterTyreDetailsTab = true;
        let data = [];
        let filter = this.tyreHistoryData1.filter((tyre: any) => {
          return tyre.is_smart_tyre === 1;
        });
        this.filterdData = filter;
        // console.log("Smart Tyres", filter);
      } else if (title == "No of Regular Tyres") {
        this.filterVehicleDeatilsTab = false;
        this.filterTyreDetailsTab = true;
        this.tyreNeedServiceDetailsTab=false;
        let data = [];
        let filter = this.tyreHistoryData1.filter((tyre: any) => {
          return tyre.is_smart_tyre === 0;
        });
        this.filterdData = filter;
        // console.log("Regular Tyres", filter);
      } else if (title == "No of Tyre Which Need Service") {
        this.tyreNeedServiceDetailsTab=true;
        this.filterVehicleDeatilsTab = false;
        this.filterTyreDetailsTab = false;
        // this.filterdData = [];
        this.tyreNeedserviceList=[];
        this.getTyresWhichNeedService();

      }
    }
  }

  filterVehNo(event) {
    let value = event.target.value;
    // console.log("filterVehNo-->", event.target.value);
    if (value) {
      this.vehicleNo = value.trim();
      // this.filterFunction();
    } else {
      this.vehicleNo = "";
    }
    this.getTyrePurchaseHistory();
  }

  filterIsSmartTyre(event) {
    let value = event;
    // console.log("filterIsSmartTyre-->", value);
    if (event && event != undefined) {
      if (value == "yes") {
        this.smartTyre = 1;
      } else if (value == "no") {
        this.smartTyre = 0;
      }
    } else {
      this.smartTyre = "";
    }
    this.getTyrePurchaseHistory();
  }

  filterSerialNo(event) {
    let value = event.target.value;
    // console.log("filterSerialNo-->", value);
    if (value) {
      this.serialNo = value.trim();
      // this.filterFunction();
    } else {
      this.serialNo = "";
    }
    this.getTyrePurchaseHistory();
  }

  getTyresWhichNeedService(){

    this.isNeedServiceData=true;
    let payload={
     customer: this.customer
    }

    this._erpService.getTyreNeedService(payload).subscribe((res:any)=>{
      // console.log("tyres need service-->",res);
      if(res.errorCode==0 && res.data.message && typeof(res.data.message)!='string'){
        let response=res.data.message;
        this.tyreNeedserviceList=response;

        for(let item of  this.tyreNeedserviceList){
          if(item.nsd_value<=4){
            item.nsd_status="Needs Change"
          }
          else if(item.nsd_value>4 && item.nsd_value<=10){
            item.nsd_status="Low"
          }
          else if(item.nsd_value>10 && item.nsd_value<=20){
            item.nsd_status="Medium"
          }
          else if(item.nsd_value>20){
            item.nsd_status="High"
          }
          else{
            item.nsd_status="NULL"
          }


        }

        // console.log(this.tyreNeedserviceList);
        this.noOfTyreNeedService= this.tyreNeedserviceList.length
        this.isNeedServiceData=false;
        this.serviceCardLoader=false;

      }
      else{
        this.noOfTyreNeedService=0
        this.isNeedServiceData=false;
        this.serviceCardLoader=false;
      }
    })
  }



}
