import {
  HttpClient,
  HttpHeaders,
  HttpParamsOptions,
} from "@angular/common/http";
import { Component, OnInit } from "@angular/core";

import {
  FormControl,
  FormGroup,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ErpnextapiService } from "../common/service/erpnextapi.service";
// import { Twilio } from "twilio";

@Component({
  selector: "app-vehicle-document",
  templateUrl: "./vehicle-document.component.html",
  styleUrls: ["./vehicle-document.component.css"],
})
export class VehicleDocumentComponent implements OnInit {
  vehicle_Form: any;
  Details_of_Vehicle_Form: any;
  Query_Number: any;

  AuthToken: any;
  customerDetails: any;
  Insurance_Policy_file: File = null;
  Vehicle_RC_file: File = null;
  Vehicle_Form_Response: any;
  existVehicleNo: any;
  isSubmit: boolean = true;

  public accountSid = environment.TWILIO_ACCOUNT_SID;

  public authToken = environment.TWILIO_AUTH_TOKEN;
  querySubscription: any;

  // client = new Twilio(this.accountSid, this.authToken);

  constructor(
    private fb: FormBuilder,
    private _actRoute: ActivatedRoute,
    private _http: HttpClient,
    private _tostr: ToastrService,
    private _erpNextApiService: ErpnextapiService,
    private router: Router
  ) {
    this.vehicle_Form = new FormGroup({
      username: new FormControl("", [Validators.required]),
      user: new FormControl("", [Validators.required]),
      customer_name: new FormControl("", [Validators.required]),
      customer_info: new FormControl("", [Validators.required]),
      phone_no: new FormControl("", [Validators.required]),
      vehicle_no: new FormControl("", [
        Validators.required,
        Validators.pattern("^[A-Za-z]{2}[0-9]{2}[A-Za-z]{2}[0-9]{4}$"),
      ]),
      existing_insurance_policy: new FormControl(""),
      vehicle_rc: new FormControl(""),
    });

    // force route reload whenever params change;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  ngOnInit(): void {
    this._actRoute.queryParams.subscribe((qry) => {
      this.Query_Number = qry["p"];
      // console.log("************", this.Query_Number);
    });
    if (this.Query_Number) {
      //calling authtoken-api
      this.login();
    } else {
      this._tostr.error("User Not Found");
    }
  }

  login() {
    let formData = {
      usr: environment.usr,
      pwd: environment.pwd,
    };

    // this.dataLoading = true;
    this._erpNextApiService.login(formData).subscribe((res) => {
      // console.log("loginResponse--->", res);

      if (res["errorCode"] == "0") {
        this.AuthToken = `token ${res["data"]["message"].api_key}:${res["data"]["message"].api_secret}`;
        // console.log("token--->", this.AuthToken);
        this.getUserDetails();
        // this.checkExistVehicleNo();
      } else {
        console.log("loginerror");
      }
    });
  }

  getUserDetails() {
    let token = this.AuthToken;
    let phone_no = this.Query_Number;
    this._erpNextApiService
      .getUserDetailsInsInfo(token, phone_no)
      .subscribe((res) => {
        console.log(res);

        // console.log("customerDetails---->", res["data"]["data"]);
        this.customerDetails = res["data"]["data"];

        if (res["errorCode"] == "0") {
          if (this.customerDetails.length != 0) {
            this.vehicle_Form.controls.username.setValue(
              `${res["data"]["data"][0]["full_name"]}`
            );
            this.vehicle_Form.controls.user.setValue(
              `${res["data"]["data"][0]["name"]}`
            );
            this.vehicle_Form.controls.customer_name.setValue(
              `${res["data"]["data"][0]["customer"]}`
            );
            this.vehicle_Form.controls.customer_info.setValue(
              `${res["data"]["data"][0]["customer"]}`
            );
            this.vehicle_Form.controls.phone_no.setValue(
              `${res["data"]["data"][0]["mobile_no"]}`
            );
          }
        } else {
          this._tostr.error("User Not Found");
        }
      });
  }

  vehicleNo_ChangeEvent(event) {
    let currentVehicleNo = event.target.value;
    let token = this.AuthToken;
    // console.log("exist vehicleNo----->", currentVehicleNo);
    this.checkExistVehicleNo(currentVehicleNo, token);
  }

  checkExistVehicleNo(vehicleNo, authToken) {
    let token = authToken;
    let vehicle_no = vehicleNo;
    // console.log("vehicle-no--->", vehicle_no);

    this._erpNextApiService.getVehicleNo(token, vehicle_no).subscribe((res) => {
      // console.log("ExistVehicleNo Response--->", res["data"]["data"]);
      let Response = res["data"]["data"];

      if (Response.length > 0) {
        this.existVehicleNo = Response;
      }
    });
  }

  InsurancePolicy_ChangeEvent(event) {
    // console.log("Insurance event--->", event);

    if (event.target.files.length > 0) {
      this.Insurance_Policy_file = (event.target as HTMLInputElement).files[0];
      // console.log(
      //   " this.Insurance_Policy_file-------",
      //   this.Insurance_Policy_file
      // );
      this.vehicle_Form.patchValue({
        existing_insurance_policy: this.Insurance_Policy_file,
      });
      // console.log(this.vehicle_Form.get("existing_insurance_policy").value);
      this.InsurancePolicy_file();
    }
  }

  VehicleRc_ChangeEvent(event) {
    if (event.target.files.length > 0) {
      this.Vehicle_RC_file = (event.target as HTMLInputElement).files[0];

      // console.log("Vehicle Rc File---->", this.Vehicle_RC_file);
      this.vehicle_Form.patchValue({
        vehicle_rc: this.Vehicle_RC_file,
      });

      this.VehicleRc_file();
    }
  }

  Upload() {
    if (this.customerDetails.length > 0) {
      if (this.vehicle_Form.valid) {
        let token = this.AuthToken;
        this.Details_of_Vehicle_Form = this.vehicle_Form.value;
        // console.log("Vehicle-form---->", this.Details_of_Vehicle_Form);

        this._erpNextApiService
          .newVehicleInsReq(this.Details_of_Vehicle_Form, token)
          .subscribe((res) => {
            // console.log("Form Response----->", res);

            if (res["errorCode"] == "0") {
              this._tostr.success("Request successfully submited");
              this.router.navigate(["/insurance-dashboard"]);

              // setTimeout(function(){

              // }, 2000);
            }
          });
      } else {
        this._tostr.error("Enter valid details");
        // this.router.navigate(['/insurance-dashboard']);
      }
    } else {
      this._tostr.error("User Not Found");
    }

    // // console.log("------------------", this.customerDetails);
    // if (this.vehicle_Form.valid && this.customerDetails.length) {
    //   // this._tostr.success("Request successfully submited");
    //   this.Details_of_Vehicle_Form = this.vehicle_Form.value;
    //   // console.log("Vehicle-form---->", this.Details_of_Vehicle_Form);

    //   const head = {
    //     headers: {
    //       'Authorization': this.AuthToken
    //     }
    //   };

    //   this._http.post<any>(`${this.BaseUrl}/api/resource/New Vehicle Insurance Request`, this.Details_of_Vehicle_Form, head).subscribe(res => {
    //     console.log('FORM-RESPONSE--->', res);
    //     if (res) {
    //       this._tostr.success("Request successfully submited");
    //       // this.sendWhatsappMsg(res["data"]["data"]);
    //     }
    //     this.Vehicle_Form_Response = res;

    //   });
    // }
    // else {
    //   this._tostr.error("Enter valid details");
    // }
  }

  InsurancePolicy_file() {
    let token = this.AuthToken;
    // console.log("AuthToken---------------", token);
    // console.log(
    //   "Name-------->",
    //   this.vehicle_Form.get("existing_insurance_policy").value.name
    // );
    // console.log(
    //   "value--------->",
    //   this.vehicle_Form.get("existing_insurance_policy").value
    // );

    let formData: any = new FormData();
    formData.append(
      "file",
      this.vehicle_Form.get("existing_insurance_policy").value,
      this.vehicle_Form.get("existing_insurance_policy").value.name
    );
    // formData.append("file", this.vehicle_Form.get('existing_insurance_policy').value);

    this._erpNextApiService.uploadInsDoc(token, formData).subscribe((res) => {
      // console.log(res);

      if (res["errorCode"] == "0") {
        this.vehicle_Form.patchValue({
          existing_insurance_policy: res["data"]["message"]["file_url"],
        });
      } else {
        // console.log("Failed to upload Insurance-File");
      }
    });
    //    (error) => {
    //      // this.error = true;
    //      // this.errorMessage = error.message;
    //      // this.dataLoading = false;
    //      console.log("user error");
    //    },
    //    () => {
    //      // this.dataLoading = false;
    //    });

    // let head = {
    //   headers: {
    //     "Accept": "application/json",
    //     "Authorization": this.AuthToken
    //   }
    // }
    // console.log("headers", head)

    // this._http.post<any>(`${this.BaseUrl}/api/method/upload_file`, formData, head).subscribe(res => {
    //   // console.log("Insurance-UPLOAD-FILES-RESPONSE---->", res);
    //   // this.vehicle_Form.controls.existing_insurance_policy.setValue(res["message"]["file_url"]);

    //   if (res) {
    //     // console.log("Success");
    //     this.vehicle_Form.controls.existing_insurance_policy.setValue(res["message"]["file_url"]);

    //   }
    //   else {
    //     this._tostr.error("Vehicle insurance policy not uploaded");

    //   }
    //   // return res["message"]["file_url"]

    // })
  }

  VehicleRc_file() {
    let token = this.AuthToken;
    // console.log("AuthToken---------------", token);
    // console.log("Name-------->",this.vehicle_Form.get('vehicle_rc').value.name);
    // console.log("value--------->", this.vehicle_Form.get("vehicle_rc").value);

    let formData: any = new FormData();
    formData.append(
      "file",
      this.vehicle_Form.get("vehicle_rc").value,
      this.vehicle_Form.get("vehicle_rc").value.name
    );
    // formData.append("file", this.vehicle_Form.get('existing_insurance_policy').value);
    // console.log(formData);

    this._erpNextApiService.uploadInsDoc(token, formData).subscribe((res) => {
      // console.log(res);

      if (res["errorCode"] == "0") {
        this.vehicle_Form.patchValue({
          vehicle_rc: res["data"]["message"]["file_url"],
        });
      } else {
        console.log("Failed to upload Vehicle-Rc file");
      }
    });
  }

  //   const formData1 = new FormData();
  //   formData1.append("file", this.Vehicle_RC_file, this.Vehicle_RC_file.name);

  //   let head = {
  //     headers: {
  //       "Accept": "application/json",
  //       "Authorization": this.AuthToken
  //     }
  //   }
  //   this._http.post<any>(`${this.BaseUrl}/api/method/upload_file`, formData1, head).subscribe(res => {
  //     // console.log("Vehicle-UPLOAD-FILES-RESPONSE---->", res);
  //     // this.vehicle_Form.controls.vehicle_rc.setValue(res["message"]["file_url"]);

  //     if (res) {
  //       // console.log("Success");
  //       this.vehicle_Form.controls.vehicle_rc.setValue(res["message"]["file_url"]);

  //     }
  //     else {
  //       this._tostr.error("Vehicle RC policy not uploaded");

  //     }
  //     // return res["message"]["file_url"];
  //   });
  // }

  // sendWhatsappMsg(data){

  //   let body={
  //     userdata:data
  //   }

  //   this._http.post("http://localhost:8080/sendMessage",body).subscribe(res=>{
  //     console.log("sendMessage Response--->",res);
  //   })

  //   // this.client.messages.create({

  //   //   from: 'whatsapp:+14155238886',
  //   //   body: 'Thank you for sharing the documents. Our support team will contact you asap.',
  //   //   to: 'whatsapp:+19784876203'
  //   // });

  // }
}
