import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ErpnextapiService } from '../common/service/erpnextapi.service';

@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent implements OnInit {

  invoiceName;
  error: boolean = false;
  errorMessage: String = "";
  dataLoading: boolean = false;
  private querySubscription;
  savedChanges: boolean = false;
  invoiceList;
  config;
  userRole;
  // invoiceParams = '?limit_page_length=100&fields=%5B"name","posting_date","status","grand_total","sales_order"%5D'
 invoiceParams;
 userInfo;
 userEmail;
 customer;
 invoiceLength;
 p: number = 1;

 // baseServerUrl = "https://web.lnder.in";
 baseServerUrl = environment.erpBaseUrl;

  // ?limit_page_length=5&fields=["name","posting_date","status","grand_total"]
  constructor(private _erpNextApiService: ErpnextapiService,private router: Router) { }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.userEmail = localStorage.getItem('email');
    this.customer = localStorage.getItem('customer');
  // this.getUserDetails();
  if(this.userRole === 'Owner'){
    this. invoiceParams = `?fields=%5B"name","posting_date","sales_order","status","item_group","grand_total","owner"%5D&filters=[["customer","=","${this.customer}"],["payment_type","!=","Advance"]]&limit_page_length=100&order_by=name desc`;
  }else{
  this. invoiceParams = `?fields=%5B"name","posting_date","sales_order","status","item_group","grand_total"%5D&filters=[["customer","=","${this.customer}"],["owner","=","${this.userEmail}"],["payment_type","!=","Advance"]]&limit_page_length=100&order_by=name desc`;
  }
  this.getSalesInvoiceList();
    this.config = {
      id: 'custom',
      itemsPerPage: 10,
      currentPage: 1,
    };
    this.getLoggedInUser();
  }
  getLoggedInUser() {
    this._erpNextApiService.getUserDetails().subscribe((res) => {
    //console.log(res);
      if(res["data"]=== ''){
        this.logout();
      }
  })
  }
  logout(){
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    localStorage.removeItem('tax_category');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }
  // getUserDetails() {
  //   this.dataLoading = true;
  //   this.querySubscription = this._erpNextApiService.getUserDetails().subscribe((res) => {
  //     if (res["errorCode"] == '0') {
  //       this.error = false;
  //       this.errorMessage = "";
  //       this.dataLoading = false;
  //       this.userInfo = res["data"]["data"];
  //       // console.log(this.userInfo);
  //       if(this.userRole === 'Owner'){
  //         this. invoiceParams = `?fields=%5B"name","posting_date","status","grand_total","sales_order","owner"%5D&filters=[["customer","=","${this.customer}"]]&limit_page_length=100&order_by=posting_date desc`;
  //       }else{
  //       this. invoiceParams = `?fields=%5B"name","posting_date","status","grand_total","sales_order"%5D&filters=[["customer","=","${this.customer}"],["owner","=","${this.userEmail}"]]&limit_page_length=100&order_by=posting_date desc`;
  //       }
  //       this.getSalesInvoiceList();
  //     } else {
  //       this.error = true;
  //       this.errorMessage = res["errorMessage"];
  //       this.dataLoading = false;
  //     }
  //   },
  //     (error) => {
  //       this.error = true;
  //       this.errorMessage = error.message;
  //       this.dataLoading = false;
  //     },
  //     () => {
  //       this.dataLoading = false;
  //     });
  // }
  getSalesInvoiceList(){
  console.log(this.invoiceParams)
    this._erpNextApiService.getInvoicesList(this.invoiceParams).subscribe(res =>{
      
      console.log(res);
      if (res["errorCode"] == '0') {
        this.error = false;
        this.errorMessage = "";
        this.dataLoading = false;
        this.invoiceList=res["data"];
        if(this.invoiceList != undefined){
          this.invoiceLength = this.invoiceList.length;
          this.config = {
            id: 'custom',
            itemsPerPage: 10,
            currentPage: 1,
            totalItems: this.invoiceList.length
          };
        }
      }else {
        this.error = true;
        this.errorMessage = res["errorMessage"];
        this.dataLoading = false;
    }
  },
    (error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.dataLoading = false;
    },
    () => {
        this.dataLoading = false;
    });
    localStorage.removeItem('cartItems4Invoice');
  }
//   getNewInvoices(){
//   //console.log("Test");
//   //console.log(this.invoiceList.length)
//    let updatedInvParams = `?limit_page_length=50`
//  //console.log(updatedInvParams);
//    this._erpNextApiService.getInvoicesList(updatedInvParams).subscribe(res =>{
//     //console.log(res);
//         if (res["errorCode"] == '0') {
//           this.error = false;
//           this.errorMessage = "";
//           this.dataLoading = false;
//           this.invoiceList=res["data"];
//           this.config = {
//             id: 'custom',
//             itemsPerPage: 10,
//             currentPage: 1,
//             totalItems: this.invoiceList.length
//           };
//         }else {
//           this.error = true;
//           this.errorMessage = res["errorMessage"];
//           this.dataLoading = false;
//       }
//     },
//       (error) => {
//           this.error = true;
//           this.errorMessage = error.message;
//           this.dataLoading = false;
//       },
//       () => {
//           this.dataLoading = false;
//       });
//   }
}
