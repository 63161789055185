import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import Swal from "sweetalert2";

@Component({
  selector: "app-jio-bp-assign-credits",
  templateUrl: "./jio-bp-assign-credits.component.html",
  styleUrls: ["./jio-bp-assign-credits.component.css"],
})
export class JioBpAssignCreditsComponent implements OnInit {
  searchString: any = "";
  dataLoading: any;

  customer: any;
  JioBpBalance: number = 0;
  avl_creditBal: number = 0;
  set_autoRech_amt: number = 0;
  wallet_thr_limit: number = 0;
  intrcity_credit_limit: number = 0;

  cardBalanceDetails: any;
  cardDetailsDatalength: number = 0;
  noCardDetailsData: any = false;
  isCardDeatils: any = true;

  creditAllocCardDetails: any = [];
  autoRechargeCardDetails: any = [];

  totalAutoRechageLimit: any = 30000;
  cardDetails: any;

  creditAllocConfirmButton: any;
  autoRechConfirmButton: any;

  p1: any = 1;
  p2: any = 1;

  resMsg: any;
  autoRecharPopUp: any = false;
  totalAutoRechargeamt: number = 0;
  totalAutoRechargePer: number = 0;
  isAutoWalletEnabled: any;

  totalSetLimit: number = 0;

  constructor(
    private fb: FormBuilder,
    private _erpService: ErpnextapiService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe,
    private tostr: ToastrService
  ) {}

  ngOnInit(): void {
    this.customer = localStorage.getItem("customer");

    this.getCardDetails();
  }

  getCardDetails() {
    let body = {
      // customer:'UBS Intermediary'
      customer: this.customer,
    };

    this._erpService.getJioBPCardBalDetails(body).subscribe((res: any) => {
      // console.log("getCardDetails-->", res);

      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"]["exc_type"] != "ValidationError" &&
        res["data"].message
      ) {
        if (res["data"].message.card_details != "No record found") {
          let response = res.data.message;
          this.JioBpBalance =
            Math.round(100 * res.data.message.total_card_balance) / 100;
          console.log(this.JioBpBalance);
          if (response.auto_wallet_details) {
            this.set_autoRech_amt =
              response.auto_wallet_details.wallet_recharge_amount;
            this.wallet_thr_limit =
              response.auto_wallet_details.wallet_threshold_limit;
            this.isAutoWalletEnabled =
              response.auto_wallet_details.is_auto_recharge_enabled;
            // this.isAutoWalletEnabled = 0;
          }
          console.log(this.isAutoWalletEnabled);
          this.cardDetails = res.data.message.card_details;
          this.cardDetailsDatalength = this.cardDetails.length;

          let totalAmount = 0;

          this.cardDetails.forEach((id: any, i: any) => {
            totalAmount += id.avail_balance;
            this.creditAllocCardDetails.push(
              new Object({
                index: i,
                card_number: id.card_number,
                vehicle_number: id.vehicle_number,
                avail_balance: id.avail_balance,
                amount: id.avail_balance,
              })
            );

            this.autoRechargeCardDetails.push(
              new Object({
                index: i,
                card_number: id.card_number,
                vehicle_number: id.vehicle_number,
                avail_balance: id.avail_balance,
                avlBal_Percentage: (id.avail_balance / this.JioBpBalance) * 100,
                recharge_percentage: id.recharge_percentage,
                new_amount: 0,
                new_amount_percenatge: 0,
              })
            );
          });

          this.totalSetLimit = totalAmount;
          // this.totalSetLimit=8266.56;

          // console.log(this.autoRechargeCardDetails);

          if (this.cardDetailsDatalength == 0) {
            this.noCardDetailsData = true;
            // this.dataLoading=false;
          }

          this.isCardDeatils = false;
          // this.dataLoading=false;
        } else {
          this.isCardDeatils = false;
          this.noCardDetailsData = true;
          // this.dataLoading=false;
        }
      } else {
        this.isCardDeatils = false;
        this.noCardDetailsData = true;
        // this.dataLoading=false;
      }
    });

    this.autoRechargeCardDetails.forEach((id) => {
      if (id.initial_limit) {
        // id.set_limit=id.initial_limit;
        id.intial_limit_percentage =
          (id.initial_limit / this.totalAutoRechageLimit) * 100;
        // id.set_percentage= id.intial_limit_percentage
      }
    });
    // console.log(this.cardDetails);
  }

  setCreditsLimitEvent(event, i) {
    let value = event.target.value;
    console.log(value);

    if (!isNaN(value) && value>=0) {

      console.log('positive')
      console.log(Math.round(100 * value) / 100);
      // let percentage=(value/this.totalAutoRechageLimit)*100;
      this.creditAllocCardDetails[i].amount = Math.round(100 * value) / 100;
      // this.cardDetails[i].set_percentage=percentage

      // this.calculation();
    } else {
      // console.log(value);

      console.log('negative')
      this.creditAllocCardDetails[i].amount =
        Math.round(100 * this.creditAllocCardDetails[i].avail_balance) / 100;
      // this.calculation();
    }
    console.log(this.creditAllocCardDetails);
    let totalAmount = 0;
    this.creditAllocCardDetails.forEach((id: any) => {
      // console.log(id.amount);
      totalAmount += Math.round(100 * id.amount) / 100;
    });

    this.totalSetLimit = Math.round(100 * totalAmount) / 100;
    // console.log(this.totalSetLimit);
  }

  setAutoRechargeEvent(event, i) {
    // autoRechConfirmButton= true;
    let value = parseFloat(event.target.value);
    console.log(value);
    if (!isNaN(value)) {
      let percentage = (value / 100) * 100;
      this.autoRechargeCardDetails[i].new_amount = value;
      this.autoRechargeCardDetails[i].new_amount_percenatge = percentage;

      // this.calculation();
    } else {
      this.autoRechargeCardDetails[i].new_amount = 0;
      this.autoRechargeCardDetails[i].new_amount_percenatge = 0;
      // this.calculation();
    }

    // let totalAmount = 0;
    // this.autoRechargeCardDetails.forEach((id: any) => {
    //   console.log(id.new_amount);
    //   totalAmount += id.new_amount;
    // });
    // console.log(totalAmount);
    // if (totalAmount != 100) {
    //   this.autoRechConfirmButton = true;
    // } else {
    //   this.autoRechConfirmButton = false;
    // }
  }

  setAutoRechargeAmountEvent(event: any, i: any) {
    // let value = parseFloat(event.target.value);
    let value = Math.round(event.target.value*100)/100;
    console.log(value);
    if (!isNaN(value) && value>=0) {
      let percentage = (value / this.set_autoRech_amt) * 100;
      this.autoRechargeCardDetails[i].new_amount = value;
      this.autoRechargeCardDetails[i].new_amount_percenatge =  Math.round(percentage*100)/100;;

      // this.calculation();
    } else {
      this.autoRechargeCardDetails[i].new_amount = 0;
      this.autoRechargeCardDetails[i].new_amount_percenatge = 0;
      // this.calculation();
    }
    let amount = 0;
    let per = 0;

    this.autoRechargeCardDetails.forEach((id: any) => {
      amount += id.new_amount;
      per += id.new_amount_percenatge;
    });

    this.totalAutoRechargeamt = amount;
    this.totalAutoRechargePer = per;
  }

  setAutoRechargePercentageEvent(event: any, i: any) {
    // let value = parseFloat(event.target.value);
      let value = Math.round(event.target.value*100)/100;
    console.log(i);
    if (!isNaN(value) && value>=0) {
      let amount = (value / 100) * 100;
      this.autoRechargeCardDetails[i].new_amount =Math.round(amount*100)/100 ;
      this.autoRechargeCardDetails[i].new_amount_percenatge = value;

      // this.calculation();
    } else {
      this.autoRechargeCardDetails[i].new_amount = 0;
      this.autoRechargeCardDetails[i].new_amount_percenatge = 0;
      // this.calculation();
    }

    let amount = 0;
    let per = 0;

    this.autoRechargeCardDetails.forEach((id: any) => {
      amount += id.new_amount;
      per += id.new_amount_percenatge;
    });

    this.totalAutoRechargeamt = amount;
    this.totalAutoRechargePer = per;
  }

  calculation() {
    let totalSetLimit = 0;

    this.cardDetails.forEach((id) => {
      if (id) {
        totalSetLimit += id.set_limit;
      }
    });
    // console.log("totalSetLimit", totalSetLimit);
    // console.log(this.cardDetails);
    if (totalSetLimit == 0 || totalSetLimit > this.totalAutoRechageLimit) {
      // this.creditAllocConfirmButton=true
    } else {
      // this.creditAllocConfirmButton=false
    }

    // console.log(this.cardDetails);
  }

  confirmAssignCredit() {
    // console.log(this.creditAllocCardDetails);
    this.dataLoading = true;

    let cardDetailsPayload = [];
    let totalSetLimitAmt = 0;
    // console.log(this.creditAllocCardDetails);

    this.creditAllocCardDetails.forEach((id: any) => {
      totalSetLimitAmt += id.amount;
      cardDetailsPayload.push(
        new Object({
          card_number: id.card_number,
          amount: id.amount,
        })
      );
    });

    console.log(totalSetLimitAmt);
    // console.log(cardDetailsPayload);
    console.log(Math.round(totalSetLimitAmt * 100) / 100);
    console.log(this.JioBpBalance);
    if (Math.round(totalSetLimitAmt * 100) / 100 > this.JioBpBalance) {

      console.log("Greater")
      Swal.fire({
        title: `<h4>Failed !</h4>`,
        text: "Limit Amount Should Not Exceed The Available Card Balance",
        // icon: "error",
        width: "300px",
        confirmButtonText: "Ok",
      });

      this.dataLoading = false;
    } else if (Math.round(totalSetLimitAmt * 100) / 100 < this.JioBpBalance) {
      console.log("Lesser")
      Swal.fire({
        title: `<h4>Failed !</h4>`,
        text: "Limit Amount Should Not less than Available Card Balance",
        // icon: "error",
        width: "300px",
        confirmButtonText: "Ok",
      });

      this.dataLoading = false;
    } else {
      console.log("Equal")
      let finalPayload = {
        args: {
          customer_name: this.customer,
          total_card_balance: this.JioBpBalance,
          card_details: cardDetailsPayload,
        },
      };

      // console.log(finalPayload);

      this._erpService.jioBpCreditAlloc(finalPayload).subscribe((res: any) => {
        //   // console.log(res);
        console.log("response--->",res)
        if (res.errorCode == 0 && res.data.message) {
          this.dataLoading = false;
          Swal.fire({
            title: `<h4>Success !</h4>`,
            text: res.data.message,
            // icon: "success",
            width: "300px",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.reload();
          });
        } else {
          if (res.data.exception) {
            let message = res.data.exception.slice(
              res.data.exception.indexOf(":") + 1
            );

            Swal.fire({
              title: `<h4>Failed !</h4>`,
              text: message,
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
            // this.resMsg=message
            // this.autoRecharPopUp=true
          } else {
            Swal.fire({
              title: `<h4>Failed !</h4>`,
              // text: "Recharge Failed",
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
          }
          this.dataLoading = false;
        }
      });
    }
  }

  confirmAutoRecharge() {
    // console.log(this.autoRechargeCardDetails)

    this.dataLoading = true;

    let cardDetailsPayload = [];
    let totalRechargePercentage = 0;

    this.autoRechargeCardDetails.forEach((id: any) => {
      totalRechargePercentage += id.new_amount;
      // if(id.new_amount>0){
      cardDetailsPayload.push(
        new Object({
          card_number: id.card_number,
          percentage: id.new_amount_percenatge,
        })
      );
    });

    // console.log(cardDetailsPayload);
    // console.log(totalRechargePercentage);
    if (totalRechargePercentage > 100) {
      Swal.fire({
        title: `<h4>Failed !</h4>`,
        text: `Percentage value cannot be greater than 100%`,
        // icon: "error",
        width: "300px",
        confirmButtonText: "Ok",
      });
      this.dataLoading = false;
    } else if (totalRechargePercentage < 100) {
      Swal.fire({
        title: `<h4>Failed !</h4>`,
        text: `Percentage value cannot be less than 100%`,
        // icon: "error",
        width: "300px",
        confirmButtonText: "Ok",
      });
      this.dataLoading = false;
    } else {
      let finalPayload = {
        args: {
          customer: this.customer,
          recharge_type: "Custom",
          card_list: cardDetailsPayload,
        },
      };

      // console.log(finalPayload);

      this._erpService.jioBpAutoRecharge(finalPayload).subscribe((res: any) => {
        // console.log(res);

        if (res.errorCode == 0 && res.data.message) {
          this.dataLoading = false;
          this.resMsg = res.data.message;
          // this.autoRecharPopUp = true;

          Swal.fire({
            title: `<h4>Success !</h4>`,
            text: res.data.message,
            // icon: "success",
            width: "300px",
            confirmButtonText: "Ok",
          }).then(() => {
            window.location.reload();
          });
        } else {
          if (res.data.exception) {
            let message = res.data.exception.slice(
              res.data.exception.indexOf(":") + 1
            );

            Swal.fire({
              title: `<h4>Failed !</h4>`,
              text: message,
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
            // this.resMsg=message
            // this.autoRecharPopUp=true
          } else {
            Swal.fire({
              title: `<h4>Failed !</h4>`,
              // text: "Recharge Failed",
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
          }

          this.dataLoading = false;
        }
      });
    }
  }
}
