import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';

@Component({
  selector: 'app-setautorecharge',
  templateUrl: './setautorecharge.component.html',
  styleUrls: ['./setautorecharge.component.css']
})
export class SetautorechargeComponent implements OnInit {

  customer: any;
  selected_wallet_priority: any;

  actualbpclThrsLimitAmt: number;
  actualbpclRechargeAmt: number;
  enable_bpcl_auto_recharge: any;
  bpclThrsLimitAmt: number;
  bpclRechargeAmt: number;
  bpclThrsLimitEventValue: number;
  bpclRechargeEventValue: number;
  bpclThrshInputDisable: boolean = true;
  bpclRechargeInputDisable: boolean = true;
  bpcl_priority_options: any = ["1", "2", "3"];
  selected_bpcl_priority: any;

  actualfastagThrsLimitAmt: number;
  actualfastagRechargeAmt: number;
  enable_fastag_auto_recharge: any;
  fastagThrsLimitAmt: number;
  fastagRechargeAmt: number;
  fastagThrsLimitEventValue: number;
  fastagRechargeEventValue: number;
  fastagThrshInputDisable: boolean = true;
  fastagRechargeInputDisable: boolean = true;
  fastag_priority_options: any = ["1", "2", "3"]
  selected_fastag_priority: any;

  actualioclThrsLimitAmt: number;
  actualioclRechargeAmt: number;
  enable_iocl_auto_recharge: any;
  ioclThrsLimitAmt: number;
  ioclRechargeAmt: number;
  ioclThrsLimitEventValue: number;
  ioclRechargeEventValue: number;
  ioclThrshInputDisable: boolean = true;
  ioclRechargeInputDisable: boolean = true;
  iocl_priority_options: any = ["1", "2", "3"]
  selected_iocl_priority: any;

  is_priority_option_disable: boolean = true;
  is_updateAutoWallet_disable: boolean = false;
  is_resetPri_disable: boolean = true;
  resMsg: boolean;
  resLoader: boolean;

  //iocl enable functionality
  is_iocl_disabled: boolean = true;



  constructor(private toastrService: ToastrService, private _erpService: ErpnextapiService) { }

  ngOnInit(): void {
    this.customer = localStorage.getItem('customer');
    // this.updateWalletRechargeWallet();
    this.getThresholdRechargeAmt();


  }


  //fetch current threshold and recharge amonut

  getThresholdRechargeAmt() {
    console.log(this.customer);
    let customer = this.customer;
    // let customer = "SRI SHA VOYAGES"

    this._erpService.getCurrentThresholdRechargeAmt(customer).subscribe((res) => {
      console.log("getThresholdRechargeAmt--->", res)
      if (res["errorCode"] == 0 && res['data'].message) {
        let responsedata = res['data'].message;
        this.bpclThrsLimitEventValue = responsedata.bpcl_threshold;
        this.bpclRechargeEventValue = responsedata.bpcl_recharge_amount;
        this.bpclThrsLimitAmt = responsedata.bpcl_threshold;
        this.bpclRechargeAmt = responsedata.bpcl_recharge_amount;
        this.actualbpclThrsLimitAmt = responsedata.bpcl_threshold;
        this.actualbpclRechargeAmt = responsedata.bpcl_recharge_amount;

        this.fastagThrsLimitEventValue = responsedata.fastag_threshold;
        this.fastagRechargeEventValue = responsedata.fastag_recharge_amount;
        this.fastagThrsLimitAmt = responsedata.fastag_threshold;
        this.fastagRechargeAmt = responsedata.fastag_recharge_amount;
        this.actualfastagThrsLimitAmt = responsedata.fastag_threshold;
        this.actualfastagRechargeAmt = responsedata.fastag_recharge_amount;

        this.ioclThrsLimitEventValue = responsedata.iocl_threshold;
        this.ioclRechargeEventValue = responsedata.iocl_recharge_amount;
        this.ioclThrsLimitAmt = responsedata.iocl_threshold;
        this.ioclRechargeAmt = responsedata.iocl_recharge_amount;
        this.actualioclThrsLimitAmt = responsedata.iocl_threshold;
        this.actualioclRechargeAmt = responsedata.iocl_recharge_amount;





      }
    })
  }


  walletPriorityEvent(event: any) {
    this.selected_wallet_priority = event.target.value;
    console.log(' this.selected_wallet_priority--->', this.selected_wallet_priority);

    if (this.selected_wallet_priority == "By Priority") {
      this.is_priority_option_disable = false;
      this.is_resetPri_disable = false;

    }
    else {
      this.is_priority_option_disable = true;
      this.is_resetPri_disable = true;
      this.selected_bpcl_priority = "select";
      this.selected_fastag_priority = "select";
      this.selected_iocl_priority = "select"
    }

  }

  bpclPriortityEvent(event) {
    if (this.selected_wallet_priority == "By Priority") {
      if (event.target.value) {
        this.selected_bpcl_priority = event.target.value;
        
      }
    }

  }


  fastagPriortityEvent(event) {


    if (this.selected_wallet_priority == "By Priority") {
      if (event.target.value) {

        this.selected_fastag_priority = event.target.value;
        console.log(this.selected_fastag_priority);

      }

      // this.bpcl_priority_options = ["1", "2", "3"]
      // this.iocl_priority_options = ["1", "2", "3"]

      // let index = this.bpcl_priority_options.indexOf(this.selected_fastag_priority);
      // let index1 = this.iocl_priority_options.indexOf(this.selected_fastag_priority);
      // console.log(index, index1);
      // this.bpcl_priority_options.splice(index, 1);
      // this.iocl_priority_options.splice(index1, 1);


    }

  }
  ioclPriortityEvent(event) {
    this.selected_iocl_priority = event.target.value;

    if (this.selected_wallet_priority == "By Priority") {

      if (event.target.value) {
        this.selected_iocl_priority = event.target.value;
        console.log(this.selected_iocl_priority);


      }

    }


  }


  resetPriority() {

    this.selected_bpcl_priority = "select";
    this.selected_fastag_priority = "select";
    this.selected_iocl_priority = "select";
    // if()
  }

  yesEnableBpclEvent(isChecked: boolean) {
    if (isChecked) {


      this.enable_bpcl_auto_recharge = 1;
      console.log(this.enable_bpcl_auto_recharge);
    }
  }
  noEnableBpclEvent(isChecked: boolean) {
    if (isChecked) {
      this.enable_bpcl_auto_recharge = 0;
      console.log(this.enable_bpcl_auto_recharge);
    }
  }

  yesEnableFastagEvent(isChecked: boolean) {
    if (isChecked) {
      this.enable_fastag_auto_recharge = 1;
      console.log(this.enable_fastag_auto_recharge);
    }

  }
  noEnableFastagEvent(isChecked: boolean) {
    if (isChecked) {
      this.enable_fastag_auto_recharge = 0;
      console.log(this.enable_fastag_auto_recharge);
    }

  }

  yesEnableioclEvent(isChecked: boolean) {
    if (isChecked) {
      this.enable_iocl_auto_recharge = 1;
      console.log(this.enable_iocl_auto_recharge);
    }

  }
  noEnableioclEvent(isChecked: boolean) {
    if (isChecked) {
      this.enable_iocl_auto_recharge = 0;
      console.log(this.enable_iocl_auto_recharge);
    }

  }








  //******************************************Bpcl Auto Recharge********************************************************// 

  editBpclThrsholdLimit() {
    this.bpclThrshInputDisable = false;
    this.isEnableUpdateAutoRechargebtn();
  }
  bpclThrshLimtAmtEvent(event: any) {
    console.log(event.target.value);


    if (event != "") {
      this.bpclThrsLimitEventValue = event.target.value;
      console.log(this.bpclThrsLimitEventValue);


    }



  }
  saveBpclThrsLimitAmt() {


    setTimeout(() => {
      if (this.bpclThrsLimitEventValue >= 0 && this.bpclThrsLimitEventValue) {
        // console.log("yes");

        this.bpclThrsLimitAmt = this.bpclThrsLimitEventValue
        // this.toastrService.success(
        //   `Succesfully saved your Bpcl ThresholdLimit of Rs. ${this.bpclThrsLimitAmt}`,
        // );
        this.bpclThrshInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        console.log('this.bpclThrsLimitAmt-->', this.bpclThrsLimitAmt);
      }


    }, 100)

    // if (this.bpclThrsLimitAmt > 0 ) {

    //   this.toastrService.success(
    //     `Succesfully saved your Bpcl ThresholdLimit of Rs. ${this.bpclThrsLimitAmt}`,
    //   );
    //   this.bpclThrshInputDisable = true;
    // }

  }
  cancelBpclThrsLimitAmt() {




    console.log('this.bpclThrsLimitAmt-->', this.bpclThrsLimitAmt);
    this.bpclThrsLimitEventValue = this.actualbpclThrsLimitAmt;

    this.bpclThrshInputDisable = true;


  }




  editBpclRechargeAmt() {
    this.bpclRechargeInputDisable = false;
    this.isEnableUpdateAutoRechargebtn();
  }
  bpclRechargeAmtEvent(event: any) {
    if (event != "") {
      this.bpclRechargeEventValue = event.target.value;
      console.log(" this.bpclRechargeEventValue-->", this.bpclRechargeEventValue);
    }


  }
  saveBpclRechargeAmt() {
    setTimeout(() => {
      if (this.bpclRechargeEventValue >= 0 && this.bpclRechargeEventValue) {


        this.bpclRechargeAmt = this.bpclRechargeEventValue
        this.bpclRechargeInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        // this.toastrService.success(
        //   `Succesfully saved your Bpcl Recharge of Rs. ${this.bpclRechargeAmt}`,
        // );
        console.log('this.bpclRechargeAmt-->', this.bpclRechargeAmt);
      }
    }, 100)



  }
  cancelBpclRechargeAmt() {

    this.bpclRechargeEventValue = this.actualbpclRechargeAmt;
    this.bpclRechargeInputDisable = true;
  }

  //******************************************End Bpcl Auto Recharge********************************************************// 

  //******************************************FASTSG Auto Recharge********************************************************// 

  editFastagThrsholdLimit() {
    this.fastagThrshInputDisable = false;
    this.isEnableUpdateAutoRechargebtn();
  }
  fastagThrshLimtAmtEvent(event: any) {
    if (event != "") {
      this.fastagThrsLimitEventValue = event.target.value;
      console.log(" this.fastagThrsLimitAmt-->", this.fastagRechargeEventValue);
    }

  }
  saveFastagThrsLimitAmt() {
    setTimeout(() => {
      if (this.fastagThrsLimitEventValue >= 0 && this.fastagThrsLimitEventValue) {


        this.fastagThrsLimitAmt = this.fastagThrsLimitEventValue
        this.fastagThrshInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        // this.toastrService.success(
        //   `Succesfully saved your Fastag ThresholdLimit of Rs. ${this.fastagThrsLimitAmt}`,
        // );
        console.log('this.fastagThrsLimitAmt-->', this.fastagThrsLimitAmt);
      }
    }, 100)


    // if (this.fastagThrsLimitAmt > 0) {
    //   this.fastagThrshInputDisable = true;
    //   this.toastrService.success(
    //     `Succesfully saved your Fastag ThresholdLimit of Rs. ${this.fastagThrsLimitAmt}`,
    //   );
    // }

  }
  cancelFastagThrsLimitAmt() {
    this.fastagThrsLimitEventValue = this.actualfastagThrsLimitAmt
    this.fastagThrshInputDisable = true;
  }


  editFastagrechargeAmt() {
    this.fastagRechargeInputDisable = false;
    this.isEnableUpdateAutoRechargebtn();
  }
  fastagRechargeAmtEvent(event: any) {
    if (event != "") {
      this.fastagRechargeEventValue = event.target.value
      console.log(" this.fasatgRechargeAmt-->", this.fastagRechargeEventValue);
    }

  }
  saveFastagRechargetAmt() {
    setTimeout(() => {

      if (this.fastagRechargeEventValue >= 0 && this.fastagRechargeEventValue) {


        this.fastagRechargeAmt = this.fastagRechargeEventValue
        this.fastagRechargeInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        // this.toastrService.success(
        //   `Succesfully saved your Fastag Recharge of Rs. ${this.fastagRechargeAmt}`,
        // );
        console.log('this.fastagRechargeAmt-->', this.fastagRechargeAmt);
      }
    }, 100)


    // if (this.fastagRechargeAmt > 0) {
    //   this.fastagRechargeInputDisable = true;
    //   this.toastrService.success(
    //     `Succesfully saved your Fastag Recharge of Rs. ${this.fastagRechargeAmt}`,
    //   );
    // }

  }
  cancelFastagRechargetAmt() {
    this.fastagRechargeEventValue = this.actualfastagRechargeAmt
    this.fastagRechargeInputDisable = true;
  }







  //******************************************END FASTAG Auto Recharge********************************************************// 

  //******************************************IOCL Auto Recharge********************************************************// 

  editIoclThrsholdLimit() {

    this.ioclThrshInputDisable = false;
    this.isEnableUpdateAutoRechargebtn();
  }
  ioclThrshLimtAmtEvent(event: any) {
    if (event != "") {
      this.ioclThrsLimitEventValue = event.target.value;
      console.log(" this.ioclThrsLimitAmt-->", this.ioclThrsLimitEventValue);
    }

  }
  saveIoclThrsLimitAmt() {
    setTimeout(() => {

      if (this.ioclThrsLimitEventValue >= 0 && this.ioclThrsLimitEventValue) {


        this.ioclThrsLimitAmt = this.ioclThrsLimitEventValue
        this.ioclThrshInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        // this.toastrService.success(
        //   `Succesfully saved your Iocl ThresholdLimit of Rs. ${this.ioclThrsLimitAmt}`,
        // );
        console.log('this.bpclThrsLimitAmt-->', this.ioclThrsLimitAmt);
      }
    }, 100)

    // if (this.ioclThrsLimitAmt > 0) {
    //   this.ioclThrshInputDisable = true;
    //   this.toastrService.success(
    //     `Succesfully saved your Iocl ThresholdLimit of Rs. ${this.ioclThrsLimitAmt}`,
    //   );
    // }

  }
  cancelIoclThrsLimitAmt() {
    this.ioclThrsLimitEventValue = this.actualioclThrsLimitAmt
    this.ioclThrshInputDisable = true;
  }

  editIoclRechargeAmt() {
    this.ioclRechargeInputDisable = false;
    this.isEnableUpdateAutoRechargebtn()
  }
  ioclRechargeAmtEvent(event: any) {
    if (event != "") {
      this.ioclRechargeEventValue = event.target.value;
      console.log("  this.ioclRechargeAmt-->", this.ioclRechargeEventValue);
    }

  }
  saveIoclRechargetAmt() {
    setTimeout(() => {
      if (this.ioclRechargeEventValue >= 0 && this.ioclRechargeEventValue) {


        this.ioclRechargeAmt = this.ioclRechargeEventValue
        this.ioclRechargeInputDisable = true;
        this.isEnableUpdateAutoRechargebtn();
        // this.toastrService.success(
        //   `Succesfully saved your Iocl Recharge of Rs. ${this.ioclRechargeAmt}`,
        // );
        console.log('this.bpclThrsLimitAmt-->', this.ioclRechargeAmt);
      }
    }, 100)

    // if (this.ioclRechargeAmt > 0) {
    //   this.ioclRechargeInputDisable = true;
    //   this.toastrService.success(
    //     `Succesfully saved your Iocl Recharge of Rs. ${this.ioclRechargeAmt}`,
    //   );
    // }

  }

  cancelIoclRechargetAmt() {
    this.ioclRechargeEventValue = this.actualioclRechargeAmt;
    this.ioclRechargeInputDisable = true;
    // this.isEnableUpdateAutoRechargebtn()
  }








  //******************************************END IOCL Auto Recharge********************************************************// 

  isEnableUpdateAutoRechargebtn() {
    if ((this.bpclThrshInputDisable && this.bpclRechargeInputDisable && this.fastagThrshInputDisable && this.fastagRechargeInputDisable) == false) {
      this.is_updateAutoWallet_disable = true
    }
    else {
      this.is_updateAutoWallet_disable = false;
    }
  }


  //updateAutoWalletRecharge


  updateWalletRechargeWallet() {
    this.resLoader = true;


    console.log(this.selected_wallet_priority);

    if (this.selected_wallet_priority == undefined) {
      this.selected_wallet_priority = "";
    }
    if (this.enable_bpcl_auto_recharge == undefined) {
      this.enable_bpcl_auto_recharge = "";
    }

    if (this.selected_bpcl_priority == undefined || this.selected_bpcl_priority == "select") {
      this.selected_bpcl_priority = "";
    }

    if (this.enable_fastag_auto_recharge == undefined) {
      this.enable_fastag_auto_recharge = "";
    }
    if (this.selected_fastag_priority == undefined || this.selected_fastag_priority == "select") {
      this.selected_fastag_priority = "";
    }

    if (this.enable_iocl_auto_recharge == undefined) {
      this.enable_iocl_auto_recharge = "";
    }
    if (this.selected_iocl_priority == undefined || this.selected_iocl_priority == "select") {
      this.selected_iocl_priority = "";
    }


    let body_obj = {
      "customer": this.customer,
      "wallet_priority": this.selected_wallet_priority,
      "is_auto_recharge_enabled_bpcl": this.enable_bpcl_auto_recharge,
      "wallet_threshold_limit_bpcl": this.bpclThrsLimitAmt,
      "wallet_recharge_amount_bpcl": this.bpclRechargeAmt,
      "priority_bpcl": this.selected_bpcl_priority,
      "is_auto_recharge_enabled_fastag": this.enable_fastag_auto_recharge,
      "wallet_threshold_limit_fastag": this.fastagThrsLimitAmt,
      "wallet_recharge_amount_fastag": this.fastagRechargeAmt,
      "priority_fastag": this.selected_fastag_priority,
      "is_auto_recharge_enabled_iocl": this.enable_iocl_auto_recharge,
      "wallet_threshold_limit_iocl": this.ioclThrsLimitAmt,
      "wallet_recharge_amount_iocl": this.ioclRechargeAmt,
      "priority_iocl": this.selected_iocl_priority


    }


    // console.log(body_obj);



    // let body_obj = {
    //   "customer": this.customer,
    //   "wallet_priority": "By Equal",
    //   "is_auto_recharge_enabled_fastag": 1,
    //   "wallet_threshold_limit_fastag": 5,
    //   "wallet_recharge_amount_fastag": 10,
    //   "priority_fastag": "1",
    //   "is_auto_recharge_enabled_bpcl": 0,
    //   "wallet_threshold_limit_bpcl": 2,
    //   "wallet_recharge_amount_bpcl": 10,
    //   "priority_bpcl": "2",
    //   "is_auto_recharge_enabled_iocl": 0,
    //   "wallet_threshold_limit_iocl": "",
    //   "wallet_recharge_amount_iocl": "",
    //   "priority_iocl": "3"
    // }

    this._erpService.updateAutoWalletRecharge(body_obj).subscribe((res) => {
      console.log(body_obj);

      console.log("Auto Wallet Recharge Response-->", res);
      if (res["data"].message) {
        this.resMsg = true
        this.resLoader = false;
      }
      else {
        setTimeout(()=>{
          this.resMsg = false
          this.resLoader = false;
        },100)
       
      }

    })
  }

  popupokbtn() {
    location.reload();
  }




}
