import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";
import * as XLSX from "xlsx";

@Component({
  selector: "app-intrcity-fastag-details",
  templateUrl: "./intrcity-fastag-details.component.html",
  styleUrls: ["./intrcity-fastag-details.component.css"],
})
export class IntrcityFastagDetailsComponent implements OnInit {
  fileName:any ;
  searchString: any = "";
  Fastag_Cust_Id: string;
  walletId: string;
  marchantid: string;
  email: string;

  dataLoading: any = false;

  dailyFastagData: any = [];
  isDailyFastagTxn: boolean = true;
  noDailyFastagData: any = false;

  yearFastagData: any = [];
  isYearsFastagTxn: boolean;



  weeklyFastagData: any = [];
  isWeeklyFastagTxn: boolean;
  noWeeklyFastagData: any = false;

  monthlyFastagData: any = [];
  isMonthlyFastagTxn: boolean;
  noMonthlyFastagData: any = false;

  customFastagData: any = [];
  isCustomFasTxn: boolean;
  noCustomFasData: any = false;
  customTimeFilterDetails: any = [];

  fa_id: string;
  customer: any;

  todaysDate: any;
  weeklyDate: string;
  monthlyDate: string;

  todaysDate1: any;
  todaysDate2:any;
  weeklyDate1: string;
  monthlyDate1: string;
  monthlyDate2:any;
  yearAgoDate: any;
  yearAgoDate1:any;

  p1: any = 1;
  p2: any = 1;
  p3: any = 1;
  p4: any = 1;

  fastagTxnReport: any = [];
  customFastagForm: any;
  marchentId: string;

  isDownload: boolean;
  fastag_balance: number = 0;
  fastagBalCardLoader: boolean = false;

  constructor(
    private _erpService: ErpnextapiService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private _ar: ActivatedRoute,
    private route: Router
  ) {
    this.customFastagForm = this.fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      time: ['']

    })
  }

  ngOnInit(): void {
    this.fa_id = localStorage.getItem("FAID");
    this.customer = localStorage.getItem("customer");

    this.email = localStorage.getItem("email");
    this.marchantid = localStorage.getItem("marchantid");
    this.marchentId = 'P_W012208200823400034';
    this.walletId = localStorage.getItem("walletid");
    this.Fastag_Cust_Id = localStorage.getItem("FastagCustomerID");

    let today = new Date();
    this.todaysDate = this.datePipe.transform(today, "dd/MM/yyyy");
    this.todaysDate1 = this.datePipe.transform(today, "yyyyMMdd");
    this.todaysDate2 = this.datePipe.transform(today, "dd-MM-yyyy");

    let sevenDaysBack = new Date(new Date().setDate(today.getDate() - 6));
    this.weeklyDate = this.datePipe.transform(sevenDaysBack, "dd/MM/yyyy");
    this.weeklyDate1 = this.datePipe.transform(sevenDaysBack, "yyyyMMdd");

    let thirtyDaysBack = new Date(new Date().setDate(today.getDate() - 31));
    this.monthlyDate = this.datePipe.transform(thirtyDaysBack, "dd/MM/yyyy");
    this.monthlyDate1 = this.datePipe.transform(thirtyDaysBack, "yyyyMMdd");
    this.monthlyDate2 = this.datePipe.transform(thirtyDaysBack, "dd-MM-yyyy");


    let yearAgo = new Date(new Date().setDate(today.getDate() - 365));
    this.yearAgoDate1 = this.datePipe.transform(yearAgo, "dd-MM-yyyy");
    this.yearAgoDate = this.datePipe.transform(yearAgo, "yyyyMMdd");

    console.log(this.yearAgoDate);
    this.getFastagBalance() 


    // this.getYearsFastagTxnData();
    this.getMonthlyFastagTxn();
    this.getDailyFastagTxn();
    this.getWeeklyFastagTxn();


    // setTimeout(() => {
    //   this.getDailyFastagTxn();

    //   this.getWeeklyFastagTxn();
    // }, 1500)


  }



  getFastagBalance() {
    console.log("*******")
    let data = {
      uuid: localStorage.getItem("walletid"),
      merchantID:  localStorage.getItem("marchantid"),
      requestSource: localStorage.getItem("requsetSource"),
      // "requestSource": ""
    };
    // console.log(localStorage.getItem("FastagCustomerID"),data);
    // console.log("customer id-->", this.Fastag_Cust_Id);

    // let data = {
    //   uuid: "W0122040712524350013",
    //   merchantID: "HDFCWL",
    //   requestSource: "TOLLPARTNER1",
    // };

    this._erpService
      .getFastagBal(localStorage.getItem("FastagCustomerID"), data)
      .subscribe((res) => {
        // console.log(res);

        if (res["errorCode"] == "0") {
          // console.log("fastagWalletBalance------>", res["data"]);
          this.fastag_balance = res["data"]["currentBalance"];
          this.fastagBalCardLoader=false
        } else {
          this.fastagBalCardLoader=false
        }
      });
  }


  getYearsFastagTxnData() {
    // this.isDailyFastagTxn = true;
    let payload = {
      merchantid: this.marchantid,
      from_date: `${this.yearAgoDate}T00:00`,
      to_date: `${this.todaysDate1}T23:59`,
      walletid: this.walletId,
    };

    this._erpService.getFastagTollTxn(payload).subscribe((res) => {
      // console.log("getYearsFastagTxn()----->", res);

      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"]["exc_type"] != "ValidationError"
      ) {
        this.yearFastagData = res["data"].message.reverse();

        if (this.yearFastagData.length > 0) {
          // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

          for (let i = 0; i < this.yearFastagData.length; i++) {
            if (this.yearFastagData[i].reqTime == null) {
              this.yearFastagData[i]["reqTime"] = "NA";
            }

            if (this.yearFastagData[i].vehicleNo == null) {
              this.yearFastagData[i].vehicleNo = "NA";
            }
            if (this.yearFastagData[i].tollplazaname == null) {
              this.yearFastagData[i].tollplazaname = "NA";
            }

            let date = this.yearFastagData[i]["reqTime"]?.slice(0, 10);
            let time = this.yearFastagData[i]["reqTime"]?.slice(11, 19);

            this.yearFastagData[i]["Date"] = date;
            this.yearFastagData[i]["Time"] = time;


            let newObj = {
              "Date": `${this.yearFastagData[i]["Date"]}`,
              "Time": `${this.yearFastagData[i]["Time"]}`,
              VehicleNo: this.yearFastagData[i].vehicleNo,
              TransactionID: this.yearFastagData[i].partnerRefId,
              "Toll Plaza name": this.yearFastagData[i].tollplazaname,
              Type: this.yearFastagData[i].transactiontype,
              "Txn Amount": this.yearFastagData[i].txnAmt,
              "Opening Balance": this.yearFastagData[i].openingBalance,
              "Closing Balance": this.yearFastagData[i].closingBalance,
              "Wallet Id": this.yearFastagData[i].walletId,
              "Merchant Id": this.yearFastagData[i].merchantId,
              "Txn Mode": this.yearFastagData[i].txnMode,
              "udf1": this.yearFastagData[i].udf1,
              "udf2": this.yearFastagData[i].udf2,
              "udf3": this.yearFastagData[i].udf3,
              "udf4": this.yearFastagData[i].udf4,
              "udf5": this.yearFastagData[i].udf5,
              "laneDirection": this.yearFastagData[i].laneDirection,
              "laneId": this.yearFastagData[i].laneId,
              "tollplazaid": this.yearFastagData[i].tollplazaid,
              "txnPostedtime": this.yearFastagData[i].txnPostedtime,
              "transactiontype": this.yearFastagData[i].transactiontype,
              "vehicleCatageory": this.yearFastagData[i].vehicleCatageory,
              "vehicleDesc": this.yearFastagData[i].vehicleDesc,
              "narration": this.yearFastagData[i].narration,
              "tagId": this.yearFastagData[i].tagId,
              "originalTxnId": this.yearFastagData[i].originalTxnId,
              "tId": this.yearFastagData[i].tId,
              "acqId": this.yearFastagData[i].acqId,
              "tollTxnDateTime": this.yearFastagData[i].tollTxnDateTime
            };

            this.fastagTxnReport.push(newObj);
          }
          // return this.fastagTxnReport

          // console.log(this.fastagTxnReport);


          // this.isDailyFastagTxn = false;
        } else {
          // this.isDailyFastagTxn = false;

          // return this.fastagTxnReport
        }
      } else {
        // this.isDailyFastagTxn = false;
        // return this.fastagTxnReport

      }
    });


  }

  getDailyFastagTxn() {
    this.isDailyFastagTxn = true;
    let payload = {
      merchantid: this.marchantid,
      from_date: `${this.todaysDate1}T00:00`,
      to_date: `${this.todaysDate1}T23:59`,
      walletid: this.walletId,
    };
    // console.log("daily payload", payload);

    this._erpService.getFastagTollTxn(payload).subscribe((res) => {
      // console.log("getDailyFastagTxn()----->", res);

      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"]["exc_type"] != "ValidationError"
      ) {
        this.dailyFastagData = res["data"].message.reverse();

        if (this.dailyFastagData.length > 0) {
          // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

          for (let i = 0; i < this.dailyFastagData.length; i++) {
            if (this.dailyFastagData[i].reqTime == null) {
              this.dailyFastagData[i]["reqTime"] = "NA";
            }

            if (this.dailyFastagData[i].vehicleNo == null) {
              this.dailyFastagData[i].vehicleNo = "NA";
            }
            if (this.dailyFastagData[i].tollplazaname == null) {
              this.dailyFastagData[i].tollplazaname = "NA";
            }

            let date = this.dailyFastagData[i]["reqTime"]?.slice(0, 10);
            let time = this.dailyFastagData[i]["reqTime"]?.slice(11, 19);

            this.dailyFastagData[i]["Date"] = date;
            this.dailyFastagData[i]["Time"] = time;
          }

          // console.log(this.dailyFastagData);

          if (this.dailyFastagData == 0) {
            this.noDailyFastagData = true;
          }
          this.isDailyFastagTxn = false;
        } else {
          this.isDailyFastagTxn = false;
          this.noDailyFastagData = true;
        }
      } else {
        this.isDailyFastagTxn = false;
        this.noDailyFastagData = true;
      }
    });
  }
  getWeeklyFastagTxn() {
    this.isWeeklyFastagTxn=true;
    let payload = {
      merchantid: this.marchantid,
      from_date: `${this.weeklyDate1}T00:00`,
      to_date: `${this.todaysDate1}T23:59`,
      walletid: this.walletId,
    };
    // console.log("weekly payload-->", payload);

    this._erpService.getFastagTollTxn(payload).subscribe((res) => {
      // console.log("getWeeklyFastagTxn()----->", res);

      if (
        res["data"].message != "No record found" &&
        res["data"].message.length > 0 &&
        res["data"]["exc_type"] != "ValidationError"
      ) {
        this.weeklyFastagData = res["data"].message.reverse();

        if (this.weeklyFastagData.length > 0) {
          // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

          for (let i = 0; i < this.weeklyFastagData.length; i++) {
            if (this.weeklyFastagData[i].reqTime == null) {
              this.weeklyFastagData[i]["reqTime"] = "NA";
            }

            if (this.weeklyFastagData[i].vehicleNo == null) {
              this.weeklyFastagData[i].vehicleNo = "NA";
            }
            if (this.weeklyFastagData[i].tollplazaname == null) {
              this.weeklyFastagData[i].tollplazaname = "NA";
            }

            let date = this.weeklyFastagData[i]["reqTime"]?.slice(0, 10);
            let time = this.weeklyFastagData[i]["reqTime"]?.slice(11, 19);

            this.weeklyFastagData[i]["Date"] = date;
            this.weeklyFastagData[i]["Time"] = time;
          }

          // console.log(this.weeklyFastagData);

          if (this.weeklyFastagData == 0) {
            this.noWeeklyFastagData = true;
          }
          this.isWeeklyFastagTxn = false;
        } else {
          this.isWeeklyFastagTxn = false;
          this.noWeeklyFastagData = true;
        }
      } else {
        this.isWeeklyFastagTxn = false;
        this.noWeeklyFastagData = true;
      }
    });
  }
  getMonthlyFastagTxn() {
    this.isMonthlyFastagTxn=true;
    let payload = {
      merchantid: this.marchantid,
      from_date: `${this.monthlyDate1}T00:00`,
      to_date: `${this.todaysDate1}T23:59`,
      walletid: this.walletId,
    };
    console.log("monthly payload-->", payload);

    this._erpService.getFastagTollTxn(payload).subscribe((res) => {
      // console.log("getMonthlyFastagTxn()----->", res);

      if (
        res["data"].message != "No record found" &&
        res["data"].message.length > 0 &&
        res["data"]["exc_type"] != "ValidationError"
      ) {
        this.monthlyFastagData = res["data"].message.reverse();

        if (this.monthlyFastagData.length > 0) {
          // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

          for (let i = 0; i < this.monthlyFastagData.length; i++) {
            if (this.monthlyFastagData[i].reqTime == null) {
              this.monthlyFastagData[i]["reqTime"] = "NA";
            }

            if (this.monthlyFastagData[i].vehicleNo == null) {
              this.monthlyFastagData[i].vehicleNo = "NA";
            }
            if (this.monthlyFastagData[i].tollplazaname == null) {
              this.monthlyFastagData[i].tollplazaname = "NA";
            }

            let date = this.monthlyFastagData[i]["reqTime"]?.slice(0, 10);
            let time = this.monthlyFastagData[i]["reqTime"]?.slice(11, 19);

            this.monthlyFastagData[i]["Date"] = date;
            this.monthlyFastagData[i]["Time"] = time;

            // let newObj = {
            //   "Date": `${this.monthlyFastagData[i]["Date"]}`,
            //   "Time": `${this.monthlyFastagData[i]["Time"]}`,
            //   VehicleNo: this.monthlyFastagData[i].vehicleNo,
            //   TransactionID: this.monthlyFastagData[i].partnerRefId,
            //   "Toll Plaza name": this.monthlyFastagData[i].tollplazaname,
            //   Type: this.monthlyFastagData[i].transactiontype,
            //   "Txn Amount": this.monthlyFastagData[i].txnAmt,
            //   "Opening Balance": this.monthlyFastagData[i].openingBalance,
            //   "Closing Balance": this.monthlyFastagData[i].closingBalance,
            //   "Wallet Id": this.monthlyFastagData[i].walletId,
            //   "Merchant Id": this.monthlyFastagData[i].merchantId,
            //   "Txn Mode": this.monthlyFastagData[i].txnMode,
            //   "udf1": this.monthlyFastagData[i].udf1,
            //   "udf2": this.monthlyFastagData[i].udf2,
            //   "udf3": this.monthlyFastagData[i].udf3,
            //   "udf4": this.monthlyFastagData[i].udf4,
            //   "udf5": this.monthlyFastagData[i].udf5,
            //   "laneDirection": this.monthlyFastagData[i].laneDirection,
            //   "laneId": this.monthlyFastagData[i].laneId,
            //   "tollplazaid": this.monthlyFastagData[i].tollplazaid,
            //   "txnPostedtime": this.monthlyFastagData[i].txnPostedtime,
            //   "transactiontype": this.monthlyFastagData[i].transactiontype,
            //   "vehicleCatageory": this.monthlyFastagData[i].vehicleCatageory,
            //   "vehicleDesc": this.monthlyFastagData[i].vehicleDesc,
            //   "narration": this.monthlyFastagData[i].narration,
            //   "tagId": this.monthlyFastagData[i].tagId,
            //   "originalTxnId": this.monthlyFastagData[i].originalTxnId,
            //   "tId": this.monthlyFastagData[i].tId,
            //   "acqId": this.monthlyFastagData[i].acqId,
            //   "tollTxnDateTime": this.monthlyFastagData[i].tollTxnDateTime
            // };

            // this.fastagTxnReport.push(newObj);
          }

          // console.log(this.monthlyFastagData);

          if (this.monthlyFastagData == 0) {
            this.noMonthlyFastagData = true;
          }
          this.isMonthlyFastagTxn = false;
        } else {
          this.isMonthlyFastagTxn = false;
          this.noMonthlyFastagData = true;
        }
        this.isMonthlyFastagTxn=false
      } else {
        this.isMonthlyFastagTxn = false;
        this.noMonthlyFastagData = true;
        this.isMonthlyFastagTxn=false;
      }
    });
  }

  onCustomTxn() {

    this.customFastagData = [];
    this.customTimeFilterDetails = []

    console.log(this.customFastagForm.value.fromdate);



    this.customFastagForm.value.fromdate = this.customFastagForm.value.fromdate.replace(/-/g, "");
    this.customFastagForm.value.todate = this.customFastagForm.value.todate.replace(/-/g, "");

    console.log(this.customFastagForm.value.fromdate);



    if (this.customFastagForm.valid) {
      this.p4 = 1;
      this.isCustomFasTxn = true;
      this.noCustomFasData = false;
      // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
      this.getCustomFastagTxn(this.customFastagForm.value);
    }




  }

  getCustomFastagTxn(formvalue) {
    let MerId;
    console.log("fromdate-->", formvalue.fromdate);
    console.log("Todate-->", formvalue.todate);

    // let timeFilterdetails=[];
    let fromyear = formvalue.fromdate.slice(0, 4);
    let toyear = formvalue.todate.slice(0, 4);
    console.log(fromyear, toyear);

    // if ((fromyear && toyear) < 2023) {
    //   MerId = this.marchentId;

    // }
    // else {
    //   MerId = this.marchantid;
    // }

    let data = {
      "merchantid": this.marchantid,
      "from_date": formvalue.fromdate,
      "to_date": formvalue.todate,
      "walletid": this.walletId
    }

    // console.log("Fastag Custom Txn Body--->", data);

    // this.customFastagDatalength = 0;

    this._erpService.getFastagTollTxn(data).subscribe((res) => {
      // console.log(" getFastagcustomTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']) {
        if (res['data'].message != 'No record found' && res["data"]["exc_type"] != "ValidationError") {

          this.customFastagData = res['data'].message;



          for (let i = 0; i < this.customFastagData.length; i++) {

            if (this.customFastagData[i].reqTime == null) {
              this.customFastagData[i]['reqTime'] = "NA";
            }

            if (this.customFastagData[i].vehicleNo == null) {
              this.customFastagData[i].vehicleNo = "NA";
            }
            if (this.customFastagData[i].tollplazaname == null) {
              this.customFastagData[i].tollplazaname = "NA";
            }


            let date = this.customFastagData[i]['reqTime'].slice(0, 10);
            let time = this.customFastagData[i]['reqTime'].slice(11, 19);


            this.customFastagData[i]['Date'] = date;
            this.customFastagData[i]['Time'] = time;




          }


          console.log("custom fastag-->", this.customFastagData);
          // this.customFastagDatalength = this.customFastagTransactionData.length;
          this.isCustomFasTxn = false;
          this.dataLoading = false;

        }
        else {
          // console.log();
          // this.customFastagDatalength=0;
          this.isCustomFasTxn = false;
          this.noCustomFasData = true;
          this.dataLoading = false;
        }

        // console.log(res['data'].message[0].response)
        // var x = res['data'].message[0].response;  
        // console.log(Object.keys(x),Object.values(x));



        // this.customFastagTransactionData=res['data'].expenditure.monthly_transaction;



      } else {
        this.isCustomFasTxn = false;
      }

    })

  }


  downloadFun() {
    this.isDownload = true;
    let dateRange=`${this.monthlyDate2} to ${this.todaysDate2} `.toString()
    console.log(`${this.monthlyDate2} to ${this.todaysDate2}`)
    this.fileName =`Fastag Txn(${dateRange}).xlsx`

    const mypromise = new Promise((resolve, reject) => {

      let payload = {
        merchantid: this.marchantid,
        from_date: `${this.monthlyDate1}T00:00`,
        to_date: `${this.todaysDate1}T23:59`,
        walletid: this.walletId,
      };
      // console.log(payload)

      this._erpService.getFastagTollTxn(payload).subscribe((res) => {
        // console.log("getYearsFastagTxn()----->", res);

        if (
          res["errorCode"] == "0" &&
          res["data"].message != "No record found" &&
          res["data"]["exc_type"] != "ValidationError"
        ) {
          this.yearFastagData = res["data"].message.reverse();

          if (this.yearFastagData.length > 0) {
            // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

            for (let i = 0; i < this.yearFastagData.length; i++) {
              if (this.yearFastagData[i].reqTime == null) {
                this.yearFastagData[i]["reqTime"] = "NA";
              }

              if (this.yearFastagData[i].vehicleNo == null) {
                this.yearFastagData[i].vehicleNo = "NA";
              }
              if (this.yearFastagData[i].tollplazaname == null) {
                this.yearFastagData[i].tollplazaname = "NA";
              }

              let date = this.yearFastagData[i]["reqTime"]?.slice(0, 10);
              let time = this.yearFastagData[i]["reqTime"]?.slice(11, 19);

              this.yearFastagData[i]["Date"] = date;
              this.yearFastagData[i]["Time"] = time;


              let newObj = {
                "Date": `${this.yearFastagData[i]["Date"]}`,
                "Time": `${this.yearFastagData[i]["Time"]}`,
                VehicleNo: this.yearFastagData[i].vehicleNo,
                TransactionID: this.yearFastagData[i].tId,
                "Toll Plaza name": this.yearFastagData[i].tollplazaname,
                Type: this.yearFastagData[i].transactiontype,
                "Txn Amount": this.yearFastagData[i].txnAmt,
                "Opening Balance": this.yearFastagData[i].openingBalance,
                "Closing Balance": this.yearFastagData[i].closingBalance,
                "Wallet Id": this.yearFastagData[i].walletId,
                "partnerRefId": this.yearFastagData[i].partnerRefId,
                "Merchant Id": this.yearFastagData[i].merchantId,
                "Txn Mode": this.yearFastagData[i].txnMode,
                "udf1": this.yearFastagData[i].udf1,
                "udf2": this.yearFastagData[i].udf2,
                "udf3": this.yearFastagData[i].udf3,
                "udf4": this.yearFastagData[i].udf4,
                "udf5": this.yearFastagData[i].udf5,
                "Lane Direction": this.yearFastagData[i].laneDirection,
                "Lane Id": this.yearFastagData[i].laneId,
                "Toll Plaza Id": this.yearFastagData[i].tollplazaid,
                "Txn Posted Time": this.yearFastagData[i].txnPostedtime,
                "Transaction Type": this.yearFastagData[i].transactiontype,
                "Vehicle Catageory": this.yearFastagData[i].vehicleCatageory,
                "Vehicle Desc": this.yearFastagData[i].vehicleDesc,
                "Narration": this.yearFastagData[i].narration,
                "Tag Id": this.yearFastagData[i].tagId,
                "Original Txn Id": this.yearFastagData[i].originalTxnId,
                "acq Id": this.yearFastagData[i].acqId,
                "Toll Txn Date Time": this.yearFastagData[i].tollTxnDateTime
              };

              this.fastagTxnReport.push(newObj);


            }
            // return this.fastagTxnReport
            resolve(this.fastagTxnReport);

            // console.log(this.fastagTxnReport);
            this.isDownload = false;


            // this.isDailyFastagTxn = false;
          } else {
            this.isDownload = false;
            resolve(this.fastagTxnReport);

          }
        } else {
          this.isDownload = false
          resolve(this.fastagTxnReport);


        }
      });
    })

    mypromise.then((res: any) => {
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(res);
      // const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      /* save to file */
      XLSX.writeFile(wb, this.fileName);
    })

    // let element = document.getElementById("excel-table");


  }

  dailyFastagtxn() {
    this.searchString = "";
  }
  weeklyFastagtxn() {
    this.searchString = "";
  }
  monthlyFastagtxn() {
    this.searchString = "";
  }
  customFastagtxn() {
    this.searchString = "";
  }
}
