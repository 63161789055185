import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { DatePipe } from "@angular/common";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { ToastrModule } from "ngx-toastr";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AuthGuard } from "./common/service/auth.guard";
import { AccessDeniedComponent } from "./shared/access-denied/access-denied.component";
import { InvoiceListComponent } from "./invoice-list/invoice-list.component";
import { SharedModule } from "./shared/shared.module";
import { NgxPaginationModule } from "ngx-pagination";
import { PayoutComponent } from "./payout/payout.component";
import { VehicleDocumentComponent } from "./vehicle-document/vehicle-document.component";
import { OptDashboardComponent } from "./operator-dashboard/opt-dashboard/opt-dashboard.component";
import { FastagDetailsComponent } from "./operator-dashboard/fastag-details/fastag-details.component";
import { InsuranceDetailsComponent } from "./insurance-details/insurance-details.component";
import { BpclDashboardComponent } from "./operator-dashboard/bpcl-dashboard/bpcl-dashboard.component";
import { InsightsComponent } from "./operator-dashboard/insights/insights.component";
import { NgApexchartsModule } from "ng-apexcharts";
import { WtsappVehicleDocComponent } from "./wtsapp-vehicle-doc/wtsapp-vehicle-doc.component";
import { VehicleExpensesGraphComponent } from "./operator-dashboard/vehicle-expenses-graph/vehicle-expenses-graph.component";
import { SetautorechargeComponent } from "./operator-dashboard/setautorecharge/setautorecharge.component";
import { IntrcityDashboardComponent } from "./intrcity-dashboard/intrcity-dashboard.component";
import { IntrcityInvoiceListComponent } from "./intrcity-dashboard/intrcity-invoice-list/intrcity-invoice-list.component";
import { CreditsAllocationComponent } from "./operator-dashboard/credits-allocation/credits-allocation.component";
import { CreditsAllocationHistoryComponent } from "./intrcity-dashboard/credits-allocation-history/credits-allocation-history.component";
import { JioBpDashboardComponent } from "./operator-dashboard/jio-bp-dashboard/jio-bp-dashboard.component";
import { JioBpAssignCreditsComponent } from "./operator-dashboard/jio-bp-assign-credits/jio-bp-assign-credits.component";
import { PurchaseOrderComponent } from "./purchase-order/purchase-order.component";
import { TyreDetailsComponent } from "./tyre-details/tyre-details.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { VehiclesHistoryComponent } from "./vehicles-history/vehicles-history.component";
import { TyreServiceReportComponent } from "./tyre-service-report/tyre-service-report.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { VehGeolocationFormComponent } from "./driver/veh-geolocation-form/veh-geolocation-form.component";
import { CalculateRoutePriceComponent } from "./calculate-route-price/calculate-route-price.component";
import { IntrcityFastagDetailsComponent } from "./intrcity-dashboard/intrcity-fastag-details/intrcity-fastag-details.component";
import { DlDashboardComponent } from "./operator-dashboard/dl-dashboard/dl-dashboard.component";
import { FcDashboardComponent } from "./operator-dashboard/fc-dashboard/fc-dashboard.component";
import { RcDashboardComponent } from "./operator-dashboard/rc-dashboard/rc-dashboard.component";
import { CreateDlComponent } from "./operator-dashboard/dl-dashboard/create-dl/create-dl.component";
import { UpdateDlComponent } from "./operator-dashboard/dl-dashboard/update-dl/update-dl.component";
import { DlDashboardModule } from "./operator-dashboard/dl-dashboard/dl-dashboard.module";
import { CreateRcComponent } from './operator-dashboard/rc-dashboard/create-rc/create-rc.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccessDeniedComponent,
    InvoiceListComponent,
    PayoutComponent,
    VehicleDocumentComponent,
    OptDashboardComponent,
    FastagDetailsComponent,
    InsuranceDetailsComponent,

    BpclDashboardComponent,
    InsightsComponent,
    WtsappVehicleDocComponent,
    VehicleExpensesGraphComponent,
    SetautorechargeComponent,
    IntrcityDashboardComponent,
    IntrcityInvoiceListComponent,
    CreditsAllocationComponent,
    CreditsAllocationHistoryComponent,
    JioBpDashboardComponent,
    JioBpAssignCreditsComponent,
    PurchaseOrderComponent,
    TyreDetailsComponent,
    VehiclesHistoryComponent,
    TyreServiceReportComponent,
    NotificationsComponent,
    VehGeolocationFormComponent,
    CalculateRoutePriceComponent,
    IntrcityFastagDetailsComponent,
    // DlDashboardComponent,
    // FcDashboardComponent,
    // RcDashboardComponent,
    // CreateRcComponent,
    // CreateDlComponent,
    // UpdateDlComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule,
    BrowserAnimationsModule,
    NgxPaginationModule,
    NgApexchartsModule,
    NgSelectModule,

    ToastrModule.forRoot({
      timeOut: 3000,
      progressBar: true,
    }),
  ],
  providers: [AuthGuard, DatePipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
