import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { HeaderComponent } from './header/header.component';
import { SideheaderComponent } from './sideheader/sideheader.component';
import { LoaderComponent } from './loader/loader.component';
import { InsideLoaderComponent } from './inside-loader/inside-loader.component';
import { FullscreenloaderComponent } from './fullscreenloader/fullscreenloader.component';
import { SearchFilterPipePipe } from './common/search-filter-pipe.pipe';


@NgModule({
  declarations: [
    HeaderComponent,
    SideheaderComponent,
    LoaderComponent,
    InsideLoaderComponent,
    FullscreenloaderComponent,
    SearchFilterPipePipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule
  ],
  exports:[
    HeaderComponent,
    SideheaderComponent,
    LoaderComponent,
    InsideLoaderComponent,
    FullscreenloaderComponent,
    SearchFilterPipePipe
  ]
})
export class SharedModule { }
