import { Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-intrcity-invoice-list",
  templateUrl: "./intrcity-invoice-list.component.html",
  styleUrls: ["./intrcity-invoice-list.component.css"],
})
export class IntrcityInvoiceListComponent implements OnInit {
  email: any;
  customer: any;

  list_of_customer: any = [];

  isNoCustomerDeatils: any;
  selected_customer: any;

  invoice_list: any = [];
  isInvoiceTxn: any = true;
  noInvoiceData: any;
  InvoiceListlength: any = 0;

  p1: any = 1;
  baseServerUrl = environment.erpBaseUrl;

  constructor(
    private _erpService: ErpnextapiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.email = localStorage.getItem("email");
    this.customer = localStorage.getItem("customer");
    this.getIntrcityCustomerDetails();
    this.getInvoiceList();
  }

  getIntrcityCustomerDetails() {
    this.isNoCustomerDeatils = true;

    //   this. customer_details=[
    //     {
    //         "sno":1234,
    //         "customer":"Universal Bus Service",
    //         "address":"Chennai",
    //         "credit_limit":"100000",
    //         "net_bal":"50000",
    //         "status":"Active"
    //     },
    //     {
    //         "sno":1111,
    //         "customer":"Universal Travels",
    //         "address":" Banglore",
    //         "credit_limit":"10000",
    //         "net_bal":"5000",
    //         "status":"Active"
    //     },
    //     {
    //         "sno": 111,
    //         "customer":"KRS",
    //         "address":"Hyderbad",
    //         "credit_limit":"1000",
    //         "net_bal":"500",
    //         "status":"Active"
    //     },
    //     {
    //         "sno":7777,
    //         "customer":"SRISHA",
    //         "address":"TR Puram",
    //         "credit_limit":"100",
    //         "net_bal":"50",
    //         "status":"Active"
    //     }
    // ]

    this._erpService
      .getCustomerDetailsofIntrcity(this.email)
      .subscribe((res: any) => {
        // console.log(res)
        if (
          res["errorCode"] == "0" &&
          res["data"].message != "No record found" &&
          res["data"]["exc_type"] != "ValidationError" &&
          res["data"].message
        ) {
          let customer_details = res["data"].message;
          for (let data of customer_details) {
            this.list_of_customer.push(data.customer);
          }
          // this.selectCustomer("All")

          this.isNoCustomerDeatils = false;
        } else {
          this.isNoCustomerDeatils = false;
        }
      });
  }

  // selectCustomer(customer){
  //   this.invoice_list=[];
  //   console.log(customer)
  //   if(customer){
  //     this.selected_customer=customer;
  //   }

  //  console.log(this.selected_customer)

  //  if( this.selected_customer=="All" &&  this.selected_customer!=""){
  //   for(let cus of this.list_of_customer){
  //     console.log(cus)
  //     this.getInvoiceList(cus)
  //   }

  //  }
  //  else if(this.selected_customer!=""){
  //    this.getInvoiceList(this.selected_customer);
  //  }

  // }

  getInvoiceList() {
    // let customer ="IndoRailBus Travels Pvt Ltd";
    let body = {
      args: {
        customer: this.customer,
        from_date: "",
        to_date: "",
        limit: 100,
      },
    };

    // let customparams=`?fields=%5B"name","posting_date","sales_order","status","item_group","grand_total","owner"%5D&filters=[["customer","=","${this.customer}"]]&limit_page_length=100&order_by=name desc`;

    // this._erpService.getInvoicesList(customparams).subscribe((res:any)=>{
    //   // console.log(res)
    //   if (res['errorCode'] == '0' && res['data'] != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].length>0) {
    //     this.invoice_list=res['data'];
    //     for(let index in this.invoice_list){
    //       // console.log(this.invoice_list[index].status)

    //       if( this.invoice_list[index].status==="Cancelled"){
    //         // console.log(index)
    //         this.invoice_list.splice(index,1)
    //       }
    //     }

    //     // if(this.selected_customer=="All"){
    //     //   this.invoice_list=this.invoice_list.concat(res['data'])
    //     // }
    //     // else{
    //     //   this.invoice_list=res['data']
    //     // }

    //     // console.log( this.invoice_list.length)
    //     this.InvoiceListlength=this.invoice_list.length
    //     this.isInvoiceTxn=false
    //     this.noInvoiceData=false;
    //   }
    //   else{
    //     this.InvoiceListlength=0;
    //     this.isInvoiceTxn=false
    //     this.noInvoiceData=true;
    //   }
    // })

    this._erpService.s_getNewSalesInvoices(body).subscribe((res: any) => {
      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"]["exc_type"] != "ValidationError" &&
        res["data"].message.length > 0
      ) {
        let responsedata = res["data"].message;
        // console.log(responsedata)
        const filterdate = responsedata.map((obj) => {
          return { ...obj, newdate: new Date(obj.date) };
        });
        // console.log(filterdate)
        this.invoice_list = filterdate.sort(
          (a, b) => Number(b.newdate) - Number(a.newdate)
        );

        // console.log( this.invoice_list)

        this.InvoiceListlength = this.invoice_list.length;
        this.isInvoiceTxn = false;
        this.noInvoiceData = false;
      } else {
        this.InvoiceListlength = 0;
        this.isInvoiceTxn = false;
        this.noInvoiceData = true;
      }
    });
  }
}
