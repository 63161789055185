import { Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "../common/service/erpnextapi.service";
import { ActivatedRoute } from "@angular/router";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
  selector: "app-tyre-details",
  templateUrl: "./tyre-details.component.html",
  styleUrls: ["./tyre-details.component.css"],
})
export class TyreDetailsComponent implements OnInit {
  dataLoading: any;
  cardLoader: any = true;
  searchString: any = "";
  customer: any;

  vehicleList: any = [];
  tyreStatus: any;
  selectedVehNo: any = "";

  currentOdoValue: any = 0;
  currentengineHours: any = 0;
  todaysTotalDistance: any = 0;
  todaystotalenginehours: any = 0;

  frontTyres = [];
  middleTyres = [];
  rearTyres = [];

  middleLeftTyres = [];
  middleRightTyres = [];
  rearLeftTyres = [];
  rearRightTyres = [];

  tyrePositions: any = [];
  noOfSmartTyres: any = [];
  middlerow: any;

  vehForm: any;
  vehCurrentLoc: any;
  constructor(
    private _erpService: ErpnextapiService,
    private _ar: ActivatedRoute
  ) {
    this.vehForm = new FormGroup({
      vehicle_no: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.customer = localStorage.getItem("customer");
    this._ar.queryParams.subscribe((params) => {
      // console.log(params)
      if (Object.keys(params).length > 0) {
        // console.log(params)

        this.selectedVehNo = params["vehicle_no"];
        this.vehForm.patchValue({
          vehicle_no: this.selectedVehNo,
        });
        this.tyrePositions = [];
        this.currentOdoValue = 0;
        this.currentengineHours = 0;
        this.todaysTotalDistance = 0;
        this.todaystotalenginehours = 0;
        this.getOdometerData(this.selectedVehNo);
        this.getRealTimeData(this.selectedVehNo);
        this.getVehicleLocation(this.selectedVehNo);
      } else {
        this.selectedVehNo = "";
        this.vehForm.patchValue({
          vehicle_no: "",
        });
      }
    });

    this.getLinkedVehicles();
    this.getFleetOperatorKeyPoints();
    // this.selectedVehNo = "NL01B1289";
    // this.tyreStatus = [
    //   {
    //     sno: "TESTTYRE001",
    //     position: "front_left_1",
    //     pressure: 130,
    //     temp: 76,
    //   },
    //   {
    //     sno: "TESTTYRE002",
    //     position: "front_right_1",
    //     pressure: 133,
    //     temp: 77,
    //   },

    //   {
    //     sno: "TESTTYRE003",
    //     position: "middle_left_1",
    //     pressure: 138,
    //     temp: 81,
    //   },
    //   {
    //     sno: "TESTTYRE004",
    //     position: "middle_left_2",
    //     pressure: 150,
    //     temp: 80,
    //   },
    //   {
    //     sno: "TESTTYRE005",
    //     position: "middle_right_1",
    //     pressure: 130,
    //     temp: 75,
    //   },

    //   {
    //     sno: "TESTTYRE006",
    //     position: "middle_right_2",
    //     pressure: 140,
    //     temp: 85,
    //   },
    //   {
    //     sno: "TESTTYRE007",
    //     position: "rear_left_1",
    //     pressure: 135,
    //     temp: 73,
    //   },
    //   {
    //     sno: "TESTTYRE008",
    //     position: "rear_left_2",
    //     pressure: 138,
    //     temp: 79,
    //   },

    //   {
    //     sno: "TESTTYRE009",
    //     position: "rear_right_1",
    //     pressure: 130,
    //     temp: 75,
    //   },
    //   {
    //     sno: "TESTTYRE010",
    //     position: "rear_right_2",
    //     pressure: 130,
    //     temp: 40,
    //   },
    // ];
    // this.getTyreStatus();

    // let response = [
    //   {
    //     tyre_position: "front_left_1",
    //     tyre_serial_no: "C0233441023",
    //     Pres: "-132",
    //     Temp: "35",
    //     Bat: "100",
    //     Event: "0",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "front_right_1",
    //     tyre_serial_no: "C0150290523",
    //     Pres: "134",
    //     Temp: "34",
    //     Bat: "100",
    //     Event: "0",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "middle_left_1",
    //     tyre_serial_no: "C0100680223",
    //     Pres: "0",
    //     Temp: "34",
    //     Bat: "100",
    //     Event: "1",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "rear_left_1",
    //     tyre_serial_no: "C0100680223",
    //     Pres: "0",
    //     Temp: "34",
    //     Bat: "100",
    //     Event: "1",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "rear_left_2",
    //     tyre_serial_no: "C0105010623",
    //     Pres: "128",
    //     Temp: "35",
    //     Bat: "100",
    //     Event: "0",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "rear_right_1",
    //     tyre_serial_no: "C0148020523",
    //     Pres: "0",
    //     Temp: "34",
    //     Bat: "100",
    //     Event: "32",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    //   {
    //     tyre_position: "rear_right_2",
    //     tyre_serial_no: "C0107640523",
    //     Pres: "116",
    //     Temp: "34",
    //     Bat: "100",
    //     Event: "0",
    //     erp_time_stamp: "2023-10-18 16:38:02.720263",
    //   },
    // ];

    // for (let tyre of response) {
    //   let pressure = Number(tyre["Pres"]);
    //   let temp = Number(tyre["Temp"]);
    //   console.log(pressure)
    //   let pressCond = pressure > 140 || pressure < 120;
    //   let tempCond = temp > 80 || temp < 70;

    //   if (pressCond || tempCond) {
    //     tyre["status"] = "Need Service";
    //   } else {
    //     tyre["status"] = "Good";
    //   }

    //   // this.tyrePositions.push(tyre);
    // }
    // console.log(this.tyrePositions);
  }
  getFleetOperatorKeyPoints() {
    let payload = {
      customer: this.customer,
    };
    this._erpService.getTyresKeyPoints(payload).subscribe((res: any) => {
      // console.log("getTyreKeyPointsData---->", res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message;

        this.noOfSmartTyres = response.vehicles_with_smart_tyre;
      }
    });
  }

  // getTyreStatus() {
  //   for (let tyre of this.tyreStatus) {
  //     let pressCond = tyre.pressure > 140 || tyre.pressure < 120;
  //     let tempCond = tyre.temp > 80 || tyre.temp < 70;
  //     if (pressCond || tempCond) {
  //       tyre.status = "Need Service";
  //     } else {
  //       tyre.status = "Good";
  //     }

  //     let frontLeftTyre = tyre.position == "front_left_1";
  //     let frontRightTyre = tyre.position == "front_right_1";

  //     if (frontLeftTyre || frontRightTyre) {
  //       this.frontTyres.push(tyre);
  //     } else if (
  //       tyre.position == "middle_left_1" ||
  //       tyre.position == "middle_left_2" ||
  //       tyre.position == "middle_left_3" ||
  //       tyre.position == "middle_left_4" ||
  //       tyre.position == "middle_right_1" ||
  //       tyre.position == "middle_right_2" ||
  //       tyre.position == "middle_right_3" ||
  //       tyre.position == "middle_right_4"
  //     ) {
  //       this.middleTyres.push(tyre);
  //     } else if (
  //       tyre.position == "rear_left_1" ||
  //       tyre.position == "rear_left_2" ||
  //       tyre.position == "rear_left_3" ||
  //       tyre.position == "rear_left_4" ||
  //       tyre.position == "rear_right_1" ||
  //       tyre.position == "rear_right_2" ||
  //       tyre.position == "rear_right_3" ||
  //       tyre.position == "rear_right_4"
  //     ) {
  //       this.rearTyres.push(tyre);
  //     }
  //   }
  //   // console.log("fontTyres", this.frontTyres);
  //   // console.log("middle tyres-->", this.middleTyres);
  //   // console.log("rear tyres-->", this.rearTyres);
  // }

  getVehicleLocation(vno) {
    let paylod = {
      filters: {
        vehicle_no: [vno],

        sort: "DESC",
      },
    };

    this._erpService
      .getVehilcleCurrentLocation(paylod)
      .subscribe((res: any) => {
        // console.log(res);
        if (
          res.errorCode == 0 &&
          res.data.message &&
          res.data.message[`${vno}`]
        ) {
          let response = res.data.message[`${vno}`];
          if (response.location_details.address.city_district && response.location_details.address.city) {
            this.vehCurrentLoc = `${response.location_details.address.city_district},
            ${response.location_details.address.city}`;
          } else if (response.location_details.address.state_district && response.location_details.address.state) {
            this.vehCurrentLoc = `${response.location_details.address.state_district},
            ${response.location_details.address.state}`;
          } else {
          }
        } else {
          this.vehCurrentLoc = "";
        }
        // console.log(  this.vehCurrentLoc);
      });
  }

  getRealTimeData(vno: any) {
    this.dataLoading = true;
    let payload = {
      filters: {
        vehicle_no: [vno],
        sort: "DESC",
      },
      odometer_value: false,
    };

    this._erpService.getSmartTyreRealTimeData(payload).subscribe((res: any) => {
      // console.log("real time tyre data response-->", res.data.message);
      if (
        res.errorCode == 0 &&
        res.data.message &&
        res.data.message[`${vno}`]
      ) {
        let response = res.data.message[`${vno}`];

        // console.log("real time tyre data response", response);
        //! looping for updating tyre status and tyre health
        for (let tyre of response) {
          let nsd_value = tyre.nsd_value;
          let minpress = tyre.min_tyre_pressure;
          let maxpress = tyre.max_tyre_pressure;
          let mintemp = tyre.min_tyre_temperature;
          let maxtemp = tyre.max_tyre_temperature;

          let pressure = Number(tyre["Pres"]);
          let temp = Number(tyre["Temp"]);

          let pressCond = pressure > maxpress || pressure < minpress;
          let tempCond = temp > maxtemp || temp < mintemp;
          if (!isNaN(pressure && temp)) {
            //! Updating Tyre  Status based on temp and pressure values
            if (pressCond || tempCond) {
              tyre["status"] = "Needs Service";
            } else {
              tyre["status"] = "Good";
            }

            //! Updating Tyre Health Status based on NSD Values
            // if (nsdvalue <= 4) {
            //   tyre["status"] = "Needs Change";
            // }


          }

          if (nsd_value < 4) {
            tyre["status"] = "Needs Change";
          } else if (nsd_value >= 4 && nsd_value < 10) {
            // tyre.nsd_status = "Tyre wear high";
            tyre.nsd_status = "Low";
          } else if (nsd_value >= 10 && nsd_value < 20) {
            // tyre.nsd_status = "Tyre wear medium";
            tyre.nsd_status = "Medium";
          } else if (nsd_value >= 20) {
            // tyre.nsd_status = "Tyre wear low";
            tyre.nsd_status = "High";
          } else {
            tyre.nsd_status = "NULL";
          }

          this.tyrePositions.push(tyre);
        }
        // console.log(this.tyrePositions);

        this.dataLoading = false;
      } else {
        this.tyrePositions = [];
        this.dataLoading = false;
      }
    });
  }

  getOdometerData(vno: any) {
    this.cardLoader = true;
    let payload = {
      vehicle_no: [vno],
    };
    this._erpService.getOdometerData(payload).subscribe((res: any) => {
      // console.log("odometer response-->", res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message[0];

        if (response.plate == vno) {
          let response1 = response.end;
          this.currentOdoValue = response1.odo_km;
          this.currentengineHours = response1.engine_hours;
          this.todaysTotalDistance = response1.total_distance;
          this.todaystotalenginehours = response1.total_engine_hours;
        }
        // console.log("odometer response-->", this.currentOdoValue);

        this.cardLoader = false;
      } else {
        this.cardLoader = false;
      }
    });
  }

  // getLinkedVehiclesofCustomer

  getLinkedVehicles() {
    let payload = {
      customer: this.customer,
      doctype: "Vehicle Registration Certificate",
    };
    // console.log(payload);

    this._erpService
      .getLinkedVehiclesofCustomer(payload)
      .subscribe((res: any) => {
        // console.log("getLinkedVehicles--->", res);
        if (res.errorCode == 0 && res.data.message) {
          let response = res.data.message;
          // this.vehicleList = response;

          for (let veh of response) {
            let obj = {};
            obj["veh"] = veh;
            if (this.noOfSmartTyres.includes(veh)) {
              obj["realtimedata"] = true;
            } else {
              obj["realtimedata"] = false;
            }
            this.vehicleList.push(obj);
          }
        }
      });
  }

  selectVehicleNo(event) {
    let vno = event;
    if (vno != undefined) {
      this.selectedVehNo = vno;
      this.vehForm.patchValue({
        vehicle_no: this.selectedVehNo,
      });
      this.tyrePositions = [];
      this.currentOdoValue = 0;
      this.currentengineHours = 0;
      this.todaysTotalDistance = 0;
      this.todaystotalenginehours = 0;
      this.getOdometerData(this.selectedVehNo);
      this.getRealTimeData(this.selectedVehNo);
      this.getVehicleLocation(this.selectedVehNo);
    } else {
      this.tyrePositions = [];
      this.selectedVehNo = "";
      this.vehForm.patchValue({
        vehicle_no: this.selectedVehNo,
      });
    }
  }
}
