import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
// import 'rxjs/add/Observable/interval';
// import 'rxjs/add/operator/startWith';
// import 'rxjs/add/operator/switchMap';
import { ErpnextapiService } from "../common/service/erpnextapi.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  dataLoading: any;
  realTimeData: any;
  notifications: any = [];

  constructor(private _erpService: ErpnextapiService, private _router: Router) {
    // let notifications = new Observable((observer) => {
    //   setInterval(() =>
    //     observer.next(localStorage.getItem("arrayofnotifications"))
    //   ),
    //     3000;
    // });
    // notifications.subscribe((res:any) => {
    //   this.notifications = JSON.parse(res);

    // });

    let payload = {
      filters: {
        sort: "DESC",
      },
      odometer_value: false,
    };
    this.dataLoading = true;
    this._erpService.getRealTimeData(payload).subscribe((res: any) => {
      // this.realTimeData=res

      res.subscribe((val) => {
        // console.log(val)

        if (val.errorCode == 0 && val.data.message) {
          this.notifications = [];
          let response = val.data.message;

          let keys = Object.keys(response);
          // this.realTimeData=val.data.message["NL01B1289"]
          // console.log( keys)
          for (let veh of keys) {
            let obj = {};
            for (let tyre of response[veh]) {
              // console.log(val)
              let nsdvalue = tyre.nsd_value;
              let minpress = tyre.min_tyre_pressure;
              let maxpress = tyre.max_tyre_pressure;
              let mintemp = tyre.min_tyre_temperature;
              let maxtemp = tyre.max_tyre_temperature;

              let pressure = Number(tyre["Pres"]);
              let temp = Number(tyre["Temp"]);

              let pressCond = pressure > maxpress || pressure < minpress;
              let tempCond = temp > maxtemp || temp < mintemp;
              if (!isNaN(pressure && temp)) {
                // console.log(veh);
                if (pressCond || tempCond || nsdvalue < 4) {
                  obj["vehicle_no"] = veh;
                  obj["status"] = "Needs Service";
                  this.notifications.push(obj);
                  break;
                }

                // if (nsdvalue < 4) {
                //   tyre["status"] = "Need Change";
                // }
              }
            }

            // console.log(this.notifications);
          }
          this.dataLoading = false;
        } else {
          this.dataLoading = false;
        }
      });

      // if (res.errorCode == 0 && res.data.message) {
      //   this.realTimeData = res.data.message;
      //   console.log(res.data.message);
      //   // return res.data.message
      // }
    });
  }

  ngOnInit(): void {


    // console.log(  this.realTimeData.subscribe())
    // this.realTimeData.subscribe()
  }

  rowClick(veh: any) {
    console.log(veh);
    console.log("*****")
    if (veh != "") {
      this._router.navigate(["/tyre-details"], {
        queryParams: { vehicle_no: veh },
      });
    }
  }

  // getData() {
  //   let payload = {
  //     filters: {
  //       sort: "DESC",
  //     },
  //     odometer_value: false,
  //   };
  //   this._erpService.getRealTimeData(payload).subscribe((res: any) => {
  //     // console.log(res)

  //     if (res.errorCode == 0 && res.data.message) {
  //       this.notifications = res.data.message;
  //       console.log(res.data.message);
  //       // return res.data.message
  //     }
  //   });
  // }
}
