import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { ErpnextapiService } from "./erpnextapi.service";

@Injectable()
export class AuthGuard implements CanActivate{

     apiKey = localStorage.getItem('apiKey');
     apiSecret = localStorage.getItem('apiSecret');
    // let params = new HttpParams();
    eligibility : Observable<boolean>;
    public eligibleModules = JSON.parse(localStorage.getItem('authModule'));
    moduleRoute = [
      {"module":"Dashboard", "route":"/dashboard"},
      {"module":"All Products", "route":"/products"},
      {"module":"Assign Credits", "route":"/dashboard/creditAssign"},
      {"module":"Assign Fuel Credits", "route":"/dashboard/fuelcreditAssign"},
      {"module":"Orders", "route":"/orders"},
      {"module":"List of Invoices","route":"/invoiceList"},
      {"module":"Cart","route":'/cart'},
      {"module":"Operator Dashboard","route":'/operator-dashboard'},
      {"module":"Fastag Dashboard","route":'/fastag-dashboard'},
      {"module":"BPCL Dashboard","route":'/bpcl-dashboard'},
      {"module":"Insight Dashboard","route":'/insights-dashboard'},
      {"module":"Insurance Dashboard","route":'/insurance-dashboard'},
      {"module":"Upload Vehicle Document","route":'/upload-vehicle-document'},
      {"module":"Set Limit","route":'/setautorecharge-dashboard'},
      {"module":"Credits Allocation","route":"/intrcity-credit-alloc"},
      {"module":"Intrcity Dashboard","route":"/intrcity-dashboard"},
      {"module":"Intrcity Invoice List Dashboard","route":"/intrcity-invoice-list"},
      {"module":"Intrcity Credits Allocation History","route":"/credit-alloc-history"},
      {"module":"Intrcity Fastag Txn","route":"/intrcity-fastag-txn"},
      {"module":"Jio-Bp Dashboard", "route":"/jio-bp-dashboard"},
      {"module":"Jio-Bp Credits Alloaction", "route":"/jio-bp-credits-allocation"},
      {"module":"Purchase Order", "route":"/purchase-order"},
      {"module":"Tyre Details", "route":"/tyre-details"},
      {"module":"Vehicles History", "route":"/vehicles-history"},
      {"module":"Tyre Service Reports", "route":"/tyre-service-reports"},
      {"module":"Notifications", "route":"/notifications"},
      {"module":"DL Dashboard", "route":"/dl-dashboard"},
      {"module":"FC Dashboard", "route":"/fc-dashboard"},
      {"module":"RC Dashboard", "route":"/rc-dashboard"},

      {"module":"Calculate Route Price", "route":"/calculate-route-price"},

      //for drivers
      // {"module":"Vehicle Geolocation Form", "route":"/driverGeoLocationForm"}


      // {"module":"FasTag Credits Drivers","route":'/dashboard/fastagAssign'},
      // {"module":"Assign FasTag Credits","route":'/dashboard/fastagDriverAssign'}
       ]
    constructor(private _erpnextapiservice: ErpnextapiService, private router: Router){}

    // canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) : boolean  {

      // if((this.apiKey != null && this.apiSecret != null)||(this.apiKey != undefined && this.apiSecret != undefined) ){
      //     return true;
      // }else{
      //     this.router.navigate(['/access-denied'])
      //   return false;
      // }
      // }
      canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean  {
        return new Promise((resolve,reject) => {
          if((this.apiKey != null && this.apiSecret != null)||(this.apiKey != undefined && this.apiSecret != undefined) && this.eligibleModules){
              var selectedRoute = this.moduleRoute.filter(item => item.route === state.url || new RegExp(item.route).test(state.url));
              if(selectedRoute && selectedRoute.length){
                var currRoute = selectedRoute[0]["module"];
                var eligibility = this.eligibleModules.some(item => item === currRoute);
                console.log("eligibility-->",eligibility);

                if(eligibility){
                  resolve(eligibility);
                }else{
                this.router.navigate(['/login']);
                resolve(eligibility);
                }

              }else{
                resolve(false);

              }
          }else{
            resolve(false);

          }
        })
      }
}