import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpnextapiService } from '../common/service/erpnextapi.service';

@Component({
  selector: 'app-payout',
  templateUrl: './payout.component.html',
  styleUrls: ['./payout.component.css']
})
export class PayoutComponent implements OnInit {
  error: boolean = false;
  errorMessage: String = "";
  dataLoading: boolean = false;
  private querySubscription;
  savedChanges: boolean = false;
  showErrorMsg = false;
  orderParams;
  salesOrderDetails;
  userInfo;
  userRole;
  customer;
  userEmail;
  p: number = 1;
  itemsLength;
  fuelRoute;
  fuelTransactionList;
  fc_id;
  fa_id;
  fasTagCustomerDetails;
  showOrders = false;
  purchaseOrderDetails;
  invoiceParams;
  config;
  purchaseinvoiceList: any;
  purchaseinvoiceLength: any;
  //config: { id: string; itemsPerPage: number; currentPage: number; };

  constructor(private _erpNextApiService: ErpnextapiService, private router:Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.userRole = localStorage.getItem('userRole');
    this.userEmail = localStorage.getItem('email');
    this.customer = localStorage.getItem('customer');
  // this.getUserDetails();
  if(this.userRole === 'Owner'){
    this. invoiceParams = `?fields=%5B"name","posting_date","sales_order","status","item_group","grand_total","owner"%5D&filters=[["customer","=","${this.customer}"],["payment_type","!=","Advance"]]&limit_page_length=100&order_by=name desc`;
  }else{
  this. invoiceParams = `?fields=%5B"name","posting_date","sales_order","status","item_group","grand_total"%5D&filters=[["customer","=","${this.customer}"],["owner","=","${this.userEmail}"],["payment_type","!=","Advance"]]&limit_page_length=100&order_by=name desc`;
  }
  this.getAllPurchaseInvoice();
    this.config={
      id:'custom',
      itemsPerPage:10,
      currentPage:1,
    };
    this.getLoggedInUser();
  }
  getLoggedInUser() {
    this._erpNextApiService.getUserDetails().subscribe((res) => {
      //console.log(res);
      if(res["data"]=== ''){
        this.logout();
      }
  })
  }
  logout(){
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    localStorage.removeItem('tax_category');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }
  // getAllPurchaseOrderNames(){
  // this.dataLoading = true;
  // console.log(this.orderParams);
  // this._erpNextApiService.getAllPurchaseOrder(this.orderParams).subscribe((res) => {
  //   if (res["errorCode"] == '0') {
  //       this.error = false;
  //       this.errorMessage = "";
  //       this.dataLoading = false;
  //       this.purchaseOrderDetails=res["data"];
  //       console.log(this.purchaseOrderDetails)
  //       if(this.purchaseOrderDetails != undefined){
  //         this.itemsLength = this.purchaseOrderDetails.length;
  //         this.showOrders = true;
  //       }
  //       console.log(res)
      
  //     } else {
  //       this.error = true;
  //       this.errorMessage = res["errorMessage"];
  //       this.dataLoading = false;
  //   }
  // },
  //   (error) => {
  //       this.error = true;
  //       this.errorMessage = error.message;
  //       this.dataLoading = false;
  //   },
  //   () => {
  //       this.dataLoading = false;
  //   });
  // }

  getAllPurchaseInvoice(){
    //console.log(this.invoiceParams)
      this._erpNextApiService.getPurchaseInvoice(
        ).subscribe(res=>{
      console.log(res)
      if(res["errorCode"]=='0'){
        this.error=false;
        this.errorMessage=" ";
        this.dataLoading=false;
        this.purchaseinvoiceList=res["data"];
        console.log(res["data"])
        if(this.purchaseinvoiceList!=undefined){
          this.purchaseinvoiceLength = this.purchaseinvoiceList.length;
          this.config={
            id: 'custom',
            itemsPerPage:10,
            currentPage:1,
            totalItems:this.purchaseinvoiceList.length
          };
        }
      }else{
        this.error=true;
        this.errorMessage=res["errorMessage"];
        this.dataLoading==false;
      }
    },
      (error)=>{
        this.error=true;
        this.errorMessage=error.message;
        this.dataLoading=false;
      },
      ()=>{this.dataLoading=false;}
    );
    localStorage.removeItem('cartItems4Invoice');


  }
}

