// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // key:'rzp_test_ZmHwqJYdQR0PC1'
  key: 'rzp_test_ldtKOg8gPhbzwb',
 
  TWILIO_ACCOUNT_SID: 'ACbcdc5e78c52b7470f2d9e80f1cf42fbc',
  TWILIO_AUTH_TOKEN: '823a36028125615c738567cefbc2a8b0',

  //  erpBaseUrl: 'https://web.lnder.in',
  // usr: 'devadmin@gmail.com',
  // pwd: "Devadmin@123",
  
  usr: 'devadmin@gmail.com',
  pwd: 'LnderDesk123',
  erpBaseUrl:'https://desk.lnder.in'
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
