import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "search",
})
export class SearchFilterPipePipe implements PipeTransform {
  transform(values: any[], args: any): any {
    // console.log('search-pipe-value---', values)
    // console.log('search-pipe-args---', args)

    if (!values) return null;
    if (!args) return values;
    // console.log()

    args = args.toLowerCase().trim();
    // console.log(args)
    return values.filter(function (item) {
      // console.log("item--to--be--searched-------", item)
      return JSON.stringify(item).toLowerCase().includes(args);
    });
  }
}
