import { AfterViewInit, Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { environment } from "src/environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import * as XLSX from "xlsx";
import { log } from "console";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { promise } from "protractor";

@Component({
  selector: "app-intrcity-dashboard",
  templateUrl: "./intrcity-dashboard.component.html",
  styleUrls: ["./intrcity-dashboard.component.css"],
})
export class IntrcityDashboardComponent implements OnInit {
  searchString: any = "";
  isDownloadOpt: any = true;
  fileName = "Lnder.xlsx";
  dataLoading:any=false;

  email: any;
  marchantid: any;
  walletId: any;
  Fastag_Cust_Id: any;
  customer: any;

  customer_details: any = [];
  isCustomerDeatils: any;
  NoCustomerDeatils: any = false;
  customerDetailsDatalength: number = 0;

  monthlyBpclTransactionData: any = [];
  monthlyBpclTransactionData1: any;
  monthlyBpclDatalength: number = 0;
  monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;
  isMonthlyBpclTxn: boolean = true;
  noMonthlyBpclData: boolean = false;

  total_credit_limit: number = 0;
  total_avl_credit_limit: number = 0;
  total_net_balance: number = 0;
  total_bpcl_bal: number = 0;
  credit_utilization: number = 0;
  fastag_balance: number = 0;

  todayDate: any;
  todaysDate: any;
  weeklyDate: any;
  monthlyDate: any;
  fa_id: any;

  p1: any = 1;
  p2: any = 1;
  p3: any = 1;
  p4: any = 1;

  bpcl_bal: number = 0;
  fuel_allottment: number = 0;
  toll_allottment: number = 0;

  utilizationDateForm: any;
  utilizationData: any = [];
  noUtilizationData: any = false;
  isUtilizationDataTxn: any = false;

  cashbackDateForm: any;
  cashBackData: any = [];
  noCashBackData: any = false;
  isCashBackDataTxn: any = false;

  fastagRechargeForm: any;
  fastagRechargeHistory: any = [];
  isFastagRechargeTxn: boolean;
  rechargeTransactionData: any;
  fastag_recharge_response: any;

  bpclCustomerBalance:number=0;
  fastagBalCardLoader:boolean=true;

  constructor(
    private _erpService: ErpnextapiService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) {
    this.fastagRechargeForm = fb.group({
      amount: ['', Validators.required],
    });

    this.utilizationDateForm = fb.group({
      fromdate: ["", Validators.required],
      todate: ["", Validators.required],
    });

    this.cashbackDateForm = fb.group({
      fromdate: ["", Validators.required],
      todate: ["", Validators.required],
    });
  }

  async ngOnInit(): Promise<void> {
    // this.fa_id = localStorage.getItem("FAID");
    this.email = localStorage.getItem("email");
     this.marchantid = localStorage.getItem("marchantid");
    this.walletId =  localStorage.getItem("walletid");
    this.Fastag_Cust_Id = localStorage.getItem("FastagCustomerID");
    this.customer = localStorage.getItem("customer");
    // this.getFastagBalance();


    setTimeout(()=>{
      this.getFastagBalance()
    },3000)


    let today = new Date();
    this.todayDate = this.datePipe.transform(today, "yyyyMMdd");
    // this.todayDate = this.datePipe.transform(today,'yyyy-MM-dd');
    // console.log(this.todaysDate);
    let sevenDaysBack = new Date(new Date().setDate(today.getDate() - 6));
    this.weeklyDate = this.datePipe.transform(sevenDaysBack, "yyyyMMdd");
    // console.log(this.weeklyDate)
    let thirtyDaysBack = new Date(new Date().setDate(today.getDate() - 31));
    this.monthlyDate = this.datePipe.transform(thirtyDaysBack, "yyyyMMdd");

    this.getIntrcityCustomerDetails();

    // this.getSuperCusDetails();
    // this.getBpcltxn('FA3000043391');
    // this.getBpclBal('FA3000043391');


    this. getFastagRechargeHistory();

    this.utilizationData = [
      {
        customer: "UBS Intermediary",
        type: "BPCL",
        totalAllocAmt: 100000,
        totalUtilizedAmt: 30000,
        totalNotUtilized: 70000,
      },
      {
        customer: "UBS Intermediary",
        type: "Fastag",
        totalAllocAmt: 50000,
        totalUtilizedAmt: 10000,
        totalNotUtilized: 40000,
      },
      {
        customer: "UBS Intermediary",
        type: "IOCL",
        totalAllocAmt: 10000,
        totalUtilizedAmt: 5000,
        totalNotUtilized: 5000,
      },
    ];

    this.cashBackData = [
      {
        outlet: "VEERABADRAN ENTERPRISES BHARAT PETROLEUM DEALERS",
        city: "Chennai",
        totalAllocAmt: 100000,
        cashback: 4000,
      },
      {
        outlet: "SRI VENKATESWARA FLG STN. BHARAT PETROLEUM DEALERS",
        city: "Banglore",
        totalAllocAmt: 50000,
        cashback: 1000,
      },
      {
        outlet: "KONARK FILLING STATION BHARAT PETROLEUM DEALERS",
        city: "Hyderbad",
        totalAllocAmt: 100000,
        cashback: 3000,
      },
    ];
  }
  // ngAfterViewInit(){
  //   this.getFastagBalance();
  // }

  getIntrcityCustomerDetails() {
    this.isCustomerDeatils = true;
    // let promise= new Promise((resolve:any,reject)=>{
    //   if(this.walletId&&this.marchantid){
    //     resolve()
    //   }
    // })
    // promise.then(()=>this.getFastagBalance())
    // this.getFastagBalance();

    this._erpService
      .getCustomerDetailsofIntrcity(this.email)
      .subscribe((res: any) => {
        // console.log(res);
        if (
          res["errorCode"] == "0" &&
          res["data"].message != "Super Customer Not Found" &&
          res["data"]["exc_type"] != "ValidationError" &&
          res["data"].message
        ) {
          this.customer_details = res["data"].message;
          for (let data of this.customer_details) {
            this.total_credit_limit += data.total_credit_limit;
            this.fuel_allottment += data.allotted_amt_fuel;
            this.toll_allottment += data.allotted_amt_fastag;
            this.total_bpcl_bal+=data.avail_bpcl_cms_balance;
            this.fa_id = data.fa_id;
            // console.log(this.fa_id);

            // this.total_credit_limit+=data.total_credit_limit

            // this.total_avl_credit_limit+=data.credit_limit
            // this.total_net_balance+=data.net_credit_balance
            // this.total_bpcl_bal+=data.avail_bpcl_cms_balance
            // this.getBpclBal(this.fa_id);
            this.getBpcltxn(this.fa_id);


            // data['bpcl_balance']=this.bpcl_bal;
          }
          // console.log(" this.total_net_balance-->", this.total_net_balance);
          // console.log(" this.total_credit_limit-->", this.total_credit_limit)

          this.customerDetailsDatalength = this.customer_details.length;
          this.credit_utilization =
            this.total_credit_limit - this.total_avl_credit_limit;
          this.isCustomerDeatils = false;
        } else {
          this.customerDetailsDatalength = 0;
          this.isCustomerDeatils = false;
          this.NoCustomerDeatils = true;
          this.isMonthlyBpclTxn = false;
          this.noMonthlyBpclData = true;
        }
      });
  }

  getSuperCusDetails() {
    this._erpService
      .S_getSuperustomerDetailsofIntrcity(this.customer)
      .subscribe((res: any) => {
        // console.log(res)
        if (
          res["errorCode"] == "0" &&
          res["data"].message != "Customer Not Found" &&
          res["data"]["exc_type"] != "ValidationError" &&
          res["data"].message
        ) {
          let responseData = res["data"].message;
          // console.log("Super customer Deatils",responseData)
          for (let data of responseData) {
            this.total_credit_limit += data.total_credit_limit;
            this.fuel_allottment += data.total_allocatted_amt_fuel;
            this.toll_allottment += data.total_allocatted_amt_fastag;
          }

          // this.total_credit_limit = responseData[0].total_credit_limit;
          // this.fuel_allottment = responseData[0].allotted_amt_bpcl;
          // this.toll_allottment = responseData[0].allotted_amt_fastag;
        } else {
        }
      });
  }

  getBpcltxn(id) {
    let body = {
      account_id: id,
      from_date: ` ${this.monthlyDate}T00:00`,
      to_date: `${this.todayDate}T23:59`,
    };
    // console.log(body);

    this._erpService.getBpclTxnHistory(body).subscribe((res) => {
      // console.log("getMonthlyBpclTxn()----->", res);

      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"]["exc_type"] != "ValidationError"
      ) {
        // this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
        // this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

        // console.log(new Date('2023/06/07 04:18:41 PM'));

        if (res["data"].message != "No record found") {
          this.monthlyBpclTransactionData =
            this.monthlyBpclTransactionData.concat(res["data"].message);

          // this.monthlyBpclTransactionData = this.monthlyBpclTransactionData.concat(res['data'].message);

          for (let i = 0; i < this.monthlyBpclTransactionData.length; i++) {
            let date = this.monthlyBpclTransactionData[i][
              "product_details"
            ].createdDT
              .slice(0, 10)
              .replace(/\//g, " - ")
              .split(" ")
              .reverse()
              .join("");
            let time = this.monthlyBpclTransactionData[i][
              "product_details"
            ].createdDT.slice(11, 22);
            if (
              this.monthlyBpclTransactionData[i]["transaction_details"]
                .vehicleNumber == ""
            ) {
              this.monthlyBpclTransactionData[i][
                "transaction_details"
              ].vehicleNumber = "NA";
            }
            // console.log(date);
            // console.log(time);

            this.monthlyBpclTransactionData[i]["product_details"]["Date"] =
              date;
            this.monthlyBpclTransactionData[i]["product_details"]["Time"] =
              time;
          }

          // console.log("05/06/2023".replace(/\//g, " - ").split(" ").reverse().join(""))

          // console.log(this.monthlyBpclTransactionData)

          let filterdate = this.monthlyBpclTransactionData.map((obj) => {
            return { ...obj, newdate: new Date(obj["product_details"].Date) };
          });

          // console.log(filterdate);

          this.monthlyBpclTransactionData1 = filterdate.sort(
            (a, b) => Number(b.newdate) - Number(a.newdate)
          );

          // console.log(this.monthlyBpclTransactionData1);

          this.monthlyBpclDatalength = this.monthlyBpclTransactionData1.length;

          if (this.monthlyBpclDatalength == 0) {
            this.noMonthlyBpclData = true;
          }
          this.isMonthlyBpclTxn = false;
        } else {
          this.isMonthlyBpclTxn = false;
          this.noMonthlyBpclData = true;
        }
      } else {
        this.isMonthlyBpclTxn = false;
        this.noMonthlyBpclData = true;
      }

      if (this.monthlyBpclTransactionData1.length > 0) {
        this.noMonthlyBpclData = false;
        this.isMonthlyBpclTxn = false;
      }
    });
  }

  // getBpclBal(id) {
  //   this._erpService.FromErpGetBpclFuelBal(id).subscribe((res) => {
  //     // console.log(res)
  //     if (
  //       res["errorCode"] == "0" &&
  //       res["data"].message != "No record found" &&
  //       res["data"]["exc_type"] != "ValidationError" &&
  //       res["data"].message
  //     ) {
  //       this.bpcl_bal = res["data"].message;
  //       console.log( this.bpcl_bal)
  //       // this.total_bpcl_bal += this.bpcl_bal;
  //     }
  //   });
  // }

  getFastagBalance() {
    console.log("*******")
    let data = {
      uuid: localStorage.getItem("walletid"),
      merchantID:  localStorage.getItem("marchantid"),
      requestSource: localStorage.getItem("requsetSource"),
      // "requestSource": ""
    };
    // console.log(localStorage.getItem("FastagCustomerID"),data);
    // console.log("customer id-->", this.Fastag_Cust_Id);

    // let data = {
    //   uuid: "W0122040712524350013",
    //   merchantID: "HDFCWL",
    //   requestSource: "TOLLPARTNER1",
    // };

    this._erpService
      .getFastagBal(localStorage.getItem("FastagCustomerID"), data)
      .subscribe((res) => {
        // console.log(res);

        if (res["errorCode"] == "0") {
          // console.log("fastagWalletBalance------>", res["data"]);
          this.fastag_balance = res["data"]["currentBalance"];
          this.fastagBalCardLoader=false
        } else {
          this.fastagBalCardLoader=false
        }
      });
  }

  downloadFun() {
    let element = document.getElementById("excel-table");

    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  getFastagRechargeHistory() {
    this.fastagRechargeHistory=[];
    this.isFastagRechargeTxn = true;
    let payload = {
      args: {
        customer: this.customer,
        start_date: this.monthlyDate,
        end_date: this.todayDate,
      },
    };

    this._erpService.s_getRechargeTxnHistory(payload).subscribe((res: any) => {
      // console.log("getFastagRechargeHistory()-->", res);
      if (
        res["errorCode"] == "0" &&
        res["data"].message != "No record found" &&
        res["data"].message.length > 0
      ) {
        let responsedata = res["data"].message;
        for (let i = 0; i < responsedata.length; i++) {
          let date = responsedata[i]["creation"].slice(0, 10);
          let time = responsedata[i]["creation"].slice(11, 19);
          // console.log(date);
          // console.log(time);

          responsedata[i]["Date"] = date;
          responsedata[i]["Time"] = time;
        }
        const filterdate = responsedata.map((obj) => {
          return { ...obj, date: new Date(obj.Date) };
        });

        this.rechargeTransactionData = filterdate.sort(
          (a, b) => Number(b.date) - Number(a.date)
        );
        // console.log(  this.rechargeTransactionData);

        for (let i = 0; i < this.rechargeTransactionData.length; i++) {
          let recharge_type =
            this.rechargeTransactionData[i].recharge_type.toLowerCase();
          //  console.log(recharge_type);

          if (recharge_type === "fastag recharge") {
            this.fastagRechargeHistory.push(this.rechargeTransactionData[i]);
          }
        }

        this.isFastagRechargeTxn = false;
      }
    });
  }

  fastagRechargeAmtEvent(event: any) {
    let amt = event.target.value;
    if (amt > 0) {
      this.fastagRechargeForm.patchValue({
        amount: amt,
      });
    } else {
      this.fastagRechargeForm.patchValue({
        amount: '',
      });
    }
  }

  onFastagRecharge() {
    // const Toast = Swal.mixin({
    //   toast: true,
    //   position: 'top-right',
    //   showConfirmButton: false,
    //   timer: 4000,
    //   timerProgressBar: false,
    //   didOpen: (toast) => {
    //     toast.addEventListener('mouseenter', Swal.stopTimer)
    //     toast.addEventListener('mouseleave', Swal.resumeTimer)
    //   }
    // })
    // Toast.fire({
    //   icon: "info",
    //   title: 'Thank You! Please wait for 5sec.'
    // })
    this.dataLoading=true
    let amount = parseInt(this.fastagRechargeForm.get("amount").value);
    // console.log(amount);

    this._erpService.getFastagRechargeRes(this.customer,amount).subscribe((res:any)=>{
      if (res["errorCode"] == "0" && res["data"]) {
        // console.log("fastag recharge response---->", res);
        let response=res["data"]["message"]
        this.fastag_recharge_response = res["data"]["message"]["success"];
        // console.log(  this.fastag_recharge_response );





        if( this.fastag_recharge_response){
          Swal.fire({
            icon:'success',
            text:response.msg
          })
        }
        else{
          Swal.fire({
            icon:'error',
            text:response.msg
          })
        }
        this.dataLoading=false;
        this.getFastagRechargeHistory();





      }
      else{
        this.dataLoading=false
      }
    })

  }
  resetRechargeForm() {
    this.fastagRechargeForm.reset();
  }

  Customer_details_Tab() {
    this.searchString = "";
    this.isDownloadOpt = true;
  }
  Bpcl_Txn_Tab() {
    this.searchString = "";
    this.isDownloadOpt = false;
  }
  Fastag_Rec_His() {
    this.searchString = "";
    this.isDownloadOpt = false;
  }
  utilizationApplyBtn() {
    console.log(this.utilizationDateForm.value);
  }

  cashBackApplyBtn() {
    console.log(this.cashbackDateForm.value);
  }
}
