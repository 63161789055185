import { Component, OnInit } from "@angular/core";
import { ErpnextapiService } from "../common/service/erpnextapi.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Observable, ReplaySubject } from "rxjs";
import { DatePipe } from "@angular/common";
import Swal from "sweetalert2";
import { environment } from "src/environments/environment.prod";

@Component({
  selector: "app-tyre-service-report",
  templateUrl: "./tyre-service-report.component.html",
  styleUrls: ["./tyre-service-report.component.css"],
})
export class TyreServiceReportComponent implements OnInit {
  erpBaseUrl: any;
  dataLoading: any;
  tyreServiceForm: any;

  p1: any = 1;
  customer: any;
  searchString: any = "";
  insUplaodvehicleNo: any;
  serviceReports: any = [];
  allLinkedVehicles: any = [];
  linkedTyres: any = [];

  constructor(
    private _erpService: ErpnextapiService,
    private datePipe: DatePipe
  ) {
    this.tyreServiceForm = new FormGroup({
      ref_doctype: new FormControl("Vehicle Registration Certificate"),
      time_stamp: new FormControl(""),
      vehicle_no: new FormControl("", [Validators.required]),
      serial_no: new FormControl("", [Validators.required]),
      vehicle_tire_position: new FormControl(""),
      vehicle_odometer_value_at_service: new FormControl(0),
      // tyre_milage_at_service: new FormControl(0, [Validators.required]),
      nsd_value: new FormControl("", [Validators.required]),
      cost: new FormControl("", [Validators.required]),
      maintenance_type: new FormControl("", [Validators.required]),
      attach_img_byte: new FormControl(""),
      attach_img_name: new FormControl(""),
      attach_img_extension: new FormControl(""),
    });
  }

  ngOnInit(): void {
    this.erpBaseUrl = environment.erpBaseUrl;
    this.customer = localStorage.getItem("customer");
    this.getTyreServiceReports();
    this.getVehiclesList();
  }

  getTyreServiceReports() {
    this.dataLoading = true;
    let params = `?fields=%5B"*"%5D&limit_page_length=Infinity&filters=[["customer","=","${this.customer}"]]`;
    this._erpService.getAllTyreServiceReports(params).subscribe((res: any) => {
      // console.log("All service Reports-->", res);
      if (res.errorCode == 0 && res.data.data) {
        let response = res.data.data;
        this.serviceReports = response;
        for (let item of this.serviceReports) {
          if (item.nsd_value <= 4) {
            item.nsd_status = "Needs Change";
          } else if (item.nsd_value > 4 && item.nsd_value <= 10) {
            item.nsd_status = "Low";
          } else if (item.nsd_value > 10 && item.nsd_value <= 20) {
            item.nsd_status = "Medium";
          } else if (item.nsd_value > 20) {
            item.nsd_status = "High";
          } else {
            item.nsd_status = "NULL";
          }
        }
        this.dataLoading = false;
      } else {
        this.dataLoading = false;
      }
    });
  }
  selectVehicleNo(event: any) {
    let vno = event;
    if (vno != undefined) {
      this.getOdometerData(vno);
      this.getLinkedTyres(vno);
      this.tyreServiceForm.patchValue({
        vehicle_no: vno,
      });
    } else {
      this.tyreServiceForm.patchValue({
        vehicle_no: vno,
        vehicle_odometer_value_at_service: 0,
      });
    }
  }
  getOdometerData(vno: any) {
    let payload = {
      vehicle_no: [vno],
    };

    this._erpService.getOdometerData(payload).subscribe((res: any) => {
      // console.log("getOdometerData-->",res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message[0];
        if (response.plate == vno) {
          this.tyreServiceForm.patchValue({
            vehicle_odometer_value_at_service: response.end.odo_km,
          });
        }
      }
    });
  }

  getLinkedTyres(vno: any) {
    let payload = {
      vehicles: [[vno]],
    };
    this._erpService.getLinkedTyres(payload).subscribe((res: any) => {
      // console.log(res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message[vno];
        let values = Object.values(response);
        this.linkedTyres = values;
        // console.log(Object.values(response))
      }
    });
  }
  selectedTyre(event: any) {
    let value = event;
    console.log(event);
    if (value) {
      this.tyreServiceForm.patchValue({
        serial_no: value,
      });
    } else {
      this.tyreServiceForm.patchValue({
        serial_no: "",
      });
    }
  }

  reportFileAttach(event: any) {
    let file = event.target.files[0];
    if (file) {
      let fileFormat = file.name.split(".");
      // console.log(file.type);
      // console.log(file.name.split("."));
      this.tyreServiceForm.patchValue({
        attach_img_name: fileFormat[0],
        attach_img_extension: fileFormat[1],
      });
      this.convertFile(event.target.files[0]).subscribe((base64res) => {
        let base64 = base64res;
        // console.log(base64);
        this.tyreServiceForm.patchValue({
          attach_img_byte: base64,
        });
      });
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }
  maintainceType(event: any) {
    // console.log(event);
    let type = event;
    if (type != undefined) {
      this.tyreServiceForm.patchValue({
        maintenance_type: type,
      });
    }
  }

  getVehiclesList() {
    let payload = {
      customer: this.customer,
      doctype: "Vehicle Registration Certificate",
    };
    this._erpService
      .getLinkedVehiclesofCustomer(payload)
      .subscribe((res: any) => {
        // console.log("getVehiclesList-->", res);
        if (
          res.errorCode == "0" &&
          res.data.message &&
          typeof res.data.message != "string"
        ) {
          let response = res.data.message;

          this.allLinkedVehicles = response;

          // this.serviceReports = response;
        }
      });
  }

  uploadReport() {
    this.dataLoading = true;
    let date = new Date();
    let dateformat = this.datePipe.transform(date, "yyyy-MM-dd h:mm:ss");
    // console.log(dateformat);
    this.tyreServiceForm.patchValue({
      time_stamp: dateformat,
    });
    if (this.tyreServiceForm.valid) {
      let payload = this.tyreServiceForm.value;

      console.log(this.tyreServiceForm.value);
      this._erpService
        .uploadTyreServiceReport(payload)
        .subscribe((res: any) => {
          // console.log("uploadtyre service resposne-->", res);
          if (res.errorCode == 0 && res.data && !res.data._server_messages) {
            Swal.fire({
              icon: "success",
              title: "Uploaded Successfully ",
            }).then(() => {
              location.reload();
            });
          } else {
            let error = res.data._server_messages;
            // console.log(error[0]);
            let errorRes = JSON.parse(error);
            let error1 = JSON.parse(errorRes[0]);

            // console.log("uploadtyre service resposne-->", error1.message);
            Swal.fire({
              icon: "error",
              text: error1.message,
            });
          }
        });
      this.dataLoading = false;
    } else {
      this.dataLoading = false;
    }
  }
}
