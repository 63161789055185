import { Component, OnInit } from '@angular/core';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-credits-allocation',
  templateUrl: './credits-allocation.component.html',
  styleUrls: ['./credits-allocation.component.css']
})
export class CreditsAllocationComponent implements OnInit {
  customer:any;
  email:any;
  marchantid:any;
  walletId:any;
  Fastag_Cust_Id:any;
  fa_id:any;



  bpcl_balance:number=0;
  fastag_balance:number=0;

  total_credit:number=0;
  total_avl_credit:number=0;

  assigned_bpcl_val:number=0;
  assigned_fastag_val:number=0;
  confirm_btn_disabled:boolean=true;



  constructor(private _erpService: ErpnextapiService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router, private tostr:ToastrService) { }

  ngOnInit(): void {

    this.fa_id=localStorage.getItem('FAID');
    this.customer=localStorage.getItem('customer');
    this.email=localStorage.getItem('email');
    this.marchantid = localStorage.getItem('marchantid');
    this.walletId = localStorage.getItem('walletid');
    this.Fastag_Cust_Id = localStorage.getItem('FastagCustomerID');


    this. getCustomerDeatils();
    this.getBpclFuelBal();
    this. getFastagWalletbalance();
    

  }



  getFastagWalletbalance() {
    // this.dataLoading = true;
    // console.log(marchantId);

    let data = {
      "uuid": this.walletId,
      "merchantID": this.marchantid,
      "requestSource": localStorage.getItem("requsetSource")
    }
    // console.log(data);



    this._erpService.getFastagBal(this.Fastag_Cust_Id, data).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagWalletBalance------>", res["data"]);
        this.fastag_balance = res["data"]["currentBalance"];

        // this.dataLoading = false;
      }
      else {
        // console.log("fastag wallet balance error");

      }


    })

  }


// From ERP SERVER
  getBpclFuelBal(){
    this._erpService.FromErpGetBpclFuelBal(this.fa_id).subscribe((res)=>{
      // console.log("BPCL Balance",res)
      if (res["errorCode"] == "0" && res["data"]['message']>0 ) {
        this.bpcl_balance =res["data"]['message']
      }
      else{
        this.bpcl_balance = 0
      }
    })
  }

  getCustomerDeatils(){


    this._erpService.getSubCustomerDetails(this.email).subscribe((res)=>{
      // console.log("getSubCustomerDetails ---->", res);

      if(res['errorCode'] == '0' && res['data'].message != 'No Record Found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message){
        let responseData=res["data"].message
        this.total_credit=responseData.total_credit_limit;
        this.total_avl_credit=responseData.total_available_credit_limit

      }
      else{
        this.total_credit=0;
        this.total_avl_credit=0;
      }
    })

  }


  assignCredit(event,category){
    let value =parseInt(event.target.value)
    console.log(value)
    
    if(isNaN(value)){
     value=0
    }

    if(category=='bpcl'  ){
      this.assigned_bpcl_val=0
      this.assigned_bpcl_val+=value;
      this.totalValidation()
    }
    else if(category=='fastag' ){
      this.assigned_fastag_val=0
      this.assigned_fastag_val+=value;
      this.totalValidation()
    }
    // else if ((  this.assigned_bpcl_val>0) || (  this.assigned_fastag_val >0)){
    //   this.confirm_btn_disabled=false
    // }
    


 

  }

  totalValidation(){

    let total = this.assigned_bpcl_val +  this.assigned_fastag_val;
    // console.log(total)

    if(total>0 && total<=this.total_avl_credit && !isNaN(total)){
      this.confirm_btn_disabled=false;
      // console.log("Passed");
    }
    else if (total>this.total_avl_credit && this.total_avl_credit>0 ){
      this.confirm_btn_disabled=true;
      // console.log("Exceeded");
      this.tostr.warning("Value is exceeded of Total Credit Limit");
    }
    else{
      this.confirm_btn_disabled=true;
    }

  }


  ConfirmedCreditsAssigned(){
    let body={
      "args": {
        "customer": this.customer,
        "bpcl_amount": this.assigned_bpcl_val,
        "fastag_amount": this.assigned_fastag_val,
        "super_customer": ""
      }
    }

    console.log("confirmedCreditsAssigned body--->",body)

    this._erpService.S_CreditsAllocation(body).subscribe((res)=>{
      console.log("getSCreditsAllocationDetails ---->", res);

      if(res['errorCode'] == '0' && res['data'] != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message){
        let responseData=res["data"].message
        if(responseData=="Allocation Successfull"){
          this.tostr.success("Allocation Successfully Done")
          setTimeout(()=>{
            location.reload()
          },2500)
        }
        else{
          this.tostr.error("Failed Allocation")
        }

      }
    })


  }







}
