import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";
import { Observable, ReplaySubject } from "rxjs";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-veh-geolocation-form",
  templateUrl: "./veh-geolocation-form.component.html",
  styleUrls: ["./veh-geolocation-form.component.css"],
})
export class VehGeolocationFormComponent implements OnInit {
  isBreakdown: any = false;
  isPreventive: any = false;
  isTyreIssue: any = false;
  incidentForm: any;
  start_time: any;
  end_time: any;
  vno: any;

  selectedStatus: any;
  tyreMaintenceForm: any;

  type: any;
  isPreventiveReport: any = false;
  isBreakdownReport: any = false;
  addForm: FormGroup;
  rows: any = [{}];
  itemForm: FormGroup;

  addrowDisabled: any = true;

  arrayOfItems: any = [{ tyre_serial_no: "", nsd_value: "", cost: 0 }];

  trackingLogName: any = "";
  total_cost:number=0;

  linkedTyres:any=[]

  constructor(
    private _erpService: ErpnextapiService,
    private _ar: ActivatedRoute,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.incidentForm = new FormGroup({
      ref_doctype: new FormControl("Vehicle Registration Certificate"),
      driver_name: new FormControl("", [Validators.required]),
      vehicle_no: new FormControl({ value: "", disabled: true }),
      status: new FormControl(""),
      issue_based_on: new FormControl(""),
    });

    this.tyreMaintenceForm = new FormGroup({
      ref_doctype: new FormControl("Vehicle Registration Certificate"),
      time_stamp: new FormControl(""),
      vehicle_no: new FormControl(""),
      serial_no: new FormControl(""),
      vehicle_tire_position: new FormControl(""),
      vehicle_odometer_value_at_service: new FormControl(0),
      // tyre_milage_at_service: new FormControl(""),
      nsd_value: new FormControl(0),
      cost: new FormControl(""),
      maintenance_type: new FormControl(""),
      attach_img_byte: new FormControl(""),
      attach_img_name: new FormControl(""),
      attach_img_extension: new FormControl(""),
    });

    // this.rows = this.fb.array([]);
  }

  ngOnInit(): void {

    this._ar.params.subscribe((params: any) => {
      this.type = params["type"];
      this.vno = params["vno"];

      if (this.type == "breakdown") {
        this.isBreakdownReport = true;
        this.type = "Breakdown";
      } else if (this.type == "preventive") {
        this.isPreventiveReport = true;
        this.type = "Preventive Maintenance";
      }
    });
    if (this.vno != "" && this.vno != undefined) {
      console.log(this.type, this.vno);
      this. getLinkedTyres();
      this.getTrackingLogName();
      this.getOdometerData(this.vno);
      this.incidentForm.patchValue({
        vehicle_no: this.vno,
      });
      this.tyreMaintenceForm.patchValue({
        vehicle_no: this.vno,
        maintenance_type: this.type,
      });
    }

    console.log(this.vno);
  }


  getLinkedTyres(){
    let payload ={
      vehicles:[[this.vno]]
    }
    this._erpService.getLinkedTyres(payload).subscribe((res:any)=>{
      console.log(res);
      if(res.errorCode==0 && res.data.message){
        let response =res.data.message[this.vno];
        let values=Object.values(response);
        this.linkedTyres=values
        // console.log(Object.values(response))

      }

    })
  }

  getTrackingLogName() {
    let payload = {
      vehicle: this.vno,
    };

    this._erpService
      .getVehicleTrackingLogName(payload)
      .subscribe((res: any) => {
        console.log(res);
        if (res.errorCode == 0 && res.data.message) {
          let response = res.data.message;
          if (response.length > 0) {
            this.trackingLogName = response[0].name;
          }

          console.log(this.trackingLogName);
        }
      });
  }
  selectedTyre(event,i){
    let value=event;
    console.log(event);
    if (value) {
      this.arrayOfItems[i]['tyre_serial_no']= value;
      let item = this.arrayOfItems[this.arrayOfItems.length - 1];
      if (
        Object.keys(item).length == 0 ||
        item.tyre_serial_no == "" ||
        item.nsd_value == "" ||
        item.cost == ""
      ) {
        this.addrowDisabled = true;
      } else {
        this.addrowDisabled = false;
      }
    }


  }

  onAddRow() {
    this.arrayOfItems.push({
      tyre_serial_no: "",
      nsd_value: "",
      cost: "",
    });
    let item = this.arrayOfItems[this.arrayOfItems.length - 1];
    if (
      Object.keys(item).length == 0 ||
      item.tyre_serial_no == "" ||
      item.nsd_value == "" ||
      item.cost == ""
    ) {
      this.addrowDisabled = true;
    } else {
      this.addrowDisabled = false;
    }
  }

  tyreSerialNoEvent(event: any, i: any) {
    let value = event.target.value;
    if (value) {
      this.arrayOfItems[i].tyre_serial_no = value;
      let item = this.arrayOfItems[this.arrayOfItems.length - 1];
      if (
        Object.keys(item).length == 0 ||
        item.tyre_serial_no == "" ||
        item.nsd_value == "" ||
        item.cost == ""
      ) {
        this.addrowDisabled = true;
      } else {
        this.addrowDisabled = false;
      }
    }
  }

  nsdValueEvent(event: any, i: any) {
    let value = event.target.value;
    console.log(i, event);
    if (value) {
      this.arrayOfItems[i].nsd_value = value;
      let item = this.arrayOfItems[this.arrayOfItems.length - 1];

      if (
        Object.keys(item).length == 0 ||
        item.tyre_serial_no == "" ||
        item.nsd_value == "" ||
        item.cost == ""
      ) {
        this.addrowDisabled = true;
      } else {
        this.addrowDisabled = false;
      }
    }
  }
  costEvent(event: any, i: any) {
    let value = event.target.value;
    console.log(value);
    if (value) {
      this.arrayOfItems[i].cost = value;
      let item = this.arrayOfItems[this.arrayOfItems.length - 1];

      if (
        Object.keys(item).length == 0 ||
        item.tyre_serial_no == "" ||
        item.nsd_value == "" ||
        item.cost == ""
      ) {
        this.addrowDisabled = true;
      } else {
        this.addrowDisabled = false;
      }
    }

    console.log(this.arrayOfItems);
  }

  onRemoveRow(rowIndex: number) {
    this.rows.removeAt(rowIndex);
  }

  seletedReason(event: any) {
    this.isTyreIssue = false;
    let value = event.target.value.trim();
    if (value && value == "Vehicle is in Breakdown".trim()) {
      this.isBreakdown = true;
      this.isPreventive = false;
      this.tyreMaintenceForm.patchValue({
        maintenance_type: "Breakdown",
      });
    } else if (value && value == "Vehicle at Preventive Maintenance".trim()) {
      this.isPreventive = true;
      this.isBreakdown = false;
      this.tyreMaintenceForm.patchValue({
        maintenance_type: "Preventive Maintenance",
      });
    } else {
      this.isBreakdown = false;
      this.isPreventive = false;
      this.tyreMaintenceForm.patchValue({
        maintenance_type: "",
      });
    }
    console.log(value);
  }

  selectBreakdown(event: any) {
    let value = event.target.value.trim();

    if (value == "Tyre Issue") {
      this.isTyreIssue = true;
    } else {
      this.isTyreIssue = false;
    }
  }

  startTime(time: any) {
    this.start_time = time.target.value;
    this.calculateTimetaken();
    // console.log(start_time)
  }

  endTime(time: any) {
    this.end_time = time.target.value;
    this.calculateTimetaken();
    // console.log(end_time)
  }
  calculateTimetaken() {
    let timeTaken = this.end_time - this.start_time;
    console.log(timeTaken);
  }

  breakdownCostInvoled(event) {
    let value = event.target.value;
    if (value > 0) {
      this.tyreMaintenceForm.patchValue({
        cost: value,
      });
    } else {
      this.tyreMaintenceForm.patchValue({
        cost: 0,
      });
    }
  }
  tyreSerialNo(event) {
    let value = event.target.value;
    if (value) {
      this.tyreMaintenceForm.patchValue({
        serial_no: value,
      });
    }
  }
  nsdValue(event) {
    let value = event.target.value;
    if (value) {
      this.tyreMaintenceForm.patchValue({
        nsd_value: value,
      });
    }
  }

  selectStatus(status: any) {
    if (status != undefined) {
      this.selectedStatus = status;
    }
  }

  getOdometerData(vno: any) {
    let payload = {
      vehicle_no: [vno],
    };

    this._erpService.getOdometerData(payload).subscribe((res: any) => {
      // console.log("getOdometerData-->",res);
      if (res.errorCode == 0 && res.data.message) {
        let response = res.data.message[0];
        if (response.plate == vno) {
          this.tyreMaintenceForm.patchValue({
            vehicle_odometer_value_at_service: response.end.odo_km,
          });
        }
      }
    });
  }

  reportFileAttach(event: any) {
    let file = event.target.files[0];
    if (file) {
      let fileFormat = file.name.split(".");
      // console.log(file.type);
      // console.log(file.name.split("."));
      this.tyreMaintenceForm.patchValue({
        attach_img_name: fileFormat[0],
        attach_img_extension: fileFormat[1],
      });
      this.convertFile(event.target.files[0]).subscribe((base64res) => {
        let base64 = base64res;
        // console.log(base64);
        this.tyreMaintenceForm.patchValue({
          attach_img_byte: base64,
        });
      });
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) =>
      result.next(btoa(event.target.result.toString()));
    return result;
  }

  submit() {
    let date = new Date();
    let dateformat = this.datePipe.transform(date, "yyyy-MM-dd h:mm:ss");
    let dateformat1=this.datePipe.transform(date, "dd-MM-yyyy h:mm:ss");
    console.log(this.incidentForm.value);
    this.tyreMaintenceForm.patchValue({
      time_stamp: dateformat,
    });
    let formArray = [];
    let totalCost = 0;

    if (this.type == "Breakdown".trim()) {
      formArray.push(this.tyreMaintenceForm.value);
      this.total_cost =parseInt(this.tyreMaintenceForm.value.cost);
      console.log(  this.total_cost)
    } else if (this.type == "Preventive Maintenance".trim()) {
      console.log(this.arrayOfItems);
      for (let obj of this.arrayOfItems) {
        this.total_cost+= parseInt(obj.cost);
        if (obj.tyre_serial_no != "" && obj.nsd_value != "" && obj.cost != "") {
          this.tyreMaintenceForm.patchValue({
            serial_no: obj.tyre_serial_no,
            nsd_value: obj.nsd_value,
            cost: obj.cost,
          });
          formArray.push(this.tyreMaintenceForm.value);
        }
      }
    }

    console.log("formArray-->", formArray);
    console.log("totalCosty-->", this.total_cost);

    let len = 0;
    for (let payload of formArray) {
      // let payload = this.tyreMaintenceForm.value;
      len = len + 1;

      this._erpService
        .uploadTyreServiceReport(payload)
        .subscribe((res: any) => {
          console.log("uploadtyre service resposne-->", res);
          if (res.errorCode == 0 && res.data && !res.data._server_messages) {
            this.toastr.success(
              `Successfully uploaded tyre Maintenance for ${res.data.data.serial_no} `
            );
            // Swal.fire({
            //   icon: "success",
            //   title: "Uploaded Successfully ",
            // }).then(() => {
            //   location.reload();
            // });
          } else {
            this.toastr.error(
              `Failed to upload tyre Maintenance`
            );
            // let error = res.data._server_messages;
            // // console.log(error[0]);
            // let errorRes = JSON.parse(error);
            // let error1 = JSON.parse(errorRes[0]);

            // // console.log("uploadtyre service resposne-->", error1.message);
            // Swal.fire({
            //   icon: "error",
            //   text: error1.message,
            // });
          }
        });
      console.log(len, formArray.length);

      if (len == formArray.length) {
        this.updateTrackingLog(dateformat1);
        console.log({
        status: "Completed",
        cost: this.total_cost,
        docstatus: 1,

      })
      }
    }

    let payload = {
      status: "Completed",
      cost: this.total_cost,
      docstatus: 1,
      // end_time:dateformat
    };
    console.log(payload)

    // let payload = this.tyreMaintenceForm.value;

    // this._erpService.uploadTyreServiceReport(payload).subscribe((res: any) => {
    //   // console.log("uploadtyre service resposne-->", res);
    //   if (res.errorCode == 0 && res.data && !res.data._server_messages) {
    //     Swal.fire({
    //       icon: "success",
    //       title: "Uploaded Successfully ",
    //     }).then(() => {
    //       location.reload();
    //     });
    //   } else {
    //     let error = res.data._server_messages;
    //     // console.log(error[0]);
    //     let errorRes = JSON.parse(error);
    //     let error1 = JSON.parse(errorRes[0]);

    //     // console.log("uploadtyre service resposne-->", error1.message);
    //     Swal.fire({
    //       icon: "error",
    //       text: error1.message,
    //     });
    //   }
    // });

    console.log(this.tyreMaintenceForm.value);
  }

  updateTrackingLog(date) {
    if (this.trackingLogName != "") {
      console.log(this.trackingLogName);
      let params = `/${this.trackingLogName}`;
      let payload = {
        status: "Completed",
        cost_involved: this.total_cost,
        docstatus: 1,
        // end_time:date
      };

      console.log("updateTrackingLog-->", payload);

      this._erpService
        .updateVeicleTrackingLog(payload, params)
        .subscribe((res: any) => {
          console.log("updateVeicleTrackingLog-->", res);
          if(res.errorCode==0 && res.data.data){
            Swal.fire({
              icon: "success",
              title: "Uploaded Successfully ",
            }).then(() => {
              location.reload();
            });
          }
          else{
             Swal.fire({
              icon: "error",
              text:"Failed to upload",
            });
          }

        });
    }
  }
}
