import { Component, OnInit } from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ErpnextapiService } from "src/app/common/service/erpnextapi.service";

import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-calculate-route-price",
  templateUrl: "./calculate-route-price.component.html",
  styleUrls: ["./calculate-route-price.component.css"],
})
export class CalculateRoutePriceComponent implements OnInit {
  dataLoading:any=false
  addressForm: any;
  // fromToAddresses: any = [
  //   "Majestic Bus Stand, Bangalore, Bangalore district, Karnataka",
  //   "Kukatpally metro, Hyderabad, Hyderabad district, Telangana",
  //   "Madiwala, Bangalore, Bangalore district, Karnataka",
  //   "L.B Nagar, Hyderabad, Hyderabad district, Telangana",
  //   "Gudalur, Cumbam, Theni district, Tamil Nadu",
  //   "Nilakkottai, Dindigul, Dindigul district, Tamil Nadu",
  //   "Vadamadurai, Dindigul, Dindigul district, Tamil Nadu",
  //   "Kaliyal, Kanniyakumari, Kanyakumari district, Tamil Nadu",
  //   "Attoor, Kanyakumari, Kanyakumari district, Tamil Nadu",
  //   "Myladi, Kanyakumari district, Tamil Nadu",
  //   "Thoothoor, Kanyakumari, Kanyakumari district, Tamil Nadu",
  //   "Puduvayal, Karaikudi, Sivaganga district, Tamil Nadu",
  //   "Oothu, Kodaikanal, Dindigul district, Tamil Nadu",
  //   "Perumalmalai, Kodaikanal, Dindigul district, Tamil Nadu",
  //   "Karungalakudi, Madurai district, Tamil Nadu",
  //   "Otthakadai, Madurai district, Tamil Nadu",
  //   "Adhanur, Nagapattinam district, Tamil Nadu",
  //   "Akkur, Nagapattinam district, Tamil Nadu",
  //   "Kollidam, Nagapattinam district, Tamil Nadu",
  //   "Kuthalam, Thanjavur district, Tamil Nadu",
  //   "Manganallur, Nagapattinam district, Tamil Nadu",
  //   "Poompuhar, Nagapattinam district, Tamil Nadu",
  //   "Pudupattinam, Nagapattinam district, Tamil Nadu",
  //   "Thanikottagam, Nagapattinam district, Tamil Nadu",
  //   "Thirumulaivasal, Nagapattinam district, Tamil Nadu",
  //   "Thopputhurai, Nagapattinam district, Tamil Nadu",
  //   "Vaimedu, Nagapattinam district, Tamil Nadu",
  //   "Mangalapuram, Namagiripettai, Namakkal district, Tamil Nadu",
  //   "Vadachery, Pattukottai, Thanjavur district, Tamil Nadu",
  //   "Jegathapattinam, Pudukkottai district, Tamil Nadu",
  //   "Alangulam, Rajapalayam, Virudhunagar district, Tamil Nadu",
  //   "Nambuthalai, Ramanathapuram, Ramanathapuram district, Tamil Nadu",
  //   "Pasipattinam, Ramanathapuram, Ramanathapuram district, Tamil Nadu",
  //   "Vellayapuram, Ramanathapuram, Ramanathapuram district, Tamil Nadu",
  //   "Ervadi, Ramnad, Ramanathapuram district, Tamil Nadu",
  //   "Elanthangudi, Tanjore, Nagapattinam district, Tamil Nadu",
  //   "Papanasam, Tanjore, Thanjavur district, Tamil Nadu",
  //   "Nagalapuram, Theni, Theni district, Tamil Nadu",
  //   "Hanumanthampatti, Theni, Theni district, Tamil Nadu",
  //   "Jangalpatti, Theni, Theni district, Tamil Nadu",
  //   "Kamatchipuram, Theni, Theni district, Tamil Nadu",
  //   "Koduvilarpatti, Theni, Theni district, Tamil Nadu",
  //   "Kottur, Theni, Theni district, Tamil Nadu",
  //   "Odaipatti, Theni, Theni district, Tamil Nadu",
  //   "pudupatti, uthamapalayam, Theni district, Tamil Nadu",
  //   "Seelayampatti, Theni, Theni district, Tamil Nadu",
  //   "Venkatachalapuram, Theni, Theni district, Tamil Nadu",
  //   "Kamalapuram, Thiruvarur, Thiruvarur district, Tamil Nadu",
  //   "Kollumangudi, Thiruvarur, Thiruvarur district, Tamil Nadu",
  //   "Alangulam, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Eamankulam, Tirunelveli, திருநெல்வே	லி district, Tamil Nadu",
  //   "Elankulam, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Eruvadi, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Kadayalurutti, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Moondradaippu, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Virasigamani, Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Gudimangalam, Tiruppur, Tiruppur district, Tamil Nadu",
  //   "Alangudi, Tiruvarur, Thiruvarur district, Tamil Nadu",
  //   "Nagalapuram, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Kootampuli, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Korampallam, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Pandaravilai, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Perungulam, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Polaiyarpuram, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Pudukottai, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Sawyerpuram, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Servaikaranmadam, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Sivathaiyapuram, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Subramaniapuram, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Thandupathu, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Tharuvaikulam, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Veppalodai, Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Anthoniyarpuram, Tuticorn, Thoothukudi district, Tamil Nadu",
  //   "Vikramasingapuram, V K Puram, Tirunelveli district, Tamil Nadu",
  //   "Ariyanayagipuram, Vilathikulam, Tirunelveli district, Tamil Nadu",
  //   "Vilathikulam, Thoothukudi district, Tamil Nadu",
  //   "Panaiyur, Vilathikulam, Thoothukudi district, Tamil Nadu",
  //   "Alangulam, Tirunelveli district, Tamil Nadu",
  //   "Alagapuri, Virudhunagar, Virudhunagar district, Tamil Nadu",
  //   "Erichanatham, Virudhunagar, Madurai district, Tamil Nadu",
  //   "Watrap, Virudhunagar, Virudhunagar district, Tamil Nadu",
  //   "chithode, Erode district, Tamil Nadu",
  //   "chithode, Erode district, Tamil Nadu",
  //   "Aathipatti, Madurai district, Tamil Nadu",
  //   "Abiramam, Virudhunagar district, Tamil Nadu",
  //   "Adaiya Karungulam, Tirunelveli district, Tamil Nadu",
  //   "Adirampattinam, Thanjavur district, Tamil Nadu",
  //   "Aduthurai, Thanjavur district, Tamil Nadu",
  //   "Agasthiyarpatti, Tirunelveli district, Tamil Nadu",
  //   "Alagiapandiapuram, Tirunelveli district, Tamil Nadu",
  //   "Alagiyamandapam, Kanyakumari district, Tamil Nadu",
  //   "Alanthalai, Thoothukudi district, Tamil Nadu",
  //   "Alathur, Thanjavur district, Tamil Nadu",
  //   "Alwarkurichi, Tirunelveli district, Tamil Nadu",
  //   "Alwarthirunagiri, Thoothukudi district, Tamil Nadu",
  //   "Ambalavanapuram, Tirunelveli district, Tamil Nadu",
  //   "Ambasamudram, Tirunelveli district, Tamil Nadu",
  //   "Ambur, Vellore district, Tamil Nadu",
  //   "Ammanpuram, Thoothukudi district, Tamil Nadu",
  //   "Ammapattinam, Pudukkottai district, Tamil Nadu",
  //   "Anaimalai, Coimbatore district, Tamil Nadu",
  //   "Anandur, Ramanathapuram district, Tamil Nadu",
  //   "Ananthapuram, Villupuram district, Tamil Nadu",
  //   "Andagalur Gate, Namakkal district, Tamil Nadu",
  //   "Andichiurani, Sivaganga district, Tamil Nadu",
  //   "Andimadam, Ariyalur district, Tamil Nadu",
  //   "Andipatti, Theni district, Tamil Nadu",
  //   "Aniyapuram, Namakkal district, Tamil Nadu",
  //   "Anjugramam, Kanyakumari district, Tamil Nadu",
  //   "Annanji, Theni district, Tamil Nadu",
  //   "Annur, Coimbatore district, Tamil Nadu",
  //   "Anthakudi, Sivaganga district, Tamil Nadu",
  //   "Anthiyur, Erode district, Tamil Nadu",
  //   "Aralikottai, Sivaganga district, Tamil Nadu",
  //   "AralvaiMozhi, Kanyakumari district, Tamil Nadu",
  //   "Arani, Tiruvannamalai district, Tamil Nadu",
  //   "Aranmanai Pudhur, Theni district, Tamil Nadu",
  //   "Aranthangi, Pudukkottai district, Tamil Nadu",
  //   "Aravakurichi, Karur district, Tamil Nadu",
  //   "Arcot, Vellore district, Tamil Nadu",
  //   "Ariyalur, Ariyalur district, Tamil Nadu",
  //   "Arumuganeri, Thoothukudi district, Tamil Nadu",
  //   "Aruppukottai, Virudhunagar district, Tamil Nadu",
  //   "Aruppukottai, Virudhunagar district, Tamil Nadu",
  //   "Athangudi, Ramanathapuram district, Tamil Nadu",
  //   "Athiyuthu, Ramanathapuram district, Tamil Nadu",
  //   "Athupalam, Coimbatore district, Tamil Nadu",
  //   "Attur, Salem district, Tamil Nadu",
  //   "Authoor, Thoothukudi district, Tamil Nadu",
  //   "Authoor North, Thoothukudi district, Tamil Nadu",
  //   "Authoor South, Thoothukudi district, Tamil Nadu",
  //   "Avanam Kaikatti, Pudukkottai district, Tamil Nadu",
  //   "Avinashi, Tiruppur district, Tamil Nadu",
  //   "Avudaiyapuram, Tirunelveli district, Tamil Nadu",
  //   "Avudaiyarkoil, Pudukkottai district, Tamil Nadu",
  //   "Ayyampettai, Thanjavur district, Tamil Nadu",
  //   "Azhagappapuram, Kanyakumari district, Tamil Nadu",
  //   "Alagapuri",
  //   "Bannari, Erode district, Tamil Nadu",
  //   "Bathalagundu, Dindigul district, Tamil Nadu",
  //   "Batlagundu",
  //   "Belukurichi, Namakkal district, Tamil Nadu",
  //   "Bhavani, Erode district, Tamil Nadu",
  //   "Bhavani, Erode district, Tamil Nadu",
  //   "Bodi, Theni district, Tamil Nadu",
  //   "Bodimettu, Theni district, Tamil Nadu",
  //   "Chatrapatti, Virudhunagar district, Tamil Nadu",
  //   "Chengalpattu, Kanchipuram district, Tamil Nadu",
  //   "Chengam, Tiruvannamalai district, Tamil Nadu",
  //   "Koyambedu, Chennai district, Tamil Nadu",
  //   "Cheranmahadevi, Tirunelveli district, Tamil Nadu",
  //   "Chettikulam, Tirunelveli district, Tamil Nadu",
  //   "Chettiyapathu, Thoothukudi district, Tamil Nadu",
  //   "Chidambaram, Cuddalore district, Tamil Nadu",
  //   "Chinnalapatti, Dindigul district, Tamil Nadu",
  //   "Chinnamanur, Theni district, Tamil Nadu",
  //   "Chinnasalem, Kallakurichi district, Tamil Nadu",
  //   "Chithode, Erode district, Tamil Nadu",
  //   "Chittode, Erode district, Tamil Nadu",
  //   "Cholapuram, Thanjavur district, Tamil Nadu",
  //   "Gandhipuram, Coimbatore, Coimbatore district, Tamil Nadu",
  //   "Colachel, Kanyakumari district, Tamil Nadu",
  //   "Coonoor, Nilgiris district, Tamil Nadu",
  //   "Courtallam, Tirunelveli district, Tamil Nadu",
  //   "Cuddallore, Cuddalore district, Tamil Nadu",
  //   "Cuddalore, Cuddalore district, Tamil Nadu",
  //   "Cumbum, Theni district, Tamil Nadu",
  //   "Devadanapatti, Theni district, Tamil Nadu",
  //   "Devadhanapatti, Theni district, Tamil Nadu",
  //   "Devakottai, Sivaganga district, Tamil Nadu",
  //   "Devarkulam, Tirunelveli district, Tamil Nadu",
  //   "Devipattinam, Ramanathapuram district, Tamil Nadu",
  //   "Dharapuram, Dindigul district, Tamil Nadu",
  //   "Dharmapuri, Dharmapuri district, Tamil Nadu",
  //   "Dindigul, Dindigul district, Tamil Nadu",
  //   "Dindigul, Dindigul district, Tamil Nadu",
  //   "Eachanari, Coimbatore district, Tamil Nadu",
  //   "Edambadal, Ramanathapuram district, Tamil Nadu",
  //   "Elachipalayam, Namakkal district, Tamil Nadu",
  //   "Eppodumvendran, Thoothukudi district, Tamil Nadu",
  //   "Eral, Thoothukudi district, Tamil Nadu",
  //   "Erode, Erode district, Tamil Nadu",
  //   "Erumapatty, Namakkal district, Tamil Nadu",
  //   "Eruvadi, Tirunelveli district, Tamil Nadu",
  //   "Ervadi, salem district, Tamil Nadu",
  //   "Ettayapuram, Thoothukudi district, Tamil Nadu",
  //   "Ettayapuram, Thoothukudi district, Tamil Nadu",
  //   "GandhiNagar, Vellore district, Tamil Nadu",
  //   "Gingee, Villupuram district, Tamil Nadu",
  //   "Gobi, Erode district, Tamil Nadu",
  //   "Harur, Dharmapuri district, Tamil Nadu",
  //   "Hosur, Krishnagiri district, Tamil Nadu",
  //   "Idaichivilai, Thoothukudi district, Tamil Nadu",
  //   "Idaikal, Tirunelveli district, Tamil Nadu",
  //   "Idaiyankudi, Thoothukudi district, Tamil Nadu",
  //   "Idayamelur, Sivaganga district, Tamil Nadu",
  //   "Idinthakarai, Tirunelveli district, Tamil Nadu",
  //   "Ilayangudi, Sivaganga district, Tamil Nadu",
  //   "INS Kattabomman, Tirunelveli district, Tamil Nadu",
  //   "INS Vijayanarayanam, Tirunelveli district, Tamil Nadu",
  //   "Ittamozhi, Tirunelveli district, Tamil Nadu",
  //   "Jayankondam, Ariyalur district, Tamil Nadu",
  //   "Kadaladi, Ramanathapuram district, Tamil Nadu",
  //   "Kadayam, Tirunelveli district, Tamil Nadu",
  //   "Kadayanallur, Tirunelveli district, Tamil Nadu",
  //   "Kalakkad, Tirunelveli district, Tamil Nadu",
  //   "Kalappanaickenpatti, Namakkal district, Tamil Nadu",
  //   "Kalayarkoil, Sivaganga district, Tamil Nadu",
  //   "Kaliakkavilai, Kanyakumari district, Tamil Nadu",
  //   "Kaliyakkavilai, Kanyakumari district, Tamil Nadu",
  //   "Kalkurichi, Virudhunagar district, Tamil Nadu",
  //   "Kallakurichi, Villupuram district, Tamil Nadu",
  //   "Kallal, Sivaganga district, Tamil Nadu",
  //   "Kallamanaickerpatti, Virudhunagar district, Tamil Nadu",
  //   "Kallidaikurichi, Tirunelveli district, Tamil Nadu",
  //   "Kalligudi, Madurai district, Tamil Nadu",
  //   "Kallimandayam, Dindigul district, Tamil Nadu",
  //   "Kalloorani, Virudhunagar district, Tamil Nadu",
  //   "Kallupatti, Madurai district, Tamil Nadu",
  //   "Kalugumalai, Thoothukudi district, Tamil Nadu",
  //   "Kaluneerkulam, Tirunelveli district, Tamil Nadu",
  //   "Kamanayakkanpatti, Virudhunagar district, Tamil Nadu",
  //   "Kamuthi, Virudhunagar district, Tamil Nadu",
  //   "Kamuthi Vilakku, Virudhunagar district, Tamil Nadu",
  //   "Kanadukathan, Sivaganga district, Tamil Nadu",
  //   "Kanchipuram, Kanchipuram district, Tamil Nadu",
  //   "Kanganakulam, Tirunelveli district, Tamil Nadu",
  //   "Kangeyam, Tiruppur district, Tamil Nadu",
  //   "Kannirajapuram, Ramanathapuram district, Tamil Nadu",
  //   "Kanyakumari, Kanyakumari district, Tamil Nadu",
  //   "Karaikal, Karaikal district, Tamil Nadu",
  //   "Karaikudi, Sivaganga district, Tamil Nadu",
  //   "Kariapatti, Madurai district, Tamil Nadu",
  //   "Karivalamvandanallur, Tirunelveli district, Tamil Nadu",
  //   "Kariyapattinam, Nagapattinam district, Tamil Nadu",
  //   "Karumathampatti, Coimbatore district, Tamil Nadu",
  //   "Karungal, Kanyakumari district, Tamil Nadu",
  //   "Karunya Nagar, Coimbatore district, Tamil Nadu",
  //   "Karur, Karur district, Tamil Nadu",
  //   "Kattampatti, Coimbatore district, Tamil Nadu",
  //   "Kattumavadi, Thanjavur district, Tamil Nadu",
  //   "Kattunaickenpatti, Theni district, Tamil Nadu",
  //   "Kavalkinaru, Tirunelveli district, Tamil Nadu",
  //   "Kavalkinaru, Tirunelveli district, Tamil Nadu",
  //   "Kayalpattinam, Thoothukudi district, Tamil Nadu",
  //   "Kayamozhi, Thoothukudi district, Tamil Nadu",
  //   "Kayathar, Thoothukudi district, Tamil Nadu",
  //   "Kayathar, Thoothukudi district, Tamil Nadu",
  //   "Keela Eral, Thoothukudi district, Tamil Nadu",
  //   "Keelakarai, Ramanathapuram district, Tamil Nadu",
  //   "Keelapavoor, Tirunelveli district, Tamil Nadu",
  //   "Keelaselvanur, Ramanathapuram district, Tamil Nadu",
  //   "Keelasevalpatti, Sivaganga district, Tamil Nadu",
  //   "Keeranur, Pudukkottai district, Tamil Nadu",
  //   "Keezhapalur, Ariyalur district, Tamil Nadu",
  //   "Kinathukadavu, Coimbatore district, Tamil Nadu",
  //   "Kodai Road, Dindigul district, Tamil Nadu",
  //   "Kodaikanal, Dindigul district, Tamil Nadu",
  //   "Kodumudi, Erode district, Tamil Nadu",
  //   "Kolathur, Chennai district, Tamil Nadu",
  //   "Kollangudi, Sivaganga district, Tamil Nadu",
  //   "Kommadikottai, Thoothukudi district, Tamil Nadu",
  //   "Konapattu, Pudukkottai district, Tamil Nadu",
  //   "Kooduthalai, Tirunelveli district, Tamil Nadu",
  //   "Radhapuram, Tirunelveli district, Tamil Nadu",
  //   "Koothanallur, Thiruvarur district, Tamil Nadu",
  //   "Kottaipattinam, Pudukkottai district, Tamil Nadu",
  //   "Kottampatti, Madurai district, Tamil Nadu",
  //   "Kottankadu, Thoothukudi district, Tamil Nadu",
  //   "Kovilpalayam, Coimbatore district, Tamil Nadu",
  //   "Kovilpatti, Thoothukudi district, Tamil Nadu",
  //   "Kovilpatti, Thoothukudi district, Tamil Nadu",
  //   "Kovilpatti, Thoothukudi district, Tamil Nadu",
  //   "Koyambedu, Chennai district, Tamil Nadu",
  //   "Krishnagiri, Krishnagiri district, Tamil Nadu",
  //   "Krishnankoil, Virudhunagar district, Tamil Nadu",
  //   "Krishnapuram, Virudhunagar district, Tamil Nadu",
  //   "Kudankulam, Tirunelveli district, Tamil Nadu",
  //   "Kulasekaram, Kanyakumari district, Tamil Nadu",
  //   "Kulasekarapattinam, Thoothukudi district, Tamil Nadu",
  //   "Kulithalai, Karur district, Tamil Nadu",
  //   "Kumaramangalam, Namakkal district, Tamil Nadu",
  //   "Kumbakonam, Thanjavur district, Tamil Nadu",
  //   "Kumily, Idukki district, Tamil Nadu",
  //   "Kundrakudi, Sivaganga district, Tamil Nadu",
  //   "Kunnam, Perambalur district, Tamil Nadu",
  //   "Kurukkal Patti, Tirunelveli district, Tamil Nadu",
  //   "Kurukkuchalai, Tuticorin district, Tamil Nadu",
  //   "Kurumbur, Tiruvannamalai district, Tamil Nadu",
  //   "Kuthenkully, Tirunelveli district, Tamil Nadu",
  //   "Kuttam, Thoothukudi district, Tamil Nadu",
  //   "Kuzhithurai, Kanyakumari district, Tamil Nadu",
  //   "Lakshmangudi, Thiruvarur district, Tamil Nadu",
  //   "Lakshmipuram, Theni district, Tamil Nadu",
  //   "Lalgudi, Tiruchirappalli district, Tamil Nadu",
  //   "Levengipuram, Tirunelveli district, Tamil Nadu",
  //   "M.Rediapatti, Virudhunagar district, Tamil Nadu",
  //   "Maanur, Tirunelveli district, Tamil Nadu",
  //   "Madagupatti, Sivaganga district, Tamil Nadu",
  //   "Madathukulam, Tiruppur district, Tamil Nadu",
  //   "Madathupatti, Virudhunagar district, Tamil Nadu",
  //   "Madukkur, Thanjavur district, Tamil Nadu",
  //   "Mudukulathur, Ramanathapuram district, Tamil Nadu",
  //   "Mattuthavani, Madurai, Madurai district, Tamil Nadu",
  //   "Mattuthavani, Madurai, Madurai district, Tamil Nadu",
  //   "Maduranthakam, Kanchipuram district, Tamil Nadu",
  //   "Mahabalipuram, Kanchipuram district, Tamil Nadu",
  //   "Malaikovilur, Karur district, Tamil Nadu",
  //   "Mallipattinam, Thanjavur district, Tamil Nadu",
  //   "Malumichampatti, Coimbatore district, Tamil Nadu",
  //   "Manamadurai, Sivaganga district, Tamil Nadu",
  //   "Manamelkudi, Pudukkottai district, Tamil Nadu",
  //   "Manaparai, Tiruchirappalli district, Tamil Nadu",
  //   "Mandapam, Ramanathapuram district, Tamil Nadu",
  //   "Mandapasalai, Virudhunagar district, Tamil Nadu",
  //   "Mangalakudi, Ramanathapuram district, Tamil Nadu",
  //   "Mani Nagar, Thoothukudi district, Tamil Nadu",
  //   "Mannargudi, Thiruvarur district, Tamil Nadu",
  //   "Mannarpuram, Tirunelveli district, Tamil Nadu",
  //   "Maravamangalam, Sivaganga district, Tamil Nadu",
  //   "Mariyur, Ramanathapuram district, Tamil Nadu",
  //   "Marthandam, Kanyakumari district, Tamil Nadu",
  //   "Marudamalai, Coimbatore district, Tamil Nadu",
  //   "Mayakulam, Ramanathapuram district, Tamil Nadu",
  //   "Mayavaram, Nagapattinam district, Tamil Nadu",
  //   "Mayiladuthurai, Nagapattinam district, Tamil Nadu",
  //   "Megnanapuram, Thoothukudi district, Tamil Nadu",
  //   "Melapalayam, Tirunelveli district, Tamil Nadu",
  //   "Melaselvanur, Ramanathapuram district, Tamil Nadu",
  //   "Melaseval, Tirunelveli district, Tamil Nadu",
  //   "Melmaruvathur, Kanchipuram district, Tamil Nadu",
  //   "Melur, Madurai district, Tamil Nadu",
  //   "Menganapuram",
  //   "Mettupalayam, Coimbatore district, Tamil Nadu",
  //   "Mettur, Salem district, Tamil Nadu",
  //   "Mimisal, Pudukkottai district, Tamil Nadu",
  //   "Mohanur, Namakkal district, Tamil Nadu",
  //   "MondayMarket, Kanyakumari district, Tamil Nadu",
  //   "Moolakaraipatti, Tirunelveli district, Tamil Nadu",
  //   "Mudalur, Thoothukudi district, Tamil Nadu",
  //   "Mudukulathur, Ramanathapuram district, Tamil Nadu",
  //   "Mukkani, Thoothukudi district, Tamil Nadu",
  //   "Mukkudal, Tirunelveli district, Tamil Nadu",
  //   "Mulanur, Tiruppur district, Tamil Nadu",
  //   "Mullakadu, Thoothukudi district, Tamil Nadu",
  //   "Munanjipatti, Tirunelveli district, Tamil Nadu",
  //   "Muppaiyur, Sivaganga district, Tamil Nadu",
  //   "Murambu, Virudhunagar district, Tamil Nadu",
  //   "Muthugapatty, Namakkal district, Tamil Nadu",
  //   "Muthupettai, Thiruvarur district, Tamil Nadu",
  //   "Muthur, Tiruppur district, Tamil Nadu",
  //   "Muthuramalingapuram, Virudhunagar district, Tamil Nadu",
  //   "Naduvakurichi, Thoothukudi district, Tamil Nadu",
  //   "Nagapattinam, Nagapattinam district, Tamil Nadu",
  //   "Nagercoil, Kanyakumari district, Tamil Nadu",
  //   "Nagore, Nagapattinam district, Tamil Nadu",
  //   "Nalumavadi, Thoothukudi district, Tamil Nadu",
  //   "Namagiripettai, Namakkal district, Tamil Nadu",
  //   "Namakkal, Namakkal district, Tamil Nadu",
  //   "Nanguneri, Tirunelveli district, Tamil Nadu",
  //   "Narippaiyur, Ramanathapuram district, Tamil Nadu",
  //   "Nattarasankottai, Sivaganga district, Tamil Nadu",
  //   "Navaladi, Tirunelveli district, Tamil Nadu",
  //   "Nazareth, Thoothukudi district, Tamil Nadu",
  //   "Nellai, Tirunelveli district, Tamil Nadu",
  //   "Nemathanpatti, Sivaganga district, Tamil Nadu",
  //   "Neyveli, Cuddalore district, Tamil Nadu",
  //   "Nidamangalam, Thiruvarur district, Tamil Nadu",
  //   "Nithiravilai, Kanyakumari district, Tamil Nadu",
  //   "Okkur, Sivaganga district, Tamil Nadu",
  //   "Ooty, Nilgiris district, Tamil Nadu",
  //   "Oppilan, Ramanathapuram district, Tamil Nadu",
  //   "Orathanadu, Thanjavur district, Tamil Nadu",
  //   "Oriyur, Ramanathapuram district, Tamil Nadu",
  //   "Ottanchattram, Dindigul district, Tamil Nadu",
  //   "Ottapidaram, Thoothukudi district, Tamil Nadu",
  //   "Paarthibanur, Ramanathapuram district, Tamil Nadu",
  //   "Padukkapathu, Thoothukudi district, Tamil Nadu",
  //   "Palani, Dindigul district, Tamil Nadu",
  //   "Palayakayal, Thoothukudi district, Tamil Nadu",
  //   "Palladam, Tiruppur district, Tamil Nadu",
  //   "Pallapatti, Karur district, Tamil Nadu",
  //   "Pallathur, Sivaganga district, Tamil Nadu",
  //   "Pallipalayam, Namakkal district, Tamil Nadu",
  //   "Pamban, Ramanathapuram district, Tamil Nadu",
  //   "Panakudi, Tirunelveli district, Tamil Nadu",
  //   "Panavadali Chatram, Tirunelveli district, Tamil Nadu",
  //   "Pandalgudi, Virudhunagar district, Tamil Nadu",
  //   "Pandalgudi, Virudhunagar district, Tamil Nadu",
  //   "Pandalkudi, Virudhunagar district, Tamil Nadu",
  //   "Pandamangalam, Namakkal district, Tamil Nadu",
  //   "Pannaivilai, Thoothukudi district, Tamil Nadu",
  //   "Panruti, Cuddalore district, Tamil Nadu",
  //   "Paralachi, Virudhunagar district, Tamil Nadu",
  //   "Paramakudi, Ramanathapuram district, Tamil Nadu",
  //   "Paramankurichi, Thoothukudi district, Tamil Nadu",
  //   "Paramathi, Namakkal district, Tamil Nadu",
  //   "Paramathi Velur, Namakkal district, Tamil Nadu",
  //   "Paramathi Velur, Namakkal district, Tamil Nadu",
  //   "Parappadi, Tirunelveli district, Tamil Nadu",
  //   "Parthibanur, Ramanathapuram district, Tamil Nadu",
  //   "Pasuvanthanai, Thoothukudi district, Tamil Nadu",
  //   "Pattamadai, Tirunelveli district, Tamil Nadu",
  //   "Pattukottai, Thanjavur district, Tamil Nadu",
  //   "Paulkulam, Thoothukudi district, Tamil Nadu",
  //   "Pavithiram, Namakkal district, Tamil Nadu",
  //   "Pavoorchatram, Tirunelveli district, Tamil Nadu",
  //   "Peikulam, Thoothukudi district, Tamil Nadu",
  //   "Peraiyur, Madurai district, Tamil Nadu",
  //   "Peralam, Thiruvarur district, Tamil Nadu",
  //   "Perambalur, Perambalur district, Tamil Nadu",
  //   "Peravurani, Thanjavur district, Tamil Nadu",
  //   "Periyakulam, Theni district, Tamil Nadu",
  //   "Periyathalai, Thoothukudi district, Tamil Nadu",
  //   "Perumanallur, Tiruppur district, Tamil Nadu",
  //   "Perunali, Ramanathapuram district, Tamil Nadu",
  //   "Perundurai, Erode district, Tamil Nadu",
  //   "Pillayarpatti, Sivaganga district, Tamil Nadu",
  //   "Pollachi, Coimbatore district, Tamil Nadu",
  //   "Ponnamaravathi, Pudukkottai district, Tamil Nadu",
  //   "Poolangal, Virudhunagar district, Tamil Nadu",
  //   "Poonamallee, Tiruvallur district, Tamil Nadu",
  //   "Poonthottam, Thiruvarur district, Tamil Nadu",
  //   "Poovalur, Tiruchirappalli district, Tamil Nadu",
  //   "Pothakalanvilai, Thoothukudi district, Tamil Nadu",
  //   "Pottalpudur, Tirunelveli district, Tamil Nadu",
  //   "Pudhukadai, Kanyakumari district, Tamil Nadu",
  //   "Pudukkottai, Pudukkottai district, Tamil Nadu",
  //   "Pudukudi, Pudukkottai district, Tamil Nadu",
  //   "Pudur, Tirunelveli district, Tamil Nadu",
  //   "Pudur Uthamanur, Tiruchirappalli district, Tamil Nadu",
  //   "Puliyadithammam, Sivaganga district, Tamil Nadu",
  //   "Puliyal, Sivaganga district, Tamil Nadu",
  //   "Puliyampatti, Erode district, Tamil Nadu",
  //   "Puliyangudi, Tirunelveli district, Tamil Nadu",
  //   "Punjai Puliyampatti, Erode district, Tamil Nadu",
  //   "Puthiamputhur, Thoothukudi district, Tamil Nadu",
  //   "R S Mangalam, Ramanathapuram district, Tamil Nadu",
  //   "R.R.Nagar, Virudhunagar district, Tamil Nadu",
  //   "Rajapalayam, Virudhunagar district, Tamil Nadu",
  //   "Ramanathapuram, Ramanathapuram district, Tamil Nadu",
  //   "Rameshwaram, Ramanathapuram district, Tamil Nadu",
  //   "Ramnad, Ramanathapuram district, Tamil Nadu",
  //   "Ranipet, Vellore district, Tamil Nadu",
  //   "Rasipuram, Namakkal district, Tamil Nadu",
  //   "Reddiarpatti, Thoothukudi district, Tamil Nadu",
  //   "S P Pattinam, Ramanathapuram district, Tamil Nadu",
  //   "S.P.Pattinam, Ramanathapuram district, Tamil Nadu",
  //   "Sakkamalpuram, Thoothukudi district, Tamil Nadu",
  //   "Salaigramam, Sivaganga district, Tamil Nadu",
  //   "Salem, Salem district, Tamil Nadu",
  //   "Salem, Salem district, Tamil Nadu",
  //   "Samayapuram, Tiruchirappalli district, Tamil Nadu",
  //   "Sangagiri, Salem district, Tamil Nadu",
  //   "Sankarankovil, Tirunelveli district, Tamil Nadu",
  //   "Sannanallur, Thiruvarur district, Tamil Nadu",
  //   "Saravanampatti, Coimbatore district, Tamil Nadu",
  //   "Sarugani, Sivaganga district, Tamil Nadu",
  //   "Sathankulam, Thoothukudi district, Tamil Nadu",
  //   "Sathyamangalam, Erode district, Tamil Nadu",
  //   "Sattur, Virudhunagar district, Tamil Nadu",
  //   "Sattur, Virudhunagar district, Tamil Nadu",
  //   "Sattur, Virudhunagar district, Tamil Nadu",
  //   "Sayalkudi, Ramanathapuram district, Tamil Nadu",
  //   "Seepalakottai, Theni district, Tamil Nadu",
  //   "Seithur, Virudhunagar district, Tamil Nadu",
  //   "Sempatti, Virudhunagar district, Tamil Nadu",
  //   "Sempatti, Dindigul district, Tamil Nadu",
  //   "Sengottai, Tirunelveli district, Tamil Nadu",
  //   "Senthamangalam, Namakkal district, Tamil Nadu",
  //   "Senthamaram, Tirunelveli district, Tamil Nadu",
  //   "Sernthamaram, Tirunelveli district, Tamil Nadu",
  //   "Sethiyathope, Cuddalore district, Tamil Nadu",
  //   "Sethukkuvaithan, Thoothukudi district, Tamil Nadu",
  //   "Seydunganallur, Thoothukudi district, Tamil Nadu",
  //   "Sikkal, Nagapattinam district, Tamil Nadu",
  //   "Silukuvarpatti, Dindigul district, Tamil Nadu",
  //   "Singampunari, Sivaganga district, Tamil Nadu",
  //   "Sinthalakarai, Virudhunagar district, Tamil Nadu",
  //   "Sirkazhi, Nagapattinam district, Tamil Nadu",
  //   "Siruganur, Tiruchirappalli district, Tamil Nadu",
  //   "Sirumugai, Coimbatore district, Tamil Nadu",
  //   "Sithurajapuram, Virudhunagar district, Tamil Nadu",
  //   "Sivagangai, Sivaganga district, Tamil Nadu",
  //   "Sivagiri, Tenkasi district, Tamil Nadu",
  //   "Sivagnanapuram, Thoothukudi district, Tamil Nadu",
  //   "Sivakasi, Virudhunagar district, Tamil Nadu",
  //   "Sivanthipuram, Tirunelveli district, Tamil Nadu",
  //   "Sonaganvilai, Thoothukudi district, Tamil Nadu",
  //   "Sonakanvilai, Thoothukudi district, Tamil Nadu",
  //   "Soolakkarai, Virudhunagar district, Tamil Nadu",
  //   "SPIC Nagar, Thoothukudi district, Tamil Nadu",
  //   "Sri Krishna Bakery, avinashi bypass, Tiruppur district, Tamil Nadu",
  //   "Sriperumbudur, Kanchipuram district, Tamil Nadu",
  //   "Srirangam, Tiruchirappalli district, Tamil Nadu",
  //   "Srirengapuram, Theni district, Tamil Nadu",
  //   "SriVaikundam, Thoothukudi district, Tamil Nadu",
  //   "Srivilliputhur, Virudhunagar district, Tamil Nadu",
  //   "Suchindram, Kanyakumari district, Tamil Nadu",
  //   "Sundarapuram, Coimbatore district, Tamil Nadu",
  //   "Surandai, Tirunelveli district, Tamil Nadu",
  //   "Surangudi, Thoothukudi district, Tamil Nadu",
  //   "Swamimalai, Thanjavur district, Tamil Nadu",
  //   "T Kallupatti, Madurai district, Tamil Nadu",
  //   "Tanjore, Thanjavur district, Tamil Nadu",
  //   "Tenkasi, Tirunelveli district, Tamil Nadu",
  //   "Thadicherry, Theni district, Tamil Nadu",
  //   "Thakalai, Kanyakumari district, Tamil Nadu",
  //   "Thalaivasal, Salem district, Tamil Nadu",
  //   "Thalaivasal, Salem district, Tamil Nadu",
  //   "Thalaiyuthu, Tirunelveli district, Tamil Nadu",
  //   "Thalavaipuram, Virudhunagar district, Tamil Nadu",
  //   "Thandavankadu, Thoothukudi district, Tamil Nadu",
  //   "Thangachimadam, Ramanathapuram district, Tamil Nadu",
  //   "Thangammalpuram, Thoothukudi district, Tamil Nadu",
  //   "Thanjavur, Thanjavur district, Tamil Nadu",
  //   "Thappukundu, Theni district, Tamil Nadu",
  //   "Tharangambadi, Nagapattinam district, Tamil Nadu",
  //   "Thattarmadam, Thoothukudi district, Tamil Nadu",
  //   "Theni, Theni district, Tamil Nadu",
  //   "Thiruchendhur, Thoothukudi district, Tamil Nadu",
  //   "Thiruchitrambalam, Thanjavur district, Tamil Nadu",
  //   "Thirukadaiyur, Nagapattinam district, Tamil Nadu",
  //   "Thirukkolur, Thoothukudi district, Tamil Nadu",
  //   "Thirukoshtiyur, Sivaganga district, Tamil Nadu",
  //   "Thirumangalam, Madurai district, Tamil Nadu",
  //   "Thirumanur, Ariyalur district, Tamil Nadu",
  //   "Thirumayam, Pudukkottai district, Tamil Nadu",
  //   "Thirunagar, Madurai district, Tamil Nadu",
  //   "Thirunallar, Karaikal district, Tamil Nadu",
  //   "Thiruparankundram, Madurai district, Tamil Nadu",
  //   "Thirupathur, Sivaganga district, Tamil Nadu",
  //   "Thirupur, Tiruppur district, Tamil Nadu",
  //   "Thirupuvanam, Sivaganga district, Tamil Nadu",
  //   "Thiruthani, Tiruvallur district, Tamil Nadu",
  //   "Thiruthuraipoondi, Thiruvarur district, Tamil Nadu",
  //   "Thiruvadanai, Ramanathapuram district, Tamil Nadu",
  //   "Thiruvadanai Kaikatti, Pudukkottai district, Tamil Nadu",
  //   "Thiruvaiyaru, Thanjavur district, Tamil Nadu",
  //   "Thiruvallur, Tiruvallur district, Tamil Nadu",
  //   "Thiruvarur, Thiruvarur district, Tamil Nadu",
  //   "Thiruvegampet, Ramanathapuram district, Tamil Nadu",
  //   "Thiruvengadam, Virudhunagar district, Tamil Nadu",
  //   "Thiruvidaimaruthur, Thanjavur district, Tamil Nadu",
  //   "Thisaiyanvilai, Tirunelveli district, Tamil Nadu",
  //   "Thondi, Ramanathapuram district, Tamil Nadu",
  //   "Thovalai, Kanyakumari district, Tamil Nadu",
  //   "Thozhuthur, Cuddalore district, Tamil Nadu",
  //   "Thuckalay, Kanyakumari district, Tamil Nadu",
  //   "Thulasiyapattinam, Nagapattinam district, Tamil Nadu",
  //   "Thuraiyur, Tiruchirappalli district, Tamil Nadu",
  //   "Thuvarankurichi, Tiruchirappalli district, Tamil Nadu",
  //   "Thuvarankurinchi, Villupuram district, Tamil Nadu",
  //   "Tindivanam, Villupuram district, Tamil Nadu",
  //   "Tirparappu, Kanyakumari district, Tamil Nadu",
  //   "Tiruchengode, Namakkal district, Tamil Nadu",
  //   "Tirunelveli, Tirunelveli district, Tamil Nadu",
  //   "Tirupattur, Vellore district, Tamil Nadu",
  //   "Tiruppur, Tiruppur district, Tamil Nadu",
  //   "Tiruvannamalai, Tiruvannamalai district, Tamil Nadu",
  //   "Trichy, Tiruchirappalli district, Tamil Nadu",
  //   "Trichy, Tiruchirappalli district, Tamil Nadu",
  //   "Tuticorin, Thoothukudi district, Tamil Nadu",
  //   "Uchipuli, Ramanathapuram district, Tamil Nadu",
  //   "Udangudi, Thoothukudi district, Tamil Nadu",
  //   "Udumalaipet, Tiruppur district, Tamil Nadu",
  //   "Ukkadam, Coimbatore district, Tamil Nadu",
  //   "Ukkirankottai, Tirunelveli district, Tamil Nadu",
  //   "Ulundurpet, Villupuram district, Tamil Nadu",
  //   "Umarikadu, Thoothukudi district, Tamil Nadu",
  //   "Usilampatti, Madurai district, Tamil Nadu",
  //   "Uthamapalayam, Theni district, Tamil Nadu",
  //   "Uthamapalayam, Theni district, Tamil Nadu",
  //   "Uthangarai, Krishnagiri district, Tamil Nadu",
  //   "Uvari, Tirunelveli district, Tamil Nadu",
  //   "V.M.Chatram, Thoothukudi district, Tamil Nadu",
  //   "Vadalur, Cuddalore district, Tamil Nadu",
  //   "Vadipatti, Madurai district, Tamil Nadu",
  //   "Vagaikulam, Tirunelveli district, Tamil Nadu",
  //   "Vairavanpatti, Sivaganga district, Tamil Nadu",
  //   "Vaitheeswaran koil, Nagapattinam district, Tamil Nadu",
  //   "Vaiyappamalai, Namakkal district, Tamil Nadu",
  //   "Valangaiman, Thiruvarur district, Tamil Nadu",
  //   "Valinokkam, Ramanathapuram district, Tamil Nadu",
  //   "Valliyur, Tirunelveli district, Tamil Nadu",
  //   "Valliyur, Tirunelveli district, Tamil Nadu",
  //   "Vanaramutti, Thoothukudi district, Tamil Nadu",
  //   "Vanathirupathi, Thoothukudi district, Tamil Nadu",
  //   "Vangal, Karur district, Tamil Nadu",
  //   "Vaniyambadi, Vellore district, Tamil Nadu",
  //   "Vannicondendal, Tirunelveli district, Tamil Nadu",
  //   "Vanniconendal, Tirunelveli district, Tamil Nadu",
  //   "Vannikonendal, Tirunelveli district, Tamil Nadu",
  //   "Vasudevanallur, Tirunelveli district, Tamil Nadu",
  //   "Vazhaiyadi, Thoothukudi district, Tamil Nadu",
  //   "Vedachandur, Dindigul district, Tamil Nadu",
  //   "Vedaranyam, Nagapattinam district, Tamil Nadu",
  //   "Veerakeralampudur, Tirunelveli district, Tamil Nadu",
  //   "Veeranam, Cuddalore district, Tamil Nadu",
  //   "Veerasigamani, Tirunelveli district, Tamil Nadu",
  //   "Veeravanallur, Tirunelveli district, Tamil Nadu",
  //   "Velankanni, Nagapattinam district, Tamil Nadu",
  //   "Velayuthampalayam, Valluvar Nagar",
  //   "Velayuthampalayam, Valluvar Nagar",
  //   "Vellakoil, Tiruppur district, Tamil Nadu",
  //   "Vellore, Vellore district, Tamil Nadu",
  //   "Vembakkottai, Virudhunagar district, Tamil Nadu",
  //   "Vembar, Thoothukudi district, Tamil Nadu",
  //   "Veppur, Cuddalore, Cuddalore district, Tamil Nadu",
  //   "Vettavalam, Tiruvannamalai district, Tamil Nadu",
  //   "Vijayamangalam Tollgate, Erode district, Tamil Nadu",
  //   "Vijayapathi, Tirunelveli district, Tamil Nadu",
  //   "Vikravandi, Villupuram district, Tamil Nadu",
  //   "Vilathikulam, Thoothukudi district, Tamil Nadu",
  //   "Villathikulam, Thoothukudi district, Tamil Nadu",
  //   "Villupuram, Villupuram district, Tamil Nadu",
  //   "vilupuram, Villupuram district, Tamil Nadu",
  //   "Viralimalai, Pudukkottai district, Tamil Nadu",
  //   "Virudhachalam, Cuddalore district, Tamil Nadu",
  //   "Virudhunagar, Virudhunagar district, Tamil Nadu",
  //   "Virudhunagar, Virudhunagar district, Tamil Nadu",
  // ];

  fromToAddresses: any = [
    "Majestic Bus Stand,Bangalore,Bangalore district,Karnataka",
    "Kukatpally metro,Hyderabad,Hyderabad district,Telangana",
    "Madiwala,Bangalore,Bangalore district,Karnataka",
    "L.B Nagar,Hyderabad,Hyderabad district,Telangana",
    "Gudalur,Cumbam,Theni district,Tamil Nadu",
    "Nilakkottai,Dindigul,Dindigul district,Tamil Nadu",
    "Vadamadurai,Dindigul,Dindigul district,Tamil Nadu",
    "Kaliyal,Kanniyakumari,Kanyakumari district,Tamil Nadu",
    "Attoor,Kanyakumari,Kanyakumari district,Tamil Nadu",
    "Myladi,Kanyakumari district,Tamil Nadu",
    "Thoothoor,Kanyakumari,Kanyakumari district,Tamil Nadu",
    "Puduvayal,Karaikudi,Sivaganga district,Tamil Nadu",
    "Oothu,Kodaikanal,Dindigul district,Tamil Nadu",
    "Perumalmalai,Kodaikanal,Dindigul district,Tamil Nadu",
    "Karungalakudi,Madurai district,Tamil Nadu",
    "Otthakadai,Madurai district,Tamil Nadu",
    "Adhanur,Nagapattinam district,Tamil Nadu",
    "Akkur,Nagapattinam district,Tamil Nadu",
    "Kollidam,Nagapattinam district,Tamil Nadu",
    "Kuthalam,Thanjavur district,Tamil Nadu",
    "Manganallur,Nagapattinam district,Tamil Nadu",
    "Poompuhar,Nagapattinam district,Tamil Nadu",
    "Pudupattinam,Nagapattinam district,Tamil Nadu",
    "Thanikottagam,Nagapattinam district,Tamil Nadu",
    "Thirumulaivasal,Nagapattinam district,Tamil Nadu",
    "Thopputhurai,Nagapattinam district,Tamil Nadu",
    "Vaimedu,Nagapattinam district,Tamil Nadu",
    "Mangalapuram,Namagiripettai,Namakkal district,Tamil Nadu",
    "Vadachery,Pattukottai,Thanjavur district,Tamil Nadu",
    "Jegathapattinam,Pudukkottai district,Tamil Nadu",
    "Alangulam,Rajapalayam,Virudhunagar district,Tamil Nadu",
    "Nambuthalai,Ramanathapuram,Ramanathapuram district,Tamil Nadu",
    "Pasipattinam,Ramanathapuram,Ramanathapuram district,Tamil Nadu",
    "Vellayapuram,Ramanathapuram,Ramanathapuram district,Tamil Nadu",
    "Ervadi,Ramnad,Ramanathapuram district,Tamil Nadu",
    "Elanthangudi,Tanjore,Nagapattinam district,Tamil Nadu",
    "Papanasam,Tanjore,Thanjavur district,Tamil Nadu",
    "Nagalapuram,Theni,Theni district,Tamil Nadu",
    "Hanumanthampatti,Theni,Theni district,Tamil Nadu",
    "Jangalpatti,Theni,Theni district,Tamil Nadu",
    "Kamatchipuram,Theni,Theni district,Tamil Nadu",
    "Koduvilarpatti,Theni,Theni district,Tamil Nadu",
    "Kottur,Theni,Theni district,Tamil Nadu",
    "Odaipatti,Theni,Theni district,Tamil Nadu",
    "pudupatti,uthamapalayam,Theni district,Tamil Nadu",
    "Seelayampatti,Theni,Theni district,Tamil Nadu",
    "Venkatachalapuram,Theni,Theni district,Tamil Nadu",
    "Kamalapuram,Thiruvarur,Thiruvarur district,Tamil Nadu",
    "Kollumangudi,Thiruvarur,Thiruvarur district,Tamil Nadu",
    "Alangulam,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Eamankulam,Tirunelveli,திருநெ\u0005ல்வே\tலி district,Tamil Nadu",
    "Elankulam,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Eruvadi,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Kadayalurutti,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Moondradaippu,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Virasigamani,Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Gudimangalam,Tiruppur,Tiruppur district,Tamil Nadu",
    "Alangudi,Tiruvarur,Thiruvarur district,Tamil Nadu",
    "Nagalapuram,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Kootampuli,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Korampallam,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Pandaravilai,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Perungulam,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Polaiyarpuram,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Pudukottai,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Sawyerpuram,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Servaikaranmadam,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Sivathaiyapuram,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Subramaniapuram,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Thandupathu,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Tharuvaikulam,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Veppalodai,Tuticorin,Thoothukudi district,Tamil Nadu",
    "Anthoniyarpuram,Tuticorn,Thoothukudi district,Tamil Nadu",
    "Vikramasingapuram,V K Puram,Tirunelveli district,Tamil Nadu",
    "Ariyanayagipuram,Vilathikulam,Tirunelveli district,Tamil Nadu",
    "Vilathikulam,Thoothukudi district,Tamil Nadu",
    "Panaiyur,Vilathikulam,Thoothukudi district,Tamil Nadu",
    "Alangulam,Tirunelveli district,Tamil Nadu",
    "Alagapuri,Virudhunagar,Virudhunagar district,Tamil Nadu",
    "Erichanatham,Virudhunagar,Madurai district,Tamil Nadu",
    "Watrap,Virudhunagar,Virudhunagar district,Tamil Nadu",
    "chithode,Erode district,Tamil Nadu",
    "chithode,Erode district,Tamil Nadu",
    "Aathipatti,Madurai district,Tamil Nadu",
    "Abiramam,Virudhunagar district,Tamil Nadu",
    "Adaiya Karungulam,Tirunelveli district,Tamil Nadu",
    "Adirampattinam,Thanjavur district,Tamil Nadu",
    "Aduthurai,Thanjavur district,Tamil Nadu",
    "Agasthiyarpatti,Tirunelveli district,Tamil Nadu",
    "Alagiapandiapuram,Tirunelveli district,Tamil Nadu",
    "Alagiyamandapam,Kanyakumari district,Tamil Nadu",
    "Alanthalai,Thoothukudi district,Tamil Nadu",
    "Alathur,Thanjavur district,Tamil Nadu",
    "Alwarkurichi,Tirunelveli district,Tamil Nadu",
    "Alwarthirunagiri,Thoothukudi district,Tamil Nadu",
    "Ambalavanapuram,Tirunelveli district,Tamil Nadu",
    "Ambasamudram,Tirunelveli district,Tamil Nadu",
    "Ambur,Vellore district,Tamil Nadu",
    "Ammanpuram,Thoothukudi district,Tamil Nadu",
    "Ammapattinam,Pudukkottai district,Tamil Nadu",
    "Anaimalai,Coimbatore district,Tamil Nadu",
    "Anandur,Ramanathapuram district,Tamil Nadu",
    "Ananthapuram,Villupuram district,Tamil Nadu",
    "Andagalur Gate,Namakkal district,Tamil Nadu",
    "Andichiurani,Sivaganga district,Tamil Nadu",
    "Andimadam,Ariyalur district,Tamil Nadu",
    "Andipatti,Theni district,Tamil Nadu",
    "Aniyapuram,Namakkal district,Tamil Nadu",
    "Anjugramam,Kanyakumari district,Tamil Nadu",
    "Annanji,Theni district,Tamil Nadu",
    "Annur,Coimbatore district,Tamil Nadu",
    "Anthakudi,Sivaganga district,Tamil Nadu",
    "Anthiyur,Erode district,Tamil Nadu",
    "Aralikottai,Sivaganga district,Tamil Nadu",
    "AralvaiMozhi,Kanyakumari district,Tamil Nadu",
    "Arani,Tiruvannamalai district,Tamil Nadu",
    "Aranmanai Pudhur,Theni district,Tamil Nadu",
    "Aranthangi,Pudukkottai district,Tamil Nadu",
    "Aravakurichi,Karur district,Tamil Nadu",
    "Arcot,Vellore district,Tamil Nadu",
    "Ariyalur,Ariyalur district,Tamil Nadu",
    "Arumuganeri,Thoothukudi district,Tamil Nadu",
    "Aruppukottai,Virudhunagar district,Tamil Nadu",
    "Aruppukottai,Virudhunagar district,Tamil Nadu",
    "Athangudi,Ramanathapuram district,Tamil Nadu",
    "Athiyuthu,Ramanathapuram district,Tamil Nadu",
    "Athupalam,Coimbatore district,Tamil Nadu",
    "Attur,Salem district,Tamil Nadu",
    "Authoor,Thoothukudi district,Tamil Nadu",
    "Authoor North,Thoothukudi district,Tamil Nadu",
    "Authoor South,Thoothukudi district,Tamil Nadu",
    "Avanam Kaikatti,Pudukkottai district,Tamil Nadu",
    "Avinashi,Tiruppur district,Tamil Nadu",
    "Avudaiyapuram,Tirunelveli district,Tamil Nadu",
    "Avudaiyarkoil,Pudukkottai district,Tamil Nadu",
    "Ayyampettai,Thanjavur district,Tamil Nadu",
    "Azhagappapuram,Kanyakumari district,Tamil Nadu",
    "Alagapuri",
    "Bannari,Erode district,Tamil Nadu",
    "Bathalagundu,Dindigul district,Tamil Nadu",
    "Batlagundu",
    "Belukurichi,Namakkal district,Tamil Nadu",
    "Bhavani,Erode district,Tamil Nadu",
    "Bhavani,Erode district,Tamil Nadu",
    "Bodi,Theni district,Tamil Nadu",
    "Bodimettu,Theni district,Tamil Nadu",
    "Chatrapatti,Virudhunagar district,Tamil Nadu",
    "Chengalpattu,Kanchipuram district,Tamil Nadu",
    "Chengam,Tiruvannamalai district,Tamil Nadu",
    "Koyambedu,Chennai district,Tamil Nadu",
    "Cheranmahadevi,Tirunelveli district,Tamil Nadu",
    "Chettikulam,Tirunelveli district,Tamil Nadu",
    "Chettiyapathu,Thoothukudi district,Tamil Nadu",
    "Chidambaram,Cuddalore district,Tamil Nadu",
    "Chinnalapatti,Dindigul district,Tamil Nadu",
    "Chinnamanur,Theni district,Tamil Nadu",
    "Chinnasalem,Kallakurichi district,Tamil Nadu",
    "Chithode,Erode district,Tamil Nadu",
    "Chittode,Erode district,Tamil Nadu",
    "Cholapuram,Thanjavur district,Tamil Nadu",
    "Gandhipuram,Coimbatore,Coimbatore district,Tamil Nadu",
    "Colachel,Kanyakumari district,Tamil Nadu",
    "Coonoor,Nilgiris district,Tamil Nadu",
    "Courtallam,Tirunelveli district,Tamil Nadu",
    "Cuddallore,Cuddalore district,Tamil Nadu",
    "Cuddalore,Cuddalore district,Tamil Nadu",
    "Cumbum,Theni district,Tamil Nadu",
    "Devadanapatti,Theni district,Tamil Nadu",
    "Devadhanapatti,Theni district,Tamil Nadu",
    "Devakottai,Sivaganga district,Tamil Nadu",
    "Devarkulam,Tirunelveli district,Tamil Nadu",
    "Devipattinam,Ramanathapuram district,Tamil Nadu",
    "Dharapuram,Dindigul district,Tamil Nadu",
    "Dharmapuri,Dharmapuri district,Tamil Nadu",
    "Dindigul,Dindigul district,Tamil Nadu",
    "Dindigul,Dindigul district,Tamil Nadu",
    "Eachanari,Coimbatore district,Tamil Nadu",
    "Edambadal,Ramanathapuram district,Tamil Nadu",
    "Elachipalayam,Namakkal district,Tamil Nadu",
    "Eppodumvendran,Thoothukudi district,Tamil Nadu",
    "Eral,Thoothukudi district,Tamil Nadu",
    "Erode,Erode district,Tamil Nadu",
    "Erumapatty,Namakkal district,Tamil Nadu",
    "Eruvadi,Tirunelveli district,Tamil Nadu",
    "Ervadi,salem district,Tamil Nadu",
    "Ettayapuram,Thoothukudi district,Tamil Nadu",
    "Ettayapuram,Thoothukudi district,Tamil Nadu",
    "GandhiNagar,Vellore district,Tamil Nadu",
    "Gingee,Villupuram district,Tamil Nadu",
    "Gobi,Erode district,Tamil Nadu",
    "Harur,Dharmapuri district,Tamil Nadu",
    "Hosur,Krishnagiri district,Tamil Nadu",
    "Idaichivilai,Thoothukudi district,Tamil Nadu",
    "Idaikal,Tirunelveli district,Tamil Nadu",
    "Idaiyankudi,Thoothukudi district,Tamil Nadu",
    "Idayamelur,Sivaganga district,Tamil Nadu",
    "Idinthakarai,Tirunelveli district,Tamil Nadu",
    "Ilayangudi,Sivaganga district,Tamil Nadu",
    "INS Kattabomman,Tirunelveli district,Tamil Nadu",
    "INS Vijayanarayanam,Tirunelveli district,Tamil Nadu",
    "Ittamozhi,Tirunelveli district,Tamil Nadu",
    "Jayankondam,Ariyalur district,Tamil Nadu",
    "Kadaladi,Ramanathapuram district,Tamil Nadu",
    "Kadayam,Tirunelveli district,Tamil Nadu",
    "Kadayanallur,Tirunelveli district,Tamil Nadu",
    "Kalakkad,Tirunelveli district,Tamil Nadu",
    "Kalappanaickenpatti,Namakkal district,Tamil Nadu",
    "Kalayarkoil,Sivaganga district,Tamil Nadu",
    "Kaliakkavilai,Kanyakumari district,Tamil Nadu",
    "Kaliyakkavilai,Kanyakumari district,Tamil Nadu",
    "Kalkurichi,Virudhunagar district,Tamil Nadu",
    "Kallakurichi,Villupuram district,Tamil Nadu",
    "Kallal,Sivaganga district,Tamil Nadu",
    "Kallamanaickerpatti,Virudhunagar district,Tamil Nadu",
    "Kallidaikurichi,Tirunelveli district,Tamil Nadu",
    "Kalligudi,Madurai district,Tamil Nadu",
    "Kallimandayam,Dindigul district,Tamil Nadu",
    "Kalloorani,Virudhunagar district,Tamil Nadu",
    "Kallupatti,Madurai district,Tamil Nadu",
    "Kalugumalai,Thoothukudi district,Tamil Nadu",
    "Kaluneerkulam,Tirunelveli district,Tamil Nadu",
    "Kamanayakkanpatti,Virudhunagar district,Tamil Nadu",
    "Kamuthi,Virudhunagar district,Tamil Nadu",
    "Kamuthi Vilakku,Virudhunagar district,Tamil Nadu",
    "Kanadukathan,Sivaganga district,Tamil Nadu",
    "Kanchipuram,Kanchipuram district,Tamil Nadu",
    "Kanganakulam,Tirunelveli district,Tamil Nadu",
    "Kangeyam,Tiruppur district,Tamil Nadu",
    "Kannirajapuram,Ramanathapuram district,Tamil Nadu",
    "Kanyakumari,Kanyakumari district,Tamil Nadu",
    "Karaikal,Karaikal district,Tamil Nadu",
    "Karaikudi,Sivaganga district,Tamil Nadu",
    "Kariapatti,Madurai district,Tamil Nadu",
    "Karivalamvandanallur,Tirunelveli district,Tamil Nadu",
    "Kariyapattinam,Nagapattinam district,Tamil Nadu",
    "Karumathampatti,Coimbatore district,Tamil Nadu",
    "Karungal,Kanyakumari district,Tamil Nadu",
    "Karunya Nagar,Coimbatore district,Tamil Nadu",
    "Karur,Karur district,Tamil Nadu",
    "Kattampatti,Coimbatore district,Tamil Nadu",
    "Kattumavadi,Thanjavur district,Tamil Nadu",
    "Kattunaickenpatti,Theni district,Tamil Nadu",
    "Kavalkinaru,Tirunelveli district,Tamil Nadu",
    "Kavalkinaru,Tirunelveli district,Tamil Nadu",
    "Kayalpattinam,Thoothukudi district,Tamil Nadu",
    "Kayamozhi,Thoothukudi district,Tamil Nadu",
    "Kayathar,Thoothukudi district,Tamil Nadu",
    "Kayathar,Thoothukudi district,Tamil Nadu",
    "Keela Eral,Thoothukudi district,Tamil Nadu",
    "Keelakarai,Ramanathapuram district,Tamil Nadu",
    "Keelapavoor,Tirunelveli district,Tamil Nadu",
    "Keelaselvanur,Ramanathapuram district,Tamil Nadu",
    "Keelasevalpatti,Sivaganga district,Tamil Nadu",
    "Keeranur,Pudukkottai district,Tamil Nadu",
    "Keezhapalur,Ariyalur district,Tamil Nadu",
    "Kinathukadavu,Coimbatore district,Tamil Nadu",
    "Kodai Road,Dindigul district,Tamil Nadu",
    "Kodaikanal,Dindigul district,Tamil Nadu",
    "Kodumudi,Erode district,Tamil Nadu",
    "Kolathur,Chennai district,Tamil Nadu",
    "Kollangudi,Sivaganga district,Tamil Nadu",
    "Kommadikottai,Thoothukudi district,Tamil Nadu",
    "Konapattu,Pudukkottai district,Tamil Nadu",
    "Kooduthalai,Tirunelveli district,Tamil Nadu",
    "Radhapuram,Tirunelveli district,Tamil Nadu",
    "Koothanallur,Thiruvarur district,Tamil Nadu",
    "Kottaipattinam,Pudukkottai district,Tamil Nadu",
    "Kottampatti,Madurai district,Tamil Nadu",
    "Kottankadu,Thoothukudi district,Tamil Nadu",
    "Kovilpalayam,Coimbatore district,Tamil Nadu",
    "Kovilpatti,Thoothukudi district,Tamil Nadu",
    "Kovilpatti,Thoothukudi district,Tamil Nadu",
    "Kovilpatti,Thoothukudi district,Tamil Nadu",
    "Koyambedu,Chennai district,Tamil Nadu",
    "Krishnagiri,Krishnagiri district,Tamil Nadu",
    "Krishnankoil,Virudhunagar district,Tamil Nadu",
    "Krishnapuram,Virudhunagar district,Tamil Nadu",
    "Kudankulam,Tirunelveli district,Tamil Nadu",
    "Kulasekaram,Kanyakumari district,Tamil Nadu",
    "Kulasekarapattinam,Thoothukudi district,Tamil Nadu",
    "Kulithalai,Karur district,Tamil Nadu",
    "Kumaramangalam,Namakkal district,Tamil Nadu",
    "Kumbakonam,Thanjavur district,Tamil Nadu",
    "Kumily,Idukki district,Tamil Nadu",
    "Kundrakudi,Sivaganga district,Tamil Nadu",
    "Kunnam,Perambalur district,Tamil Nadu",
    "Kurukkal Patti,Tirunelveli district,Tamil Nadu",
    "Kurukkuchalai,Tuticorin district,Tamil Nadu",
    "Kurumbur,Tiruvannamalai district,Tamil Nadu",
    "Kuthenkully,Tirunelveli district,Tamil Nadu",
    "Kuttam,Thoothukudi district,Tamil Nadu",
    "Kuzhithurai,Kanyakumari district,Tamil Nadu",
    "Lakshmangudi,Thiruvarur district,Tamil Nadu",
    "Lakshmipuram,Theni district,Tamil Nadu",
    "Lalgudi,Tiruchirappalli district,Tamil Nadu",
    "Levengipuram,Tirunelveli district,Tamil Nadu",
    "M.Rediapatti,Virudhunagar district,Tamil Nadu",
    "Maanur,Tirunelveli district,Tamil Nadu",
    "Madagupatti,Sivaganga district,Tamil Nadu",
    "Madathukulam,Tiruppur district,Tamil Nadu",
    "Madathupatti,Virudhunagar district,Tamil Nadu",
    "Madukkur,Thanjavur district,Tamil Nadu",
    "Mudukulathur,Ramanathapuram district,Tamil Nadu",
    "Mattuthavani,Madurai,Madurai district,Tamil Nadu",
    "Mattuthavani,Madurai,Madurai district,Tamil Nadu",
    "Maduranthakam,Kanchipuram district,Tamil Nadu",
    "Mahabalipuram,Kanchipuram district,Tamil Nadu",
    "Malaikovilur,Karur district,Tamil Nadu",
    "Mallipattinam,Thanjavur district,Tamil Nadu",
    "Malumichampatti,Coimbatore district,Tamil Nadu",
    "Manamadurai,Sivaganga district,Tamil Nadu",
    "Manamelkudi,Pudukkottai district,Tamil Nadu",
    "Manaparai,Tiruchirappalli district,Tamil Nadu",
    "Mandapam,Ramanathapuram district,Tamil Nadu",
    "Mandapasalai,Virudhunagar district,Tamil Nadu",
    "Mangalakudi,Ramanathapuram district,Tamil Nadu",
    "Mani Nagar,Thoothukudi district,Tamil Nadu",
    "Mannargudi,Thiruvarur district,Tamil Nadu",
    "Mannarpuram,Tirunelveli district,Tamil Nadu",
    "Maravamangalam,Sivaganga district,Tamil Nadu",
    "Mariyur,Ramanathapuram district,Tamil Nadu",
    "Marthandam,Kanyakumari district,Tamil Nadu",
    "Marudamalai,Coimbatore district,Tamil Nadu",
    "Mayakulam,Ramanathapuram district,Tamil Nadu",
    "Mayavaram,Nagapattinam district,Tamil Nadu",
    "Mayiladuthurai,Nagapattinam district,Tamil Nadu",
    "Megnanapuram,Thoothukudi district,Tamil Nadu",
    "Melapalayam,Tirunelveli district,Tamil Nadu",
    "Melaselvanur,Ramanathapuram district,Tamil Nadu",
    "Melaseval,Tirunelveli district,Tamil Nadu",
    "Melmaruvathur,Kanchipuram district,Tamil Nadu",
    "Melur,Madurai district,Tamil Nadu",
    "Menganapuram",
    "Mettupalayam,Coimbatore district,Tamil Nadu",
    "Mettur,Salem district,Tamil Nadu",
    "Mimisal,Pudukkottai district,Tamil Nadu",
    "Mohanur,Namakkal district,Tamil Nadu",
    "MondayMarket,Kanyakumari district,Tamil Nadu",
    "Moolakaraipatti,Tirunelveli district,Tamil Nadu",
    "Mudalur,Thoothukudi district,Tamil Nadu",
    "Mudukulathur,Ramanathapuram district,Tamil Nadu",
    "Mukkani,Thoothukudi district,Tamil Nadu",
    "Mukkudal,Tirunelveli district,Tamil Nadu",
    "Mulanur,Tiruppur district,Tamil Nadu",
    "Mullakadu,Thoothukudi district,Tamil Nadu",
    "Munanjipatti,Tirunelveli district,Tamil Nadu",
    "Muppaiyur,Sivaganga district,Tamil Nadu",
    "Murambu,Virudhunagar district,Tamil Nadu",
    "Muthugapatty,Namakkal district,Tamil Nadu",
    "Muthupettai,Thiruvarur district,Tamil Nadu",
    "Muthur,Tiruppur district,Tamil Nadu",
    "Muthuramalingapuram,Virudhunagar district,Tamil Nadu",
    "Naduvakurichi,Thoothukudi district,Tamil Nadu",
    "Nagapattinam,Nagapattinam district,Tamil Nadu",
    "Nagercoil,Kanyakumari district,Tamil Nadu",
    "Nagore,Nagapattinam district,Tamil Nadu",
    "Nalumavadi,Thoothukudi district,Tamil Nadu",
    "Namagiripettai,Namakkal district,Tamil Nadu",
    "Namakkal,Namakkal district,Tamil Nadu",
    "Nanguneri,Tirunelveli district,Tamil Nadu",
    "Narippaiyur,Ramanathapuram district,Tamil Nadu",
    "Nattarasankottai,Sivaganga district,Tamil Nadu",
    "Navaladi,Tirunelveli district,Tamil Nadu",
    "Nazareth,Thoothukudi district,Tamil Nadu",
    "Nellai,Tirunelveli district,Tamil Nadu",
    "Nemathanpatti,Sivaganga district,Tamil Nadu",
    "Neyveli,Cuddalore district,Tamil Nadu",
    "Nidamangalam,Thiruvarur district,Tamil Nadu",
    "Nithiravilai,Kanyakumari district,Tamil Nadu",
    "Okkur,Sivaganga district,Tamil Nadu",
    "Ooty,Nilgiris district,Tamil Nadu",
    "Oppilan,Ramanathapuram district,Tamil Nadu",
    "Orathanadu,Thanjavur district,Tamil Nadu",
    "Oriyur,Ramanathapuram district,Tamil Nadu",
    "Ottanchattram,Dindigul district,Tamil Nadu",
    "Ottapidaram,Thoothukudi district,Tamil Nadu",
    "Paarthibanur,Ramanathapuram district,Tamil Nadu",
    "Padukkapathu,Thoothukudi district,Tamil Nadu",
    "Palani,Dindigul district,Tamil Nadu",
    "Palayakayal,Thoothukudi district,Tamil Nadu",
    "Palladam,Tiruppur district,Tamil Nadu",
    "Pallapatti,Karur district,Tamil Nadu",
    "Pallathur,Sivaganga district,Tamil Nadu",
    "Pallipalayam,Namakkal district,Tamil Nadu",
    "Pamban,Ramanathapuram district,Tamil Nadu",
    "Panakudi,Tirunelveli district,Tamil Nadu",
    "Panavadali Chatram,Tirunelveli district,Tamil Nadu",
    "Pandalgudi,Virudhunagar district,Tamil Nadu",
    "Pandalgudi,Virudhunagar district,Tamil Nadu",
    "Pandalkudi,Virudhunagar district,Tamil Nadu",
    "Pandamangalam,Namakkal district,Tamil Nadu",
    "Pannaivilai,Thoothukudi district,Tamil Nadu",
    "Panruti,Cuddalore district,Tamil Nadu",
    "Paralachi,Virudhunagar district,Tamil Nadu",
    "Paramakudi,Ramanathapuram district,Tamil Nadu",
    "Paramankurichi,Thoothukudi district,Tamil Nadu",
    "Paramathi,Namakkal district,Tamil Nadu",
    "Paramathi Velur,Namakkal district,Tamil Nadu",
    "Paramathi Velur,Namakkal district,Tamil Nadu",
    "Parappadi,Tirunelveli district,Tamil Nadu",
    "Parthibanur,Ramanathapuram district,Tamil Nadu",
    "Pasuvanthanai,Thoothukudi district,Tamil Nadu",
    "Pattamadai,Tirunelveli district,Tamil Nadu",
    "Pattukottai,Thanjavur district,Tamil Nadu",
    "Paulkulam,Thoothukudi district,Tamil Nadu",
    "Pavithiram,Namakkal district,Tamil Nadu",
    "Pavoorchatram,Tirunelveli district,Tamil Nadu",
    "Peikulam,Thoothukudi district,Tamil Nadu",
    "Peraiyur,Madurai district,Tamil Nadu",
    "Peralam,Thiruvarur district,Tamil Nadu",
    "Perambalur,Perambalur district,Tamil Nadu",
    "Peravurani,Thanjavur district,Tamil Nadu",
    "Periyakulam,Theni district,Tamil Nadu",
    "Periyathalai,Thoothukudi district,Tamil Nadu",
    "Perumanallur,Tiruppur district,Tamil Nadu",
    "Perunali,Ramanathapuram district,Tamil Nadu",
    "Perundurai,Erode district,Tamil Nadu",
    "Pillayarpatti,Sivaganga district,Tamil Nadu",
    "Pollachi,Coimbatore district,Tamil Nadu",
    "Ponnamaravathi,Pudukkottai district,Tamil Nadu",
    "Poolangal,Virudhunagar district,Tamil Nadu",
    "Poonamallee,Tiruvallur district,Tamil Nadu",
    "Poonthottam,Thiruvarur district,Tamil Nadu",
    "Poovalur,Tiruchirappalli district,Tamil Nadu",
    "Pothakalanvilai,Thoothukudi district,Tamil Nadu",
    "Pottalpudur,Tirunelveli district,Tamil Nadu",
    "Pudhukadai,Kanyakumari district,Tamil Nadu",
    "Pudukkottai,Pudukkottai district,Tamil Nadu",
    "Pudukudi,Pudukkottai district,Tamil Nadu",
    "Pudur,Tirunelveli district,Tamil Nadu",
    "Pudur Uthamanur,Tiruchirappalli district,Tamil Nadu",
    "Puliyadithammam,Sivaganga district,Tamil Nadu",
    "Puliyal,Sivaganga district,Tamil Nadu",
    "Puliyampatti,Erode district,Tamil Nadu",
    "Puliyangudi,Tirunelveli district,Tamil Nadu",
    "Punjai Puliyampatti,Erode district,Tamil Nadu",
    "Puthiamputhur,Thoothukudi district,Tamil Nadu",
    "R S Mangalam,Ramanathapuram district,Tamil Nadu",
    "R.R.Nagar,Virudhunagar district,Tamil Nadu",
    "Rajapalayam,Virudhunagar district,Tamil Nadu",
    "Ramanathapuram,Ramanathapuram district,Tamil Nadu",
    "Rameshwaram,Ramanathapuram district,Tamil Nadu",
    "Ramnad,Ramanathapuram district,Tamil Nadu",
    "Ranipet,Vellore district,Tamil Nadu",
    "Rasipuram,Namakkal district,Tamil Nadu",
    "Reddiarpatti,Thoothukudi district,Tamil Nadu",
    "S P Pattinam,Ramanathapuram district,Tamil Nadu",
    "S.P.Pattinam,Ramanathapuram district,Tamil Nadu",
    "Sakkamalpuram,Thoothukudi district,Tamil Nadu",
    "Salaigramam,Sivaganga district,Tamil Nadu",
    "Salem,Salem district,Tamil Nadu",
    "Salem,Salem district,Tamil Nadu",
    "Samayapuram,Tiruchirappalli district,Tamil Nadu",
    "Sangagiri,Salem district,Tamil Nadu",
    "Sankarankovil,Tirunelveli district,Tamil Nadu",
    "Sannanallur,Thiruvarur district,Tamil Nadu",
    "Saravanampatti,Coimbatore district,Tamil Nadu",
    "Sarugani,Sivaganga district,Tamil Nadu",
    "Sathankulam,Thoothukudi district,Tamil Nadu",
    "Sathyamangalam,Erode district,Tamil Nadu",
    "Sattur,Virudhunagar district,Tamil Nadu",
    "Sattur,Virudhunagar district,Tamil Nadu",
    "Sattur,Virudhunagar district,Tamil Nadu",
    "Sayalkudi,Ramanathapuram district,Tamil Nadu",
    "Seepalakottai,Theni district,Tamil Nadu",
    "Seithur,Virudhunagar district,Tamil Nadu",
    "Sempatti,Virudhunagar district,Tamil Nadu",
    "Sempatti,Dindigul district,Tamil Nadu",
    "Sengottai,Tirunelveli district,Tamil Nadu",
    "Senthamangalam,Namakkal district,Tamil Nadu",
    "Senthamaram,Tirunelveli district,Tamil Nadu",
    "Sernthamaram,Tirunelveli district,Tamil Nadu",
    "Sethiyathope,Cuddalore district,Tamil Nadu",
    "Sethukkuvaithan,Thoothukudi district,Tamil Nadu",
    "Seydunganallur,Thoothukudi district,Tamil Nadu",
    "Sikkal,Nagapattinam district,Tamil Nadu",
    "Silukuvarpatti,Dindigul district,Tamil Nadu",
    "Singampunari,Sivaganga district,Tamil Nadu",
    "Sinthalakarai,Virudhunagar district,Tamil Nadu",
    "Sirkazhi,Nagapattinam district,Tamil Nadu",
    "Siruganur,Tiruchirappalli district,Tamil Nadu",
    "Sirumugai,Coimbatore district,Tamil Nadu",
    "Sithurajapuram,Virudhunagar district,Tamil Nadu",
    "Sivagangai,Sivaganga district,Tamil Nadu",
    "Sivagiri,Tenkasi district,Tamil Nadu",
    "Sivagnanapuram,Thoothukudi district,Tamil Nadu",
    "Sivakasi,Virudhunagar district,Tamil Nadu",
    "Sivanthipuram,Tirunelveli district,Tamil Nadu",
    "Sonaganvilai,Thoothukudi district,Tamil Nadu",
    "Sonakanvilai,Thoothukudi district,Tamil Nadu",
    "Soolakkarai,Virudhunagar district,Tamil Nadu",
    "SPIC Nagar,Thoothukudi district,Tamil Nadu",
    "Sri Krishna Bakery,avinashi bypass,Tiruppur district,Tamil Nadu",
    "Sriperumbudur,Kanchipuram district,Tamil Nadu",
    "Srirangam,Tiruchirappalli district,Tamil Nadu",
    "Srirengapuram,Theni district,Tamil Nadu",
    "SriVaikundam,Thoothukudi district,Tamil Nadu",
    "Srivilliputhur,Virudhunagar district,Tamil Nadu",
    "Suchindram,Kanyakumari district,Tamil Nadu",
    "Sundarapuram,Coimbatore district,Tamil Nadu",
    "Surandai,Tirunelveli district,Tamil Nadu",
    "Surangudi,Thoothukudi district,Tamil Nadu",
    "Swamimalai,Thanjavur district,Tamil Nadu",
    "T Kallupatti,Madurai district,Tamil Nadu",
    "Tanjore,Thanjavur district,Tamil Nadu",
    "Tenkasi,Tirunelveli district,Tamil Nadu",
    "Thadicherry,Theni district,Tamil Nadu",
    "Thakalai,Kanyakumari district,Tamil Nadu",
    "Thalaivasal,Salem district,Tamil Nadu",
    "Thalaivasal,Salem district,Tamil Nadu",
    "Thalaiyuthu,Tirunelveli district,Tamil Nadu",
    "Thalavaipuram,Virudhunagar district,Tamil Nadu",
    "Thandavankadu,Thoothukudi district,Tamil Nadu",
    "Thangachimadam,Ramanathapuram district,Tamil Nadu",
    "Thangammalpuram,Thoothukudi district,Tamil Nadu",
    "Thanjavur,Thanjavur district,Tamil Nadu",
    "Thappukundu,Theni district,Tamil Nadu",
    "Tharangambadi,Nagapattinam district,Tamil Nadu",
    "Thattarmadam,Thoothukudi district,Tamil Nadu",
    "Theni,Theni district,Tamil Nadu",
    "Thiruchendhur,Thoothukudi district,Tamil Nadu",
    "Thiruchitrambalam,Thanjavur district,Tamil Nadu",
    "Thirukadaiyur,Nagapattinam district,Tamil Nadu",
    "Thirukkolur,Thoothukudi district,Tamil Nadu",
    "Thirukoshtiyur,Sivaganga district,Tamil Nadu",
    "Thirumangalam,Madurai district,Tamil Nadu",
    "Thirumanur,Ariyalur district,Tamil Nadu",
    "Thirumayam,Pudukkottai district,Tamil Nadu",
    "Thirunagar,Madurai district,Tamil Nadu",
    "Thirunallar,Karaikal district,Tamil Nadu",
    "Thiruparankundram,Madurai district,Tamil Nadu",
    "Thirupathur,Sivaganga district,Tamil Nadu",
    "Thirupur,Tiruppur district,Tamil Nadu",
    "Thirupuvanam,Sivaganga district,Tamil Nadu",
    "Thiruthani,Tiruvallur district,Tamil Nadu",
    "Thiruthuraipoondi,Thiruvarur district,Tamil Nadu",
    "Thiruvadanai,Ramanathapuram district,Tamil Nadu",
    "Thiruvadanai Kaikatti,Pudukkottai district,Tamil Nadu",
    "Thiruvaiyaru,Thanjavur district,Tamil Nadu",
    "Thiruvallur,Tiruvallur district,Tamil Nadu",
    "Thiruvarur,Thiruvarur district,Tamil Nadu",
    "Thiruvegampet,Ramanathapuram district,Tamil Nadu",
    "Thiruvengadam,Virudhunagar district,Tamil Nadu",
    "Thiruvidaimaruthur,Thanjavur district,Tamil Nadu",
    "Thisaiyanvilai,Tirunelveli district,Tamil Nadu",
    "Thondi,Ramanathapuram district,Tamil Nadu",
    "Thovalai,Kanyakumari district,Tamil Nadu",
    "Thozhuthur,Cuddalore district,Tamil Nadu",
    "Thuckalay,Kanyakumari district,Tamil Nadu",
    "Thulasiyapattinam,Nagapattinam district,Tamil Nadu",
    "Thuraiyur,Tiruchirappalli district,Tamil Nadu",
    "Thuvarankurichi,Tiruchirappalli district,Tamil Nadu",
    "Thuvarankurinchi,Villupuram district,Tamil Nadu",
    "Tindivanam,Villupuram district,Tamil Nadu",
    "Tirparappu,Kanyakumari district,Tamil Nadu",
    "Tiruchengode,Namakkal district,Tamil Nadu",
    "Tirunelveli,Tirunelveli district,Tamil Nadu",
    "Tirupattur,Vellore district,Tamil Nadu",
    "Tiruppur,Tiruppur district,Tamil Nadu",
    "Tiruvannamalai,Tiruvannamalai district,Tamil Nadu",
    "Trichy,Tiruchirappalli district,Tamil Nadu",
    "Trichy,Tiruchirappalli district,Tamil Nadu",
    "Tuticorin,Thoothukudi district,Tamil Nadu",
    "Uchipuli,Ramanathapuram district,Tamil Nadu",
    "Udangudi,Thoothukudi district,Tamil Nadu",
    "Udumalaipet,Tiruppur district,Tamil Nadu",
    "Ukkadam,Coimbatore district,Tamil Nadu",
    "Ukkirankottai,Tirunelveli district,Tamil Nadu",
    "Ulundurpet,Villupuram district,Tamil Nadu",
    "Umarikadu,Thoothukudi district,Tamil Nadu",
    "Usilampatti,Madurai district,Tamil Nadu",
    "Uthamapalayam,Theni district,Tamil Nadu",
    "Uthamapalayam,Theni district,Tamil Nadu",
    "Uthangarai,Krishnagiri district,Tamil Nadu",
    "Uvari,Tirunelveli district,Tamil Nadu",
    "V.M.Chatram,Thoothukudi district,Tamil Nadu",
    "Vadalur,Cuddalore district,Tamil Nadu",
    "Vadipatti,Madurai district,Tamil Nadu",
    "Vagaikulam,Tirunelveli district,Tamil Nadu",
    "Vairavanpatti,Sivaganga district,Tamil Nadu",
    "Vaitheeswaran koil,Nagapattinam district,Tamil Nadu",
    "Vaiyappamalai,Namakkal district,Tamil Nadu",
    "Valangaiman,Thiruvarur district,Tamil Nadu",
    "Valinokkam,Ramanathapuram district,Tamil Nadu",
    "Valliyur,Tirunelveli district,Tamil Nadu",
    "Valliyur,Tirunelveli district,Tamil Nadu",
    "Vanaramutti,Thoothukudi district,Tamil Nadu",
    "Vanathirupathi,Thoothukudi district,Tamil Nadu",
    "Vangal,Karur district,Tamil Nadu",
    "Vaniyambadi,Vellore district,Tamil Nadu",
    "Vannicondendal,Tirunelveli district,Tamil Nadu",
    "Vanniconendal,Tirunelveli district,Tamil Nadu",
    "Vannikonendal,Tirunelveli district,Tamil Nadu",
    "Vasudevanallur,Tirunelveli district,Tamil Nadu",
    "Vazhaiyadi,Thoothukudi district,Tamil Nadu",
    "Vedachandur,Dindigul district,Tamil Nadu",
    "Vedaranyam,Nagapattinam district,Tamil Nadu",
    "Veerakeralampudur,Tirunelveli district,Tamil Nadu",
    "Veeranam,Cuddalore district,Tamil Nadu",
    "Veerasigamani,Tirunelveli district,Tamil Nadu",
    "Veeravanallur,Tirunelveli district,Tamil Nadu",
    "Velankanni,Nagapattinam district,Tamil Nadu",
    "Velayuthampalayam,Valluvar Nagar",
    "Velayuthampalayam,Valluvar Nagar",
    "Vellakoil,Tiruppur district,Tamil Nadu",
    "Vellore,Vellore district,Tamil Nadu",
    "Vembakkottai,Virudhunagar district,Tamil Nadu",
    "Vembar,Thoothukudi district,Tamil Nadu",
    "Veppur,Cuddalore,Cuddalore district,Tamil Nadu",
    "Vettavalam,Tiruvannamalai district,Tamil Nadu",
    "Vijayamangalam Tollgate,Erode district,Tamil Nadu",
    "Vijayapathi,Tirunelveli district,Tamil Nadu",
    "Vikravandi,Villupuram district,Tamil Nadu",
    "Vilathikulam,Thoothukudi district,Tamil Nadu",
    "Villathikulam,Thoothukudi district,Tamil Nadu",
    "Villupuram,Villupuram district,Tamil Nadu",
    "vilupuram,Villupuram district,Tamil Nadu",
    "Viralimalai,Pudukkottai district,Tamil Nadu",
    "Virudhachalam,Cuddalore district,Tamil Nadu",
    "Virudhunagar,Virudhunagar district,Tamil Nadu",
    "Virudhunagar,Virudhunagar district,Tamil Nadu",
  ];
  routePriceResponse: any = [];
  customer: string;
  allLinkedVehicles: any=[];
  showTable:any=false;

  constructor(
    private _erpService: ErpnextapiService,
    private _ar: ActivatedRoute,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {
    this.addressForm = new FormGroup({
      from_address: new FormControl("", [Validators.required]),
      to_address: new FormControl("", [Validators.required]),
      vehicle_registration_number: new FormControl("", [Validators.required]),
    });
  }

  ngOnInit(): void {
    this.customer = localStorage.getItem("customer");
    this. getVehiclesList() ;
    let str = "Majestic Bus Stand, Bangalore, Bangalore district, Karnataka";
    let newStr = str.replace(/,\s/g, ",");
    console.log(newStr);
  }

  getVehiclesList() {
    let payload = {
      customer: this.customer,
      doctype: "Vehicle Registration Certificate",
    };
    this._erpService
      .getLinkedVehiclesofCustomer(payload)
      .subscribe((res: any) => {
        // console.log("getVehiclesList-->", res);
        if (
          res.errorCode == "0" &&
          res.data.message &&
          typeof res.data.message != "string"
        ) {
          let response = res.data.message;

          this.allLinkedVehicles = response;

          // this.serviceReports = response;
        }
      });
  }

  submit() {
    this.dataLoading=true;
    if (this.addressForm.valid) {
      console.log("route form--->", this.addressForm.value);

      let payload = this.addressForm.value;

      this._erpService.routePriceCalculate(payload).subscribe((res: any) => {
        // console.log("route price response-->", res);
        if(res.errorCode==0 && res.data.data){
          this.toastr.success("Sucessfully Fetched Route Details");
          let response= res.data.data;
          this.routePriceResponse=response;
          this.showTable=true;
          this.dataLoading=false

        }
        else{
          this.showTable=false;
          let error = res.data._server_messages;
            // console.log(error[0]);
            let errorRes = JSON.parse(error);
            let error1 = JSON.parse(errorRes[0]);
          // this.toastr.error("Something went wrong, please try again !");
          this.toastr.error(error1.message);

          this.dataLoading=false
        }

      });

      // console.log("route response--->"res)
    }
  }
}
