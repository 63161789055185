import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';
import { DatePipe } from '@angular/common';
import Swal from "sweetalert2";

@Component({
  selector: 'app-jio-bp-dashboard',
  templateUrl: './jio-bp-dashboard.component.html',
  styleUrls: ['./jio-bp-dashboard.component.css']
})
export class JioBpDashboardComponent implements OnInit {

  dataLoading:any;
  searchString:any="";



  fa_id: any;
  customer:any;
  JioBpBalance:number=0;

  rechargeTransactionData:any;
  rechargeTransactionDatalength:number=0;
  isRechargeTxn:boolean=true;
  noRechargeTxnData:boolean=false;


  jioBpRechargeHistory:any=[];
  jioBpRechargeHistoryDatalength:number=0;
  isJioBpRechargeTxn:boolean=true;
  noJioBpRechargeTxnData:boolean=false;

  dailyJioBpTransactionData: any;
  dailyJioBpDatalength: number = 0;
  dailyTransaction: number = 0;
  dailyPendingCashback: number = 0;


  weeklyJioBpTransactionData: any;
  weeklyJioBpDatalength: number = 0;
  weeklyTransaction: number = 0;
  weeklyPendingCashback: number = 0;

  monthlyJioBpTransactionData: any;
  monthlyJioBpDatalength: number = 0;
  monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;

  customJioBpTransactionData: any;
  customJioBpDatalength: number = 0;
  customTransaction: number = 0;
  customPendingCashback: number = 0;

  isDailyJioBpTxn: boolean=true;
  isMonthlyJioBpTxn: boolean=true;
  isWeeklyJioBpTxn: boolean=true;
  isCustomJioBpTxn: boolean;


  noDailyJioBpData: boolean = false;
  noWeeklyJioBpData: boolean = false;
  noMonthlyJioBpData: boolean = false;
  noCustomJioBpData: boolean = false;
  todayDate: any;

  p:number=1;
  p1: number
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;

  customJioBpForm: any;

  todaysDate:any;
  todaysDate1:any;
  weeklyDate:any;
  monthlyDate:any;
  yearagoDate:any;

  customTimeFilterDetails:any=[];

  JioBpFuelBalance:number=0;
  rechargeAmt:number=0;
  isDisabledRechBtn:any=true;

  jioBprechargeForm:any


  constructor(private fb: FormBuilder, private _erpService: ErpnextapiService, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe) {
    this.customJioBpForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      time:['']

    })

    this.jioBprechargeForm = new FormGroup({
      amount: new FormControl('', [Validators.required])


    })



   }

  ngOnInit(): void {

    this.fa_id = localStorage.getItem("FAID");
    this.customer=localStorage.getItem('customer')

    let today = new Date();
    this.todaysDate = this.datePipe.transform(today,'dd/MM/yyyy');
    this.todaysDate1 = this.datePipe.transform(today,'yyyy-MM-dd');
    // console.log(this.todaysDate);
    let sevenDaysBack= new Date(new Date().setDate(today.getDate() - 6));
    this.weeklyDate=this.datePipe.transform(sevenDaysBack,'dd/MM/yyyy');
    // console.log(this.weeklyDate)
    let thirtyDaysBack=new Date(new Date().setDate(today.getDate() - 31));
    this.monthlyDate=this.datePipe.transform(thirtyDaysBack,'dd/MM/yyyy');

    let date=new Date(new Date().setDate(today.getDate() - 365));
    this.yearagoDate=this.datePipe.transform(date,'yyyy-MM-dd');

    // console.log(this.yearagoDate);

    this.getJioBpBalance();

    this.getRechargeTxnHistory();
    this.getDailyJioBpTxn();
    this. getWeeklyJioBpTxn();
    this.getMonthlyJioBpTxn() ;


  }




  getJioBpBalance(){
    let body={
      // customer:'UBS Intermediary'
      customer:this.customer
    }


    this._erpService.getJioBPCardBalDetails(body).subscribe((res:any)=>{
      // console.log("getCardDetails-->",res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {


          if (res['data'].message.card_details != 'No record found') {
            this.JioBpBalance=res.data.message.total_card_balance;


      }
      else{
        this.JioBpBalance=0
      }
    }
  })

  }


  resetRechargeForm(){
    this.isDisabledRechBtn=true;
  this.jioBprechargeForm.reset();
  }



  /***************************Jio BP Recharge History**********************************************/
  getRechargeTxnHistory(){

    let data = {
      "args": {
        "customer": this.customer,
        "start_date": this.yearagoDate,
        "end_date": this.todayDate
      }
    }
    // console.log(data);


    this._erpService.s_getRechargeTxnHistory(data).subscribe((res:any)=>{
      // console.log("getRechargeTxnHistory()-->",res);
      // res['data'].message=[]

      if (res["errorCode"]=="0" && res['data'].message != 'No record found' && res['data'].message.length > 0) {


       let responsedata= res['data'].message;
        for (let i = 0; i < responsedata.length; i++) {
          let date = responsedata[i]['creation'].slice(0, 10);
          let time = responsedata[i]['creation'].slice(11, 19);
          // console.log(date);
          // console.log(time);


          responsedata[i]['Date'] = date;
          responsedata[i]['Time'] = time;

        }
        const filterdate=responsedata.map(obj=>{
          return {...obj, date: new Date(obj.Date)};
        })

        this.rechargeTransactionData=filterdate.sort((a,b)=>
          Number(b.date)-Number(a.date)
        )
        // console.log(  this.rechargeTransactionData);


        for (let i = 0; i < this.rechargeTransactionData.length; i++) {


         let recharge_type= this.rechargeTransactionData[i].recharge_type.toLowerCase();
        //  console.log(recharge_type);


         if(recharge_type==="jio bp recharge"){
          this.jioBpRechargeHistory.push(this.rechargeTransactionData[i]);

         }



        }





        this.jioBpRechargeHistoryDatalength=this.jioBpRechargeHistory.length;


        if( this.jioBpRechargeHistoryDatalength==0){
          this.noJioBpRechargeTxnData=true;
        }

        // console.log( this.bpclRechargeHistory);
        // console.log( this.ioclRechargeHistory);
        // console.log( this.fastagRechargeHistory);




        // this.rechargeTransactionDatalength = this.rechargeTransactionData.length;
        // this.isRechargeTxn = false;
        this.isJioBpRechargeTxn=false;


      }
      else {

        this.isJioBpRechargeTxn=false;

        this.noJioBpRechargeTxnData=true;


        // this.isRechargeTxn = false;
        // this.noRechargeTxnData = true;
      }

    })
  }
   /*******************************************************************************************/

  /***************************Daily Bpcl Txn **********************************************/
  getDailyJioBpTxn() {

    let body = {
      "customer": this.customer,
      "from_date":  this.todaysDate,
      "to_date":  this.todaysDate
    }
// console.log("daily txn payload-->",body)
    this._erpService.getJioBpTxn(body).subscribe(res => {
      // console.log("getJioBpTxn()----->", res);
      console.log("*******");

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {
        this.dailyTransaction = res['data'].expenditure.daily_transaction;
        // this.dailyPendingCashback = (0.4 / 100) * this.dailyTransaction;


        if (res['data'].message != 'No record found') {
          console.log("*******");


          this.dailyJioBpTransactionData = res['data'].message.reverse();
          // this.dailyBpclTransactionData=[];
          for(let i=0; i<this.dailyJioBpTransactionData.length;i++){
            let date= this.dailyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.dailyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.dailyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.dailyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.dailyJioBpTransactionData[i]['product_details']['Date']=date;
            this.dailyJioBpTransactionData[i]['product_details']['Time']=time;

          }


          this.dailyJioBpDatalength = this.dailyJioBpTransactionData.length;


          if (this.dailyJioBpDatalength == 0) {
            this.noDailyJioBpData = true;
            this.dataLoading=false;
          }


          this.isDailyJioBpTxn = false;
          this.dataLoading=false;
        }
        else {

          this.isDailyJioBpTxn = false;
          this.noDailyJioBpData = true;
          this.dataLoading=false;
        }

      }
      else{

          this.isDailyJioBpTxn = false;
          this.noDailyJioBpData = true;
          this.dataLoading=false;
      }

    });

  }


  /*******************************************************************************************/



  /***************************Weekly Bpcl Txn **********************************************/
  getWeeklyJioBpTxn() {


    // console.log(sevendaysAgoDate);

    let body = {
      "customer": this.customer,
      "from_date":  this.weeklyDate,
      "to_date":  this.todaysDate
    }


// console.log(body)
    this._erpService.getJioBpTxn(body).subscribe(res => {
      // console.log("getJioBpTxn()----->", res);

      if (res['errorCode'] == '0' &&res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message ) {

        if(res['data'].expenditure.weekly_transaction!=null){
          this.weeklyTransaction = res['data'].expenditure.weekly_transaction;
        }

        // this.weeklyPendingCashback = (0.4 / 100) * this.weeklyTransaction;

        if (res['data'].message != 'No record found') {

          this.weeklyJioBpTransactionData = res['data'].message.reverse();

          for(let i=0; i<this.weeklyJioBpTransactionData.length;i++){
            let date= this.weeklyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.weeklyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.weeklyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.weeklyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.weeklyJioBpTransactionData[i]['product_details']['Date']=date;
            this.weeklyJioBpTransactionData[i]['product_details']['Time']=time;

          }

          this.weeklyJioBpDatalength = this.weeklyJioBpTransactionData.length
          if (this.weeklyJioBpDatalength == 0) {
            this.noWeeklyJioBpData = true;
          }
          this.isWeeklyJioBpTxn = false;
        }
        else {
          this.isWeeklyJioBpTxn = false;
          this.noWeeklyJioBpData = true;
        }

      }
      else {
        this.isWeeklyJioBpTxn = false;
        this.noWeeklyJioBpData = true;
      }

    });







  }


  /*******************************************************************************************/

  /***************************Monthly Bpcl Txn **********************************************/
  getMonthlyJioBpTxn() {


    // console.log(thirtydaysAgoDate);

    let body = {
      "customer": this.customer,
      "from_date":  this.monthlyDate,
      "to_date":  this.todaysDate
    }

    // console.log(body)
    this._erpService.getJioBpTxn(body).subscribe(res => {
      // console.log("getMonthlyBpclTxn()----->", res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {
        this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
        // this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

        if (res['data'].message != 'No record found') {

          this.monthlyJioBpTransactionData = res['data'].message.reverse();
          for(let i=0; i<this.monthlyJioBpTransactionData.length;i++){
            let date= this.monthlyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
            let time=this.monthlyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
            if(this.monthlyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
              this.monthlyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
            }
            // console.log(date);
            // console.log(time);


            this.monthlyJioBpTransactionData[i]['product_details']['Date']=date;
            this.monthlyJioBpTransactionData[i]['product_details']['Time']=time;

          }

          this.monthlyJioBpDatalength = this.monthlyJioBpTransactionData.length;

          if (this.monthlyJioBpDatalength == 0) {
            this.noMonthlyJioBpData = true;
          }
          this.isMonthlyJioBpTxn = false;
        }
        else {
          this.isMonthlyJioBpTxn = false;
          this.noMonthlyJioBpData = true;
        }

      }
      else {
        this.isMonthlyJioBpTxn = false;
        this.noMonthlyJioBpData = true;
      }

    });



  }


  /*******************************************************************************************/

/***************************Custon Bpcl Txn **********************************************/

onCustomTxn() {
  this.customJioBpTransactionData=[];
  this.customTimeFilterDetails=[]
  this.isCustomJioBpTxn=true;
  this.customJioBpForm.value.fromdate = this.customJioBpForm.value.fromdate.replace(/-/g, "/");
  this.customJioBpForm.value.todate = this.customJioBpForm.value.todate.replace(/-/g, "/");

  // console.log(this.customFastagForm.value);
  // console.log(this.customFastagForm.value.todate.split());

  if (this.customJioBpForm.valid) {
    this.p4=1;
    this.isCustomJioBpTxn = true;
    this.noCustomJioBpData = false;
    // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
    this.getCustomJioBpTxn(this.customJioBpForm.value);
  }
}



getCustomJioBpTxn(formvalue) {

  let body = {
    "customer": this.customer,
    "from_date": formvalue.fromdate,
    "to_date": formvalue.todate

  }

  // console.log("Jio Bp  Custom Txn Body--->", body);

  this.customJioBpDatalength = 0;

  this._erpService.getJioBpTxn(body).subscribe((res) => {
    // console.log(" getCustomJioBpTxn()---->", res);
    if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message) {
      if (res['data'].message != 'No record found') {
        // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;

        this.customJioBpTransactionData = res['data'].message;
        let timefilter=this.customJioBpForm.value.time.slice(0,2);
        console.log(timefilter);


        for(let i=0; i<this.customJioBpTransactionData.length;i++){
          let date= this.customJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
          let time=this.customJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
          // console.log(date);
          // console.log(time);


          this.customJioBpTransactionData[i]['product_details']['Date']=date;
          this.customJioBpTransactionData[i]['product_details']['Time']=time;
          if(this.customJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
            this.customJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
          }




        }


        this.customJioBpDatalength = this.customJioBpTransactionData.length;
        if (this.customJioBpDatalength == 0) {
          this.noCustomJioBpData = true;
        }
        this.isCustomJioBpTxn = false;

      }
      else {

        this.isCustomJioBpTxn = false;
        this.noCustomJioBpData = true;
      }




    }
    else {

      this.isCustomJioBpTxn = false;
      this.noCustomJioBpData = true;
    }

  })


}

/*******************************************************************************************/



  dailyJioBptxn(){
    this.searchString='';
  }
  weeklyJioBptxn(){
    this.searchString='';
  }
  monthlyJioBptxn(){
    this.searchString='';
  }
  customJioBptxn(){
    this.searchString='';
  }

  jioBpRechargeAmtEvent(event:any){
    let value=parseFloat(event.target.value);
    if(value && value>0){
      this.isDisabledRechBtn=false
      this.rechargeAmt=value

     this.jioBprechargeForm.patchValue({
      amount:this.rechargeAmt
     })
    }
    else{
      this.jioBprechargeForm.patchValue({
        amount:''
       })
      this.isDisabledRechBtn=true
    }



  }


  onRecharge(){
    this.dataLoading=true;
    if(this.rechargeAmt>0){
      let payload={
        "args": {
          "party": this.customer,
          "amount":this.rechargeAmt
        }
      }

      // console.log(payload)
      this._erpService.jioBpRecharge(payload).subscribe((res:any)=>{
        console.log(res)
        if(res.errorCode==0 && res.data.message){
          let response=res["data"]["message"];
          let success=res["data"]["message"]["success"];
          if(success){
            Swal.fire({
              title: `<h4>Success !</h4>`,
              text: `Jio-Bp ${response.msg} for Rs. ${this.rechargeAmt}`,
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
          }
          else{
            Swal.fire({
              title: `<h4>Success !</h4>`,
              text: response.msg,
              // icon: "error",
              width: "300px",
              confirmButtonText: "Ok",
            });
          }

          this.dataLoading=false;
        }
        else{

          Swal.fire({
            title: `<h4>Failed !</h4>`,
            text: `Jio-Bp Recharge Failed for Rs. ${this.rechargeAmt}`,
            // icon: "error",
            width: "300px",
            confirmButtonText: "Ok",
          });
          this.dataLoading=false;
        }
      })
    }
    else{
      Swal.fire({
        title: `<h4>Failed !</h4>`,
        text: `Jio-Bp Recharge Failed for Rs. ${this.rechargeAmt}`,
        // icon: "error",
        width: "300px",
        confirmButtonText: "Ok",
      });
      this.dataLoading=false;
    }
  }


}
