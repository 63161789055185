import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';

@Component({
  selector: 'app-fastag-details',
  templateUrl: './fastag-details.component.html',
  styleUrls: ['./fastag-details.component.css']
})
export class FastagDetailsComponent implements OnInit {


  parentTransactionData: any;
  parentDatalength: number = 0;
  parentWalletBalnce:number=0;


  dailyFastagTxnEncryptDetails: any;
  dailyFastagTransactionEnqResponse: any;
  dailyFastagTransactionData: any;
  dailyFastagDatalength: number = 0;
  dailyTransaction: number = 0;
  dailyPendingCashback: number = 0;

  weeklyFastagTxnEncryptDetails: any;
  weeklyFastagTransactionEnqResponse: any;
  weeklyFastagTransactionData: any;
  weeklyFastagDatalength: number = 0;
  weeklyTransaction: number = 0;
  weeklyPendingCashback: number = 0;


  monthlyFastagTxnEncryptDetails: any;
  monthlyFastagTransactionEnqResponse: any;
  monthlyFastagTransactionData: any;
  monthlyFastagDatalength: number = 0;
  monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;



  customFastagTxnEncryptDetails: any;
  customFastagTransactionEnqResponse: any;
  customFastagTransactionData: any;
  customFastagDatalength: number = 0;
  customTransaction: number = 0;
  customPendingCashback: number = 0;

  duplicateFastagTransactionData: any = [];
  onlyDuplicateTransactionData: any = [];
  duplicateFastagDatalength: number = 0;
  onlyDuplicateFastagDatalength: number = 0;
  isOnlyDuplicates: boolean;
  isAllDuplicates: boolean = true;


  isDailyFasTxn: boolean;
  isMonthlyFasTxn: boolean;
  isWeeklyFasTxn: boolean;
  isCustomFasTxn: boolean;
  isDuplicateFasTxn: boolean;
  isParentWalletTxn:boolean;

  noDailyFasData: boolean = false;
  noWeeklyFasData: boolean = false;
  noMonthlyFasData: boolean = false;
  noCustomFasData: boolean = false;
  noDuplicateFasData: boolean = false;
  noOnlyDuplicateFasData: boolean = false;
  noParentWalletData:boolean=false;

  todayDate: any;
  fa_id: any;
  fc_id: any;
  customer: any;
  userEmail: any;
  customFastagForm: any;
  fastagCustomer: any;
  dataLoading: boolean = false;
  requestsource: any;
  marchentid: any;
  walletId: any;
  enable_dup_btn: boolean = false;
  customTimeFilterDetails: any = [];
  searchString: any = '';


  // marchentId:any='P_W012208200823400034';
  marchentId: any;

  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;
  p7: number=1

  todaysDate: any;
  weeklyDate: any;
  monthlyDate: any;
  userFromdateHourFilter: number;
  userFromdateMinuteFilter: number;
  userTodateHourFilter: number;
  userTodateMinuteFilter: number;
  Fastag_Cust_Id: string;
  fastagBalance: number = 0;

  last_30_days_date:any;




  constructor(private fb: FormBuilder, private _erpService: ErpnextapiService, private route: ActivatedRoute, private router: Router) {

    this.customFastagForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      time: ['']

    })
  }

  ngOnInit(): void {
    this.marchentid = localStorage.getItem('marchantid');
    this.marchentId = 'P_W012208200823400034';


    this.fa_id = localStorage.getItem('FAID');
    this.fc_id = localStorage.getItem('FCID');
    this.customer = localStorage.getItem('customer');
    this.userEmail = localStorage.getItem('email');
    // this.requestsource=localStorage.getItem('requestSource');
    this.walletId = localStorage.getItem('walletid');
    this.Fastag_Cust_Id = localStorage.getItem('FastagCustomerID');

    // this.marchentid=localStorage.getItem('marchentid');




    // this.marchentId=localStorage.getItem('fasTagCustomerId')

    

    let todayDate = new Date();
    let month = (todayDate.getMonth() + 1).toString();
    let day = todayDate.getDate().toString();
    if ((month.length == 2 && day.length == 2)) {
      this.todayDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/${(todayDate.getMonth() + 1).toString()}/${todayDate.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      this.todayDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/0${(todayDate.getMonth() + 1).toString()}/${todayDate.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      this.todayDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/${(todayDate.getMonth() + 1).toString()}/0${todayDate.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      this.todayDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/0${(todayDate.getMonth() + 1).toString()}/0${todayDate.getDate().toString()}`
    }
    // console.log("Today date---->", this.todayDate);

    // this.getDailyFastagTxnEncryptDetails();
    // this.getFastagDoctypeId(); // for getting daily txn history
    this.getFastagWalletbalance();

    this.getFastagDailyTxn(); // now only for getting daily expenditure
    this.getFastagWeeklyTxn();
    this.getFastagMonthlyTxn();
    this.getDuplicateFastagTxn();
    this.getParentWalletHistory();
    this.getLoggedInUser();
  }



  getLoggedInUser() {
    this._erpService.getUserDetails().subscribe((res) => {
      //console.log(res);
      if (res["data"] === '') {
        this.logout();
      }
    })
  }
  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }


  onlyDuplicateTxn() {
    this.isOnlyDuplicates = true;
  }

  resetDuplicateTxn() {
    this.isOnlyDuplicates = false;
  }


  getFastagWalletbalance() {
    this.dataLoading = true;
    // console.log(marchantId);

    let data = {
      "uuid": this.walletId,
      "merchantID": this.marchentid,
      "requestSource": localStorage.getItem("requsetSource")
    }
    // console.log(data);



    this._erpService.getFastagBal(this.Fastag_Cust_Id, data).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagWalletBalance------>", res["data"]);
        this.fastagBalance = res["data"]["currentBalance"];

        this.dataLoading = false;
      }
      else {
        // console.log("fastag wallet balance error");

      }


    })

  }


  // getFastagTollTxn
  //************  Fastag data daily and weekly and custom from erp-next****************//


  // getFastagDailyTxn(){
  //   this.isDailyFasTxn=true;

  //   // let data={
  //   //   "merchantid":this.marchentId,
  //   //   "from_date":`${this.todayDate}T00:00`,
  //   //   "to_date":`${this.todayDate}T23:59`,
  //   //   "walletid":this.walletId

  //   // }

  //   let data={
  //     "merchantid":this.marchentId,
  //     "from_date":`20230102T00:00`,
  //     "to_date":`20230102T23:59`,
  //     "walletid":this.walletId

  //   }
  //   // console.log("Fastag Daily Txn Body--->",data);


  //   this._erpService.getFastagTollTxn(data).subscribe((res)=>{
  //     console.log(" getFastagDailyTxn()---->",res);
  //     if(res['errorCode']=='0' && res['data'].message !='No record found'){
  //       // console.log("dygdsygy");
  //       if(res['data'].expenditure.daily_transaction!=null){
  //         this.dailyTransaction=res['data'].expenditure.daily_transaction;
  //         this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
  //       }


  //       if(res['data'].message != 'No record found'){
  //           // this.dailyTransaction=res['data'].expenditure.daily_transaction;
  //           // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;




  //       // // this.dailyTransaction=res['data'].expenditure.daily_transaction;
  //       // // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
  //       this.dailyFastagTransactionData=res['data'].message.reverse();

  //       for(let i=0; i<this.dailyFastagTransactionData.length;i++){
  //         let date= this.dailyFastagTransactionData[i]['reqTime'].slice(0,10);
  //         let time=this.dailyFastagTransactionData[i]['reqTime'].slice(11,19);
  //         // console.log(date);
  //         // console.log(time);


  //         this.dailyFastagTransactionData[i]['Date']=date;
  //         this.dailyFastagTransactionData[i]['Time']=time;

  //       }
  //       // console.log("daily Txn-->",this.dailyFastagTransactionData);
  //       this.dailyFastagDatalength=this.dailyFastagTransactionData.length
  //       this.isDailyFasTxn = false;
  //       }
  //       else{
  //         console.log("no daily fastag");

  //         this.isDailyFasTxn=false;
  //         this.noDailyFasData=true;
  //       }



  //     }
  //     else{
  //       this.isDailyFasTxn=false;
  //       this.noDailyFasData=true;
  //     }


  //   })
  // }


  //weeklyTxn

  // getFastagWeeklyTxn(){
  //   this.isWeeklyFasTxn=true;
  //   this.dataLoading=true;

  //   let todayDate = new Date();
  //   let lastSevenDaysDate = new Date(todayDate.getTime() - (6 * 24 * 60 * 60 * 1000));
  //   // console.log(lastSevenDaysDate);

  //   let lastSevendaysMon = (lastSevenDaysDate.getMonth()+1).toString();
  //   let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
  //   let last_Seven_days_Date;
  //   // console.log("last 7 days Mon-->",lastSevendaysMon);
  //   //from date validation 
  //   if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
  //     // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate=`${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
  //   }
  //   else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
  //     // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate=`${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
  //   }
  //   else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
  //     // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate=`${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
  //   }

  //   else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
  //     // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate=`${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
  //   }

  //   // console.log("last seven days date--->", last_Seven_days_Date);




  //   // let data={
  //   //   "merchantid":this.marchentId,
  //   //   "from_date":`${last_Seven_days_Date}T00:00`,
  //   //   "to_date":`${this.todayDate}T23:59`,
  //   //   "walletid":this.walletId

  //   // }

  //   let data={
  //     "merchantid":this.marchentId,
  //     "from_date":`20221227T00:00`,
  //     "to_date":`20230102T23:59`,
  //     "walletid":this.walletId

  //   }
  //   // console.log("Fastag Weekly Txn Body--->",data);

  //   this._erpService.getFastagTollTxn(data).subscribe((res)=>{
  //     console.log(" getFastagWeeklyTxn()---->",res);
  //     if(res['errorCode']=='0' && res['data']){
  //       this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
  //       this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
  //       // this.dataLoading=false;
  //       if(res['data'].message!='No record found' && res['data'].message.length>0){

  //       // console.log(res['data'].message[0].response)
  //       // var x = res['data'].message[0].response;  
  //       // console.log(Object.keys(x),Object.values(x));



  //       // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
  //       // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
  //       this.weeklyFastagTransactionData=res['data'].message.reverse();
  //       for(let i=0; i<this.weeklyFastagTransactionData.length;i++){
  //         let date= this.weeklyFastagTransactionData[i]['reqTime'].slice(0,10);
  //         let time=this.weeklyFastagTransactionData[i]['reqTime'].slice(11,19);
  //         // console.log(date);
  //         // console.log(time);


  //         this.weeklyFastagTransactionData[i]['Date']=date;
  //         this.weeklyFastagTransactionData[i]['Time']=time;

  //       }
  //       // console.log(this.weeklyFastagTransactionData);
  //       this.weeklyFastagDatalength=this.weeklyFastagTransactionData.length;
  //       this.isWeeklyFasTxn=false;

  //       }
  //       else{
  //         this.isWeeklyFasTxn=false;
  //         this.noWeeklyFasData=true;
  //       }


  //     }
  //     else{
  //       // this.dataLoading=false;
  //     }

  //   })
  // }


  getFastagMonthlyTxn() {
    this.isMonthlyFasTxn = true;
    this.dataLoading = true;

    let todayDate = new Date();
    let lastThirtyDaysDate = new Date(todayDate.getTime() - (31 * 24 * 60 * 60 * 1000));
    let lastSevendaysMon = (lastThirtyDaysDate.getMonth() + 1).toString();
    let lastSevendaysDay = lastThirtyDaysDate.getDate().toString();
    let last_Thirty_days_Date;

    // console.log("last 30 days Mon-->",lastSevendaysMon);
    //from date validation 
    if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + (lastThirtyDaysDate.getMonth() + 1).toString() + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/${(lastThirtyDaysDate.getMonth() + 1).toString()}/${lastThirtyDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + '0' + (lastThirtyDaysDate.getMonth() + 1).toString() + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/0${(lastThirtyDaysDate.getMonth() + 1).toString()}/${lastThirtyDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + (lastThirtyDaysDate.getMonth() + 1).toString() + '0' + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/${(lastThirtyDaysDate.getMonth() + 1).toString()}/0${lastThirtyDaysDate.getDate().toString()}`;
    }

    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + '0' + (lastThirtyDaysDate.getMonth() + 1).toString() + '0' + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/${(lastThirtyDaysDate.getMonth() + 1).toString()}/0${lastThirtyDaysDate.getDate().toString()}`;
    }
    this.last_30_days_date=last_Thirty_days_Date;
    // console.log("last thirty days date--->", last_Thirty_days_Date);

    let data = {
      "merchantid": this.marchentid,
      "from_date": `${last_Thirty_days_Date}T00:00`,
      "to_date": `${this.todayDate}T23:59`,
      "walletid": this.walletId

    }

    // console.log("Fastag Monthly Txn Body--->",data);


  this._erpService.getFastagTollTxn(data).subscribe((res) => {
    
      // console.log(" getFastagMonthlyTxn()---->",res);
      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {

        if (res['data'].expenditure.daily_transaction && res['data'].expenditure.daily_transaction != null) {
          this.dailyTransaction = res['data'].expenditure.daily_transaction;
          this.dailyPendingCashback = (0.4 / 100) * this.dailyTransaction;
        }

        if (res['data'].expenditure.weekly_transaction && res['data'].expenditure.weekly_transaction != null) {
           this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
        this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;

        }
        if ( res['data'].expenditure.monthly_transaction && res['data'].expenditure.monthly_transaction != null) {
          this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
          this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

       }

        
       
        
        if (res['data'].message != 'No record found' && res['data'].message.length > 0) {

          // console.log(res['data'].message[0].response)
          // var x = res['data'].message[0].response;  
          // console.log(Object.keys(x),Object.values(x));



          // this.monthlyTransaction=res['data'].expenditure.monthly_transaction;
          // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;
          this.monthlyFastagTransactionData = res['data'].message.reverse();
          // console.log(this.monthlyFastagTransactionData);
          

          for (let i = 0; i < this.monthlyFastagTransactionData.length; i++) {

            if(this.monthlyFastagTransactionData[i].reqTime==null){
              this.monthlyFastagTransactionData[i]['reqTime']="NA";
            }
           
            if(this.monthlyFastagTransactionData[i].vehicleNo==null){
              this.monthlyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.monthlyFastagTransactionData[i].tollplazaname==null){
              this.monthlyFastagTransactionData[i].tollplazaname="NA";
            }
            let date = this.monthlyFastagTransactionData[i]['reqTime'].slice(0, 10);
            let time = this.monthlyFastagTransactionData[i]['reqTime'].slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.monthlyFastagTransactionData[i]['Date'] = date;
            this.monthlyFastagTransactionData[i]['Time'] = time;

          }

          // console.log(this.monthlyFastagTransactionData);
          this.monthlyFastagDatalength = this.monthlyFastagTransactionData.length;
          this.isMonthlyFasTxn = false;
        }
        else {
          this.isMonthlyFasTxn = false;
          this.noMonthlyFasData = true;

        }
        this.dataLoading = false;


      }
      else {
        this.dataLoading = false;
        this.isMonthlyFasTxn = false;
        this.noMonthlyFasData = true;
      }

    })





  }

  //duplicate fastag Txn

  getDuplicateFastagTxn() {
    this.isDuplicateFasTxn = true;
    this.dataLoading = true;
    this.duplicateFastagDatalength = 0;

    let todayDate = new Date();
    let lastSevenDaysDate = new Date(todayDate.getTime() - (31 * 24 * 60 * 60 * 1000));
    let lastSevendaysMon = (lastSevenDaysDate.getMonth() + 1).toString();
    let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
    let last_Thirty_days_Date;

    // console.log("last 30 days Mon-->",lastSevendaysMon);

    //from date validation 
    if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();

    }
    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();

    }
    else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
    }

    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
    }

    // console.log("last thirty days date--->", last_Thirty_days_Date);

    let data = {
      "merchantid": this.marchentid,
      "from_date": last_Thirty_days_Date,
      "to_date": this.todayDate,
      "walletid": this.walletId

    }

    this._erpService.getFastagTollTxn(data).subscribe((res) => {
      // console.log(" getDuplicateFastagTxn()---->",res);
      if (res['errorCode'] == '0' && res['data']) {
        // this.monthlyTransaction=res['data'].expenditure.monthly_transaction;
        // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;

        if (res['data'].message != 'No record found' && res['data'].message.length > 0 && res["data"]["exc_type"]!="ValidationError" ) {
          this.duplicateFastagTransactionData = res['data'].message.reverse();
          let duplicate = res['data'].message;
          //  console.log(duplicate);

          let duplicateIndex;
          let slicedata = [];
          let index = 0;
          for (data of duplicate) {
            if (data["duplicate_record"] == 1) {
              duplicateIndex = duplicate.indexOf(data);
              this.duplicateFastagTransactionData[index] = this.duplicateFastagTransactionData[duplicateIndex - 2];
              this.duplicateFastagTransactionData[index + 1] = this.duplicateFastagTransactionData[duplicateIndex - 1];
              this.duplicateFastagTransactionData[index + 2] = data;
              this.duplicateFastagTransactionData[index + 3] = this.duplicateFastagTransactionData[duplicateIndex + 1];
              this.duplicateFastagTransactionData[index + 4] = this.duplicateFastagTransactionData[duplicateIndex + 2];


              // var data=this.duplicateFastagTransactionData.slice(duplicateIndex-2,duplicateIndex+2)

              this.duplicateFastagTransactionData.splice(duplicateIndex, 1);
              this.duplicateFastagTransactionData.splice(duplicateIndex - 1, 1);
              this.duplicateFastagTransactionData.splice(duplicateIndex - 2, 1);
              this.duplicateFastagTransactionData.splice(duplicateIndex + 1, 1);
              this.duplicateFastagTransactionData.splice(duplicateIndex + 2, 1);

              console.log("duplicateIndex--->", duplicateIndex);

              this.onlyDuplicateTransactionData.push(data);
            }

          }
          // this.duplicateFastagTransactionData.unshift(slicedata);

          // this.duplicateFastagTransactionData=res['data'].message;
          // console.log(this.duplicateFastagTransactionData);
          this.onlyDuplicateFastagDatalength = this.onlyDuplicateTransactionData.length;
          // this.onlyDuplicateFastagDatalength=1;
          if (this.onlyDuplicateFastagDatalength == 0) {
            this.noOnlyDuplicateFasData = true;
          }
          this.duplicateFastagDatalength = this.duplicateFastagTransactionData.length;
          if (this.duplicateFastagDatalength == 0) {
            this.noDuplicateFasData = true;
          }
          this.isDuplicateFasTxn = false;
          this.dataLoading = false;
        }
        else {

          this.isDuplicateFasTxn = false;
          this.noDuplicateFasData = true;
          this.noOnlyDuplicateFasData = true;

        }


      }
      else {
        this.dataLoading = false;
      }

    })


  }

  //custom Fastag Txn

  onCustomTxn() {
    this.customFastagTransactionData = [];
    this.customTimeFilterDetails = []

    console.log(this.customFastagForm.value.fromdate);
    // this.userFromdateHourFilter=parseInt(this.customFastagForm.value.fromdate.slice(11,13));
    // this.userFromdateMinuteFilter=parseInt(this.customFastagForm.value.fromdate.slice(14,16));
    // this.userTodateHourFilter=this.customFastagForm.value.todate.slice(11,13);
    // this.userTodateMinuteFilter=this.customFastagForm.value.todate.slice(14,16);

    // console.log("user from-date hour-->", typeof(this.userFromdateHourFilter));
    // console.log("user from-date minutes-->", this.userFromdateMinuterFilter);
    // console.log("user To-date hour-->", this.userTodateHourFilter);
    // console.log("user To-date minutes-->", this.userTodateMinuterFilter);



    this.customFastagForm.value.fromdate = this.customFastagForm.value.fromdate.replace(/-/g, "");
    this.customFastagForm.value.todate = this.customFastagForm.value.todate.replace(/-/g, "");

    console.log(this.customFastagForm.value.fromdate);

    // console.log("time--->",this.customFastagForm.value.time);

    // console.log(this.customFastagForm.value);
    // console.log(this.customFastagForm.value.todate.split());

    if (this.customFastagForm.valid) {
      this.p4 = 1;
      this.isCustomFasTxn = true;
      this.noCustomFasData = false;
      // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
      this.getCustomFastagTxn(this.customFastagForm.value);
    }




  }



  getCustomFastagTxn(formvalue) {
    let MerId;
    console.log("fromdate-->", formvalue.fromdate);
    console.log("Todate-->", formvalue.todate);

    // let timeFilterdetails=[];
    let fromyear= formvalue.fromdate.slice(0,4);
    let toyear=formvalue.todate.slice(0,4);
    console.log(fromyear,toyear);
    
    if((fromyear && toyear)<2023){
    MerId=this.marchentId;

    }
    else{
      MerId= this.marchentid;
    }

    let data = {
      "merchantid":MerId,
      "from_date": formvalue.fromdate,
      "to_date": formvalue.todate,
      "walletid": this.walletId
    }

    // console.log("Fastag Custom Txn Body--->", data);

    this.customFastagDatalength = 0;

    this._erpService.getFastagTollTxn(data).subscribe((res) => {
      console.log(" getFastagcustomTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']) {
        if (res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" ) {

          this.customFastagTransactionData = res['data'].message;



          for (let i = 0; i < this.customFastagTransactionData.length; i++) {

            if(this.customFastagTransactionData[i].reqTime==null){
              this.customFastagTransactionData[i]['reqTime']="NA";
            }
           
            if(this.customFastagTransactionData[i].vehicleNo==null){
              this.customFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.customFastagTransactionData[i].tollplazaname==null){
              this.customFastagTransactionData[i].tollplazaname="NA";
            }


            let date = this.customFastagTransactionData[i]['reqTime'].slice(0, 10);
            let time = this.customFastagTransactionData[i]['reqTime'].slice(11, 19);


            this.customFastagTransactionData[i]['Date'] = date;
            this.customFastagTransactionData[i]['Time'] = time;




          }


          console.log("custom fastag-->", this.customFastagTransactionData);
          this.customFastagDatalength = this.customFastagTransactionData.length;
          this.isCustomFasTxn = false;

        }
        else {
          // console.log();
          // this.customFastagDatalength=0;
          this.isCustomFasTxn = false;
          this.noCustomFasData = true;
          this.dataLoading=false;
        }

        // console.log(res['data'].message[0].response)
        // var x = res['data'].message[0].response;  
        // console.log(Object.keys(x),Object.values(x));



        // this.customFastagTransactionData=res['data'].expenditure.monthly_transaction;



      }

    })

  }



  //************ END  Fastag data daily and weekly and custom from erp-next****************//


  //************ Fastag data daily and weekly  from HDFC **************************************//

  getFastagDailyTxn() {
    this.dataLoading=true

    this.isDailyFasTxn = true;

    // let data={
    //   "merchantid":this.marchentId,
    //   "from_date":`${this.todayDate}T00:00`,
    //   "to_date":`${this.todayDate}T23:59`,
    //   "walletid":this.walletId

    // }

    let data = {
      "merchantID": this.marchentid,
      "fromDate": `${this.todayDate} 000000`,
      "toDate": `${this.todayDate} 235959`,
      "walletId": this.walletId,
      "contactNumber": "",
      "vehicleNumber": "",
      "requestSource": ""

    }
    // console.log("Fastag Daily Txn Body--->",data);


    this._erpService.fromHdfcFastagTollTxn(data, this.Fastag_Cust_Id).subscribe((res) => {
      // console.log(" gethdfcFastagDailyTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']['resMessage'] == 'SUCCESS') {
        let FastagDailyTxn = res['data']['data'];
      
        // console.log("dygdsygy");
        // if(res['data'].expenditure.daily_transaction!=null){
        //   this.dailyTransaction=res['data'].expenditure.daily_transaction;
        //   this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
        // }


        if (FastagDailyTxn.length > 0) {
          // this.dailyTransaction=res['data'].expenditure.daily_transaction;
          // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;




          // // this.dailyTransaction=res['data'].expenditure.daily_transaction;
          // // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
          this.dailyFastagTransactionData = FastagDailyTxn.reverse();
          

          for (let i = 0; i < this.dailyFastagTransactionData.length; i++) {

            if(this.dailyFastagTransactionData[i].reqTime==null){
              this.dailyFastagTransactionData[i]['reqTime']="NA";
            }
           
            if(this.dailyFastagTransactionData[i].vehicleNo==null){
              this.dailyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.dailyFastagTransactionData[i].tollplazaname==null){
              this.dailyFastagTransactionData[i].tollplazaname="NA";
            }


            let date = this.dailyFastagTransactionData[i]['reqTime']?.slice(0, 10);
            let time = this.dailyFastagTransactionData[i]['reqTime']?.slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.dailyFastagTransactionData[i]['Date'] = date;
            this.dailyFastagTransactionData[i]['Time'] = time;

          }

          // console.log("daily Txn-->",this.dailyFastagTransactionData);
          this.dailyFastagDatalength = this.dailyFastagTransactionData.length

          
          this.isDailyFasTxn = false;

          this.dataLoading=false;
        
          
        }

        
        else {
          console.log("no daily fastag");

          this.isDailyFasTxn = false;
          this.noDailyFasData = true;
          this.dataLoading=false;
        }



      }
      else {
        this.isDailyFasTxn = false;
        this.noDailyFasData = true;
        this.dataLoading=false;
      }


    })


  }


  //Weekly Txn from HDFC
  getFastagWeeklyTxn() {
    this.isWeeklyFasTxn = true;
    this.dataLoading = true;

    let todayDate = new Date();
    let lastSevenDaysDate = new Date(todayDate.getTime() - (6 * 24 * 60 * 60 * 1000));
    // console.log(lastSevenDaysDate);

    let lastSevendaysMon = (lastSevenDaysDate.getMonth() + 1).toString();
    let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
    let last_Seven_days_Date;
    // console.log("last 7 days Mon-->",lastSevendaysMon);
    //from date validation 
    if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
    }

    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
    }

    // console.log("last seven days date--->", last_Seven_days_Date);




    // let data={
    //   "merchantid":this.marchentId,
    //   "from_date":`${last_Seven_days_Date}T00:00`,
    //   "to_date":`${this.todayDate}T23:59`,
    //   "walletid":this.walletId

    // }


    let data = {
      "merchantID": this.marchentid,
      "fromDate": `${last_Seven_days_Date} 000000`,
      "toDate": `${this.todayDate} 235959`,
      "walletId": this.walletId,
      "contactNumber": "",
      "vehicleNumber": "",
      "requestSource": ""

    }
    // console.log("Fastag Weekly Txn Body--->",data);

    this._erpService.fromHdfcFastagTollTxn(data, this.Fastag_Cust_Id).subscribe((res) => {
      // console.log(" getFastagWeeklyTxn()---->", res);
      // console.log(" HdfcgetFastagWeeklyTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']['resMessage'] == 'SUCCESS') {
        let FastagWeeklyTxn = res['data']['data'];

        // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
        // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
        // this.dataLoading=false;
        if (FastagWeeklyTxn.length >= 0) {

          // console.log(res['data'].message[0].response)
          // var x = res['data'].message[0].response;  
          // console.log(Object.keys(x),Object.values(x));



          // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
          // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
          this.weeklyFastagTransactionData = FastagWeeklyTxn.reverse();
          
          for (let i = 0; i < this.weeklyFastagTransactionData.length; i++) {

            if(this.weeklyFastagTransactionData[i].reqTime==null){
              this.weeklyFastagTransactionData[i]['reqTime']="NA";
            }
           
            if(this.weeklyFastagTransactionData[i].vehicleNo==null){
              this.weeklyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.weeklyFastagTransactionData[i].tollplazaname==null){
              this.weeklyFastagTransactionData[i].tollplazaname="NA";
            }


            let date = this.weeklyFastagTransactionData[i]['reqTime']?.slice(0, 10);
            let time = this.weeklyFastagTransactionData[i]['reqTime']?.slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.weeklyFastagTransactionData[i]['Date'] = date;
            this.weeklyFastagTransactionData[i]['Time'] = time;

          }
          // console.log(this.weeklyFastagTransactionData);
          this.weeklyFastagDatalength = this.weeklyFastagTransactionData.length;
          this.isWeeklyFasTxn = false;

        }
        else {
          this.isWeeklyFasTxn = false;
          this.noWeeklyFasData = true;
        }


      }
      else {
        this.dataLoading=false;
        this.isWeeklyFasTxn = false;
        this.noWeeklyFasData = true;
      }

    })
  }

  //************ END Fastag data daily and weekly  from HDFC **************************************//

  
//************ Parent Wallet Histroy from HDFC **************************************//

getParentWalletHistory(){

  let data=
    {
      
      "merchantID" : this.marchentid,
      "requestSource" : "BD",
      "partnerTxnId": " ",
      "userId":`${this.Fastag_Cust_Id}`,
      "fromDate":`${this.monthlyDate} 00:00:00`,
      "toDate":`${this.todaysDate} 23:59:59`,
    
  }
  // console.log(data);
  

  this._erpService.parentWalletTxn(data,this.Fastag_Cust_Id).subscribe((res:any)=>{
    // console.log("getParentWalletHistory--->",res);
    // console.log(" gethdfcFastagDailyTxn()---->", res);
    if (res['errorCode'] == '0' && res['data']['resMessage'] == 'SUCCESS') {
      let parentTxn = res['data']['data'];

      
    
      // console.log("dygdsygy");
      // if(res['data'].expenditure.daily_transaction!=null){
      //   this.dailyTransaction=res['data'].expenditure.daily_transaction;
      //   this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
      // }


      if (parentTxn.length > 0) {

      
        
        // this.dailyTransaction=res['data'].expenditure.daily_transaction;
        // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;




        // // this.dailyTransaction=res['data'].expenditure.daily_transaction;
        // // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
        this.parentTransactionData = parentTxn.reverse();
        
        this.parentWalletBalnce= this.parentTransactionData[0]["closingBalance"];
        console.log(this.parentWalletBalnce);
        
      
        for (let i = 0; i < this.parentTransactionData.length; i++) {

          if(this.parentTransactionData[i].remarks==null){
            this.parentTransactionData[i]['remarks']="NA";
          }
         
          // if(this.parentTransactionData[i].vehicleNo==null){
          //   this.parentTransactionData[i].vehicleNo="NA";
          // }
          if(this.parentTransactionData[i].tollplazaname==null){
            this.parentTransactionData[i].tollplazaname="NA";
          }


          let date = this.parentTransactionData[i]['insertedOn']?.slice(0, 10);
          let time = this.parentTransactionData[i]['insertedOn']?.slice(11, 19);
          // console.log(date);
          // console.log(time);


          this.parentTransactionData[i]['Date'] = date;
          this.parentTransactionData[i]['Time'] = time;

        }

        // console.log("daily Txn-->",this.dailyFastagTransactionData);
        this.parentDatalength = this.parentTransactionData.length
        this.isParentWalletTxn = false;
        this.dataLoading=false;
      
        
      }

      
      else {
        console.log("no daily fastag");

        this.isParentWalletTxn = false;
        this.noParentWalletData = true;
        this.dataLoading=false;
      }



    }
    else {
      this.isParentWalletTxn = false;
      this.noParentWalletData = true;
      this.dataLoading=false;
    }


  })




}


//************ END Parent Wallet Histroy from HDFC **************************************//


  
 
 //get doc type
 // getFastagDoctypeId() {
  //   this.isDailyFasTxn=true;

  //   let customParams = `?filters=[["name","=","${this.customer}"]]&fields=["name","tax_category","tax_id","fastag_customer"]`
  //   this._erpService.getCustomerDetails(customParams).subscribe((res) => {
  //     console.log("customer details---->", res);
  //     if (res["errorCode"] == '0' && res["data"]) {
  //       this.fastagCustomer = res['data']['data'][0]['fastag_customer'];
  //       console.log( this.fastagCustomer);

  //       this.getFastagCustomerDetails(this.fastagCustomer);
  //     }

  //   })
  // }



  // getFastagCustomerDetails(fastagCustomer) {
  //   // this.dataLoading = true;

  //   // console.log("userEmail------->", this.userEmail);


  //   // let fasTagParams = `?filters=[["user","=","${this.userEmail}"]]&fields=["requestid","requesttime","requestsource","merchantid","firstname","lastname","mobileno","emailid","dob","address","city","state","pincode","accountnumber","ifsccode","branchname","vehregno","vehicleclass","vehiclecat","partnerrefid","walletid"]`;

  //   this._erpService.getFastagCustomerId(fastagCustomer).subscribe(res => {

  //     // window.localStorage.setItem("",res["data"][""]);

  //     if (res["errorCode"] == '0') {
  //       console.log("FastagCustomerDetails------>", res["data"]);

  //       window.localStorage.setItem("requsetSource", res["data"]["requestsource"]);
  //       window.localStorage.setItem("walletid", res["data"]["walletid"]);
  //       window.localStorage.setItem("marchantid", res["data"]["merchantid"]);
  //       // this.getFastagWalletEncryptDetails();
  //       this.getDailyFastagTxnEncryptDetails();
  //       // this.getWeeklyFastagTxnEncryptDetails();
  //       // this.getFastagTxnEncryptDetails();
  //       // this.dataLoading = false;

  //       // console.log("FastagCustomerDetails------>", res);
  //       // for (let data of res["data"]) {
  //       //   this.fastagCustomerDetails = data;
  //       //   if (this.fastagCustomerDetails) {
  //       //     this.getFastagWalletEncryptDetails();
  //       //     this.getFastagTxnEncryptDetails();

  //       //   }

  //       // }



  //     }
  //     else {
  //       console.log("fastag get customer details error");

  //     }

  //   })

  // }



  getDailyFastagTxnEncryptDetails() {

    let data = {
      "requestSource": localStorage.getItem("requsetSource"),
      "merchantID": localStorage.getItem("marchantid"),
      "walletId": localStorage.getItem("walletid"),
      "fromDate": this.todayDate,
      "toDate": this.todayDate,
      "contactNumber": "",
      "vehicleNumber": ""

    }

    this._erpService.getFastagEncryptDetails(data).subscribe((res) => {

      if (res["errorCode"] == "0") {
        // console.log("Daily Fastag Txn Encrypt Details---->", res['data']);
        this.dailyFastagTxnEncryptDetails = res["data"];
        // this.getFastagWalletbalance();
        this.getDailyFastagTxnEnquiry();
        // this.dataLoading=false;

      }
      else {
        // console.log("encrypt details error");

      }

    })


  }


  getDailyFastagTxnEnquiry() {
    // this.dataLoading = true;
    // let marchantId = this.fastagCustomerDetails["merchantid"];
    let marchantId = localStorage.getItem("marchantid")
    // console.log(marchantId);


    this._erpService.getFastagTranscationEnquiry(this.dailyFastagTxnEncryptDetails, marchantId).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagTxnEnquiry------>", res["data"]);
        this.dailyFastagTransactionEnqResponse = res["data"];
        this.isDailyFasTxn = true;
        this.getDailyFastagDecryptDetails(this.dailyFastagTransactionEnqResponse);

        // this.dataLoading=false;
      }
      else {
        // console.log("fastag txn enquiry error");

      }


    })

  }



  //weekly fastag txn history

  getWeeklyFastagTxnEncryptDetails() {

    let todayDate = new Date();
    let lastSevenDaysDate = new Date(todayDate.getTime() - (7 * 24 * 60 * 60 * 1000));
    let lastSevendaysMon = lastSevenDaysDate.getMonth().toString();
    let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
    let last_Seven_days_Date;

    //from date validation 
    if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();

    }
    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();

    }
    else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
    }

    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
    }

    // console.log("last seven days date--->", lastSevenDaysDate);


    let data = {
      "requestSource": localStorage.getItem("requsetSource"),
      "merchantID": localStorage.getItem("marchantid"),
      "walletId": localStorage.getItem("walletid"),
      "fromDate": last_Seven_days_Date,
      "toDate": this.todayDate,
      "contactNumber": "",
      "vehicleNumber": ""

    }
    // console.log("weekly fastag txn body-data--->", data);


    this._erpService.getFastagEncryptDetails(data).subscribe((res) => {

      if (res["errorCode"] == "0") {
        // console.log("weeklyly Fastag Txn Encrypt Details---->", res['data']);
        this.weeklyFastagTxnEncryptDetails = res["data"];

        this.getWeeklyFastagTxnEnquiry();


      }
      else {
        // console.log("encrypt details error");

      }

    })


  }


  getWeeklyFastagTxnEnquiry() {
    // this.dataLoading = true;
    // let marchantId = this.fastagCustomerDetails["merchantid"];
    let marchantId = localStorage.getItem("marchantid")
    // console.log(marchantId);


    this._erpService.getFastagTranscationEnquiry(this.weeklyFastagTxnEncryptDetails, marchantId).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagTxnEnquiry------>", res["data"]);
        this.weeklyFastagTransactionEnqResponse = res["data"];
        this.isWeeklyFasTxn = true;
        this.getDailyFastagDecryptDetails(this.weeklyFastagTransactionEnqResponse);
        // this.dataLoading=false;
      }
      else {
        // console.log("fastag txn enquiry error");

      }


    })

  }



  getCustomFastagTxnEncryptDetails(formvalue) {

    let data = {
      "requestSource": localStorage.getItem("requsetSource"),
      "merchantID": localStorage.getItem("marchantid"),
      "walletId": localStorage.getItem("walletid"),
      "fromDate": formvalue.fromdate,
      "toDate": formvalue.todate,
      "contactNumber": "",
      "vehicleNumber": ""
    }
    this._erpService.getFastagEncryptDetails(data).subscribe((res) => {

      if (res["errorCode"] == "0") {
        // console.log("custom Fastag Txn Encrypt Details---->", res['data']);
        this.customFastagTxnEncryptDetails = res["data"];

        this.getCustomFastagTxnEnquiry();


      }
      else {
        // console.log("encrypt details error");

      }

    })

  }

  getCustomFastagTxnEnquiry() {
    this.dataLoading = true;
    // let marchantId = this.fastagCustomerDetails["merchantid"];
    let marchantId = localStorage.getItem("marchantid")
    console.log(marchantId);


    this._erpService.getFastagTranscationEnquiry(this.customFastagTxnEncryptDetails, marchantId).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagTxnEnquiry------>", res["data"]);
        this.customFastagTransactionEnqResponse = res["data"];
        this.isCustomFasTxn = true;
        this.getDailyFastagDecryptDetails(this.customFastagTransactionEnqResponse);
        // this.dataLoading=false;
      }
      else {
        // console.log("fastag txn enquiry error");

      }


    })



  }





  //order wise decrypt details


  getDailyFastagDecryptDetails(data) {
    // this.dataLoading = true;
    // this.isDailyFasTxn = true;
    // this.isMonthlyFasTxn = true;
    // this.isWeeklyFasTxn = true;
    // this.isCustomFasTxn = true;

    this._erpService.getFastagDecryptDetails(data).subscribe(res => {
      // console.log("Fastag Txn Response---->", res);

      if (res["errorCode"] == "0" && res["data"]) {



        if (res["data"] != undefined) {


          if (data == this.dailyFastagTransactionEnqResponse) {
            // console.log("Fastag Txn Response---->", res);
            // this.dailyFastagTransactionData = res["data"]
            if (res["data"]["respData"] != undefined && res["data"].respData.length > 0) {
              this.dailyFastagTransactionData = res["data"]["respData"];
              this.isDailyFasTxn = false;
            }
            else if (res["data"]["respData"] == undefined) {
              this.isDailyFasTxn = false;
              this.noDailyFasData = true;
            }
            else if (res["data"]["message"]) {
              this.noDailyFasData = true;
              this.isDailyFasTxn = false;

            }
            // console.log("daily fastagTranscationDecryptDetails------>", this.dailyFastagTransactionData);
            this.dailyFastagDatalength = this.dailyFastagTransactionData.length;
            // console.log(this.dailyFastagDatalength);

          }

          else if (data == this.weeklyFastagTransactionEnqResponse) {

            // this.dailyFastagTransactionData = res["data"]
            if (res["data"]["respData"] != undefined && res["data"].respData.length > 0) {
              this.weeklyFastagTransactionData = res["data"]["respData"].reverse();
              this.isWeeklyFasTxn = false;
            }
            else if (res["data"]["respData"] == undefined) {
              this.isWeeklyFasTxn = false;
              this.noWeeklyFasData = true;
            }
            else if (res["data"]["message"]) {
              this.noWeeklyFasData = true;
              this.isWeeklyFasTxn = false;

            }
            // console.log("weekly fastagTranscationDecryptDetails------>", this.weeklyFastagTransactionData);
            this.weeklyFastagDatalength = this.weeklyFastagTransactionData.length;
            // console.log(this.weeklyFastagDatalength);

          }

          else if (data == this.monthlyFastagTransactionEnqResponse) {

            // this.dailyFastagTransactionData = res["data"]
            if (res["data"]["respData"] != undefined && res["data"].respData.length > 0) {
              this.monthlyFastagTransactionData = res["data"]["respData"].reverse();
              this.isMonthlyFasTxn = false;
            }
            else if (res["data"]["respData"] == undefined) {
              this.isMonthlyFasTxn = false;
              this.noMonthlyFasData = true;
            }
            else if (res["data"]["message"]) {
              this.noMonthlyFasData = true;
              this.isMonthlyFasTxn = false;

            }
            // console.log("monthly fastagTranscationDecryptDetails------>", this.monthlyFastagTransactionData);
            this.monthlyFastagDatalength = this.monthlyFastagTransactionData.length;
            // console.log(this.monthlyFastagDatalength);

          }

          
          else if (data == this.customFastagTransactionEnqResponse) {

            // this.dailyFastagTransactionData = res["data"]
            if (res["data"]["respData"] != undefined && res["data"].respData.length > 0) {
              // console.log("=======");

              this.customFastagTransactionData = res["data"]["respData"].reverse();
              this.isCustomFasTxn = false;
            }
            else if (res["data"]["respData"] == undefined) {
              // console.log("+++++");
              this.isCustomFasTxn = false;
              this.noCustomFasData = true;
            }
            else if (res["data"]["message"]) {
              // console.log("******");
              this.noCustomFasData = true;
              this.isCustomFasTxn = false;

            }
            // console.log("custom fastagTranscationDecryptDetails------>", this.customFastagTransactionData);
            this.customFastagDatalength = this.customFastagTransactionData.length;
            // console.log(this.customFastagDatalength);

          }



        }


        else {
          if (res['data']['message'] == "No data found") {
            // this.noWeeklyFasData = true;
            // this.noMonthlyFasData = true;
            // this.noDailyFasData = true;
            // this.noCustomFasData = true;
            // this.isDailyFasTxn=false;
            // this.isWeeklyFasTxn=false;
            // this.isMonthlyFasTxn=false;
            // this.isCustomFasTxn = false;
          }


        }






      }


    });


  }

  ParentWallethistorybtn(){
    this.enable_dup_btn = false;
    this.searchString = '';
  }
  FastagWallethistorybtn(){
    this.enable_dup_btn = false;
    this.searchString = '';
  }
  dailyFastagtxn() {

    this.enable_dup_btn = false;
    this.searchString = '';

  }
  weeklyFastagtxn() {
    this.enable_dup_btn = false;
    this.searchString = '';
  }
  monthlyFastagtxn() {
    this.enable_dup_btn = false;
    this.searchString = '';
  }
  customFastagtxn() {
    this.enable_dup_btn = false;
    this.searchString = '';
  }
  duplicateFastagtxn() {
    this.enable_dup_btn = true;
    this.searchString = '';
  }

}
