import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inside-loader',
  templateUrl: './inside-loader.component.html',
  styleUrls: ['./inside-loader.component.css']
})
export class InsideLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
