import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./common/service/auth.guard";
import { InsuranceDetailsComponent } from "./insurance-details/insurance-details.component";
import { InvoiceListComponent } from "./invoice-list/invoice-list.component";
import { LoginComponent } from "./login/login.component";
import { BpclDashboardComponent } from "./operator-dashboard/bpcl-dashboard/bpcl-dashboard.component";
import { FastagDetailsComponent } from "./operator-dashboard/fastag-details/fastag-details.component";
import { InsightsComponent } from "./operator-dashboard/insights/insights.component";
import { OptDashboardComponent } from "./operator-dashboard/opt-dashboard/opt-dashboard.component";
import { SetautorechargeComponent } from "./operator-dashboard/setautorecharge/setautorecharge.component";
import { VehicleExpensesGraphComponent } from "./operator-dashboard/vehicle-expenses-graph/vehicle-expenses-graph.component";
import { PayoutComponent } from "./payout/payout.component";
import { AccessDeniedComponent } from "./shared/access-denied/access-denied.component";
import { VehicleDocumentComponent } from "./vehicle-document/vehicle-document.component";
import { WtsappVehicleDocComponent } from "./wtsapp-vehicle-doc/wtsapp-vehicle-doc.component";
import { IntrcityDashboardComponent } from "./intrcity-dashboard/intrcity-dashboard.component";
import { IntrcityInvoiceListComponent } from "./intrcity-dashboard/intrcity-invoice-list/intrcity-invoice-list.component";
import { CreditsAllocationComponent } from "./operator-dashboard/credits-allocation/credits-allocation.component";
import { CreditsAllocationHistoryComponent } from "./intrcity-dashboard/credits-allocation-history/credits-allocation-history.component";
import { JioBpDashboardComponent } from "./operator-dashboard/jio-bp-dashboard/jio-bp-dashboard.component";
import { JioBpAssignCreditsComponent } from "./operator-dashboard/jio-bp-assign-credits/jio-bp-assign-credits.component";
import { PurchaseOrderComponent } from "./purchase-order/purchase-order.component";
import { TyreDetailsComponent } from "./tyre-details/tyre-details.component";
import { VehiclesHistoryComponent } from "./vehicles-history/vehicles-history.component";
import { TyreServiceReportComponent } from "./tyre-service-report/tyre-service-report.component";
import { NotificationsComponent } from "./notifications/notifications.component";
import { VehGeolocationFormComponent } from "./driver/veh-geolocation-form/veh-geolocation-form.component";
import { CalculateRoutePriceComponent } from "./calculate-route-price/calculate-route-price.component";
import { IntrcityFastagDetailsComponent } from "./intrcity-dashboard/intrcity-fastag-details/intrcity-fastag-details.component";
import { DlDashboardComponent } from "./operator-dashboard/dl-dashboard/dl-dashboard.component";
import { FcDashboardComponent } from "./operator-dashboard/fc-dashboard/fc-dashboard.component";
import { RcDashboardComponent } from "./operator-dashboard/rc-dashboard/rc-dashboard.component";
import { DlDashboardModule } from "./operator-dashboard/dl-dashboard/dl-dashboard.module";

const routes: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  { path: "login", component: LoginComponent },
  {
    path: "products",
    loadChildren: () =>
      import("./products/products.module").then((m) => m.ProductsModule),
    canActivate: [AuthGuard],
  },
  {
    path: "cart",
    loadChildren: () => import("./cart/cart.module").then((m) => m.CartModule),
    canActivate: [AuthGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./dashboard/dashboard.module").then((m) => m.DashboardModule),
    canActivate: [AuthGuard],
  },
  {
    path: "orders",
    loadChildren: () =>
      import("./order/order.module").then((m) => m.OrderModule),
    canActivate: [AuthGuard],
  },
  {
    path: "invoiceList",
    component: InvoiceListComponent,
    canActivate: [AuthGuard],
  },
  { path: "access-denied", component: AccessDeniedComponent },
  { path: "payout", component: PayoutComponent },
  { path: "upload-vehicle-document", component: VehicleDocumentComponent },
  {
    path: "operator-dashboard",
    component: OptDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "fastag-dashboard",
    component: FastagDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "insurance-dashboard",
    component: InsuranceDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "bpcl-dashboard",
    component: BpclDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "insights-dashboard",
    component: InsightsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "vehicle-expenses-graph",
    component: VehicleExpensesGraphComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "upload-wtsapp-vehicle-document",
    component: WtsappVehicleDocComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "intrcity-dashboard",
    component: IntrcityDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "intrcity-invoice-list",
    component: IntrcityInvoiceListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "intrcity-credit-alloc",
    component: CreditsAllocationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "credit-alloc-history",
    component: CreditsAllocationHistoryComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "setautorecharge-dashboard",
    component: SetautorechargeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "jio-bp-dashboard",
    component: JioBpDashboardComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "jio-bp-credits-allocation",
    component: JioBpAssignCreditsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "purchase-order",
    component: PurchaseOrderComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tyre-details",
    component: TyreDetailsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "vehicles-history",
    component: VehiclesHistoryComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "tyre-service-reports",
    component: TyreServiceReportComponent,
    canActivate: [AuthGuard],
  },

  {
    path: "notifications",
    component: NotificationsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "calculate-route-price",
    component: CalculateRoutePriceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "intrcity-fastag-txn",
    component: IntrcityFastagDetailsComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: "dl-dashboard",
  //   component: DlDashboardComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "dl-dashboard",
    loadChildren:()=>import("./operator-dashboard/dl-dashboard/dl-dashboard.module").then((m)=>m.DlDashboardModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "fc-dashboard",
  //   component: FcDashboardComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "fc-dashboard",
    loadChildren:()=>import("./operator-dashboard/fc-dashboard/fc-dashboard.module").then((m)=>m.FcDashboardModule),
    canActivate: [AuthGuard],
  },
  // {
  //   path: "rc-dashboard",
  //   component: RcDashboardComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "rc-dashboard",
    loadChildren:()=>import('./operator-dashboard/rc-dashboard/rc-dashboard.module').then((m)=>m.RcDashboardModule),
    canActivate: [AuthGuard],
  },


  //for drive
  // { path: "unprocessed_not_moving_vehicles/:type/:vno", component: VehGeolocationFormComponent },
  { path: "submitreport/:type/:vno", component: VehGeolocationFormComponent },

  // {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
