import { Component, OnChanges, OnInit } from '@angular/core';
import { ErpnextapiService } from 'src/app/common/service/erpnextapi.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-opt-dashboard',
  templateUrl: './opt-dashboard.component.html',
  styleUrls: ['./opt-dashboard.component.css']
})
export class OptDashboardComponent implements OnInit {

  isBPCLWallet: boolean = false;
  isIOCLWallet: boolean = false;
  isFastagWallet: boolean = false;
  BPCL_Recharge: number = 0;
  bpcl_recharge_response: number;
  IOCL_Recharge: number = 0;
  iocl_recharge_response: number;
  Fastag_Recharge: number = 0;
  fastag_recharge_response: any;
  totalRechargeAmt: number = 0;
  BPCL_List: any;
  IOCL_List: any;
  Fastag_List: any;
  bpclfuelBalance: number = 0;
  ioclfuelBalance: number = 0;
  fastagBalance: number = 0;
  receivableAmt: number = 0;
  receivableAmt1:string="0";
  creditBal: number = 0;
  overdueAmt: number = 0;

  p1: number = 1;
  p2: number = 1;
  p3: number = 1;
  p4: number = 1;
  p5: number = 1;
  p6: number = 1;
  p7: number = 1;
  p8: number = 1;
  p9: number = 1;
  p10:number=1;
  p11:number=1;
  p13:number=1
  p14:number=1
  p15:number=1
  p16:number=1
  p17:number=1

  fa_id: any;
  fc_id: any;
  lastTransactionList: any;
  customer: any;
  lnderBalance: number = 0;
  lnderBalance1:string="";
  totalOutstandAmt: number = 0;
  cashBal: number = 0;

  fastagCustomerDetails: any;

  fastagTxnEncryptDetails: any;
  fastagTransactionEnqResponse: any;
  fastagTransactionData: any;

  fastagWalletEncryptDetails: any;
  fastagWalletResponse: any;

  dailyFastagTxnEncryptDetails: any;
  dailyFastagTransactionEnqResponse: any;
  dailyFastagTransactionData: any;
  dailyFastagDatalength: number = 0;

  weeklyFastagTxnEncryptDetails: any;
  weeklyFastagTransactionEnqResponse: any;
  weeklyFastagTransactionData: any;
  weeklyFastagDatalength: number = 0;

  monthlyFastagTxnEncryptDetails: any;
  monthlyFastagTransactionEnqResponse: any;
  monthlyFastagTransactionData: any;
  monthlyFastagDatalength: number = 0;


  customFastagTxnEncryptDetails: any;
  customFastagTransactionEnqResponse: any;
  customFastagTransactionData: any;
  customFastagDatalength: number = 0;


  isDailyFasTxn: boolean;
  isMonthlyFasTxn: boolean;
  isWeeklyFasTxn: boolean;
  isCustomFasTxn: boolean;

  noDailyFasData: boolean = false;
  noWeeklyFasData: boolean = false;
  noMonthlyFasData: boolean = false;
  noCustomFasData: boolean = false;


  //bpcl

  dailyBpclTransactionData: any;
  dailyBpclDatalength: number = 0;
  dailyTransaction: number = 0;



  weeklyBpclTransactionData: any;
  weeklyBpclDatalength: number = 0;
  weeklyTransaction: number = 0;


  monthlyBpclTransactionData: any;
  monthlyBpclDatalength: number = 0;
  monthlyTransaction: number = 0;


  customBpclTransactionData: any;
  customBpclDatalength: number = 0;
  customTransaction: number = 0;


  isDailyBpclTxn: boolean = true;
  isMonthlyBpclTxn: boolean = true;
  isWeeklyBpclTxn: boolean = true;
  isCustomBpclTxn: boolean;


  noDailyBpclData: boolean = false;
  noWeeklyBpclData: boolean = false;
  noMonthlyBpclData: boolean = false;
  noCustomBpclData: boolean = false;


  //recharge history

  rechargeTransactionData:any;
  rechargeTransactionDatalength:number=0;
  isRechargeTxn:boolean=true;
  noRechargeTxnData:boolean=false;

  bpclRechargeHistory:any=[];
  bpclRechargeHistoryDatalength:number=0;
  isbpclRechargeTxn:boolean=true;
  nobpclRechargeTxnData:boolean=false;



  dailyJioBpTransactionData: any;
  dailyJioBpDatalength: number = 0;
  // dailyTransaction: number = 0;
  dailyPendingCashback: number = 0;


  weeklyJioBpTransactionData: any;
  weeklyJioBpDatalength: number = 0;
  // weeklyTransaction: number = 0;
  weeklyPendingCashback: number = 0;

  monthlyJioBpTransactionData: any;
  monthlyJioBpDatalength: number = 0;
  // monthlyTransaction: number = 0;
  monthlyPendingCashback: number = 0;

  customJioBpTransactionData: any;
  customJioBpDatalength: number = 0;
  // customTransaction: number = 0;
  customPendingCashback: number = 0;

  isDailyJioBpTxn: boolean=true;
  isMonthlyJioBpTxn: boolean=true;
  isWeeklyJioBpTxn: boolean=true;
  isCustomJioBpTxn: boolean;


  noDailyJioBpData: boolean = false;
  noWeeklyJioBpData: boolean = false;
  noMonthlyJioBpData: boolean = false;
  noCustomJioBpData: boolean = false;




  ioclRechargeHistory:any=[];
  ioclRechargeHistoryDatalength:number=0;
  isioclRechargeTxn:boolean=true;
  noioclRechargeTxnData:boolean=false;

  fastagRechargeHistory:any=[];
  fastagRechargeHistoryDatalength:number=0;
  isfastagRechargeTxn:boolean=true;
  nofastagRechargeTxnData:boolean=false;


  jioBpRechargeHistory:any=[];
  jioBpRechargeHistoryDatalength:number=0;
  isJioBpRechargeTxn:boolean=true;
  noJioBpRechargeTxnData:boolean=false;






  dailyStartDate: any;
  weeklyStartDate: any;
  monthlyStartDate: any;



  totalAvailbaleCredit: number = 1000;
  // rechargeAmtExceedMsg:string="";
  isShow: boolean = false;
  isAllow = false;
  isWithdraw: boolean = false;


  bpclDatalength: any; withDrawForm: FormGroup;
  customBpclForm: FormGroup;
  insExpPolData: any = [];
  customJioBpForm: FormGroup;
  ;
  ioclDatalength: any;;
  fastagDatalength: any;
  noData: boolean = false;
  bpclNoData: boolean;
  userEmail: any;
  // datePipe: any;
  isPartialWithdraw: boolean = false;
  isFullWithdraw: boolean = false;
  withDrawAmt: number = 0;
  partialWithdrawAmount: number = 0;
  withDrawBalResponse: number;
  isWithdrawSuccess: string = "";
  isLoadwithDraw: boolean = false;
  isBpclTxn: boolean;
  isFastagTxn: boolean;

  // success: boolean=false;
  // error:boolean=false
  // success_error:boolean=false;
  isSuccess: string = "";
  isError: string = "";
  isPartialsuccess: string = "";
  showButton: boolean = false;

  dataLoading: boolean = true;
  isLoad: boolean = false;
  partial_success: string = "";
  AuthToken: any;
  fastagCustomer: any;
  bpclSfaToken: any;
  todayDate: any;
  requsetSource: any;

  marchantid: any;
  marchentId: any;
  walletId: any;
  insurance_opt_id: any;
  insurance_token: any;
  no_of_exp_pol: number = 0;

  todaysDate: any;
  weeklyDate: any;
  monthlyDate: any;

  email: any;
  Fastag_Cust_Id: any;


  onlyBpcl: any;
  onlyIocl: any;
  onlyFastag: any;
  failedBpcl: any;
  failedIocl: any;
  failedFastag: any;
  BpclnIocl: any;
  BpclnFasatg: any;
  IoclnFasatg: any;
  failedBpclnIocl: any;
  failedBpclnFasatg: any;
  failedIoclnFasatg: any;
  allRchrgError: any;
  allRchargeSuccess: any;

  S_bpcl_f_ioclnfastag: any;
  S_iocl_f_bpclnfastag: any;
  S_fastag_f_bpclniocl: any;

  S_bpclniocl_f_fastag: any;
  S_ioclnfatsg_f_bpcl: any;
  S_fastagnbpcl_f_iocl: any;

  f_bpcl_s_iocl: any;
  s_bpcl_f_iocl: any;
  f_bpcl_s_fastag: any;
  s_bpcl_f_fastag: any;
  f_iocl_s_fastag: any;
  s_iocl_f_fastag: any;

  todaysdate:any;
  todaysDate1:any;
  weeklydate:any;
  monthlydate:any;
  yearagoDate:any;



  searchString:any="";

  LnderBalance:number=0;
  CashBalance:number=0
  JioBpBalance:number=0;


  fastagRechResMsg:any;
  bpclRechResMsg:any;
  ioclRechResMsg:any;



  form: FormGroup = new FormGroup({});
  customFastagForm: FormGroup = new FormGroup({});
  // withdrawForm: FormGroup = new FormGroup({});


  constructor(private datePipe: DatePipe,private _erpService: ErpnextapiService, private fb: FormBuilder, private route: ActivatedRoute, private router: Router) {
    this.form = fb.group({
      bpclAmt: ['', [Validators.pattern("^[0-9]*$")]],
      ioclAmt: ['', [Validators.pattern("^[0-9]*$")]],
      fastagAmt: ['', [Validators.pattern("^[0-9]*$")]],
    })

    this.withDrawForm = fb.group({
      // ioclamt: ['', [Validators.pattern("^[0-9]*$")]],
      partial: ['', [Validators.pattern("^[0-9]*$")]],
      // Fullwithdraw: ['', [Validators.pattern("^[0-9]*$")]],
    })


    this.customFastagForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],

    })

    this.customBpclForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],

    })

    this.customJioBpForm = fb.group({
      fromdate: ['', Validators.required],
      todate: ['', Validators.required],
      time:['']

    });



    // this.withdrawForm = fb.group({
    //   ioclwithdrawAmt: ['', [Validators.pattern("^[0-9]*$")]],

    // })

  }

  ngOnInit(): void {

    // const date = new Date('2023-02-10T00:00:00.000Z');
    // date.setDate(date.getDate() + 1);
    // console.log(date);

    //  this.getInsuranceToken();
    this.email = localStorage.getItem("email");


    this.fa_id = localStorage.getItem('FAID');
    this.fc_id = localStorage.getItem('FCID');
    this.customer = localStorage.getItem('customer');
    this.userEmail = localStorage.getItem('email');
    this.marchantid = localStorage.getItem('marchantid');
    this.walletId = localStorage.getItem('walletid');
    this.Fastag_Cust_Id = localStorage.getItem('FastagCustomerID');
    this.insurance_token = localStorage.getItem('InsuranceToken');
    // fasTagERPMarchentId
    this.marchentId = localStorage.getItem('fasTagERPMarchentId');

    // console.log( this.marchantid);

    // this.requsetSource=localStorage.getItem('requsetSource');

    // "requsetSource", res["data"]["requestsource"]);
    //     window.localStorage.setItem("walletid", res["data"]["walletid"]);
    //     window.localStorage.setItem("marchantid", res["data"]["merchantid"]);

    let today = new Date();
    this.todaysDate = this.datePipe.transform(today,'dd/MM/yyyy');
    this.todaysDate1 = this.datePipe.transform(today,'yyyy-MM-dd');
    // console.log(this.todaysDate);
    let sevenDaysBack= new Date(new Date().setDate(today.getDate() - 6));
    this.weeklyDate=this.datePipe.transform(sevenDaysBack,'dd/MM/yyyy');
    // console.log(this.weeklyDate)
    let thirtyDaysBack=new Date(new Date().setDate(today.getDate() - 31));
    this.monthlyDate=this.datePipe.transform(thirtyDaysBack,'dd/MM/yyyy');

    let date=new Date(new Date().setDate(today.getDate() - 365));
    this.yearagoDate=this.datePipe.transform(date,'yyyy-MM-dd');




    let todayDate = new Date();
    let month = (todayDate.getMonth() + 1).toString();
    let monthLength = month.split('');
    let day = todayDate.getDate().toString();
    let dayLength = day.split('');

    // console.log("month length---->",monthLength);
    // console.log("day length--->",dayLength);

    if ((monthLength.length == 2 && dayLength.length == 2)) {
      this.todayDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/${(todayDate.getMonth() + 1).toString()}/${todayDate.getDate().toString()}`;

    }
    else if (monthLength.length == 1 && dayLength.length == 2) {
      this.todayDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/0${(todayDate.getMonth() + 1).toString()}/${todayDate.getDate().toString()}`

    }
    else if (monthLength.length == 2 && dayLength.length == 1) {
      this.todayDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/${(todayDate.getMonth() + 1).toString()}/0${todayDate.getDate().toString()}`
    }

    else if (monthLength.length == 1 && dayLength.length == 1) {
      this.todayDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      this.todaysDate = `${todayDate.getFullYear().toString()}/0${(todayDate.getMonth() + 1).toString()}/0${todayDate.getDate().toString()}`
    }
    // console.log("Today date---->", this.todayDate);

    // this. getLoggedInUser();
    this.login();
    this.getCreditBal();
    this.getCustomerId();
    // this.getBPCLSFAToken();
    // this.login();
    this.getReceviableAmount();

    this.getJioBpBalance();

    // this.getLnderBal();
    this.getOverdueAmt();
    this.getTotalOustandAmt();
    this.getCashBalance();

    // this.getRechargeTxnHistory();
    this.getBpclFuelBal();
    this.getDailyBpclTxn();
    this.getWeeklyBpclTxn();
    this.getMonthlyBpclTxn();


    this.getDailyJioBpTxn();
    this. getWeeklyJioBpTxn();
    this.getMonthlyJioBpTxn() ;

    if (this.marchantid) {
      this.getFastagWalletbalance();
      // this.getFastagWalletEncryptDetails();
      // this.getDailyFastagTxnEncryptDetails();
      // this.getWeeklyFastagTxnEncryptDetails();

      // this.getFastagTxnEncryptDetails();
      this.getFastagDailyTxn();
      this.getFastagWeeklyTxn();
      this.getFastagMonthlyTxn();

    }

    this.getRechargeTxnHistory();
    // this.getDailyFastagTxnEncryptDetails();
    // this.getWeeklyFastagTxnEncryptDetails();

    // this.getErpNextTransactionHistory();
    // this.bpclFuelBalance();
    // this.getBpclTransactionHistory();


    // this.getFastagTransaction();
    // this.getLoggedInUser();


    this.getLoggedInUser();





  }





  // getInsuranceToken(){
  //   let body={
  //     "clientid": "197786",
  //     "appid":"57890198",
  //     "slt": "ytrgfb34",
  //     "hashval": "b0aa9017d743e4eef91362acb4c370d63da1f6bb54811f9e7f3019c6ae1fefeea73cd88d83927377c3c9b17dba15873cae762dfa82f221d94bdc07f31ccfcfba"
  // }

  // this._erpService.getInsuranceToken(body).subscribe(res=>{
  //   console.log("getInsuranceToken--->",res);

  //   if(res['data'].token && res['errorCode']=='0'){
  //     localStorage.setItem('InsuranceToken',res['data'].token);
  //   }

  // })

  // }





  getLoggedInUser() {
    this._erpService.getUserDetails().subscribe((res) => {
      // console.log(res);
      if (res["data"] === '') {
        this.logout();
      }
    })
  }
  logout() {
    this.router.navigate(['/login']);
    localStorage.removeItem('userName');
    localStorage.removeItem('apiKey');
    localStorage.removeItem('apiSecret');
    localStorage.removeItem('cartItemsList');
    localStorage.removeItem('cartItemsWithCredit');
    localStorage.removeItem('rzp_device_id');
    localStorage.removeItem('email');
    localStorage.removeItem('cartItems4Invoice');
    // localStorage.removeItem('bpclSfaAccessToken');
    localStorage.removeItem('userRole');
    localStorage.removeItem('authModule');
    localStorage.removeItem('FAID');
    localStorage.removeItem('FCID');
    localStorage.removeItem('itemGroup');
    localStorage.removeItem('customer');
    // localStorage.removeItem('bpclFAAccessToken');
    // localStorage.removeItem('fasTagCustomerId');
  }

  // For getting No of Expiring Policies//


  getCustomerId() {
    this.dataLoading = true;
    this._erpService.getcustomerId().subscribe(res => {
      // console.log("getcustomer Id--->", res);
      if (res['errorCode'] == '0' && res['data']) {
        // this.mobile_no=res['data']['mobile_no'].trim();

        this.insurance_opt_id = res['data']['insurance_operator_id'];
        localStorage.setItem("insoptid", this.insurance_opt_id);
        this.insuranceExpStatus();
      }
      else {
        this.dataLoading = false;
      }

    })
  }

  insuranceExpStatus() {

    // console.log(this.insurance_opt_id);
    // console.log(this.insurance_token);
    if (this.insurance_opt_id && this.insurance_token) {




      let customparams = `?custid=${this.insurance_opt_id}&token=${this.insurance_token}`;
      // console.log(customparams);


      this._erpService.getInsuExpPol(customparams).subscribe(res => {
        // console.log("insurance exp pol responses--->", res);

        if (res['errorCode'] == 0 && res['data']) {

          this.insExpPolData = res['data']["insexpdet"];
          // console.log( this.insExpPolData);


          let remove_index;

          for (let i in this.insExpPolData) {
            let polExpDays = this.insExpPolData[i]['polexpires'];
            // console.log(polExpDays);


            if (polExpDays > 0 && polExpDays <= 30) {
              // console.log("*****",polExpDays);
              // remove_index = this.insExpPolData.indexOf(this.insExpPolData[i]);

              this.no_of_exp_pol = this.no_of_exp_pol + 1;
              // this.insExpPolData.splice(remove_index+1, 1);


            }
            this.dataLoading = false;







          }


        }


      })
    }
    else {
      this.dataLoading = false;
    }


  }

  //************************************************************************************** */




  getBPCLSFAToken() {
    this._erpService.getBPCLSFAAuthentication().subscribe(res => {
      // console.log("bpcl SFA token--->", res);
      if (res["errorCode"] == "0") {
        this.bpclSfaToken = res["data"]["access_token"];
        localStorage.setItem("bpclSFA Token", this.bpclSfaToken)
        this.bpclFuelBalance();
        this.getBpclTransactionHistory();

      }

    })
  }

  login() {

    let formData = {
      "usr": environment.usr,
      "pwd": environment.pwd
    }



    // this.dataLoading = true;
    this._erpService.login(formData).subscribe((res) => {
      // console.log("loginResponse--->", res);

      if (res["errorCode"] == '0') {
        this.AuthToken = `token ${res["data"]["message"].api_key}:${res["data"]["message"].api_secret}`;
        // console.log("token--->", this.AuthToken);
        // this.getCreditBal(this.AuthToken);
        // this.getOverdueAmt(this.AuthToken);

        // this.checkExistVehicleNo();

      } else {

        // console.log("loginerror");

      }
    }
    );
  }
  getJioBpBalance(){
    let body={
      // customer:'UBS Intermediary'
      customer:this.customer
    }


    this._erpService.getJioBPCardBalDetails(body).subscribe((res:any)=>{
      // console.log("getCardDetails-->",res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {


          if (res['data'].message.card_details != 'No record found') {
            this.JioBpBalance=res.data.message.total_card_balance;


      }
      else{
        this.JioBpBalance=0
      }
    }
  })

  }


  BPCLWalletEvent(isChecked: boolean) {
    if (isChecked) {
      this.isBPCLWallet = true;
      // console.log(this.BPCL_Recharge);
    }
    else {
      this.BPCL_Recharge =0;

      this.form.patchValue({
        bpclAmt: this.BPCL_Recharge
      })



      this.isBPCLWallet = false;
      this.isShow = false;
      if((this.BPCL_Recharge ||  this.IOCL_Recharge || this.Fastag_Recharge)<=0){
        this.showButton=false
      }
    }

  }

  IOCLWalletEvent(isChecked: boolean) {
    if (isChecked) {
      this.isIOCLWallet = true;
    }
    else {
      this.IOCL_Recharge=0;
      this.form.patchValue({
        ioclAmt:  this.IOCL_Recharge
      })

      this.isIOCLWallet = false;
      this.isShow = false;
      if((this.BPCL_Recharge || this.IOCL_Recharge || this.Fastag_Recharge)<=0){
        this.showButton=false
      }
    }

  }

  FastagWalletEvent(isChecked: boolean) {
    if (isChecked) {
      this.isFastagWallet = true;
    }
    else {
      this.Fastag_Recharge=0;
      this.form.patchValue({
        fastagAmt:  this.Fastag_Recharge
      })

      this.isFastagWallet = false;
      this.isShow = false;
      if((this.BPCL_Recharge ||  this.IOCL_Recharge || this.Fastag_Recharge)<=0){
        this.showButton=false
      }
    }
  }

  fullWithdrawEvent(isChecked: boolean) {
    if (isChecked) {
      // console.log("Rec Amt In fullWithdrawEvent ", this.receivableAmt);
      this.isAllow = false;

      this.withDrawAmt = this.receivableAmt;
      this.isPartialWithdraw = false;
      this.isFullWithdraw = true;
      if (this.withDrawAmt > 0) {
        this.isWithdraw = true;
      }
      else {
        this.isWithdraw = false;
      }
    }
    else {
      this.isWithdraw = false;
      // this.isIoclwithdraw= false;
      // this.isShow = false;getFastagCustomerDetails
    }
  }

  partialWithdrawEvent(isChecked: boolean) {
    // console.log( this.withDrawAmt);
    // this.withDrawAmt=0;
    console.log(this.partialWithdrawAmount);
    console.log(this.withDrawAmt);

    this.withDrawAmt = this.partialWithdrawAmount




    if (isChecked && (this.withDrawAmt <= 0 || this.withDrawAmt > this.receivableAmt)) {

      this.isPartialWithdraw = true;
      this.isFullWithdraw = false;
      this.isWithdraw = false;
      if (this.withDrawAmt > this.receivableAmt) {
        this.isAllow = true;
      }



    }
    else {

      this.isWithdraw = true;
      this.isPartialWithdraw = true;
      this.isFullWithdraw = false;

    }


  }

  partialWithdrawAmt(event) {
    this.partialWithdrawAmount = 0
    let value = event.target.value;
    // console.log("ioclvalue--->", value);
    if (value > 0) {
      console.log("ioclvalue--->", value);
      this.isWithdraw = true;
      this.partialWithdrawAmount = value;

      this.withDrawAmt = parseInt(value);
      this.totalWithdrawAmt(value);


    }
    else {
      this.isAllow = false;
      this.isWithdraw = false;
    }
    // else if(value==""){
    //   this.isWithdraw=false;
    //   this.withDrawAmt=0;
    //   this.totalWithdrawAmt();
    // }


  }

  bpclRechargeAmt(event) {


    let value = event.target.value;
    console.log(value);


    if (value) {
      // console.log(value);
      this.BPCL_Recharge = parseInt(value);
      this.totalRecharge();

    }
    else if (value == "") {

      this.BPCL_Recharge = 0;
      this.totalRecharge();
    }
    else if (value < 0) {
      this.showButton = false;
    }
  }




  ioclRechargeAmt(event) {
    // this.showButton = false;

    let value = event.target.value;
    // console.log(value);


    if (value) {
      // console.log(value);
      this.IOCL_Recharge = parseInt(value);

      this.totalRecharge();
    }
    else if (value == "") {

      this.IOCL_Recharge = 0;

      this.totalRecharge();

    }
    else if (value < 0) {
      this.showButton = false;
    }
  }

  fastagRechargeAmt(event) {


    let value = event.target.value;

    // if (value) {
    //   // console.log(value);
    //   this.Fastag_Recharge = parseInt(value);

    //   this.totalRecharge();
    // }

    // console.log(value);

    if (value) {
      // console.log(value);
      this.Fastag_Recharge = parseInt(value);

      this.totalRecharge();
    }
    else if (value == "") {

      this.Fastag_Recharge = 0;

      this.totalRecharge();

    }
    else if (value < 0) {
      this.showButton = false;
    }
  }

  totalRecharge() {

    // console.log("Bpcl recharge---->", this.BPCL_Recharge);
    // console.log("IOCL Recharge-/-->, this.IOCL_Recharge);
    // console.log("Fastag Recharge--->", this.Fastag_Recharge);
    if ((this.BPCL_Recharge || this.IOCL_Recharge || this.Fastag_Recharge) > 0) {


      let totalRechargeAmt = this.BPCL_Recharge + this.IOCL_Recharge + this.Fastag_Recharge;
      // console.log("total Recharge Amount------>", totalRechargeAmt);
      this.totalRechargeAmt = totalRechargeAmt;


      if (totalRechargeAmt > this.lnderBalance) {

        this.isShow = true;
      }
      else {
        this.isShow = false;
        // this.getBpclFuelRecharge();
        // this.getIoclFuelRecharge();
      }

      if (this.isBPCLWallet && this.BPCL_Recharge > 0 && !this.isShow) {
        this.showButton = true;
      }
      else if (this.isIOCLWallet && this.IOCL_Recharge > 0 && !this.isShow) {
        this.showButton = true;
      }
      else if (this.isFastagWallet && this.Fastag_Recharge > 0 && !this.isShow) {
        this.showButton = true;
      }


      else {
        this.showButton = false;
      }
    }
    else {
      this.showButton = false;
    }

  }


  totalWithdrawAmt(value) {
    //  console.log( this.receivableAmt);
    // this.receivableAmt
    let totalAmt = this.withDrawAmt;
    if (totalAmt > this.receivableAmt) {
      this.isAllow = true;

    }
    else {
      this.isAllow = false;
    }

    if (totalAmt > 0 && !this.isAllow && value > 0) {
      this.isWithdraw = true;
    }
    else {
      this.isWithdraw = false;
    }
  }

  validateWithdrawResponse() {

    if (this.withDrawBalResponse && this.withDrawAmt > 0) {
      this.isLoadwithDraw = false;
      this.isWithdrawSuccess = "yes";
      //
    }
    else if (!this.withDrawBalResponse) {
      this.isLoadwithDraw = false;
      this.isWithdrawSuccess = "no";

    }
    else {
      this.isWithdrawSuccess = "no";
      this.isLoadwithDraw = false;
    }


  }


  // getWithDrawBal

  getWithdrawBal() {
    let customer = this.customer;
    let amount = this.withDrawAmt;

    this._erpService.getWithDrawBal(customer, amount).subscribe(res => {
      // console.log("withdraw res--->", res);

      if (res["errorCode"] == "0" && res["data"]["message"]) {
        // console.log("Withdraw Bal response---->", res);
        this.withDrawBalResponse = res["data"]["message"]["success"];
        // console.log(this.withDrawBalResponse);
        this.validateWithdrawResponse();
        // this.isLoadwithDraw=false;




      }
      else {
        // this.isLoadwithDraw=false;
      }

    })
  }


  getLnderBal() {
    // console.log("credit Balance in for lnderBal--->", this.creditBal);
    // console.log("receivable amount for lnderBal--->", this.receivableAmt)


    // this.lnderBalance = this.receivableAmt + this.creditBal;
    this.lnderBalance1= this.lnderBalance.toString();


  }


  onwithDraw() {

    this.isLoadwithDraw = true;
    // this.isWithdrawSuccess="";
    if (!this.isAllow) {

      // console.log( "On with Draw---->",this.receivableAmt);
      this.getWithdrawBal();
      this.getReceviableAmount();

      // setTimeout(() => {
      //   location.reload();

      // }, 3000)
    }

  }



  // resetWithdrawForm() {
  //   // location.reload();
  //   // window.location.reload();
  //   // this.isFullWithdraw=false;
  //   // this.resetWithdrawForm.reset();
  // }

  resetRechargeForm() {

    this.form.reset();
    // document.getElementById("vehicle1"). = false;

    this.isBPCLWallet= false;
    this.isIOCLWallet= false;
    this.isFastagWallet= false;

    // console.log(this.form.value);


    // this.BPCL_Recharge = 0;
    // this.isBPCLWallet=false;
    // this.form.setValue({bpclAmt:0,ioclAmt:0});
    //  location.reload();



  }



  rechargeValidation() {

    // if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 0 && this.fastag_recharge_response==0)) {
    //   // console.log("**********ERROR*******************");

    //   // this.success=false;
    //   // this.success_error=false;
    //   this.isLoad = false;
    //   // this.error = true;
    //   this.isError = "error";


    //   // this.success=false;


    // }
    // else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 0) || (this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 1)) {
    //   if (this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 0) {
    //     this.partial_success = "bpcl";
    //   }
    //   else if (this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 1) {
    //     this.partial_success = "iocl";
    //   }
    //   // console.log("**********ERROR-SUCCESS*******************");
    //   // this.modelId="success-error"
    //   // this.success=false;
    //   // this.error=false;
    //   this.isLoad = false;
    //   // this.success_error=true;

    //   this.isPartialsuccess = "P_Success";

    // }
    // else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 1) && (this.BPCL_Recharge > 0 && this.IOCL_Recharge > 0)) {
    //   // console.log("**********SUCCESS*******************");
    //   // this.modelId="success";
    //   // this.error=false;
    //   // this.success_error="parial_success";
    //   this.isLoad = false;
    //   // this.success = true;
    //   this.isSuccess = "success"

    // }


    //new calculation
    console.log("this.bpcl_recharge_response--->", this.bpcl_recharge_response);
    console.log("this.iocl_recharge_response--->", this.iocl_recharge_response);
    console.log("this.fastag_recharge_response-->", this.fastag_recharge_response);



    if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == undefined)) {
      this.onlyBpcl = "bpcl_recharge_response == '1' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 1 && this.fastag_recharge_response == undefined)) {
      this.onlyIocl = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '1' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 1)) {
      this.onlyFastag = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='1'"
    }



    if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == undefined)) {
      this.failedBpcl = "bpcl_recharge_response == '0' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 0 && this.fastag_recharge_response == undefined)) {
      this.failedIocl = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '0' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 0)) {
      this.failedFastag = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='0'"
    }





    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == undefined)) {
      this.BpclnIocl = "bpcl_recharge_response == '1' && iocl_recharge_response == '1' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 1)) {
      this.BpclnFasatg = "bpcl_recharge_response == '1' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='1'";
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 1)) {
      this.IoclnFasatg = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '1' && fastag_recharge_response=='1'";
    }




    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == undefined)) {
      this.failedBpclnIocl = "bpcl_recharge_response == '0' && iocl_recharge_response == '0' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 0)) {
      this.failedBpclnFasatg = "bpcl_recharge_response == '0' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='0'";
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 0)) {
      this.failedIoclnFasatg = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '0' && fastag_recharge_response=='0'";
    }




    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == undefined)) {
      this.f_bpcl_s_iocl = "bpcl_recharge_response == '0' && iocl_recharge_response == '1' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == undefined)) {
      this.s_bpcl_f_iocl = "bpcl_recharge_response == '1' && iocl_recharge_response == '0' && fastag_recharge_response=='undefined'"
    }
    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 1)) {
      this.f_bpcl_s_fastag = "bpcl_recharge_response == '0' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='1'";
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == undefined && this.fastag_recharge_response == 0)) {
      this.s_bpcl_f_fastag = "bpcl_recharge_response == '1' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='0'";
    }

    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 1)) {
      this.f_iocl_s_fastag = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '0' && fastag_recharge_response=='1'";
    }
    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 0)) {
      this.s_iocl_f_fastag = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == '1' && fastag_recharge_response=='0'";
    }




    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 0)) {
      this.S_bpcl_f_ioclnfastag = "bpcl_recharge_response == '1' && iocl_recharge_response == '0' && fastag_recharge_response=='0'"
    }
    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 0)) {
      this.S_iocl_f_bpclnfastag = "bpcl_recharge_response == '0' && iocl_recharge_response == '1' && fastag_recharge_response=='0'"
    }
    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 1)) {
      this.S_fastag_f_bpclniocl = "bpcl_recharge_response == '0' && iocl_recharge_response == '0' && fastag_recharge_response=='1'"
    }

    else if ((this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 1)) {
      this.S_ioclnfatsg_f_bpcl = "bpcl_recharge_response == '0' && iocl_recharge_response == '1' && fastag_recharge_response=='1'"
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 1)) {
      this.S_fastagnbpcl_f_iocl = "bpcl_recharge_response == '1' && iocl_recharge_response == '0' && fastag_recharge_response=='1'";
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 0)) {
      this.S_bpclniocl_f_fastag = "bpcl_recharge_response == '1' && iocl_recharge_response == '1' && fastag_recharge_response=='0'";
    }







    else if ((this.bpcl_recharge_response == undefined && this.iocl_recharge_response == undefined && this.fastag_recharge_response == undefined) || (this.bpcl_recharge_response == 0 && this.iocl_recharge_response == 0 && this.fastag_recharge_response == 0)) {
      this.allRchrgError = "bpcl_recharge_response == 'undefined' && iocl_recharge_response == 'undefined' && fastag_recharge_response=='undefined'";
    }
    else if ((this.bpcl_recharge_response == 1 && this.iocl_recharge_response == 1 && this.fastag_recharge_response == 1)) {
      this.allRchargeSuccess = "bpcl_recharge_response == '1' && iocl_recharge_response == '1' && fastag_recharge_response=='1'";
    }














  }

  getBpclFuelRecharge() {
    let customer = this.customer;
    let amount = this.BPCL_Recharge;

    // this.bpcl_recharge_response = 1;
    // // this.bpcl_recharge_response=undefined;

    //   setTimeout(() => {
    //   this.rechargeValidation();
    // }, 1000)

    this._erpService.getBpclFuelRecharge(customer, amount).subscribe(res => {
      console.log("getBpclFuelRechargeres-->", res);


      if (res["errorCode"] == "0" && res["data"]) {
        // console.log("bpcl fuel recharge response---->", res);
        this.bpcl_recharge_response = res["data"]["message"]["success"];
        this.bpclRechResMsg= res["data"]["message"]["msg"]
        console.log(this.bpclRechResMsg);

        setTimeout(() => {
          this.rechargeValidation();
        }, 1500)
      //   return new Promise((resolve:any, reject:any) => {
      //     console.log('f1');
      //     resolve();
      // });

        this.getCreditBal();





      }

    })


  }

  getIoclFuelRecharge() {
    let customer = this.customer;
    let amount = this.IOCL_Recharge;

    // this.iocl_recharge_response = 1;
    // // this.iocl_recharge_response=undefined;

    //   setTimeout(() => {
    //   this.rechargeValidation();
    // }, 1000)

    this._erpService.getIoclFuelRecharge(customer, amount).subscribe(res => {
      console.log(" getIoclFuelRecharge()-->", res);


      if (res["errorCode"] == "0" && res["data"]) {
        // console.log("iocl fuel recharge response---->", res);
        this.iocl_recharge_response = res["data"]["message"]["success"];
        // console.log(this.iocl_recharge_response);
        this.ioclRechResMsg= res["data"]["message"]["msg"];
        console.log(this.ioclRechResMsg);

        setTimeout(() => {
          this.rechargeValidation();
        }, 1000)




      }

    })




  }


  getFastagRecharge() {

    let customer = this.customer;
    let amount = this.Fastag_Recharge;


    // this.fastag_recharge_response = 0;
    // // this.fastag_recharge_response = undefined;


    //   setTimeout(() => {
    //   this.rechargeValidation();
    // }, 1000)




    this._erpService.getFastagRechargeRes(customer, amount).subscribe(res => {
      console.log(" getFasatgRecharge()-->", res);


      if (res["errorCode"] == "0" && res["data"]) {
        // console.log("fastag recharge response---->", res);
        this.fastag_recharge_response = res["data"]["message"]["success"];
        this.fastagRechResMsg=res["data"]["message"]['msg']
        console.log(this.fastagRechResMsg);
        setTimeout(() => {
          this.rechargeValidation();
        }, 1000)




      }

    })




  }




  onRecharge() {

    this.isLoad = true;

    // this.getReceviableAmount();



    // this.isError = "";
    // this.isPartialsuccess = "";
    // this.isSuccess = "";
    // this.partial_success = "";
    if (!this.isShow) {
      // console.log("==============================");
      // var delay=document.getElementById('rechargePopUp');

      if (this.BPCL_Recharge > 0) {
        this.getBpclFuelRecharge();
      }
      if (this.IOCL_Recharge > 0) {

        this.getIoclFuelRecharge();
      }
      if (this.Fastag_Recharge > 0) {
        this.getFastagRecharge();
      }


        // setTimeout(() => {
        //   this.rechargeValidation();
        // }, 3000)









      // setTimeout(() => {
      //   location.reload();

      // }, 5000);


    }

  }


  // Recharge Txn History

  getRechargeTxnHistory(){

    let data = {
      // "args": {
      //   "customer": this.customer,
      //   "start_date": this.monthlyDate,
      //   "end_date": this.todayDate
      // }

      "args": {
        "customer": this.customer,
        "start_date": this.yearagoDate,
        "end_date": this.todayDate
      }
    }
    // console.log(data);


    this._erpService.s_getRechargeTxnHistory(data).subscribe((res:any)=>{
      // console.log("getRechargeTxnHistory()-->",res);
      // res['data'].message=[]

      if (res["errorCode"]=="0" && res['data'].message != 'No record found' && res['data'].message.length > 0) {


       let responsedata= res['data'].message;
        for (let i = 0; i < responsedata.length; i++) {
          let date = responsedata[i]['creation'].slice(0, 10);
          let time = responsedata[i]['creation'].slice(11, 19);
          // console.log(date);
          // console.log(time);


          responsedata[i]['Date'] = date;
          responsedata[i]['Time'] = time;

        }
        const filterdate=responsedata.map(obj=>{
          return {...obj, date: new Date(obj.Date)};
        })

        this.rechargeTransactionData=filterdate.sort((a,b)=>
          Number(b.date)-Number(a.date)
        )
        // console.log(  this.rechargeTransactionData);


        for (let i = 0; i < this.rechargeTransactionData.length; i++) {


         let recharge_type= this.rechargeTransactionData[i].recharge_type.toLowerCase();
        //  console.log(recharge_type);


         if(recharge_type==="bpcl recharge"){
          this.bpclRechargeHistory.push(this.rechargeTransactionData[i]);

         }
         else if(recharge_type==="iocl recharge"){
          this.ioclRechargeHistory.push(this.rechargeTransactionData[i]);

         }
         else if(recharge_type==="fastag recharge"){
          this.fastagRechargeHistory.push(this.rechargeTransactionData[i]);

         }
         else if(recharge_type==="jio bp recharge"){
          this.jioBpRechargeHistory.push(this.rechargeTransactionData[i]);

         }

        }





        this.bpclRechargeHistoryDatalength=this.bpclRechargeHistory.length;
        this.ioclRechargeHistoryDatalength=this.ioclRechargeHistory.length;
        this.fastagRechargeHistoryDatalength=this.fastagRechargeHistory.length;
        this.jioBpRechargeHistoryDatalength=this.jioBpRechargeHistory.length;

        if( this.bpclRechargeHistoryDatalength==0){
          this.nobpclRechargeTxnData=true;
        }
        if( this.ioclRechargeHistoryDatalength==0){
          this.noioclRechargeTxnData=true;
        }
        if( this.fastagRechargeHistoryDatalength==0){
          this.nofastagRechargeTxnData=true;
        }
        if( this.jioBpRechargeHistoryDatalength==0){
          this.noJioBpRechargeTxnData=true;
        }

        // console.log( this.bpclRechargeHistory);
        // console.log( this.ioclRechargeHistory);
        // console.log( this.fastagRechargeHistory);




        // this.rechargeTransactionDatalength = this.rechargeTransactionData.length;
        // this.isRechargeTxn = false;
        this.isbpclRechargeTxn=false;
        this.isioclRechargeTxn=false;
        this.isfastagRechargeTxn=false;
        this.isJioBpRechargeTxn=false;

      }
      else {

        this.isbpclRechargeTxn=false;
        this.isioclRechargeTxn=false;
        this.isfastagRechargeTxn=false;
        this.isJioBpRechargeTxn=false;

        this.noJioBpRechargeTxnData=true;
        this.nobpclRechargeTxnData=true;
        this.noioclRechargeTxnData=true;
        this.nofastagRechargeTxnData=true;

        // this.isRechargeTxn = false;
        // this.noRechargeTxnData = true;
      }

    })
  }





  refresh() {
    location.reload();
    // this.Fastag_List.reset()
  }






  // getBpclAccessToken(){
  //   this._erpService.getBPCLSFAAuthentication().subscribe(res=>{
  //     console.log("token--->",res);
  //     if(res["errorCode"]=="0"){
  //       this.bpclSFAAccessToken=res["data"].access_token;
  //       console.log(this.bpclSFAAccessToken);

  //       window.localStorage.setItem("bpclAccessToken",this.bpclSFAAccessToken)
  //     }


  //   })
  // }

  // getErpNextTransactionHistory() {
  //   // let transactionParams = `?fields=["name","fc_id","transaction_user_id","transaction_category","transaction_date"]&filters=[["fc_id","=","${this.fc_id}"],["transaction_user_id","=","${this.customer}"],["transaction_category","=","SALE"]]&limit_page_length=1&order_by=transaction_date desc`;
  //     let transactionParams = `?fields=["name","fc_id","creation","retail_outlet_name","discount_amount","transaction_type","discount_pct","transaction_user_id","lnder_item_name","transaction_amount","source","transaction_date"]&filters=[["fc_id","=","${this.fc_id}"],["transaction_user_id","=","${this.customer}"],["transaction_category","=","SALE"]]&limit_page_length=Infinity&order_by=creation desc`;
  //   // console.log(transactionParams);
  //   // this.dataLoading = true;
  //   this._erpService.getErpNextTransactionHistory(transactionParams).subscribe((res) => {
  //     // console.log(res);
  //     if (res["errorCode"] == '0') {
  //       // this.error = false;
  //       // this.errorMessage = "";
  //       console.log("Erp txn history------>", res);

  //       // this.dataLoading = false;
  //       this.lastTransactionList = res["data"];
  //       console.log("LastTransactionList---->", this.lastTransactionList);

  //       // setTimeout(() => {
  //       this.getBpclTransactionHistory();
  //       // }, 1000);
  //     } else {
  //       // this.error = true;
  //       // this.errorMessage = res["errorMessage"];
  //       // this.dataLoading = false;
  //       console.log("error");

  //     }
  //   },

  //   );
  // }

  getBpclTransactionHistory() {
    let testDate1;
    let bpclFromDate;
    let bpclToDate;
    let yesterdayDate;


    this.isBpclTxn = true;


    let bpclBodyData = {
      "accountId": this.fa_id,
      "reportType": "CONSOLIDATED",
      "fromDate": "",
      // "fromDate":this.lastTransactionList.,
      "toDate": "",
      "page": "0",
      "sort": "transactionDate-desc",
      "searchText": "SALE",
      "pageSize": "500",
      "cardIds": ""
    }
    // console.log(this.fa_id);


    this._erpService.getBPCLTransactionHistory(bpclBodyData).subscribe(res => {

      // console.log("Bpcl Txn History Response--->", res);

      // console.log("bpclTransaction histroy", res["data"]["reportData"]);
      if (res["errorCode"] == "0" && res["data"]) {
        if (res["data"]["reportData"] != undefined && res["data"]["reportData"].length > 0) {
          this.BPCL_List = res["data"]["reportData"];
          for (let i = 0; i < this.BPCL_List.length; i++) {
            let date = this.BPCL_List[i].createdDT.slice(0, 10);
            let time = this.BPCL_List[i].createdDT.slice(11, 19);
            // console.log(date);
            // console.log(time);

            this.BPCL_List[i]['Date'] = date;
            this.BPCL_List[i]['Time'] = time;
          }
          this.isBpclTxn = false;
          this.bpclDatalength = this.BPCL_List.length;
          // console.log(this.bpclDatalength);

        }
        else {
          console.log("***************************");

          this.isBpclTxn = false;
          this.bpclNoData = true;

        }

        // console.log("bpclList----->", this.BPCL_List);
        // this.dataLoading=false;


      }
      else {
        this.bpclNoData = true;
      }
    })

  }
  // getTime() {
  //   throw new Error('Method not implemented.');
  // }
  // setTime(arg0: number) {
  //   throw new Error('Method not implemented.');
  // }




  // From BPCL SERVER
  bpclFuelBalance() {
    this._erpService.getBpclFuelBalance().subscribe(res => {
      console.log("bpclFuelBalanceResponse---->", res);
      let linkedAccounts = res["data"]["linkedAccounts"];
      // console.log("bpclFuelBalance----->", linkedAccounts);


      if (res["errorCode"] == "0") {


        for (let data of linkedAccounts) {

          if (data["accountId"] === this.fa_id) {
            this.bpclfuelBalance = data["cmsWalletBal"];
            // console.log(this.bpclfuelBalance);


          }
        }


      }
      else {
        // console.log("bpcl fuel balance error");

      }

    })
  };

// From ERP SERVER
  getBpclFuelBal(){
    this._erpService.FromErpGetBpclFuelBal(this.fa_id).subscribe((res)=>{
      // console.log("BPCL Balance",res)
      if (res["errorCode"] == "0" && res["data"]['message']>0 ) {
        this.bpclfuelBalance =res["data"]['message']
      }
      else{
        this.bpclfuelBalance = 0
      }
    })
  }



  getReceviableAmount() {

    let customer = this.customer;
    var email = localStorage.getItem("email")

    this._erpService.getReceviableAmt(customer).subscribe(res => {
      // console.log("receviable amount response--->", res);

      // console.log("Receivable Amount------>", res["data"]["message"]);
      // console.log("Receivable Amount Response------>", res);
      if (res["errorCode"] == '0' && res["data"]["message"] >= 0) {
        // console.log("Receivable Amount------>", res["data"]);
        // this.withDrawAmt=res["data"]["message"];


        this.receivableAmt = res["data"]["message"];
        // this.receivableAmt = 100000000000;
        this.receivableAmt1=this.receivableAmt.toString();


        // this.receivableAmt=10;
        this.getLnderBal();
        // this.withDrawAmt=this.receivableAmt;

        // this.getLnderBal();


      }
      else {
        // if (email == 'manjunatha.d@codeswift.in') {
        //   setTimeout(()=>{
        //     this.receivableAmt = 10000;
        //     this.getLnderBal();
        //   },1000)

        // }
        // else {
        //   this.receivableAmt = 0;
        // }

        this.receivableAmt = 0;
        this.receivableAmt1=this.receivableAmt.toString();


      }

    })



  }
  //

  getCreditBal() {

    // console.log("token--->",token);

    // let customer = this.customer;

    // this._erpService.getCreditBal(customer).subscribe(res => {
    //   // console.log("Credit Balance------>", res);
    //   if (res["errorCode"] == "0" && res["data"]["message"]) {
    //     this.creditBal = res["data"]["message"];
    //     this.getLnderBal();

    //   }

    // })


    let payload={
      'customer':this.customer
    }
    this._erpService.getCreditBalDetails(payload).subscribe(res => {
      //   // console.log("Credit Balance------>", res);
        if (res["errorCode"] == "0" && res["data"]["message"]) {
          let response=res["data"]["message"]
          this.creditBal = response.credit_limit;
          // this.LnderBalance=response.balance;
            this.lnderBalance=response.balance;
            this.lnderBalance1= this.lnderBalance.toString();
          this.CashBalance=response.cash_balance

          // this.getLnderBal();

        }

      })



  }


  //getOverdueAmount()

  getOverdueAmt() {



    let customer = this.customer;

    this._erpService.getOverdueAmount(customer).subscribe((res) => {
      // console.log("getOverdueAmount()-->",res);

      if (res['errorCode'] == '0' && res["data"]["message"]) {
        this.overdueAmt = res["data"]["message"];
      }

    })

  }

  getTotalOustandAmt() {
    let customer = this.customer;
    this._erpService.getTotalOutstandingAmt(customer).subscribe((res) => {
      // console.log("getToatalOutstandingAmt()-->",res);

      if (res['errorCode'] == '0' && res["data"]["message"]) {
        this.totalOutstandAmt = res["data"]["message"];
      }

    })

  }

  getCashBalance() {

    let customer = this.customer;

    this._erpService.getCashBal(customer).subscribe((res) => {
      // console.log("getCashBalance()-->",res);

      if (res['errorCode'] == '0' && res["data"]["message"]) {
        this.cashBal = res["data"]["message"];
      }

    })


  }



  //************ Fastag data daily and weekly and custom from erp-next****************//


  // getFastagDailyTxn() {
  //   this.isDailyFasTxn = true;

  //   // let data={
  //   //   "merchantid":this.marchentId,
  //   //   "from_date":`${this.todayDate}T00:00`,
  //   //   "to_date":`${this.todayDate}T23:59`,
  //   //   "walletid":this.walletId

  //   // }

  //   let data={
  //     "merchantid":this.marchentId,
  //     "from_date":`20230102T00:00`,
  //     "to_date":`20230102T23:59`,
  //     "walletid":this.walletId

  //   }
  //   // console.log("Fastag Daily Txn Body--->",data);


  //   this._erpService.getFastagTollTxn(data).subscribe((res) => {
  //     // console.log(" getFastagDailyTxn()---->",res);
  //     if (res['errorCode'] == '0' && res['data']) {

  //       if (res['data'].message != 'No record found') {
  //         // this.dailyTransaction=res['data'].expenditure.daily_transaction;
  //         // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;




  //         // // this.dailyTransaction=res['data'].expenditure.daily_transaction;
  //         // // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
  //         this.dailyFastagTransactionData = res['data'].message.reverse();

  //         for(let i=0; i<this.dailyFastagTransactionData.length;i++){
  //           let date= this.dailyFastagTransactionData[i]['reqTime'].slice(0,10);
  //           let time=this.dailyFastagTransactionData[i]['reqTime'].slice(11,19);
  //           // console.log(date);
  //           // console.log(time);


  //           this.dailyFastagTransactionData[i]['Date']=date;
  //           this.dailyFastagTransactionData[i]['Time']=time;

  //         }

  //         // console.log(this.dailyFastagTransactionData);
  //         this.dailyFastagDatalength = this.dailyFastagTransactionData.length
  //         this.isDailyFasTxn = false;
  //       }
  //       else {
  //         this.isDailyFasTxn = false;
  //         this.noDailyFasData = true;
  //       }



  //     }

  //   })
  // }

  //weeklyTxn

  // getFastagWeeklyTxn() {
  //   this.isWeeklyFasTxn = true;
  //   this.dataLoading = true;

  //   let todayDate = new Date();
  //   let lastSevenDaysDate = new Date(todayDate.getTime() - (6 * 24 * 60 * 60 * 1000));
  //   let lastSevendaysMon = (lastSevenDaysDate.getMonth() + 1).toString();
  //   let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
  //   let last_Seven_days_Date;
  //   // console.log("last 7 days Mon-->",lastSevendaysMon);
  //   //from date validation
  //   if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
  //     // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
  //   }
  //   else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
  //     // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
  //   }
  //   else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
  //     // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
  //   }

  //   else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
  //     // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
  //     last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
  //     this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
  //   }

  //   // console.log("last seven days date--->", last_Seven_days_Date);




  //   // let data = {
  //   //   "merchantid":this.marchentId,
  //   //   "from_date":`${last_Seven_days_Date}T00:00`,
  //   //   "to_date":`${this.todayDate}T23:59`,
  //   //   "walletid":this.walletId
  //   // }

  //   let data = {
  //     "merchantid": this.marchentId,
  //     "from_date": `20221227T00:00`,
  //     "to_date": `20230102T23:59`,
  //     "walletid": this.walletId

  //   }
  //   // console.log("Fastag Weekly Txn Body--->",data);

  //   this._erpService.getFastagTollTxn(data).subscribe((res) => {
  //     // console.log(" getFastagWeeklyTxn()---->",res);
  //     if (res['errorCode'] == '0' && res['data']) {

  //       // this.dataLoading=false;
  //       if (res['data'].message != 'No record found' && res['data'].message.length > 0) {

  //         // console.log(res['data'].message[0].response)
  //         // var x = res['data'].message[0].response;
  //         // console.log(Object.keys(x),Object.values(x));



  //         // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
  //         // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
  //         this.weeklyFastagTransactionData = res['data'].message.reverse();

  //         for (let i = 0; i < this.weeklyFastagTransactionData.length; i++) {
  //           let date = this.weeklyFastagTransactionData[i]['reqTime'].slice(0, 10);
  //           let time = this.weeklyFastagTransactionData[i]['reqTime'].slice(11, 19);
  //           // console.log(date);
  //           // console.log(time);


  //           this.weeklyFastagTransactionData[i]['Date'] = date;
  //           this.weeklyFastagTransactionData[i]['Time'] = time;

  //         }
  //         // console.log(this.weeklyFastagTransactionData);
  //         this.weeklyFastagDatalength = this.weeklyFastagTransactionData.length;
  //         this.isWeeklyFasTxn = false;

  //       }
  //       else {
  //         this.isWeeklyFasTxn = false;
  //         this.noWeeklyFasData = true;
  //       }


  //     }
  //     else {
  //       // this.dataLoading=false;
  //     }

  //   })
  // }




  //monthlyTxn

  getFastagMonthlyTxn() {
    this.isMonthlyFasTxn = true;
    this.dataLoading = true;

    let todayDate = new Date();
    let lastThirtyDaysDate = new Date(todayDate.getTime() - (31 * 24 * 60 * 60 * 1000));
    let lastThirtydaysMon = (lastThirtyDaysDate.getMonth() + 1).toString();
    let lastThirtydaysDay = lastThirtyDaysDate.getDate().toString();
    let last_Thirty_days_Date;
    // console.log("last 7 days Mon-->",lastSevendaysMon);
    //from date validation
    if ((lastThirtydaysMon.length == 2 && lastThirtydaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + (lastThirtyDaysDate.getMonth() + 1).toString() + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/${(lastThirtyDaysDate.getMonth() + 1).toString()}/${lastThirtyDaysDate.getDate().toString()}`;
    }
    else if (lastThirtydaysMon.length == 1 && lastThirtydaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + '0' + (lastThirtyDaysDate.getMonth() + 1).toString() + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/0${(lastThirtyDaysDate.getMonth() + 1).toString()}/${lastThirtyDaysDate.getDate().toString()}`;
    }
    else if (lastThirtydaysMon.length == 2 && lastThirtydaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + (lastThirtyDaysDate.getMonth() + 1).toString() + '0' + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/${(lastThirtyDaysDate.getMonth() + 1).toString()}/0${lastThirtyDaysDate.getDate().toString()}`;
    }

    else if (lastThirtydaysMon.length == 1 && lastThirtydaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Thirty_days_Date = lastThirtyDaysDate.getFullYear().toString() + '0' + (lastThirtyDaysDate.getMonth() + 1).toString() + '0' + lastThirtyDaysDate.getDate().toString();
      this.monthlyDate = `${lastThirtyDaysDate.getFullYear().toString()}/0${(lastThirtyDaysDate.getMonth() + 1).toString()}/0${lastThirtyDaysDate.getDate().toString()}`;
    }

    // console.log("last seven days date--->", last_Seven_days_Date);




    let data = {
      "merchantid": this.marchantid,
      "from_date": `${last_Thirty_days_Date}T00:00`,
      "to_date": `${this.todayDate}T23:59`,
      "walletid": this.walletId

    }
    // console.log("Fastag Weekly Txn Body--->",data);

    this._erpService.getFastagTollTxn(data).subscribe((res) => {
      // console.log(" getFastagWeeklyTxn()---->",res);
      if (res['errorCode'] == '0' && res['data']) {

        // this.dataLoading=false;
        if (res['data'].message != 'No record found' && res['data'].message.length > 0) {

          // console.log(res['data'].message[0].response)
          // var x = res['data'].message[0].response;
          // console.log(Object.keys(x),Object.values(x));



          // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
          // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
          this.monthlyFastagTransactionData = res['data'].message.reverse();

          for (let i = 0; i < this.monthlyFastagTransactionData.length; i++) {
            if(this.monthlyFastagTransactionData[i].reqTime==null){
              this.monthlyFastagTransactionData[i]['reqTime']="NA";
            }

            if(this.monthlyFastagTransactionData[i].vehicleNo==null){
              this.monthlyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.monthlyFastagTransactionData[i].tollplazaname==null){
              this.monthlyFastagTransactionData[i].tollplazaname="NA";
            }


            let date = this.monthlyFastagTransactionData[i]['reqTime'].slice(0, 10);
            let time = this.monthlyFastagTransactionData[i]['reqTime'].slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.monthlyFastagTransactionData[i]['Date'] = date;
            this.monthlyFastagTransactionData[i]['Time'] = time;

          }
          // console.log(this.weeklyFastagTransactionData);
          this.monthlyFastagDatalength = this.monthlyFastagTransactionData.length;
          this.isMonthlyFasTxn = false;

        }
        else {
          this.isMonthlyFasTxn = false;
          this.noMonthlyFasData = true;
        }


      }
      else {
        // this.dataLoading=false;
      }

    })
  }




  //custom Fastag Txn

  onCustomTxn() {
    this.customFastagTransactionData = [];

    this.customFastagForm.value.fromdate = this.customFastagForm.value.fromdate.replace(/-/g, "");
    this.customFastagForm.value.todate = this.customFastagForm.value.todate.replace(/-/g, "");

    // console.log(this.customFastagForm.value);
    // console.log(this.customFastagForm.value.todate.split());

    if (this.customFastagForm.valid) {
      this.p4 = 1;
      this.isCustomFasTxn = true;
      this.noCustomFasData = false;
      // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
      this.getCustomFastagTxn(this.customFastagForm.value);
    }
    // this.customFastagForm.value.fromdate = this.customFastagForm.value.fromdate.replace(/-/g, "");
    // this.customFastagForm.value.todate = this.customFastagForm.value.todate.replace(/-/g, "");

    // // console.log(this.customFastagForm.value);
    // // console.log(this.customFastagForm.value.todate.split());

    // if (this.customFastagForm.valid) {
    //   this.isCustomFasTxn = true;
    //   this.noCustomFasData = false;
    //   this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
    // }




  }


  getCustomFastagTxn(formvalue) {
    let MerId;
    let fromyear = formvalue.fromdate.slice(0, 4);
    let toyear = formvalue.todate.slice(0, 4);
    console.log(fromyear, toyear);

    if ((fromyear && toyear) < 2023) {
      MerId = this.marchentId;

    }
    else {
      MerId = this.marchantid;
    }

    let data = {
      "merchantid": MerId,
      "from_date": formvalue.fromdate,
      "to_date": formvalue.todate,
      "walletid": this.walletId
    }

    console.log("Fastag Custom Txn Body--->", data);

    this.customFastagDatalength = 0;

    this._erpService.getFastagTollTxn(data).subscribe((res) => {
      // console.log(" getFastagcustomTxn()---->",res);
      if (res['errorCode'] == '0' && res['data']) {
        if (res['data'].message != 'No record found') {
          // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;
          this.customFastagTransactionData = res['data'].message;

          for (let i = 0; i < this.customFastagTransactionData.length; i++) {

            if(this.customFastagTransactionData[i].reqTime==null){
              this.customFastagTransactionData[i]['reqTime']="NA";
            }

            if(this.customFastagTransactionData[i].vehicleNo==null){
              this.customFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.customFastagTransactionData[i].tollplazaname==null){
              this.customFastagTransactionData[i].tollplazaname="NA";
            }

            let date = this.customFastagTransactionData[i]['reqTime'].slice(0, 10);
            let time = this.customFastagTransactionData[i]['reqTime'].slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.customFastagTransactionData[i]['Date'] = date;
            this.customFastagTransactionData[i]['Time'] = time;

          }
          // console.log(this.customFastagTransactionData);
          this.customFastagDatalength = this.customFastagTransactionData.length;
          this.isCustomFasTxn = false;

        }
        else {
          // console.log();
          // this.customFastagDatalength=0;
          this.isCustomFasTxn = false;
          this.noCustomFasData = true;
        }

        // console.log(res['data'].message[0].response)
        // var x = res['data'].message[0].response;
        // console.log(Object.keys(x),Object.values(x));



        // this.customFastagTransactionData=res['data'].expenditure.monthly_transaction;



      }

    })

  }
  //************ END  Fastag data daily and weekly and custom from erp-next****************//


  //************ Fastag data daily and weekly  from HDFC **************************************//


  //From HDFC Fastag Daily Txn

  getFastagDailyTxn() {
    this.isDailyFasTxn = true;


    let data = {
      "merchantID": this.marchantid,
      "fromDate": `${this.todayDate} 000000`,
      "toDate": `${this.todayDate} 235959`,
      "walletId": this.walletId,
      "contactNumber": "",
      "vehicleNumber": "",
      "requestSource": ""

    }


    // console.log("Fastag Daily Txn Body--->",data);
    // console.log("this.Fastag_Cust_Id--->",this.Fastag_Cust_Id);


    this._erpService.fromHdfcFastagTollTxn(data, this.Fastag_Cust_Id).subscribe((res) => {
      // console.log(" gethdfcFastagDailyTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']['resMessage'] == 'SUCCESS') {
        let FastagDailyTxn = res['data']['data'];
        if (FastagDailyTxn.length >= 0) {
          // this.dailyTransaction=res['data'].expenditure.daily_transaction;
          // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;




          // // this.dailyTransaction=res['data'].expenditure.daily_transaction;
          // // this.dailyPendingCashback=(0.4/100)*this.dailyTransaction;
          this.dailyFastagTransactionData = FastagDailyTxn.reverse();

          for (let i = 0; i < this.dailyFastagTransactionData.length; i++) {
            if(this.dailyFastagTransactionData[i].reqTime==null){
              this.dailyFastagTransactionData[i]['reqTime']="NA";
            }

            if(this.dailyFastagTransactionData[i].vehicleNo==null){
              this.dailyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.dailyFastagTransactionData[i].tollplazaname==null){
              this.dailyFastagTransactionData[i].tollplazaname="NA";
            }


            let date = this.dailyFastagTransactionData[i]['reqTime']?.slice(0, 10);
            let time = this.dailyFastagTransactionData[i]['reqTime']?.slice(11, 19);
            // console.log(date);
            // console.log(time);


            this.dailyFastagTransactionData[i]['Date'] = date;
            this.dailyFastagTransactionData[i]['Time'] = time;

          }

          // console.log(this.dailyFastagTransactionData);
          this.dailyFastagDatalength = this.dailyFastagTransactionData.length
          this.isDailyFasTxn = false;
        }
        else {
          this.isDailyFasTxn = false;
          this.noDailyFasData = true;
        }



      }
      else {
        this.isDailyFasTxn = false;
        this.noDailyFasData = true;
      }

    })
  }


  // From Hdfc Fastg Weekly
  getFastagWeeklyTxn() {
    this.isWeeklyFasTxn = true;
    this.dataLoading = true;

    let todayDate = new Date();
    let lastSevenDaysDate = new Date(todayDate.getTime() - (6 * 24 * 60 * 60 * 1000));
    let lastSevendaysMon = (lastSevenDaysDate.getMonth() + 1).toString();
    let lastSevendaysDay = lastSevenDaysDate.getDate().toString();
    let last_Seven_days_Date;
    // console.log("last 7 days Mon-->",lastSevendaysMon);
    //from date validation
    if ((lastSevendaysMon.length == 2 && lastSevendaysDay.length == 2)) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 2) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/${lastSevenDaysDate.getDate().toString()}`;
    }
    else if (lastSevendaysMon.length == 2 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
    }

    else if (lastSevendaysMon.length == 1 && lastSevendaysDay.length == 1) {
      // todaysDate = todayDate.getFullYear().toString() + '0' + (todayDate.getMonth() + 1).toString() + '0' + todayDate.getDate().toString();
      last_Seven_days_Date = lastSevenDaysDate.getFullYear().toString() + '0' + (lastSevenDaysDate.getMonth() + 1).toString() + '0' + lastSevenDaysDate.getDate().toString();
      this.weeklyDate = `${lastSevenDaysDate.getFullYear().toString()}/0${(lastSevenDaysDate.getMonth() + 1).toString()}/0${lastSevenDaysDate.getDate().toString()}`;
    }

    // console.log("last seven days date--->", last_Seven_days_Date);




    // let data = {
    //   "merchantid":this.marchentId,
    //   "from_date":`${last_Seven_days_Date}T00:00`,
    //   "to_date":`${this.todayDate}T23:59`,
    //   "walletid":this.walletId
    // }

    let data = {
      "merchantID": this.marchantid,
      "fromDate": `${last_Seven_days_Date} 000000`,
      "toDate": `${this.todayDate} 235959`,
      "walletId": this.walletId,
      "contactNumber": "",
      "vehicleNumber": "",
      "requestSource": ""

    }

    // console.log("Fastag Weekly Txn Body--->",data);

    this._erpService.fromHdfcFastagTollTxn(data, this.Fastag_Cust_Id).subscribe((res) => {
      // console.log(" HdfcgetFastagWeeklyTxn()---->", res);
      if (res['errorCode'] == '0' && res['data']['resMessage'] == 'SUCCESS') {
        let FastagWeeklyTxn = res['data']['data'];
        if (FastagWeeklyTxn.length >= 0) {

          // console.log(res['data'].message[0].response)
          // var x = res['data'].message[0].response;
          // console.log(Object.keys(x),Object.values(x));



          // this.weeklyTransaction=res['data'].expenditure.weekly_transaction;
          // this.weeklyPendingCashback=(0.4/100)*this.weeklyTransaction;
          this.weeklyFastagTransactionData = FastagWeeklyTxn.reverse();
          // console.log(this.weeklyFastagTransactionData);


          for (let i = 0; i < this.weeklyFastagTransactionData.length; i++) {

            if(this.weeklyFastagTransactionData[i].reqTime==null){
              this.weeklyFastagTransactionData[i]['reqTime']="NA";
            }

            if(this.weeklyFastagTransactionData[i].vehicleNo==null){
              this.weeklyFastagTransactionData[i].vehicleNo="NA";
            }
            if(this.weeklyFastagTransactionData[i].tollplazaname==null){
              this.weeklyFastagTransactionData[i].tollplazaname="NA";
            }

            let date = this.weeklyFastagTransactionData[i]['reqTime']?.slice(0, 10);
            let time = this.weeklyFastagTransactionData[i]['reqTime']?.slice(11, 19);
            // console.log(date);
            // console.log(time);
            this.weeklyFastagTransactionData[i]['Date'] = date;
            this.weeklyFastagTransactionData[i]['Time'] = time;

          }

          // console.log(this.weeklyFastagTransactionData);
          this.weeklyFastagDatalength = this.weeklyFastagTransactionData.length;
          this.isWeeklyFasTxn = false;
          // this.dataLoading=false;

        }
        else {

          this.dataLoading = false;
          this.isWeeklyFasTxn = false;
          this.noWeeklyFasData = true;
        }


      }
      else {
        this.dataLoading = false;
        this.isWeeklyFasTxn = false;
        this.noWeeklyFasData = true;
      }

    })
  }









  //************ End Fastag data daily and weekly and from HDFC*********************************//


  ////************************Fetching wallet Balance using ENCRYPT-DECRYPT -method **************//
  // getFastagWalletEncryptDetails() {

  //   this.dataLoading = true;




  //   let data = {



  //     "requestSource": localStorage.getItem("requsetSource"),
  //     "merchantID": this.marchentId,
  //     "uuid": localStorage.getItem("walletid"),

  //   }
  //   console.log(' getFastagWalletEncryptDetails() body--->', data);


  //   this._erpService.getFastagEncryptDetails(data).subscribe(res => {


  //     if (res["errorCode"] == "0") {
  //       console.log("fastagWalletEncryptDetails---->", res['data']);
  //       this.fastagWalletEncryptDetails = res["data"];
  //       // this.getFastagWalletbalance();
  //       this.getFastagWalletbalance();
  //       // this.dataLoading=false;

  //     }
  //     else {
  //       // console.log("encrypt details error");

  //     }

  //   })

  // }

  // getFastagWalletbalance() {
  //   this.dataLoading = true;

  //   // console.log(marchantId);


  //   this._erpService.getFastagWalletBalance(this.fastagWalletEncryptDetails, this.marchentId).subscribe(res => {

  //     if (res["errorCode"] == "0") {
  //       console.log("fastagWalletBalance------>", res["data"]);
  //       this.fastagWalletResponse = res["data"];
  //       this.getFastagDecryptDetails(this.fastagWalletResponse);
  //       // this.dataLoading=false;
  //     }
  //     else {
  //       // console.log("fastag wallet balance error");

  //     }


  //   })

  // }

  // getFastagDecryptDetails(data) {
  //     // this.dataLoading = true;
  //     this.isFastagTxn = true;

  //     this._erpService.getFastagDecryptDetails(data).subscribe(res => {
  //       // console.log("Fastag Txn Response---->", res);

  //       if (res["errorCode"] == "0" && res["data"]) {



  //         if (data == this.fastagWalletResponse) {
  //           // console.log("fastagWalletDecryptDetails------>", res["data"]);

  //           // this.fastagBalance = res["data"]["respData"]["currentBalance"];
  //           // this.fastagBalance=4521
  //           this.fastagWalletResponse = res["data"];
  //           // this.dataLoading=false;
  //         }
  //       }
  //       else {
  //         // console.log("fastga decrypt details error");
  //         this.noData = true;
  //       }


  //     })

  //   }


  ////************************END Fetching wallet Balance using ENCRYPT-DECRYPT -method **************//



  getFastagWalletbalance() {
    this.dataLoading = true;
    // console.log(marchantId);

    let data = {
      "uuid": this.walletId,
      "merchantID": this.marchantid,
      "requestSource": localStorage.getItem("requsetSource")
    }
    // console.log(data);



    this._erpService.getFastagBal(this.Fastag_Cust_Id, data).subscribe(res => {

      if (res["errorCode"] == "0") {
        // console.log("fastagWalletBalance------>", res["data"]);
        this.fastagBalance = res["data"]["currentBalance"];

        this.dataLoading = false;
      }
      else {


      }


    })

  }


  //BPCl Txn History

  /***************************Daily Bpcl Txn **********************************************/
  getDailyBpclTxn() {

    let body = {
      "account_id": this.fa_id,
      "from_date": `${this.todayDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`

    }

    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getDailyBpclTxn()----->", res);
      console.log("*******");

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && !res["data"]["exc_type"]) {
        this.dailyTransaction = res['data'].expenditure.daily_transaction;
        // this.dailyPendingCashback = (0.4 / 100) * this.dailyTransaction;


        if (res['data'].message != 'No record found') {
          console.log("*******");


          this.dailyBpclTransactionData = res['data'].message.reverse();


          for (let i = 0; i < this.dailyBpclTransactionData.length; i++) {
            let date = this.dailyBpclTransactionData[i]['product_details'].createdDT.slice(0, 10);
            let time = this.dailyBpclTransactionData[i]['product_details'].createdDT.slice(11, 22);
            if (this.dailyBpclTransactionData[i]['transaction_details'].vehicleNumber == "") {
              this.dailyBpclTransactionData[i]['transaction_details'].vehicleNumber = "NA";
            }

            // console.log(date);
            // console.log(time);


            this.dailyBpclTransactionData[i]['product_details']['Date'] = date;
            this.dailyBpclTransactionData[i]['product_details']['Time'] = time;

          }


          this.dailyBpclDatalength = this.dailyBpclTransactionData.length;


          if (this.dailyBpclDatalength == 0) {
            this.noDailyBpclData = true;
          }


          this.isDailyBpclTxn = false;
        }
        else {
          console.log("+++++++++++");
          this.isDailyBpclTxn = false;
          this.noDailyBpclData = true;
        }

      }
      else {
        console.log("+++++++++++");
        this.isDailyBpclTxn = false;
        this.noDailyBpclData = true;
      }

    });

  }


  /*******************************************************************************************/




  /***************************Weekly Bpcl Txn **********************************************/
  getWeeklyBpclTxn() {

    let sevendaysAgoDate;

    let todaydate = new Date();
    var sevendaysback = new Date(new Date().setDate(todaydate.getDate() - 6));
    // console.log("sevendaysback--->", sevendaysback);
    let currentyear = sevendaysback.getFullYear().toString();
    let month = (sevendaysback.getMonth() + 1).toString();
    let day = sevendaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`

    }
    else if (month.length == 1 && day.length == 2) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/${sevendaysback.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      sevendaysAgoDate = sevendaysback.getFullYear().toString() + '0' + (sevendaysback.getMonth() + 1).toString() + '0' + sevendaysback.getDate().toString();
      this.weeklyDate = `${sevendaysback.getFullYear().toString()}/0${(sevendaysback.getMonth() + 1).toString()}/0${sevendaysback.getDate().toString()}`
    }

    // console.log(sevendaysAgoDate);

    let body = {
      "account_id": this.fa_id,
      "from_date": `${sevendaysAgoDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`
    }


    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getWeeklyBpclTxn()----->", res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"] != "ValidationError") {
        this.weeklyTransaction = res['data'].expenditure.weekly_transaction;
        // this.weeklyPendingCashback = (0.4 / 100) * this.weeklyTransaction;

        if (res['data'].message != 'No record found') {

          this.weeklyBpclTransactionData = res['data'].message.reverse();

          for (let i = 0; i < this.weeklyBpclTransactionData.length; i++) {
            let date = this.weeklyBpclTransactionData[i]['product_details'].createdDT.slice(0, 10);
            let time = this.weeklyBpclTransactionData[i]['product_details'].createdDT.slice(11, 22);
            if (this.weeklyBpclTransactionData[i]['transaction_details'].vehicleNumber == "") {
              this.weeklyBpclTransactionData[i]['transaction_details'].vehicleNumber = "NA";
            }
            // console.log(date);
            // console.log(time);


            this.weeklyBpclTransactionData[i]['product_details']['Date'] = date;
            this.weeklyBpclTransactionData[i]['product_details']['Time'] = time;

          }

          this.weeklyBpclDatalength = this.weeklyBpclTransactionData.length
          if (this.weeklyBpclDatalength == 0) {
            this.noWeeklyBpclData = true;
          }
          this.isWeeklyBpclTxn = false;
        }
        else {
          this.isWeeklyBpclTxn = false;
          this.noWeeklyBpclData = true;
        }

      }
      else {
        this.isWeeklyBpclTxn = false;
        this.noWeeklyBpclData = true;
      }

    });







  }


  /*******************************************************************************************/




  /***************************Monthly Bpcl Txn **********************************************/
  getMonthlyBpclTxn() {

    let thirtydaysAgoDate;

    let todaydate = new Date();
    var thirtydaysback = new Date(new Date().setDate(todaydate.getDate() - 31));
    // console.log("sevendaysback--->", thirtydaysback);
    let currentyear = thirtydaysback.getFullYear().toString();
    let month = (thirtydaysback.getMonth() + 1).toString();
    let day = thirtydaysback.getDate().toString();

    if ((month.length == 2 && day.length == 2)) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`;

    }
    else if (month.length == 1 && day.length == 2) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/${thirtydaysback.getDate().toString()}`

    }
    else if (month.length == 2 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }

    else if (month.length == 1 && day.length == 1) {
      thirtydaysAgoDate = thirtydaysback.getFullYear().toString() + '0' + (thirtydaysback.getMonth() + 1).toString() + '0' + thirtydaysback.getDate().toString();
      this.monthlyDate = `${thirtydaysback.getFullYear().toString()}/0${(thirtydaysback.getMonth() + 1).toString()}/0${thirtydaysback.getDate().toString()}`
    }

    // console.log(thirtydaysAgoDate);

    let body = {
      "account_id": this.fa_id,
      "from_date": ` ${thirtydaysAgoDate}T00:00`,
      "to_date": `${this.todayDate}T23:59`

    }


    this._erpService.getBpclTxnHistory(body).subscribe(res => {
      // console.log("getMonthlyBpclTxn()----->", res);

      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"] != "ValidationError") {
        this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
        // this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

        if (res['data'].message != 'No record found') {

          this.monthlyBpclTransactionData = res['data'].message.reverse();
          for (let i = 0; i < this.monthlyBpclTransactionData.length; i++) {
            let date = this.monthlyBpclTransactionData[i]['product_details'].createdDT.slice(0, 10);
            let time = this.monthlyBpclTransactionData[i]['product_details'].createdDT.slice(11, 22);
            if (this.monthlyBpclTransactionData[i]['transaction_details'].vehicleNumber == "") {
              this.monthlyBpclTransactionData[i]['transaction_details'].vehicleNumber = "NA";
            }
            // console.log(date);
            // console.log(time);


            this.monthlyBpclTransactionData[i]['product_details']['Date'] = date;
            this.monthlyBpclTransactionData[i]['product_details']['Time'] = time;

          }

          this.monthlyBpclDatalength = this.monthlyBpclTransactionData.length;

          if (this.monthlyBpclDatalength == 0) {
            this.noMonthlyBpclData = true;
          }
          this.isMonthlyBpclTxn = false;
        }
        else {
          this.isMonthlyBpclTxn = false;
          this.noMonthlyBpclData = true;
        }

      }
      else {
        this.isMonthlyBpclTxn = false;
        this.noMonthlyBpclData = true;
      }

    });



  }


  /*******************************************************************************************/



  /***************************Custon Bpcl Txn **********************************************/

  onCustomBpclTxn() {
    this.customBpclTransactionData = [];
    this.isCustomBpclTxn = true;
    this.customBpclForm.value.fromdate = this.customBpclForm.value.fromdate.replace(/-/g, "");
    this.customBpclForm.value.todate = this.customBpclForm.value.todate.replace(/-/g, "");

    // console.log(this.customFastagForm.value);
    // console.log(this.customFastagForm.value.todate.split());

    if (this.customBpclForm.valid) {
      this.p8 = 1;
      this.isCustomBpclTxn = true;
      this.noCustomBpclData = false;
      // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
      this.getCustomBpclTxn(this.customBpclForm.value);
    }
  }

  getCustomBpclTxn(formvalue) {

    let body = {
      "account_id": this.fa_id,
      "from_date": formvalue.fromdate,
      "to_date": formvalue.todate

    }

    // console.log("Bpcl Custom Txn Body--->", body);

    this.customBpclDatalength = 0;

    this._erpService.getBpclTxnHistory(body).subscribe((res) => {
      // console.log(" getBpclcustomTxn()---->", res);
      if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"] != "ValidationError") {
        if (res['data'].message != 'No record found') {
          // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;
          this.customBpclTransactionData = res['data'].message;


          for (let i = 0; i < this.customBpclTransactionData.length; i++) {
            let date = this.customBpclTransactionData[i]['product_details'].createdDT.slice(0, 10);
            let time = this.customBpclTransactionData[i]['product_details'].createdDT.slice(11, 22);
            if (this.customBpclTransactionData[i]['transaction_details'].vehicleNumber == "") {
              this.customBpclTransactionData[i]['transaction_details'].vehicleNumber = "NA";
            }
            // console.log(date);
            // console.log(time);


            this.customBpclTransactionData[i]['product_details']['Date'] = date;
            this.customBpclTransactionData[i]['product_details']['Time'] = time;

          }
          // console.log(this.customFastagTransactionData);
          this.customBpclDatalength = this.customBpclTransactionData.length;
          if (this.customBpclDatalength == 0) {
            this.noCustomBpclData = true;
          }
          this.isCustomBpclTxn = false;

        }
        else {
          // console.log();
          // this.customFastagDatalength=0;
          this.isCustomBpclTxn = false;
          this.noCustomBpclData = true;
        }




      }
      else {
        // console.log();
        // this.customFastagDatalength=0;
        this.isCustomBpclTxn = false;
        this.noCustomBpclData = true;
      }

    })


  }





//****************************************************************JIo BP Transactions **************************************************************/


/***************************Daily Bpcl Txn **********************************************/
getDailyJioBpTxn() {

  let body = {
    "customer": this.customer,
    "from_date":  this.todaysDate,
    "to_date":  this.todaysDate
  }

  this._erpService.getJioBpTxn(body).subscribe(res => {
    // console.log("getJioBpTxn()----->", res);
    // console.log("*******");

    if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {
      this.dailyTransaction = res['data'].expenditure.daily_transaction;
      // this.dailyPendingCashback = (0.4 / 100) * this.dailyTransaction;


      if (res['data'].message != 'No record found') {
        // console.log("*******");


        this.dailyJioBpTransactionData = res['data'].message.reverse();
        // this.dailyBpclTransactionData=[];
        for(let i=0; i<this.dailyJioBpTransactionData.length;i++){
          let date= this.dailyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
          let time=this.dailyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
          if(this.dailyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
            this.dailyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
          }
          // console.log(date);
          // console.log(time);


          this.dailyJioBpTransactionData[i]['product_details']['Date']=date;
          this.dailyJioBpTransactionData[i]['product_details']['Time']=time;

        }


        this.dailyJioBpDatalength = this.dailyJioBpTransactionData.length;


        if (this.dailyJioBpDatalength == 0) {
          this.noDailyJioBpData = true;
          this.dataLoading=false;
        }


        this.isDailyJioBpTxn = false;
        this.dataLoading=false;
      }
      else {

        this.isDailyJioBpTxn = false;
        this.noDailyJioBpData = true;
        this.dataLoading=false;
      }

    }
    else{

        this.isDailyJioBpTxn = false;
        this.noDailyJioBpData = true;
        this.dataLoading=false;
    }

  });

}


/*******************************************************************************************/



/***************************Weekly Bpcl Txn **********************************************/
getWeeklyJioBpTxn() {


  // console.log(sevendaysAgoDate);

  let body = {
    "customer": this.customer,
    "from_date":  this.weeklyDate,
    "to_date":  this.todaysDate
  }


// console.log(body)
  this._erpService.getJioBpTxn(body).subscribe(res => {
    // console.log("getJioBpTxn()----->", res);

    if (res['errorCode'] == '0' &&res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message ) {

      if(res['data'].expenditure.weekly_transaction!=null){
        this.weeklyTransaction = res['data'].expenditure.weekly_transaction;
      }

      // this.weeklyPendingCashback = (0.4 / 100) * this.weeklyTransaction;

      if (res['data'].message != 'No record found') {

        this.weeklyJioBpTransactionData = res['data'].message.reverse();

        for(let i=0; i<this.weeklyJioBpTransactionData.length;i++){
          let date= this.weeklyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
          let time=this.weeklyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
          if(this.weeklyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
            this.weeklyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
          }
          // console.log(date);
          // console.log(time);


          this.weeklyJioBpTransactionData[i]['product_details']['Date']=date;
          this.weeklyJioBpTransactionData[i]['product_details']['Time']=time;

        }

        this.weeklyJioBpDatalength = this.weeklyJioBpTransactionData.length
        if (this.weeklyJioBpDatalength == 0) {
          this.noWeeklyJioBpData = true;
        }
        this.isWeeklyJioBpTxn = false;
      }
      else {
        this.isWeeklyJioBpTxn = false;
        this.noWeeklyJioBpData = true;
      }

    }
    else {
      this.isWeeklyJioBpTxn = false;
      this.noWeeklyJioBpData = true;
    }

  });







}


/*******************************************************************************************/

/***************************Monthly Bpcl Txn **********************************************/
getMonthlyJioBpTxn() {


  // console.log(thirtydaysAgoDate);

  let body = {
    "customer": this.customer,
    "from_date":  this.monthlyDate,
    "to_date":  this.todaysDate
  }

  // console.log(body)
  this._erpService.getJioBpTxn(body).subscribe(res => {
    // console.log("getMonthlyBpclTxn()----->", res);

    if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message  ) {
      this.monthlyTransaction = res['data'].expenditure.monthly_transaction;
      // this.monthlyPendingCashback = (0.4 / 100) * this.monthlyTransaction;

      if (res['data'].message != 'No record found') {

        this.monthlyJioBpTransactionData = res['data'].message.reverse();
        for(let i=0; i<this.monthlyJioBpTransactionData.length;i++){
          let date= this.monthlyJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
          let time=this.monthlyJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
          if(this.monthlyJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
            this.monthlyJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
          }
          // console.log(date);
          // console.log(time);


          this.monthlyJioBpTransactionData[i]['product_details']['Date']=date;
          this.monthlyJioBpTransactionData[i]['product_details']['Time']=time;

        }

        this.monthlyJioBpDatalength = this.monthlyJioBpTransactionData.length;

        if (this.monthlyJioBpDatalength == 0) {
          this.noMonthlyJioBpData = true;
        }
        this.isMonthlyJioBpTxn = false;
      }
      else {
        this.isMonthlyJioBpTxn = false;
        this.noMonthlyJioBpData = true;
      }

    }
    else {
      this.isMonthlyJioBpTxn = false;
      this.noMonthlyJioBpData = true;
    }

  });



}


/*******************************************************************************************/

/***************************Custon Bpcl Txn **********************************************/

onJioBpCustomTxn() {
this.customJioBpTransactionData=[];
// this.customTimeFilterDetails=[]
this.isCustomJioBpTxn=true;
this.customJioBpForm.value.fromdate = this.customJioBpForm.value.fromdate.replace(/-/g, "/");
this.customJioBpForm.value.todate = this.customJioBpForm.value.todate.replace(/-/g, "/");

// console.log(this.customFastagForm.value);
// console.log(this.customFastagForm.value.todate.split());

if (this.customJioBpForm.valid) {
  this.p4=1;
  this.isCustomJioBpTxn = true;
  this.noCustomJioBpData = false;
  // this.getCustomFastagTxnEncryptDetails(this.customFastagForm.value);
  this.getCustomJioBpTxn(this.customJioBpForm.value);
}
}



getCustomJioBpTxn(formvalue) {

let body = {
  "customer": this.customer,
  "from_date": formvalue.fromdate,
  "to_date": formvalue.todate

}

// console.log("Jio Bp  Custom Txn Body--->", body);

this.customJioBpDatalength = 0;

this._erpService.getJioBpTxn(body).subscribe((res) => {
  // console.log(" getCustomJioBpTxn()---->", res);
  if (res['errorCode'] == '0' && res['data'].message != 'No record found' && res["data"]["exc_type"]!="ValidationError" && res['data'].message) {
    if (res['data'].message != 'No record found') {
      // this.monthlyPendingCashback=(0.4/100)*this.monthlyTransaction;

      this.customJioBpTransactionData = res['data'].message;
      let timefilter=this.customJioBpForm.value.time.slice(0,2);
      console.log(timefilter);


      for(let i=0; i<this.customJioBpTransactionData.length;i++){
        let date= this.customJioBpTransactionData[i]['product_details'].createdDT.slice(0,10);
        let time=this.customJioBpTransactionData[i]['product_details'].createdDT.slice(11,22);
        // console.log(date);
        // console.log(time);


        this.customJioBpTransactionData[i]['product_details']['Date']=date;
        this.customJioBpTransactionData[i]['product_details']['Time']=time;
        if(this.customJioBpTransactionData[i]['transaction_details'].vehicleNumber==""){
          this.customJioBpTransactionData[i]['transaction_details'].vehicleNumber="NA";
        }




      }


      this.customJioBpDatalength = this.customJioBpTransactionData.length;
      if (this.customJioBpDatalength == 0) {
        this.noCustomJioBpData = true;
      }
      this.isCustomJioBpTxn = false;

    }
    else {

      this.isCustomJioBpTxn = false;
      this.noCustomJioBpData = true;
    }




  }
  else {

    this.isCustomJioBpTxn = false;
    this.noCustomJioBpData = true;
  }

})


}






//***************************************************************END JIo BP Transactions **************************************************************/
  rechargeTxn(){
    this.searchString=""
  }
  bpclTxn(){
    this.searchString=""
  }
  fastagTxn(){
    this.searchString=""
  }
  jioBpTxn(){
    this.searchString=""
  }

  //recharge history
  bpclRecharHistorytab(){
    this.searchString=""
  }
  ioclRecharHistorytab(){
    this.searchString=""
  }
  fastagRecharHistorytab(){
    this.searchString=""
  }
  jioBpRecharHistorytab(){
    this.searchString=""
  }


// Bpcl
  dailyBpclTxn(){
    this.searchString=""
  }

  weeklyBpclTxn(){
    this.searchString=""
  }

  monthlyBpclTxn(){
    this.searchString=""
  }


  customBpclTxn(){
    this.searchString=""
  }


// Fastag
  dailyFastagTxn(){
    this.searchString=""
  }

  weeklyFastagTxn(){
    this.searchString=""
  }

  monthlyFastagTxn(){
    this.searchString=""
  }


  customFastagTxn(){
    this.searchString=""
  }


  //jioBp
  dailyJioBPTxn(){
    this.searchString=""
  }

  weeklyJioBpTxn(){
    this.searchString=""
  }

  monthlyJioBpTxn(){
    this.searchString=""
  }


  customJioBpTxn(){
    this.searchString=""
  }









}
